import React, { memo } from 'react';
import styled from 'styled-components';
//styles & configs
import { BreakPoints } from 'src/configs/StyleConfig';
import { SearchFieldContainer, CheckboxContainer } from 'src/common/StylesComponents';
//components
import Checkbox from 'src/common/Checkbox';
import SearchField from 'src/common/SearchField';

const dateSortingOption = [
  { value: 0, label: '登録日時（新）' },
  { value: 1, label: '登録日時（古）' },
];

const CustomerListSearchField = memo((props) => {
  const { setSearchText, searchText, onSearchClicked, onSearchClear, checkedState, setCheckedState, dateSortSelected, selectedDateType } = props;

  return (
    <>
      <CustomerListSearchFieldStyle>
        <SearchContainer>
          <CheckBoxesContainer>
            <CheckboxContainer>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[0]}
                  changeStatus={() => setCheckedState(0)}
                  label={'対応不可'}
                />
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[1]}
                  changeStatus={() => setCheckedState(1)}
                  label={'信販不可'}
                />
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[2]}
                  changeStatus={() => setCheckedState(2)}
                  label={'転居'}
                />
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[3]}
                  changeStatus={() => setCheckedState(3)}
                  label={'クレーム'}
                />
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[4]}
                  changeStatus={() => setCheckedState(4)}
                  label={'保点有'}
                />
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[5]}
                  changeStatus={() => setCheckedState(5)}
                  label={'工事予定'}
                />
              </CheckBoxWrapper>
            </CheckboxContainer>
          </CheckBoxesContainer>
          <SearchInputContainer>
            <SearchFieldStyle>
              <SearchField placeholder={'フリーワード検索 例）田中 栃木県'} value={searchText} onChange={({ target: { value } }) => setSearchText(value)} />
            </SearchFieldStyle>
            <SearchButton type="submit" onClick={() => onSearchClicked()}><i className="fa fa-search"></i></SearchButton>
            <ShowAllButton onClick={onSearchClear}>全て表示</ShowAllButton>
          </SearchInputContainer>
        </SearchContainer>
      </CustomerListSearchFieldStyle>
      <div style={{ display: 'flex', margin: '12px 0px 0px auto' }}>
        <span style={{ color: '#3f3f3f', fontWeight: 'bold', padding: '6px 8px' }}>並び替え</span>
        <select value={selectedDateType} style={{ height: "28px", margin: '4px 36px 0px 4px' }} onChange={(e) => dateSortSelected(e)}>
          {dateSortingOption.map((key, index) => {
            return (
              <option key={index} value={key.value}>{key.label}</option>
            )
          })}
        </select>
      </div>
    </>
  )
});

const CustomerListSearchFieldStyle = styled(SearchFieldContainer)`
  min-width: 680px;
  padding: 8px 0px;
  @media screen and (max-width: ${BreakPoints.md}) {
    flex-direction: column;
    overflow-x: auto;
    display: inline;
  }
`
const SearchFieldStyle = styled.div`
  width: 280px;
  padding-right: 12px;
  @media screen and (max-width: ${BreakPoints.xl}) {
    width: 360px;
    padding-right: 12px;
  }
`

const CheckBoxesContainer = styled.div`
  @media screen and (max-width: ${BreakPoints.md}) {
    display: flex;
    flex-direction: row;
  }
`

const CheckBoxWrapper = styled.div`
  min-width: 60px;
`

const SearchButton = styled.button`
  width: 60px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 8px 24px;
  margin: 2px 4px;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

const ShowAllButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 8px 20px;
  font-weight: bold;
  margin: 2px 4px;
  transition: 0.3s;
  :hover {
    color: #1d1d1f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`
const SearchContainer = styled.div`
  color: #3f3f3f;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  background-color: white;
  padding: 36px 40px;
  margin: 30px auto 0px auto;
  border: 1px solid #dedede;
  border-radius: 6px;
  @media screen and (max-width: ${BreakPoints.xl}) {
    max-width: 960px;
    min-width: 660px;
    width: 80%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
`

export const SearchInputContainer = styled.div`
  color: #3f3f3f;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 4px 40px;
`

export default CustomerListSearchField;