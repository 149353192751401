import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
//configs & styles
import 'src/index.css';
import { Colors, FontSize, BreakPoints } from 'src/configs/StyleConfig';

import { statuses } from 'src/common/StatusMapping';

const CustomerSupportStatus = (props) => {
  const {customerInfo} = props;

  const InfoSection = (props) => {
    const { label, info } = props;

    return (
      <Row>
        <LabelStyle>
          {label}
        </LabelStyle>
        <InfoStyle>
          {info}
        </InfoStyle>
      </Row>
    )
  };

  return (
    <Container>
      <StatusContainer>
        {
          customerInfo.status.map((item) => {
            if (item.isSelected) {
              return (
                <CustomerStatusContainer color={statuses[item.id].color} selected={true} key={item.id.toString()}>
                  {item.status}
                </CustomerStatusContainer>
              )
            }
            return null
          })
        }
      </StatusContainer>
      <StatusInfoContainer>
        <InfoContainer>
          <InfoWrapper>
            <DataContainer>
              <InfoSection label={'顧客No'} info={customerInfo.customer_code} />
            </DataContainer>
            <DataContainer>
              <InfoSection label={'会社'} info={customerInfo.enterprise} />
              <InfoSection label={'営業先'} info={customerInfo.sales_office} />
            </DataContainer>
          </InfoWrapper>
          <InfoWrapper>
            <DataContainer>
              <InfoSection info={customerInfo.customer_kana} />
              <InfoSection info={customerInfo.customer_name} />
            </DataContainer>
            <DataContainer>
              <InfoSection info={customerInfo.customer_address1} />
              <InfoSection info={customerInfo.customer_postal_code} />
            </DataContainer>
            <DataContainer>
              <InfoSection info={customerInfo.customer_phone1} />
            </DataContainer>
          </InfoWrapper>
        </InfoContainer>
      </StatusInfoContainer>
    </Container>
  )
};


const Container = styled.div`
  min-height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`
const StatusContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const CustomerStatusContainer = styled.div`
  width: 150px;
  padding: 8px 12px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
  color: ${props => props.color && props.color};
  z-index:1;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
  border: 2.5px solid ${props => props.color && props.color};
`
const StatusInfoContainer = styled.div`
  min-height: 60px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  border-top: 2.5px solid gray;
  border-bottom: 2.5px solid gray;
`
const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const InfoWrapper = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: ${FontSize.sm};
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 80%;
    flex-direction: column;
    padding-left: 60px;
    align-items: flex-start;
    justify-content: center;
  }
`
const DataContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const LabelStyle = styled.span`
  width: 60px;
  padding-right: 12px;
`
const InfoStyle = styled.div`

`

CustomerSupportStatus.propTypes = {
  customerInfo: PropTypes.object.isRequired,
};

CustomerSupportStatus.defaultProps = {
  customerInfo: {},
};

export default CustomerSupportStatus;