import axios from 'axios';
import { LOGIN_URL } from 'src/apis';
//functions
import GeneralFunctions from './GeneralFunctions';

/*
ユーザーログインにまつわるクラス
- Parameters in constructor:
 - accountName: アカウント名
 - password: パスワード
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: ログイン成功時に返ってくるデータ（token, enterprise_idなど）
*/
class AuthFunctions {

  constructor(props) {
    const { accountName, password } = props;
    this.accountName = accountName;
    this.password = password;
    this.response = { error: '', data: {} };
  };

  /*
    private method
    必須項目、電話番号、提携先選択とマージンチェック
  */
  _loginValidation() {
    if (this.accountName.length === 0 || this.password.length === 0) return 'アカウント名とパスワードを入力してください。';
    return;
  }

  /*
    private method
    入力されたユーザー名とパスワードをBodyに入れてAPIを呼び出し
    成功時：
    ログインユーザーに関する情報を返す
    失敗時：
    エラーを返す
  */
  async _sendUserData() {
    try {
      const json = {
        account: this.accountName,
        password: GeneralFunctions.cryptHash(this.password)
      };

      await axios.post(LOGIN_URL, json)
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error('bad request');
          }
        }).then(({ data }) => {
          if (data[0]) {
            this.response.data = data[0];
            return;
          } else {
            throw new Error('アカウント名とパスワードが一致しませんでした。');
          }
        })
    } catch (error) {
      return 'アカウント名とパスワードが一致しませんでした。';
    }
  }

 /*
    public method
    外部から呼び出す関数
  */
  async login() {
    let error = this._loginValidation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    error = await this._sendUserData();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    return this.response;
  };
}

export default AuthFunctions;