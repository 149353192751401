import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import MtbEmployeeInputs from './children/MtbEmployeeInputs';
import PasswordConfirmationModal from './children/PasswordConfirmationModal';
import DialogMessage from 'src/common/DialogMessage';
//functions
import EmployeeFunctions from 'src/functions/mtbFunctions/EmployeeFunctions';
import GeneralFunctions from 'src/functions/GeneralFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  従業員編集コンポーネント
  保存ボタンからDBに送信
  キャンセルボタンから詳細へ推移
*/
const MtbEmployeeEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [employeeData, setEmployeeData] = useState({});
  const [errorText, setErrorText] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmModalRequired, setConfirmModalRequired] = useState(false);

  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath} = state;
      data["entered_password"] = '';
      data["updated_employee_name"] = employee_name;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _employeeData = getData();
      setEmployeeData({..._employeeData});
    }
  }, [employee_name, props]);

  const changeEmployeeData = useCallback((key, value) => {
    const _employeeData = {...employeeData};
    if (value !== null) {
      _employeeData[key] = value;
      setEmployeeData(_employeeData);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [employeeData, modalRequired]);

  const changeConfirmedPassword = useCallback((val) => {
    const {value} = val.target
    setConfirmedPassword(value);
  }, []);

  const closePasswordConfirmModal = () => {
    setConfirmedPassword('');
    setConfirmModalRequired(false);
  };

  /*
    確認用パスワード記入ダイアログメッセージでパスワード記入後に確認ボタンを押すと呼び出される関数
    もし確認用パスワードが記入している従業員のパスワードと一致していたら従業員のデータをアップデート
  */
  const onConfirmButtonPressed = useCallback(() => {

    const handleUpdate = async () => {
      const employeeFunctions = new EmployeeFunctions({ employeeData: employeeData, token: token });
      const response = await employeeFunctions.sendData();
      if (response.error) {
        setErrorText(response.error);
      } else {
        modalRequired.current = false;
        setErrorText('');
        history.replace({pathname: previousPath.current, state: {data: response.data}});
      }
    };

    const encryptedPassword = GeneralFunctions.cryptHash(confirmedPassword);
    const {password} = employeeData;
    if (encryptedPassword === password) {
      setErrorText('');
      handleUpdate();
    } else {
      setErrorText('確認用パスワードが一致しませんでした。');
    }
    closePasswordConfirmModal();
  }, [employeeData, confirmedPassword, token, history, previousPath]);



  const onSaveButtonPressed = useCallback(() => {
    //確認用パスワード記入ダイアログメッセージを表示
    setConfirmModalRequired(true);
  }, [setConfirmModalRequired]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <PasswordConfirmationModal
        modalRequired={confirmModalRequired}
        closeConfirmModal={() => closePasswordConfirmModal()}
        confirmedPassword={confirmedPassword}
        changeConfirmedPassword={changeConfirmedPassword}
        onConfirmButtonPressed={onConfirmButtonPressed}
      />
      <MtbEmployeeInputs 
        data={employeeData}
        onChange={changeEmployeeData}
        isEditing={true}
        errorText={errorText}
        onSaveButtonPressed={onSaveButtonPressed}
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  );
};

export default MtbEmployeeEdit;