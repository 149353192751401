import axios from "axios";
//functions
import GeneralFunctions from "../GeneralFunctions";
//apis
import { MTB_TAX_DATA_CHANGE_URL } from "src/apis";

const inputRequiredField = {
  start_date: '適用開始日', tax_rate: '消費税率'
};

/*
消費税率設定　消費税率の登録/編集クラス
- Parameters in constructor:
 - taxData: 消費税率データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセス時のエラー
 - data: DBに登録/編集したデータ
*/
class TaxFunctions {
  constructor(props) {
    const { taxData, token } = props;
    this.taxData = taxData;
    this.token = token;
    this.response = { error: '', data: {} };
  }

  /*
    private method
    (1) 必須項目チェック:  start_date: '適用開始日', tax_rate: '消費税率'
  */
  _validation() {
    const data = {...this.taxData};
    let errors = [];
    //(1)
    Object.keys(inputRequiredField).forEach((key) => {
      if (GeneralFunctions.isNullOrEmpty(data[key])) {
        errors.push(inputRequiredField[key]);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力してください。";
    };

    return;
  }

  /*
    private method
    APIアクセスメソッド
  */
  async _send() {
    try {
      const json = [this.taxData];
      this.response.data = this.taxData;
      
      await axios.post(MTB_TAX_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
        .then((response) => {
          if (response.status === 200) {
            return;
          } else {
            throw new Error('商品を登録/編集できませんでした。');
          }
        })
    } catch (error) {
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
    登録/編集した消費税率データもしくはエラーを返す
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    error = await this._send();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }
}

export default TaxFunctions;