import axios from 'axios';
import { LOG_CHANGE_URL, LOG_URL, FETCH_URL } from 'src/apis';

//テーブル分類（0:保証ログ、1:顧客ログ、2:工事ログ、3:対応履歴ログ）
export const logTableType = { 
  warranty: 0,
  customer: 1,
  construction: 2,
  customerSupport: 3
};

// 分類（0:新規登録、1:更新、2:削除、3:印刷、4:csv出力）
export const logCategory = {
  register: 0,
  update: 1,
  delete: 2,
  print: 3,
  csvExport: 4
}

// DBのテーブル名（1:顧客ログ、2:工事ログ、3:顧客対応）
const tableTypeToTableName = {
  1: '/mtb_customer_log',
  2: '/dtb_construction_log',
  3: '/dtb_customer_support_log'
}

/*
  全体ログに新規ログを登録する関数
  - Parameters:
   - json: 全体ログで指定されたフォーマット（/dtb_logsのカラム）のJsonデータ
   - token: APIアクセストークン
*/
export const sendLogData = async (json, token) => {
  try {
    const response = await axios.post(LOG_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${token}`} });
    if (response.status === 200) return;
    throw new Error('Failed to log the record');
  } catch (error) {
    return 'Failed to log the record';
  }
};

/*
  全体ログのデータを取得する関数
  - Parameters:
   - mtb_enterprise_id: 企業Id
   - token: APIアクセストークン
*/
export const getLogData = async (mtb_enterprise_id, token) => {
  try {
    const response = await axios.get(LOG_URL, {
      params: {
        mtb_enterprise_id: mtb_enterprise_id
      },
      headers: {"Authorization" : `Bearer ${token}`}
    });
    if (response.status !== 200) throw new Error('Failed to log the record');
    const { data } = response;
    return data;
  } catch (error) {
    console.log(error.message);
    return [];
  }
}

/*
  全体ログの中から選択された変更内容ログを表示する関数
  - Parameters:
   - table_type: 選択されたログのテーブル名
   - log_id: 選択されたログのId
   - token: APIアクセストークン
*/
export const getUpdatedLogContent = async (table_type, log_id, token) => {
  const url = `${FETCH_URL}${tableTypeToTableName[table_type]}`;
  const response = await axios.get(url, {
    params: {
      id: log_id
    },
    headers: {"Authorization" : `Bearer ${token}`}
  });
  const { data } = response;
  if (data) {
    return data;
  }
  return;
}