import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation} from 'react-router-dom';
//components
import CustomerSupportInput from './children/CustomerSupportInput';
import DeleteConfirmModal from 'src/views/settings/children/DeleteConfirmModal';
//functions
import DeletingFunctions from 'src/functions/DeletingFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  対応履歴（内容、その他１、その他２）の詳細ページ
  編集からそれぞれの編集ページへ推移（全てMtbCustomerSupportEditで統一）
  消去ボタンから消去確認モダル表示後に確認で対応履歴データを消去、リストに戻る
*/
const MtbCustomerSupportDetail = (props) => {

  const history = useHistory();
  const location = useLocation();
  const { token } = useSelector(state => state.auth);
  const [supportData, setSupportData] = useState({});
  const [errorText, setErrorText] = useState('');
  const [modalRequired, setModalRequired] = useState(false);
  const category = useRef(null);
  const previousPath = useRef(null);

  /*
    対応履歴（内容、その他１、その他２）データ、
    それぞれのタグタイトルを格納
  */
  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const data = state && state.data;
      const _category = state && state.category;
      category.current = _category;
      if (_category === 0) {
        document.title = '対応履歴 内容 詳細 | FTS顧客管理システム';
        previousPath.current = '/settings/customer-support/content';
      } else if (_category === 1) {
        document.title = '対応履歴 その他1 詳細 | FTS顧客管理システム';
        previousPath.current = '/settings/customer-support/other1';
      } else {
        document.title = '対応履歴 その他2 詳細 | FTS顧客管理システム';
        previousPath.current = '/settings/customer-support/other2';
      }
      return data;
    };
    const _supportData = getData();
    setSupportData({..._supportData});
  }, [props]);

  const onEditButtonPressed = useCallback(() => {
    const {pathname, state} = location;
    history.push({pathname: `${pathname}/edit`, state: {data: state.data, prevPath: pathname, category: category.current}});
  }, [history, location, category]);

  const onDeleteButtonPressed = useCallback(() => {
    setModalRequired(true);
  }, []);

  const deleteSelectedItem = useCallback(async () => {
    const { id } = supportData;
    const tableName = '/mtb_customer_support';
    const deletingFunctions = new DeletingFunctions({tableName: tableName, id: id, token: token});
    const error = await deletingFunctions.delete();
    if (error) {
      setErrorText(error);
    } else {
      setErrorText('');
      history.replace(previousPath.current);
    }
  }, [history, previousPath, token, supportData]);

  return (
    <>
      <DeleteConfirmModal
        modalRequired={modalRequired}
        onCloseModalButtonPressed={() => setModalRequired(false)}
        onConfirmButtonPressed={deleteSelectedItem}
      />
      <CustomerSupportInput data={supportData} isEditing={false} errorText={errorText} onEditButtonPressed={onEditButtonPressed} onDeleteButtonPressed={onDeleteButtonPressed} />
    </>
  )
};

export default MtbCustomerSupportDetail;