import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Overlay } from 'src/common/StylesComponents';
import { Colors, FontSize } from "src/configs/StyleConfig";

const DialogMessage = (props) => {

  const { modalRequired, text } = props;
  const history = useHistory();

  const [contentTexts, setContentTexts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const setText = () => {
      const _text = text.split('\n');
      setContentTexts(_text);
    };

    setText();
  }, [text]);

  const closeModal = useCallback((e) => {
    setIsModalOpen(false);
    e.stopPropagation()
  }, []);

  const onConfirmButtonPressed = useCallback((e) => {
    setIsModalOpen(false);
    modalRequired.current = false;
    history.goBack();
    e.stopPropagation();
  }, [modalRequired, history]);

  const handleBlockingRoute = useCallback((location) => {
    if (modalRequired.current) {
      setIsModalOpen(true);
      return false;
    }
    return true;
  }, [modalRequired]);

  return (
    <>
      <Prompt when={true} message={handleBlockingRoute} />
      {isModalOpen && (
        <Overlay onClick={closeModal}>
          <ModalWrapper>
            <CustomModalBody>
              {
                contentTexts && contentTexts.map((text, index) => <div key={index.toString()}>{text}</div>)
              }
            </CustomModalBody>
            <ButtonWrapper>
              <ModalButton onClick={closeModal}>キャンセル</ModalButton>
              <ModalButton Color={Colors.modalButton} onClick={onConfirmButtonPressed}>続行</ModalButton>
            </ButtonWrapper>
          </ModalWrapper>
        </Overlay>
      )}
    </>
  )
};


const ModalWrapper = styled.div`
  width: 420px;
  height: 160px;
  border-radius: 12px;
  padding: 20px;
  background-color: white;
  font-size: ${FontSize.md};
  color: ${Colors.textColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const CustomModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
  font-weight: 500;
  padding-left: 8px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  button + button {
    margin-left: 1rem;
  }
`
const ModalButton = styled.button`
  width: 100px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: ${props => props.Color ? props.Color : 'white'};
  color: ${props => props.Color ? 'white' : Colors.textColor};
`
DialogMessage.propTypes = {
  text: PropTypes.string.isRequired,
  modalRequired: PropTypes.object.isRequired
};
DialogMessage.defaultProps = {
  text: '',
  modalRequired: {}
};

export default DialogMessage;