import axios from "axios";
//functions
import GeneralFunctions from "../GeneralFunctions";
//apis
import { MTB_PRODUCT_DATA_CHANGE_URL } from "src/apis";

const inputRequiredField = {
  product_name: '商品名', unit: '単位', price: '単価'
};

/*
商品設定　商品の登録/編集クラス
- Parameters in constructor:
 - productData: 商品データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセス時のエラー
 - data: DBに登録/編集したデータ
*/
class ProductFunctions {
  constructor(props) {
    const { productData, token } = props;
    this.productData = productData;
    this.token = token;
    this.response = { error: '', data: {} };
  }

  /*
    private method
    必須項目チェック: product_name: '商品名', unit: '単位', price: '単価'
  */
  _validation() {
    const data = {...this.productData};
    let errors = [];
    Object.keys(inputRequiredField).forEach((key) => {
      if (GeneralFunctions.isNullOrEmpty(data[key])) {
        errors.push(inputRequiredField[key]);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力・選択してください。";
    };

    return;
  }

  /*
    private method
    APIアクセスメソッド
  */
  async _send() {
    try {
      const json = [this.productData];
      this.response.data = this.productData;
      
      await axios.post(MTB_PRODUCT_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
        .then((response) => {
          if (response.status === 200) {
            return;
          } else {
            throw new Error('商品を登録/編集できませんでした。');
          }
        })
    } catch (error) {
      return error.message;
    }
  }
  /*
    public method
    外部から呼び出す関数
    登録/編集した商品データもしくはエラーを返す
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    error = await this._send();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }
}

export default ProductFunctions;