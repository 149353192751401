import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import StoreInputs from './children/StoreInputs';
import DialogMessage from 'src/common/DialogMessage';
//redux
import { useSelector } from 'react-redux';
//functions
import StoreFunctions from 'src/functions/mtbFunctions/StoreFunctions';

/*
  店舗詳細編集コンポーネント
  テキスト入力後、
  保存ボタンから店舗データをDBに格納
  キャンセルボタンで入力情報を破棄してリストに戻る
*/
const MtbStoreEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [store, setStore] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  /*
    店舗データに編集者名（ログインユーザー名）を格納
  */
  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath} = state;
      data["updated_employee_name"] = employee_name;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _store = getData();
      setStore({..._store});
    }
  }, [employee_name, props]);

  /*
    stateの編集
  */
  const changeStoreData = useCallback((key, value) => {
    const _store = {...store};
    _store[key] = value;
    setStore(_store);
    if (!modalRequired.current) modalRequired.current = true;
  }, [store, modalRequired]);

  /*
    保存ボタンから店舗データをDBに格納
  */
  const onSaveButtonPressed = useCallback(async () => {
    const storeFunctions = new StoreFunctions({ storeData: store, token: token });
    const response = await storeFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current, state: {data: response.data}});
    }
  }, [store, token, history, modalRequired, previousPath]);

  /*
    キャンセルボタンで入力情報を破棄してリストに戻る
  */
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <StoreInputs 
        data={store}
        isEditing={true}
        errorText={errorText}
        onChangeData={changeStoreData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbStoreEdit;