import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { SubmitButton, CancelButton } from 'src/common/StylesComponents';

Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '500px',
    height: '150px',
    top: '50%',
    marginTop: '-100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
};

const ConfirmModal = (props) => {
  const { modalState, titleText, onConfirmButtonPressed, onCancelButtonPressed } = props;

  return (
    <Modal isOpen={modalState} style={modalStyle}>
      <span>{titleText}</span>
      <div className="field is-grouped is-grouped-right">
        <div className="control" style={{ paddingTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <SubmitButton onClick={onCancelButtonPressed}>キャンセル</SubmitButton>
          <CancelButton onClick={onConfirmButtonPressed}>消去</CancelButton>
        </div>
      </div>
    </Modal>
  )
};

ConfirmModal.propTypes = {
  modalState: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  onConfirmButtonPressed: PropTypes.func.isRequired,
  onCancelButtonPressed: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  modalState: null,
  titleText: '',
  onConfirmButtonPressed: () => { },
  onCancelButtonPressed: () => { }
};

export default memo(ConfirmModal);