import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
//components
import { Overlay, SearchInputContainer } from 'src/common/StylesComponents';
import SearchField from 'src/common/SearchField';
import Checkbox from "src/common/Checkbox";
//configs and styles
import { Colors, FontSize } from "src/configs/StyleConfig";
//redux
import { useSelector } from "react-redux";

const columnNames = {
  product_code: { label: 'CD', width: '4%', align: 'left' },
  product_name: { label: '商品名', width: '12%', align: 'left' },
  unit: { label: '単位', width: '4%', align: 'center' },
  price: { label: '単価価格', width: '8%', align: 'right' },
  is_favorite: { label: 'お気に入り', width: '8%', align: 'center' },
}


const ProductSelectionModal = (props) => {

  const { setIsModalOpen, onProductSelected } = props;
  const { productData } = useSelector(state => state.master);
  const [filteredProductData, setFilteredProductData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [onlyFavoriteProduct, setOnlyFavoriteProduct] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const onSearchClicked = useCallback(() => {
    //検索ボタン押すと商品のフィルタリング：オン
    if (searchText) setIsSearching(true);
  }, [searchText]);

  const onChangeSearchText = useCallback((val) => {
    const value = val.target.value ? val.target.value : '';
    setSearchText(value);

    //テキストが空になった時かつお気に入り商品トグルがオフトの時（ユーザーがテキスト全消去した時)フィルタリング：オフ→全表示
    if (value === '' && !onlyFavoriteProduct) setIsSearching(false);
  }, [onlyFavoriteProduct]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onCheckBoxClicked = useCallback(() => {
    if (onlyFavoriteProduct) {
      setOnlyFavoriteProduct(false);
      //テキストが空になった時かつお気に入り商品トグルがオフトの時（ユーザーがテキスト全消去した時)フィルタリング：オフ→全表示
      if (searchText === '') setIsSearching(false);
    } else {
      setOnlyFavoriteProduct(true);
      setIsSearching(true);
    }

  }, [onlyFavoriteProduct, searchText]);

  const resetProductFilter = () => {
    if (!isSearching) {
      //全表示(リセット)
      setFilteredProductData({ ...productData });
    } else {
      let data = {};
      if (onlyFavoriteProduct) {
        //検索テキストとお気に入り商品でフィルター
        Object.keys(productData).forEach((id) => {
          if (productData[id].is_favorite) {
            const { product_name } = productData[id];
            if (String(product_name).indexOf(searchText) !== -1) {
              data[id] = productData[id];
            }
          }
        });
      } else {
        //検索テキストのみでフィルター
        Object.keys(productData).forEach((id) => {
          const { product_name } = productData[id];
          if (String(product_name).indexOf(searchText) !== -1) {
            data[id] = productData[id];
          }
        });
      }
      setFilteredProductData({ ...data });
    }
  };
  useMemo(resetProductFilter, [isSearching, onlyFavoriteProduct]);

  const onRowClicked = (index) => {
    //テーブル行タップで着火：親コンポーネントに選択された商品index(idがkeyになってるので実質Id)を渡し、モダルを閉じる
    onProductSelected(index);
    setIsModalOpen(false);
  }

  const Row = (props) => {
    const { row, index } = props;
    return (
      <Tr onClick={() => onRowClicked(index)}>
        {
          Object.keys(columnNames).map((columns) => {
            return (
              <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                {row[columns]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <>
      <Overlay onClick={closeModal}>
        <ModalWrapper onClick={(e) => { e.stopPropagation() }}>
          <CustomModalBody>
            <SearchInputContainer>
              <span style={{ color: 'gray' }}>
                商品選択
              </span>
              <SearchFieldStyle>
                <SearchField placeholder={'商品検索欄'} value={searchText} onChange={onChangeSearchText} />
                <SearchButton type="submit" onClick={onSearchClicked}><i className="fa fa-search"></i></SearchButton>
              </SearchFieldStyle>
              <div style={{ width: '100%', display: 'flex', }}>
                <Checkbox
                  isChecked={onlyFavoriteProduct}
                  changeStatus={onCheckBoxClicked}
                  label={'お気に入りの商品のみ表示'}
                  fontSize={12}
                  type={'checkbox'} // radio or checkbox
                />
              </div>
            </SearchInputContainer>
            <Table>
              <Thead>
                <tr>
                  {
                    Object.keys(columnNames).map((cName) => {
                      return (
                        <Th width={columnNames[cName].width} align={columnNames[cName].align} key={cName} >{columnNames[cName].label}</Th>
                      );
                    })
                  }
                </tr>
              </Thead>
              <TableBody>
                {
                  ///工事内訳テーブル
                  Object.keys(filteredProductData).map((row) => {
                    return <Row row={filteredProductData[row]} index={row} key={row.toString()} />
                  })
                }
              </TableBody>
            </Table>
          </CustomModalBody>
        </ModalWrapper>
      </Overlay>
    </>
  )
};


const ModalWrapper = styled.div`
  width: 420px;
  border-radius: 12px;
  padding: 20px;
  background-color: white;
  font-size: ${FontSize.md};
  color: ${Colors.textColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const CustomModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 340px;
  overflow-y: auto;
  margin-top: 1.5rem;
  font-weight: 500;
  padding-left: 8px;
`
const SearchFieldStyle = styled.div`
  max-width: 600px;
  width: 90%;
  min-width: 360px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const SearchButton = styled.button`
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 24px;
  margin: 2px 4px;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`
const Table = styled.table`
  width: 95%;
  min-width: 400px;
  min-height: 200px;
  border-collapse: collapse;
  padding: 20px auto;
`
const Th = styled.th`
  color: ${Colors.textColor};
  font-size: ${FontSize.sm};
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
  padding: 4px;
`
const Thead = styled.thead`
  width: 100%;
  table-layout: fixed;
  display: table;
`
const TableBody = styled.tbody`
  border: 1px solid gray;
  width: 100%;
  min-height: 120px;
  overflow: auto;
  display: block;
`
const Tr = styled.tr`
  width: 100%;
  height: 40px;
  table-layout: fixed;
  display: table;
  font-size: 14px;
  border-bottom: 1px solid lightgray;
  :hover {
    background-color: ${Colors.boxHighlight};
  }
`
const Td = styled.td`
  color: ${Colors.textColor};
  font-weight: 500;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
  border-right: 1px solid lightgray;
`
ProductSelectionModal.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  onProductSelected: PropTypes.func.isRequired
};
ProductSelectionModal.defaultProps = {
  setIsModalOpen: () => { },
  onProductSelected: () => { }
};

export default ProductSelectionModal;