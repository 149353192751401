import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setSalesOfficeData } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';

const columnNames = {
  sales_office_code: { label: '営業所コード', width: '12%', align: 'center' },
  sales_office_name: { label: '営業所名', width: '16%', align: 'left' },
  postal_code: { label: '郵便番号', width: '16%', align: 'left' },
  address: { label: '住所', width: '20%', align: 'left' },
  phone: { label: '電話番号', width: '20%', align: 'left' },
  label: { label: '郵便局名', width: '16%', align: 'left' },
};


/*
  営業所リスト表示コンポーネント
  各行クリック：営業所詳細ページへ推移
  新規登録ボタン：営業所新規登録ページへ推移
*/
const MtbSalesOfficeList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { salesOfficeData } = useSelector(state => state.master);

  /*
    DBから営業所データをフェッチ
  */
  useEffect(() => {
    dispatch(setSalesOfficeData());
  }, [dispatch]);

  /*
    営業所詳細ページへ推移
  */
  const navigateToDetail = useCallback((id) => {
    history.push({ pathname: `${pathname}/${id}`, state: { data: salesOfficeData[id] } });
  }, [history, pathname, salesOfficeData]);

  /*
    営業所新規登録ページへ推移
  */
  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <Table>
        <ColumnNameWrapper>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </ColumnNameWrapper>
        <tbody>
          {
            Object.keys(salesOfficeData).length > 0 && Object.keys(salesOfficeData).map((key) => {
              return <Row data={salesOfficeData[key]} id={key} key={salesOfficeData[key].id} />
            })
          }
        </tbody>
      </Table>
    </Container>
  )
};

export default MtbSalesOfficeList;