import React from 'react';
import PropTypes from 'prop-types';

/*
  テーブルなどの限られた幅でテキストを表示するためのコンポーネント
  長いテキストを指定された文字数で区切り、縦に続けて表示する関数コンポーネント
  使い方は.mdファイルにて
*/
const BreakLongTextToFit = (props) => {
  
  const { text, maxLengthInOneLine, component: Component, styles } = props;
  const stringLength = text ? text.length : 0;
  let strArray = [];

  if (stringLength > maxLengthInOneLine) {
    const numIter = stringLength % maxLengthInOneLine === 0 ? parseInt(stringLength / maxLengthInOneLine) : parseInt(stringLength / maxLengthInOneLine) + 1;
    let sliceStart = 0;
  
    for (let i=0;i<numIter;i++) {
      const str = text.slice(sliceStart, sliceStart + maxLengthInOneLine);
      strArray.push(str);
      sliceStart += maxLengthInOneLine;
    };

    return (
      <>
        {
          strArray.map((str, index) => {
            return <Component style={styles} key={index.toString()}>{str}</Component>
          })
        }
      </>
    )

  }  

  return (
    <>
      <Component style={styles}>{text}</Component>
    </>
  )
};

BreakLongTextToFit.propTypes = {
  text: PropTypes.string.isRequired,
  maxLengthInOneLine: PropTypes.number.isRequired,
  styles: PropTypes.object
};

BreakLongTextToFit.defaultProps = {
  text: '',
  maxLengthInOneLine: 10,
  styles: {}
};


export default BreakLongTextToFit;