import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { BaseBodyStyle, BaseHeaderStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { CustomerInput, CustomerStatusList } from 'src/views/customer/common';
import DialogMessage from 'src/common/DialogMessage';
//configs & styles
import { BreakPoints, FontSize } from 'src/configs/StyleConfig';
//functions
import CustomerFunction from 'src/functions/CustomerFunctions';
import { sendLogData, logCategory, logTableType } from 'src/functions/LogFunctions';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDetailedCustomerData } from 'src/redux/actions/CustomerDataHandler';

const CustomerEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const { data, enterprise, options } = location.state;
  const dispatch = useDispatch();
  const { token, employee_name, mtb_enterprise_id } = useSelector(state => state.auth);

  const [customerInfo, setCustomerInfo] = useState({});
  const [enterpriseName, setEnterpriseName] = useState("");
  const [salesOfficeOption, setSalesOfficeOption] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const modalRequired = useRef(false);

  useEffect(() => {
    if (data && enterprise) {
      setCustomerInfo(data);
      setEnterpriseName(enterprise);
      setSalesOfficeOption(options);
    }
    setIsLoading(false);
  }, [data, enterprise]);

  const setStatusList = useCallback((id) => {
    //　対応不可、クレームなどのステータスの変更を行う
    const _customerInfo = { ...customerInfo };
    _customerInfo['status'][id].isSelected = !_customerInfo["status"][id].isSelected;
    setCustomerInfo(_customerInfo);
  }, [customerInfo]);

  const changeCustomerInfo = useCallback((key, value) => {
    // 全てのユーザーインプットの変更を行う関数
    const _customerInfo = { ...customerInfo };
    _customerInfo[key] = value;
    setCustomerInfo(_customerInfo);
  }, [customerInfo]);


  const onChangeSelection = useCallback((e) => {
    //営業所の変更
    const _selectedOption = e.target.value;
    const _customerInfo = { ...customerInfo };
    _customerInfo["mtb_sales_office_id"] = parseInt(_selectedOption) && parseInt(_selectedOption);
    setCustomerInfo(_customerInfo);
  }, [customerInfo]);

  const changeUnsavedStatus = () => {
    if (modalRequired.current) return;
    modalRequired.current = true;
  };
  useMemo(changeUnsavedStatus, [customerInfo]);

  const onSaveButtonPressed = useCallback(async () => {
    const customerFunctions = new CustomerFunction({ customerInfo: customerInfo, token: token });
    const response = await customerFunctions.updateData();
    if (typeof response === "object" && response.error) {
      setError(response.error);
    } else {
      const { insertLogId } = response.logs;
      const customerLog = {
        id: null,
        category: logCategory.update, //更新ログ
        remarks: '顧客情報更新',
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: logTableType.customer, //顧客ログ
        log_id: insertLogId,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      const json = [customerLog];
      const error = await sendLogData(json, token);
      dispatch(updateSelectedDetailedCustomerData(response.data));
      modalRequired.current = false;
      setError('');
      history.goBack();
    }
  }, [customerInfo, token, employee_name]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, []);

  if (isLoading) {
    return (
      <BaseBodyStyle main>
        <BaseHeaderStyle>
          <HeaderTitle>
            顧客編集
          </HeaderTitle>
        </BaseHeaderStyle>
      </BaseBodyStyle>
    )
  };

  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <BaseHeaderStyle>
        <HeaderTitle>
          顧客編集
        </HeaderTitle>
      </BaseHeaderStyle>
      <div style={{ width: '100%' }}>
        <CustomerStatusList
          statusList={customerInfo.status}
          setStatusList={setStatusList}
          enterprise={enterpriseName}
          dropdownSelection={salesOfficeOption}
          onChangeSelection={onChangeSelection}
          mtb_sales_office_id={customerInfo.mtb_sales_office_id}
          customerCode={customerInfo.customer_code}
          isEditable={true}
          isRegistering={false}
        />
      </div>
      <InputWrapper>
        <CustomerInput customerInfo={customerInfo} setCustomerInfo={changeCustomerInfo} isRegistering={false} isEditable={true} />
      </InputWrapper>
      <span style={{ color: 'red', fontSize: FontSize.lg, padding: '4px' }}>
        {error}
      </span>
      <ButtonMenu>
        <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
        <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
      </ButtonMenu>
    </BaseBodyStyle >
  );
};

const HeaderTitle = styled.h1`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50% , -50%);
  font-size: 20px;
`

const InputWrapper = styled.form`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.md}) {
    padding: 24px;
  }
`

const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 2px solid;
  padding: 16px 0;
`

export default CustomerEdit;
