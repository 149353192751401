import axios from 'axios';
import {
  IMAGE_UPLOAD_URL,
  IMPORT_FILE_CHANGE_URL,
  IMPORT_FILE_URL,
  IMAGE_URL,

  DELETE_URL,
} from 'src/apis';

/*
ファイル取り込みデータを登録/編集するクラス
- Parameters in constructor:
 - uploadingFiles: アップロードする画像ファイル
 - deletingFiles: 消去する画像ファイル
 - employee_name: ログインユーザー名
 - mtb_customer_id: 選択された工事に紐づく顧客Id
 - dtb_construction_id: 選択された工事Id
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - fileUpload: DBに登録/編集したフィル取り込みデータ
*/
class FileUploadFunctions {

  constructor(props) {
    const { uploadingFiles, deletingFiles, employee_name, mtb_customer_id, dtb_construction_id, token } = props;
    this.uploadingFiles = uploadingFiles;
    this.deletingFiles = deletingFiles;
    this.dtb_construction_id = dtb_construction_id;
    this.mtb_customer_id = mtb_customer_id;
    this.employee_name = employee_name;
    this.token = token;
    this.response = {error: '', fileUpload: []};
  }

  /*
    private method
    image FileをS3に送る関数　画像のURLと画像名等を返す
  */
  async _uploadImage(images) {
    const params = new FormData();
    images.map((image) => {
      if (image.image_file) params.append(image.file_name, image.image_file);
    })
    return (await axios.post(IMAGE_UPLOAD_URL, params,
      {
        headers: {
          'content-type': 'application/json',
          'Authorization' : `Bearer ${this.token}`
        },
      })).data;
  };

  /*
    private method
    blob array からimage Fileに直す関数S3に送る用
  */
  _getImageFileObject(fileImages) {
    const config = { responseType: 'blob' };
    const promise = fileImages.map( async (image) => {
      const response = await axios.get(image.file_url, config); // no token needed
      const fileUrl = new Date().valueOf().toString() + "_" + this.mtb_customer_id.toString();
      const file = new File([response.data], fileUrl, {type: image.file_type});
      const json = { image_file: file, file_name: image.file_name };
      return json;
    });
    return Promise.all(promise)
  };

  /*
    private method
    新しく追加されたファイルのみS3にアップロード
    (1) S3に送る画像データをFileフォーマットに直す
    (2) 新しく登録する画像をS3に送る
    (3) アップロードされた画像の画像名をDBに送る処理
  */
  async _sendFileImportData() {
    try {
      if (!this.uploadingFiles) return;
      const uploadingFileList = this.uploadingFiles.filter((file) => file.is_new_entry); //新しく追加されたファイルのみS3にアップロード
      if ( uploadingFileList.length === 0) return;

      const imageFiles = await this._getImageFileObject(uploadingFileList);　//(1) S3に送る画像データをFileフォーマットに直す
      const results = await this._uploadImage(imageFiles); // (2) 新しく登録する画像をDBに送る
      const newUploadingFiles = [];
      if (results.length > 0) {
        results && results.map((file) => {
          const json = {
            id: null,
            file_name: file.fieldname,
            file_url: file.originalname,
            dtb_construction_id: this.dtb_construction_id,
            created_employee_name: this.employee_name,
            updated_employee_name: this.employee_name,
          }
          newUploadingFiles.push(json); // 新しく登録されるファイルアップロードデータ
        });
      }

      //(3) アップロードされた画像の画像名をDBに送る処理
      this.response.fileUpload = [...this.uploadingFiles];
      await axios.post(IMPORT_FILE_CHANGE_URL, newUploadingFiles, { headers: {"Authorization" : `Bearer ${this.token}`} })
      .then((response) => {
        if(response.status === 200) {
          return response;
        }
        throw new Error("画像ファイルを保存できませんでした。");
      })
      .then(() => {
        return;
      })
    } catch (error) {
      return error.message;
    }
  };

  /*
    private method
    アップロードされている画像の消去
  */
  async _deleteFileUploadData() {
    try {
      let errors = [];
      const url = DELETE_URL + '/dtb_import_file';
      this.deletingFiles.map( async (file) => {
        const {id} = file;
        const json = {id: id};
        const response = await axios.post(url, json, { headers: {"Authorization" : `Bearer ${this.token}`} });
        if (response.status !== 200) errors.push(file.file_name);
      });
      
      if (errors.length > 0) {
        const error = errors.join(',') + 'を削除できませんでした。';
        return error;
      } 
      return;
    } catch (error) {
      return error.message;
    }
  }


  /*
    public method
    外部から呼び出すメソッド
    （１）新規で追加されたファイルをS3に送り、画像名をDBに格納する処理
    （２）消去されたファイルをIDを元にDBで消去
  */
  async sendFiles() {
    if (!this.employee_name || !this.mtb_customer_id, !this.dtb_construction_id ) {
      this.response.error = "該当する工事データを取得できませんでした。"
      return this.response;
    }

    const error = await this._sendFileImportData(); // (1) 新規で追加されたファイルをS3に送り、画像名をDBに格納する処理
    if (error) {
      this.response.error = error;
      return this.response;
    };

    if (this.deletingFiles.length > 0) {
      const deleteError = await this._deleteFileUploadData();//（２）消去されたファイルをIDを元にDBで消去
      if (deleteError) {
        this.response.error = deleteError;
        return this.response;
      }
    }

    this.response.error = "";
    return this.response;
  }
  
};

export {FileUploadFunctions};


/*
ファイル取り込みデータを表示する前処理を行うクラス
- Parameters in constructor:
 - dtb_construction_id: 選択された工事Id
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: 表示するフィル取り込みデータ
*/
class FileUploadGetFunctions {

  constructor(props) {
    const { dtb_construction_id, token } = props;
    this.dtb_construction_id = dtb_construction_id;
    this.token = token;
    this.response = {error: '', data: []};
  }

  /*
    private method
    工事データの工事データの工事情報のみ必須項目、選択肢チェック
    '提携先', '営業所', '顧客名', '店舗名', '支払い方法'は０以外の選択肢のみ受けとる
  */
  _getImageFileTypeAndName = (file_name) => {
    let json = {fileName: '', fileType: 'image/jpeg'};
    if(!file_name) return json;
    const urlArr = file_name.split('.');
    const extension = urlArr[urlArr.length -1];
    json['fileName'] = urlArr[0];
    if (extension === "pdf") {
      json['fileType'] = 'application/pdf';
      return json;
    }
    return json;
  };
  
  /*
    private method
    画像のファイル名を元にsigned urlを取ってくる関数
  */
  async _getSignedUrl(fileName, originalFilename) {
    try {
      const response = await axios.get(IMAGE_URL, {
        params: {
          filename: fileName,
          originalFilename: originalFilename
        },
        headers: {"Authorization" : `Bearer ${this.token}`}
      });
      if (response.status !== 200) return '';
      const { data } = response;
      return data;
    } catch (error) {
      return '';
    }
  }


  /*
    private method
    選択された工事データにおける画像を含んだ取り込みファイルのリストを生成する関数
  */
  _preprocessingData(data) {
    if (data.length === 0) return [];
    const promise = data.map( async (row) => {
      let json = {};
      const typeAndName = this._getImageFileTypeAndName(row.file_name);
      const url = await this._getSignedUrl(row.file_url, row.file_name);
      json['id'] = row.id;
      json["file_name"] = typeAndName.fileName;
      json["file_type"] = typeAndName.fileType;
      json["file_url"] = url;　//表示する画像のURL
      json["created_at"] = row.created_at;
      json["is_new_entry"] = false;　//この変数でファイル登録で新規追加の画像とすでに追加済みの画像を仕分ける
      json['image_file'] = null;
      return json;
    });
    return Promise.all(promise)
  };
  
  /*
    private method
    選択された工事データのIdからファイルを取ってきて前処理を行う関数
  */
  async _fetchFileUploadData() {
    try {
      if (!this.dtb_construction_id) throw new Error("dtb_construction_id is missing");
      
      await axios.get(IMPORT_FILE_URL, {
        params: {
          dtb_construction_id: this.dtb_construction_id
        },
        headers: {"Authorization" : `Bearer ${this.token}`}
      })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("アップロードファイルを取得できませんでした。");
      })
      .then(async ({data}) => {
        const preprocessedData = await this._preprocessingData(data)
        this.response.data = preprocessedData;
        return;
      });
    } catch (error) {
      this.response.error = error.message;
      return this.response;
    }
  };

  /*
    public method
    外部から呼び出す関数
    FileUploadから呼び出し
    リストに格納された表示できる取り込み画像を返す
  */
  async getFiles() {
    const error = await this._fetchFileUploadData();
    if (error) {
      this.response.error = "アップロードファイルを取得できませんでした。";
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }
};

export {FileUploadGetFunctions};