import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setProduct } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';

const columnNames = {
  product_code: { label: '商品コード', width: '15%', align: 'center' },
  jan_code: { label: 'JANコード', width: '20%', align: 'left' },
  product_name: { label: '商品名', width: '25%', align: 'left' },
  unit: { label: '単位', width: '20%', align: 'left' },
  price: { label: '単価', width: '20%', align: 'left' }
};

/*
  商品一覧ページ
  各行から選択された商品詳細ページへ推移
  新規登録ボタンから商品登録ページへ推移
*/
const MtbProductList = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { productData } = useSelector(state => state.master);
  const [data, setData] = useState({});

  useEffect(() => {
    //DBから商品データをフェッチ
    dispatch(setProduct());
  }, [dispatch]);

  const initSalesData = () => {
    if (productData) {
      setData({ ...productData });
    }
  };
  useMemo(initSalesData, [productData]);

/*
  各行から選択された商品詳細ページへ推移
*/
  const navigateToDetail = useCallback((id) => {
    history.push({ pathname: `${pathname}/${id}`, state: { data: data[id] } });
  }, [history, pathname, data]);

/*
  新規登録ボタンから商品登録ページへ推移
*/
  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <CustomTable>
        <ColumnNameWrapper>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </ColumnNameWrapper>
        <tbody>
          {
            Object.keys(data).length > 0 && Object.keys(data).map((key) => {
              return <Row data={data[key]} id={key} key={data[key].id} />
            })
          }
        </tbody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  align-self: center;
  width: 75%;
  min-width: 700px;
  max-width: 900px;
`

export default MtbProductList;