import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//configs
import { Colors } from 'src/configs/StyleConfig';

const SearchField = (props) => {
  const { placeholder, onChange, value } = props;
  return (
    <TextBoxStyle placeholder={placeholder} onChange={onChange} value={value} />
  );
};

const TextBoxStyle = styled.input`
  width: 100%;
  height: 32px;
  font-size: 16px;
  border: 1px solid ${Colors.border};
  border-radius: 6px;
  align-self: center;
  margin-right: 4px;
  padding-left: 8px;
  ::placeholder {
    color: ${Colors.border};
  }
`

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

SearchField.defaultProps = {
  value: '',
  placeholder: '',
  onChange: () => { }
};

export default memo(SearchField);