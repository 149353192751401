import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import ProductFavoriteTable from './children/ProductFavoriteTable';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setProduct } from 'src/redux/actions/MasterDataHandler';


/*
  商品お気に入り登録表示コンポーネント
  編集ボタンから商品お気に入り登録編集ページへ推移
*/
const MtbProductFavoriteDetail = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();

  const { productData } = useSelector(state => state.master);
  const [products, setProducts] = useState({});

  useEffect(() => {
    //DBから商品データをフェッチ
    dispatch(setProduct());
  }, [dispatch]);
  
  const initSalesData = () => {
    if (productData) {
      setProducts({...productData});
    }
  };
  useMemo(initSalesData, [productData]);

/*
  編集ボタンから商品お気に入り登録編集ページへ推移
*/
  const onEditButtonPressed = useCallback(() => {
    history.push({pathname: `${pathname}/edit`, state: {data: products, prevPath: pathname}});
  }, [history, pathname, products]);

  return (
    <>
      <ProductFavoriteTable 
        data={products}
        isEditing={false}
        onEditButtonPressed={onEditButtonPressed}
      />
    </>
  )
};

export default MtbProductFavoriteDetail;