import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
//styles & config
import { Colors } from 'src/configs/StyleConfig';
//apis
import { ADDRESS_SEARCH_URL } from 'src/apis';


const TextInputStyle = styled.input`
  width: 100%;
  padding: 4px;
  height: 20px;
  :disabled {
    background-color: white;
    border-radius: 2px;
    border: 1px solid ${Colors.textColor};
    color: ${Colors.textColor};
  }
  ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }  
`

const replaceHalfToFull = (str) => {
  if (typeof str === 'number') str = str.toString();
  
  let val = str.replace(/[！-～]/g,
    function( tmpStr ) {
      // 文字コードをシフト
      return String.fromCharCode( tmpStr.charCodeAt(0) - 0xFEE0 );
    }
  );
  return val.replace(/”/g, "\"")
  .replace(/’/g, "'")
  .replace(/‘/g, "`")
  .replace(/￥/g, "\\")
  .replace(/　/g, " ")
  .replace(/〜/g, "~")
  .replace(/−/g, "-")
  .replace(/ー/g, "-");
}

/*
一般のテキストを受け取るコンポーネント
*/
export const TextInput = memo((props) => {
  
  const { onChange, isEnable, value } = props;

  if (isEnable) {
    return (
      <TextInputStyle onChange={onChange} value={value ? value : ''} />
    )
  }
  return (
    <TextInputStyle disabled value={value ? value : ''} />
  )
});

TextInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isEnable: PropTypes.bool,
};

TextInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => { },
  isEnable: true,
};


/*
入力されたテキストを強制的に半角にするインプット
数字入力に使用
*/
export const TextHalfNumberInput = memo((props) => {
  const { onChange, isEnable, value } = props;

  /*
  onBlurで数字は全角から半角に変換する処理が走る
  */
  const onBlurChange = () => {
    if (value) {
      const replacedValue = replaceHalfToFull(value);
      onChange({target: { value: replacedValue}});
    } else {
      onChange({target: { value: value}});
    }
  }

  const handleKeyPress = (e) => {
    if(e.keyCode === 13) {
      e.target.blur(); 
    }
  }

  if (isEnable) {
    return (
      <TextInputStyle 
        onChange={onChange} 
        onBlur={onBlurChange} 
        value={value ? value : ''}
        onKeyDown={handleKeyPress}
      />
    )
  }
  return (
    <TextInputStyle disabled value={value ? value : ''} />
  )
});

TextHalfNumberInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isEnable: PropTypes.bool,
};

TextHalfNumberInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => { },
  isEnable: true,
};


/*
郵便番号を受け付けるInput、郵便番号入力後に郵便番号を元に住所を取ってくるAPIを呼び出し
*/
export const PostalCodeInput = memo((props) => {
  const { value, onChange, isEnable, onChangeAddress } = props;
  const [halfValue, setHalfValue] = useState('');

  useEffect(() => {
    if (halfValue) {
      onChange({target: { value: halfValue}});
    }
  }, [halfValue]);

  /*
    入力された郵便番号のハイフンをチェックし、なければ追加する関数
  */
  const addHyphen = (value) => {
    const regex = new RegExp('[-ー]+');
    if (regex.test(value)) return value;
    
    return value.slice(0,3).toString() + '-' + value.slice(3, 7);
  }

  /*
    onBlurにて郵便番号を元に住所をAPIからフェッチする関数
  */
  const onBlurZipCode = async () => {
    let replacedValue = replaceHalfToFull(value);
    replacedValue = addHyphen(replacedValue);
    if (value.length >= 7) {
      const url = `${ADDRESS_SEARCH_URL}?zipcode=${replacedValue}`;
      const response = await axios.get(url);
      const responseData = response.data;
      const { code } = responseData;
      if (code === 200) {
        const {data} = responseData;
        const { fullAddress } = data;
        onChangeAddress(fullAddress);
      }
    }
    setHalfValue(replacedValue);
  };

  if (isEnable) {
    return (
      <TextInputStyle
          name="postCode"
          size="8"
          maxLength="8"
          value={value}
          onChange={onChange}
          onBlur={onBlurZipCode}
          
      />
    );
  }
  return (
    <TextInputStyle
        name="postCode"
        size="7"
        maxLength="7"
        disabled
        value={value}
        onChange={() => {}}
    />
  );
});

PostalCodeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isEnable: PropTypes.bool,
  onChangeAddress: PropTypes.func
};

PostalCodeInput.defaultProps = {
  value: '',
  onClick: () => { },
  isEnable: false,
  onChangeAddress: () => { }
};
