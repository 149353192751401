import axios from 'axios';
//apis
import { SALES_AGGREGATION_URL } from 'src/apis';


/*
売上集計クエリクラス
- Parameters in constructor:
 - customerInfo: 顧客データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: DBに登録/編集した顧客データ
 - logs: 登録した顧客データのInsertIdなどを含むログ
*/
class SalesAggregationFunctions {
  
  async getConstructionData({query_start_date, query_end_date, mtb_enterprise_id, mtb_sales_office_id, mtb_partner_id, token, storeData}) {
    let response = {error: '', data: []};

    try {
      const response = await axios.get(SALES_AGGREGATION_URL, {
        params: {
          query_start_date: query_start_date,
          query_end_date: query_end_date,
          mtb_sales_office_id: mtb_sales_office_id === 0 ? undefined : mtb_sales_office_id,
          mtb_enterprise_id: mtb_enterprise_id === 0 ? undefined : mtb_enterprise_id,
          mtb_partner_id: mtb_partner_id === 0 ? undefined : mtb_partner_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        const { data } = response;
        data.map((constructionData) => {
          const { mtb_store_id } = constructionData;
          const storeName = storeData[mtb_store_id] ? storeData[mtb_store_id].store_name : "";
          constructionData["store_name"] = storeName;
        });
        response.data = data;
        return response;
      } else {
        throw new Error("工事データを取得できませんでした。期間を短くするか、時間を置いて再度検索してください。");
      }
    } catch (error) {
      response.error = error.message;
      return response;
    }
  }
}

export default new SalesAggregationFunctions();