import React, { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//configs & styles
import { Colors, FontSize, BreakPoints } from 'src/configs/StyleConfig';
//components
import StatusMapping from 'src/common/StatusMapping';
import ReactPaginate from 'react-paginate';
import Loader from 'react-loader';
//functions
import DateFunctions from 'src/functions/DateFunctions';
//hooks
import { useWindowSize } from 'src/views/hooks/useWindowSize';


const baseColumnNames = {
  category_name: { label: '保証書種別', width: '10%' },
  certificate_no: { label: '証書番号', width: '10%' },
  create_date: { label: '作成年月日', width: '10%' },
  work_area: { label: '作業実施面積', width: '10%' },
  warranty_date: { label: '保証期間', width: '25%' },
  customer_name: { label: '施主名', width: '25%' },
  is_exported: { label: 'csv出力', width: '10%', align: 'left' },
};

const keyPairs = {
  warranty_date: ['warranty_start_date', 'warranty_period', 'warranty_end_date'],
  is_exported: ['exported_at', 'exported_employee_name']
};

const WarrantyTable = memo((props) => {
  const { data, navigation, sortType, isDataProcessing } = props;
  const { width } = useWindowSize();
  const [columnNames, setColumnNames] = useState(baseColumnNames)
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [isTwoLine, setIsTwoLine] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 100;

  useEffect(() => {
    //windowのサイズに合わせてテーブルのカラムを調整（初期化）
    if (window.innerWidth < 790) {
      const updatedColumnNames = {
        certificate_no: { label: '証書番号', width: '14%' },
        create_date: { label: '作成年月日', width: '20%' },
        work_area: { label: '作業実施面積', width: '16%' },
        warranty_start_date: { label: '保証期間', width: '24%' },
        customer_name: { label: '施主名', width: '16%' },
      }
      setColumnNames(updatedColumnNames);
      setIsTwoLine(true);
    } else {
      setColumnNames(baseColumnNames);
      setIsTwoLine(false);
    }
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    setIsLoading(false);
  }, [itemsPerPage, itemOffset, data, sortType]);

  const changeTableColumn = () => {
    //windowのサイズに合わせてテーブルのカラムを調整（アップデート）
    if (width < 790) {
      const updatedColumnNames = {
        certificate_no: { label: '証書番号', width: '14%' },
        create_date: { label: '作成年月日', width: '20%' },
        work_area: { label: '作業実施面積', width: '16%' },
        warranty_start_date: { label: '保証期間', width: '24%' },
        customer_name: { label: '施主名', width: '16%' },
      }
      setColumnNames(updatedColumnNames);
      setIsTwoLine(true);
    } else {
      setColumnNames(baseColumnNames);
      setIsTwoLine(false);
    }
  };
  useMemo(changeTableColumn, [width]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const TableContent = ({ data }) => {

    return (
      <>
        {
          isTwoLine ?
            <TrTop>
              <td colSpan="6"><StatusMapping oneLine={true} statusList={data['status']} /></td>
              <td colSpan="1" />
              <td colSpan="1">
                <div style={{ display: 'flex', justifyContent: 'center', border: `1px solid ${Colors.statusRed}`, color: Colors.statusRed, fontSize: 10 }}>
                  出力済み
                </div>
              </td>
            </TrTop>
            :
            null
        }
        <Tr key={data.id} onClick={() => navigation(data.id)}>
          {
            Object.keys(columnNames).map((columns) => {
              if (columns === 'warranty_date') {
                const key = keyPairs[columns];
                return (
                  <Td width={columnNames[columns].width} key={columns}>
                    <MultiLine>
                      {DateFunctions.ISOStringDateToLocalDate(data[key[0]])}　から  {data[key[1]]}年間
                    </MultiLine>
                    <MultiLine>
                      {DateFunctions.ISOStringDateToLocalDate(data[key[2]])}まで
                    </MultiLine>
                  </Td>
                )
              } else if (columns === 'is_exported') {
                if (data['is_exported'] === 1) {
                  const key = keyPairs[columns];
                  return (
                    <Td width={columnNames[columns].width} key={columns}>
                      <CSVStatusMultiLine style={{ border: `1px solid ${Colors.statusRed}` }}>
                        出力済み
                      </CSVStatusMultiLine>
                      <CSVStatusMultiLine>
                        {DateFunctions.ISOStringDateToLocalDate(data[key[0]]) ? DateFunctions.ISOStringDateToLocalDate(data[key[0]]) : " "}
                      </CSVStatusMultiLine>
                      <CSVStatusMultiLine>
                        {data[key[1]] ? data[key[1]] : " "}
                      </CSVStatusMultiLine>
                    </Td>
                  )
                }
                return (
                  <Td width={columnNames[columns].width} key={columns} />
                )
              } else if (columns === 'customer_name') {
                if (data['category'] === 2) { //損害賠償
                  return (
                    <Td key={columns}>
                      {data[columns]}
                    </Td>
                  )
                }
                return (
                  <Td key={columns}>
                    {data['mtb_customer_name']}
                  </Td>
                )
              }
              return (
                <Td key={columns}>
                  {data[columns]}
                </Td>
              )
            })
          }
        </Tr>
      </>
    );
  }

  return (
    <Container>
      <Table>
        <TitleContainer>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </TitleContainer>
        <tbody>
          {
          isDataProcessing || isLoading ?
          null
          :
            currentItems &&
            currentItems.map((data, index) => (
              <TableContent data={data} key={index.toString()} />
            ))
          }
          </tbody>
      </Table>
      {
        isDataProcessing || isLoading ?
        <div style={{ height: 320, display: 'flex', alignItems: 'center', backgroundColor: 'black'}}>
          <Loader position="relative" />
        </div>
        :
        null
      }
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={'page-selection'}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </Container>
  )
});

const Container = styled.div`
  width: 100%;
  margin: 32px 0 0 0;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 95%;
  }
  @media screen and (max-width: ${BreakPoints.md}) {
    width: 92%;
  }
`
const Table = styled.table`
  max-width: ${BreakPoints.xxl};
  min-width: ${BreakPoints.md};
  width: 96%;
  border-collapse: collapse;
`
const TitleContainer = styled.thead`
  border: 1px solid #3f3f3f;
  border-radius: 6px;
  height: 48px;
  background-color: #3f3f3f;
`
const Th = styled.th`
  color: #ffffff;
  font-size: ${FontSize.md};
  font-weight: 600;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}`};
`
const Tr = styled.tr`
  min-height: 60px;
  border-bottom: 1px solid lightgray;
  font-size: ${FontSize.xs};
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: ${Colors.boxHighlight};
  }
  :after {
    content: "";
    display: block;
  }
`
const TrTop = styled.tr`
  height: 24px;
  font-size: ${FontSize.xs};
  cursor: pointer;
  background-color: #ffffff;
  @media screen and (max-width: 790) {
    border-bottom: 1px solid lightgray;
  }
`
const Td = styled.td`
  list-style-type: none;
  color: ${Colors.textColor};
  font-weight: 500;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}`};
`
const MultiLine = styled.pre`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
`

const CSVStatusMultiLine = styled(MultiLine)`
  display: flex; 
  justify-content: center;
  color: ${Colors.statusRed}; 
  font-size: 10px;
`

WarrantyTable.propTypes = {
  data: PropTypes.array.isRequired,
  navigation: PropTypes.func.isRequired,
  sortType: PropTypes.number.isRequired,
  isDataProcessing: PropTypes.bool.isRequired,
};
WarrantyTable.defaultProps = {
  data: [],
  navigation: () => { },
  sortType: 0,
  isDataProcessing: true
};

export default WarrantyTable;