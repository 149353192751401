import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import TaxInput from './children/TaxInput';
import DialogMessage from 'src/common/DialogMessage';
//functions
import TaxFunctions from 'src/functions/mtbFunctions/TaxFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  消費税登録コンポーネント
  テキスト入力後、
  保存ボタンから消費税をDBに格納
  キャンセルボタンで入力情報を破棄してリストに戻る
*/
const MtbTaxRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, token } = useSelector(state => state.auth);
  const [tax, setTax] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  /*
    消費税情報を初期化
  */
  useEffect(() => {
    const getData = () => {
      const today = new Date().toISOString();
      const { state } = location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const baseTaxData = {
        id: null,
        start_date: today,
        tax_rate: 0,
        is_deleted: 0,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      return baseTaxData;
    };
    if (employee_name) {
      const _tax = getData();
      setTax({..._tax});
    }
  }, [employee_name, location]);

  /*
    消費税情報を格納
    数字のみ受付け（半角から全角への変換も含む）
  */
  const changeTaxData = useCallback((key, value) => {
    const _tax = {...tax};
    if (key === 'tax_rate') {
      const re = /^(\s*|[-0-9.。ー−１２３４５６７８９０]+)$/
      if (!re.test(value)) {
        value = 0;
      }
    }
    if (value !== null) {
      _tax[key] = value;
      setTax(_tax);
      if (!modalRequired.current) modalRequired.current = true;
    } else {
      _tax[key] = '';
      setTax('');
    }
  }, [tax, modalRequired]);

  /*
    保存ボタンから消費税をDBに格納
  */
  const onSaveButtonPressed = useCallback(async () => {
    const taxFunctions = new TaxFunctions({ taxData: tax, token: token });
    const response = await taxFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText("");
      history.replace({pathname: previousPath.current});
    }
  }, [tax, token, history, modalRequired, previousPath]);

  /*
    キャンセルボタンで入力情報を破棄してリストに戻る
  */
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <TaxInput 
        data={tax}
        isEditing={true}
        errorText={errorText}
        onChange={changeTaxData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbTaxRegister;