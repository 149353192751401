
const statusFormat = [
  { id: 0, status: '対応不可', isSelected: false },
  { id: 1, status: '信販不可', isSelected: false },
  { id: 2, status: '転居', isSelected: false },
  { id: 3, status: 'クレーム', isSelected: false }
];

/*
顧客データに紐づくステータスの表示＜ー＞DB格納用に変換するクラス
*/
class StatusFunctions {

  /*
    public method
    顧客ステータスstringから処理しやすい様にリストに変換する処理を行う関数
    インプット："1, 3"
    アウトプット：[1, 3]
  */
  stringStatusToArray = (strStatus) => {
    // expecting format is "0,1,3"
    const _statusArr = [...statusFormat];
    if (typeof strStatus !== 'string') return _statusArr;
    const _status = strStatus.split(',');
    _statusArr.forEach((status) => {
      if (_status.includes(status.id)) {
        status.isSelected = true;
      } else {
        status.isSelected = false;
      }
    });
    return _statusArr;
  };

  /*
    public method
    顧客ステータスリストからテーブル表示用にリストに変換する処理を行う関数
    例：
    インプット：[1, 3]
    アウトプット：
      { id: 0, status: '対応不可', isSelected: false },
      { id: 1, status: '信販不可', isSelected: true },
      { id: 2, status: '転居', isSelected: false },
      { id: 3, status: 'クレーム', isSelected: true }
  */
  arrayStatusToFormattedArray = (statusArr) => {
    //expecting list such as [0, 3]
    const formattedStatusArr = [...statusFormat];
    if (!(statusArr instanceof Array)) return formattedStatusArr;
    formattedStatusArr.forEach((status) => {
      if (statusArr.includes(status.id)) {
        status.isSelected = true;
      } else {
        status.isSelected = false;
      }
    });
    return formattedStatusArr;
  }

  /*
    public method
    リスト型の顧客ステータスからDBに送る用にstring型に変換する処理を行う関数
    インプット：[1, 3]
    アウトプット："1, 3"
  */
  arrayStatusToString = (statusArr) => {
    // ステータスのストリング型を返す e.g. : "0,3" or ""
    const _statusList = []
    statusArr.filter((section) => { if (typeof section.isSelected === 'boolean' && section.isSelected) _statusList.push(section.id)});
    if (_statusList.length === 0) {
      return "";
    }
    return _statusList.join(",");
  };

};

export default new StatusFunctions();