import axios from 'axios';
import { DELETE_URL } from 'src/apis';
import GeneralFunctions from './GeneralFunctions';

/*
あるデータをDBのテーブルからの消去する際に用いるクラス
- Parameters in constructor:
 - tableName: DBのテーブル名
 - id: データのId（primary key）
 - token: APIアクセストークン
*/
class DeletingFunctions {

  constructor(props) {
    const { tableName, id, token } = props;
    this.tableName = tableName;
    this.id = id;
    this.token = token;
  }

  /*
    private method
    指定されたテーブル名から指定されたIdのデータを消去する処理
  */
  async _send() {
    try {
      const url = DELETE_URL + this.tableName;
      const json = {id: this.id};
      const response = await axios.post(url, json, { headers: {"Authorization" : `Bearer ${this.token}`} });
      if (response.status !== 200) throw new Error("指定されたデータを消去できませんでした。");
      return;
    } catch (error) {
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
  */
  async delete() {
    if (
      GeneralFunctions.isNullOrEmpty(this.tableName) ||
      GeneralFunctions.isNullOrEmpty(this.id) || 
      GeneralFunctions.isNullOrEmpty(this.token)
      ) {
        return'指定されたデータを消去できませんでした。';
      }

      const error = await this._send();
      if (error) {
        return error;
      }

      return;
  }
}

export default DeletingFunctions;