import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//components
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';

//表示日付の設定（曜日、月等）
const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
const days = ['月', '火', '水', '木', '金', '土', '日']

registerLocale('ja', ja) // カレンダーを日本語対応させるための準備

const DateSelector = (props) => {
  const { isEnable, date, onChange } = props;

  /*
  ユーザーが日付を選択した際に呼び出される関数
  DBの格納がIOSString での格納のため、日付を変更毎でISOに変更し選択した日付を格納
  Wed May 19 2021 11:20:35 GMT+0900 (Japan Standard Time) ---> 2021-05-19T12:00:00.000Z
  */
  const changeDateType = (date) => {

    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0)).toISOString();
    onChange(date);
  };

  /*
  Datepickerにて表示する日にちがUTC型なので表示の際にISO->UTCに変更する関数
  */
  const setDisplayDateType = (date) => {
    if (typeof date === 'string' && date !== '') {
      const _date = new Date(date);
      return new Date(Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate(), 12, 0, 0));
    }
    return null;
  };


  return (
    <Container>
      <div>
        <DatePicker //style is in index.css
          readOnly={!isEnable}
          dateFormat="yyyy/MM/dd"　//表示するフォーマット
          selected={setDisplayDateType(date)}
          popperPlacement="bottom-start"
          onChange={changeDateType}
          locale={"ja"}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  width: 100%;
  padding: 4px;
  display: flex;
  font-weight: 500;
  flex-direction: 'column';
`

DateSelector.propTypes = {
  isEnable: PropTypes.bool,
  date: PropTypes.string,
  onChange: PropTypes.func
};

DateSelector.defaultProps = {
  isEnable: true,
  date: '',
  onChange: () => {}
};

export default memo(DateSelector);