import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import SalesOfficeInputs from './children/SalesOfficeInputs';
import DialogMessage from 'src/common/DialogMessage';
//functions
import SalesOfficeFunctions from 'src/functions/mtbFunctions/SalesOfficeFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  営業所登録コンポーネント
  テキスト入力後、
  保存ボタンから営業所データをDBに格納
  キャンセルボタンで入力情報を破棄してリストに戻る
*/
const MtbSalesOfficeRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, mtb_enterprise_id, token } = useSelector(state => state.auth);
  const [salesOfficeData, setSalesOfficeData] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  /*
    営業所データを初期化
  */
  useEffect(() => {
    const getData = () => {
      const { state } = location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const baseSalesOfficeData = {
        id: null,
        sales_office_code: '',
        sales_office_name: '',
        postal_code: '',
        address: '',
        phone: '',
        label: '',
        mtb_enterprise_id: mtb_enterprise_id,
        examination_content: '', //検査内容
        examination_duration: '', //検査時間
        holiday: '', //定休日
        reception_time: '', //電話受付時間
        fts_enterprise_sales_office_name: '', //フマキラー出力時会社名・営業所名
        fts_phone: '', //フマキラー出力時電話番号
        is_deleted: 0,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      return baseSalesOfficeData;
    };
    if (employee_name && mtb_enterprise_id) {
      const _salesOfficeData = getData();
      setSalesOfficeData({..._salesOfficeData});
    }
  }, [employee_name, mtb_enterprise_id, location]);

 /*
    営業所state内データをアプデ
  */
  const changeSalesOfficeData = useCallback((key, value) => {
    const _salesOfficeData = {...salesOfficeData};
    if (value !== null) {
      _salesOfficeData[key] = value;
      setSalesOfficeData(_salesOfficeData);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [salesOfficeData, modalRequired]);

  /*
    保存ボタンから営業所データをDBに格納
  */
  const onSaveButtonPressed = useCallback(async () => {
    const salesOfficeFunctions = new SalesOfficeFunctions({ salesOfficeData: salesOfficeData, token: token });
    const response = await salesOfficeFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current});
    }
  }, [salesOfficeData, token, history, modalRequired, previousPath]);

  /*
    キャンセルボタンで入力情報を破棄してリストに戻る
  */
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <SalesOfficeInputs 
        data={salesOfficeData}
        onChange={changeSalesOfficeData}
        isEditing={true}
        errorText={errorText}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbSalesOfficeRegister;