import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//components
import { BaseHeaderStyle, HeaderTitle, HeaderRight } from 'src/common/StylesComponents';
import Button from 'src/common/Button';

const SalesHeader = (props) => {
  const { exportCSVData, handlePrintOut, isEditing } = props;

  return (
    <BaseHeaderStyle>
      <HeaderTitle>
        売上集計
      </HeaderTitle>
      {
        isEditing ?
          null
          :
          <HeaderRight>
            <PrintButton onClick={handlePrintOut}>印刷<i className="fa fa-print" style={{ paddingLeft: '8px', fontSize: '12px' }} /></PrintButton>
            <Button text={'CSV出力'} onClick={exportCSVData} />
          </HeaderRight>
      }
    </BaseHeaderStyle>
  )
};

SalesHeader.propTypes = {
  exportCSVData: PropTypes.func.isRequired,
  handlePrintOut: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

SalesHeader.defaultProps = {
  exportCSVData: () => { },
  handlePrintOut: () => { },
  isEditing: false,
};

export const PrintButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export default SalesHeader;