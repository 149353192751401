import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import toast from 'react-hot-toast';
//apis
import { SEARCH_WARRANTY_URL } from 'src/apis';
//components
import { BaseBodyStyle } from 'src/common/StylesComponents';
import { WarrantyListHeader, WarrantyListSearchField, WarrantyTable } from './children';
import CustomToaster from 'src/common/CustomToaster';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedWarranty, getWarrantyListData, dataPreprocessing } from 'src/redux/actions/WarrantyHandler';
import { removeErrorMessage } from 'src/redux/actions/ErrorMessageHandler';
//functions
import { WarrantyCSVFunctions } from 'src/functions/WarrantyFunctions';
import CSVFunctions from 'src/functions/CSVFunctions';
import { logCategory, sendLogData } from 'src/functions/LogFunctions';

const WarrantyList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const warranty = useSelector(state => state.warranty);
  const warrantyList = warranty.warrantyList || [];
  const actionError = useSelector(state => state.error);
  const { role, mtb_enterprise_id, token, employee_name } = useSelector(state => state.auth);

  const [searched, setSearched] = useState(false);
  const [searchText, setSearchText] = useState(''); // 検索テキストフィールド
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);

  const [searchResult, setSearchResult] = useState(new Array([])); // 検索結果
  const [checkedState, setCheckedState] = useState({
    list: [
      false,
      false,
      false,]
  });
  const [checkedIsExported, setCheckedIsExported] = useState(false);
  let checkboxFiltered = []; 
  const printRef = useRef(null);
  const [sortType, setSortType] = useState(0);
  const [selectedDateType, setSelectedDateType] = useState("");
  const [isSearchQueryFound, setIsSearchQueryFound] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const jstTime = 9 * 60 * 60 * 1000; // 9 hours ahead.

  useEffect(() => {
    //過去に検索、フィルタリングしていたらlocalStorageから検索内容を取ってきてフィルタリング関数を呼び出すフラグを立てる
    const searchQuery = JSON.parse(localStorage.getItem("warrantySearchQuery")); // local storageから前回の検索入力情報をとってくる
    const searchText = searchQuery ? searchQuery.searchText : '';
    const searchCheckState = searchQuery ? searchQuery.searchCheckState : [];
    const selectedDateType = searchQuery ? searchQuery.selectedDateType : "";
    const searchStartDate = searchQuery ? searchQuery.startDate : null;
    const searchEndDate = searchQuery ? searchQuery.endDate : null;
    const savedSortType = JSON.parse(localStorage.getItem("warrantySortType")); // local storageからsortTypeをとってくる
    const checkedStatus = searchCheckState && searchCheckState.filter(item => item === true);

    if (searchText || checkedStatus.length > 0 || selectedDateType || searchStartDate || searchEndDate) { // local storageに検索結果がある場合は、それを表示する。
      setIsLoading(true);
      setSortType(savedSortType ? parseInt(savedSortType) : 0);
      setSearchText(searchText);
      setCheckedState({
        list: searchCheckState
      });
      setSelectedDateType(selectedDateType);
      setSearchStartDate(searchStartDate ? new Date(searchStartDate) : null);
      setSearchEndDate(searchEndDate ? new Date(searchEndDate) : null);
      setIsSearchQueryFound(true); //直接onSearchWarrantysを呼び出せばいいが、stateがアップデートされるのに時間差があるため、フラグを立てる
    } else {
      // DBから保証一覧フェッチ
      dispatch(getWarrantyListData());
      setIsLoading(false);
    }

  }, []);

  /**
   * toastによるエラー表示（csvログなど）
   */
  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
    }
  }, [errorText]);

  /**
   * toastによるエラー表示（保証データ取得エラーなど）
   */
  useEffect(() => {
    // console.log(actionError)
    if (actionError.where === "warranty") {
      toast.error(actionError.msg);
      dispatch(removeErrorMessage());
      setIsLoading(false);
    }
  }, [actionError, dispatch]);

  // チェックマークを入れたときに入れたものを識別するためarrayをupdateする
  const onUpdateStatus = useCallback((i) => {
    setCheckedState(state => {
      const list = state.list.map((item, j) => {
        if (j === i) {
          return !item;
        } else {
          return item;
        }
      });
      return {
        list,
      };
    });
  }, [checkedState.list]);

  const onSearchWarrantys = async () => {
    let searchedWarranty = [];
    let checkedStatus = [];
    setSearchResult(() => []); // 検索初期化
    if (searchText.length > 0 || checkedState.list.length > 0 || searchStartDate || searchEndDate) {
      // // 全角英数ハイフン => 半角
      // let searchedText = searchText.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      //   return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      // });
      // searchedText = searchedText.replace(/[‐－―ー]/g, '-');

      checkedState.list.forEach(function (word, index) {
        if (word === true) {
          checkedStatus.push(index)
        }
      });

      setIsLoading(true);

      // UTC にJST＋９時間追加
      const startD = searchStartDate ? new Date(searchStartDate) : new Date("2000/1/1");
      const endD = searchEndDate ? new Date(searchEndDate) : new Date("2099/12/31");
      startD.setTime(startD.getTime() + jstTime);
      endD.setTime(endD.getTime() + jstTime);

      const startDateString = startD.toISOString();
      const endDateString = endD.toISOString();

      await axios.get(SEARCH_WARRANTY_URL, {
        params: {
          mtb_enterprise_id: mtb_enterprise_id,
          // searchedText: searchedText,
          checkedStatus: checkedStatus,
          selectedDateType: selectedDateType,
          startDate: startDateString,
          endDate: endDateString,
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            const processedData = dataPreprocessing(response.data);
            searchedWarranty = processedData;
          } else {
            setErrorText("保証検索に失敗しました。");
          }
        })
      
      setSearchResult(searchedWarranty);
      setSearched(true);
      setIsLoading(false);
    }

    localStorage.setItem('warrantySearchQuery', JSON.stringify({
      searchText: searchText,
      searchCheckState: checkedState.list,
      selectedDateType: selectedDateType,
      startDate: searchStartDate,
      endDate: searchEndDate,
    }));
  };

  /*
    useEffectでDependencyをリスニングし、もしlocalStorageに検索内容がある場合はフィルタリング関数を呼び出す。
  */
  useEffect(() => {
    if (isSearchQueryFound) {
      onSearchWarrantys();
    }
  }, [isSearchQueryFound]);

  //検索ボタンクリック時に発火
  const onSearchClicked = useCallback(() => {
    onSearchWarrantys();
  }, [warrantyList, searchText, searchResult, checkedState.list, selectedDateType, searchStartDate, searchEndDate]);

  // 検索クリアー（全て表示）
  const onSearchClear = useCallback(() => {
    setSearchResult(() => []); // 検索初期化
    localStorage.removeItem('warrantySearchQuery'); // local storageを空にする
    localStorage.removeItem('warrantySortType'); // local storageを空にする
    setSearched(false);
    setSearchText('');
    setCheckedState({
      list: [
        false,
        false,
        false,]
    });
    setSelectedDateType("");
    setSearchStartDate(null);
    setSearchEndDate(null);
  }, []);

  const dateSortSelected = (e) => {
    setSortType(e.target.value ? parseInt(e.target.value) : 0);
  };

  const setCSVStatusOnSearchResult = (time) => {
    const _searchResult = searchResult.map((row) => {
      if (!row.is_exported || row.is_exported === 0) {
        return {
          ...row,
          is_exported: 1,
          exported_at: time,
          exported_employee_name: employee_name
        };
      }
      return row;
    });
    setSearchResult(_searchResult);
  };

  const registerCSVLog = async () => {
    const csvWarrantyLog = {
      id: null,
      category: logCategory.csvExport,
      remarks: 'CSV出力：保証一覧',
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [csvWarrantyLog];
    const error = await sendLogData(json, token);
    if (error) {
      setErrorText("csvログの記録に失敗しました。");
    }
  };

  //保証データCSV出力 if searched -> 検索結果の出力　otherwise -> 全保証データの出力
  const onLabelExportButtonPressed = useCallback(async () => {
    let data = searched ? [...searchResult] : [...warrantyList];
    
    if (checkedIsExported) {
      // CSV出力済にcheckがついてる場合
      data = data.filter((item) => (!item.is_exported || item.is_exported === 0));
    }

    const warrantyCSVFunctions = new WarrantyCSVFunctions({
      warrantyData: data,
      token: token,
      loginEmployeeName: employee_name,
    });
    const response = warrantyCSVFunctions.getCSVData();
    if (typeof response.error === 'string' && response.error === '') {
      const { csvHeader, csvData } = response.data;
      const fileName = "保証一覧";
      new CSVFunctions(csvHeader, csvData, fileName).exportCSVData();
      if (role === 1) {
        // FTS 権限の場合ー＞dtb_warrantyのCSV出力ステータス(is_exported)変更
        const time = new Date().toISOString();
        await warrantyCSVFunctions.updateCSVExportStatus();
        if (searched) {
          //フィルターされたリストの場合、そのリスト内のis_exportをアップデート
          setCSVStatusOnSearchResult(time);
        }
      }
      // その他権限ではCSV出力ステータスを変更しない
      registerCSVLog();
      setErrorText('');
      dispatch(getWarrantyListData());
    }
  }, [searched, warrantyList, searchResult, checkedIsExported]);

  const navigateToWarrantyDetail = useCallback(async (dtb_warranty_id) => {
    let data = searched ? [...searchResult] : [...warrantyList];
    const selectedWarranty = data.find((warranty) => warranty.id === dtb_warranty_id);
    if (selectedWarranty !== undefined) {
      const warrantyType = selectedWarranty.category;
      dispatch(setSelectedWarranty(selectedWarranty, warrantyType));
      if (warrantyType === 2) {
        history.push({ pathname: `${location.pathname}/${dtb_warranty_id}/damage-warranty-detail` });
      } else {
        history.push({ pathname: `${location.pathname}/${dtb_warranty_id}/termite-antifungal-warranty-detail` });
      }
    }
  }, [searched, warrantyList, searchResult, history, dispatch, location.pathname]);

  const onPrintButtonPressed = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => registerPrintLog(),
  });

  const registerPrintLog = async () => {
    const warrantyPrintLog = {
      id: null,
      category: logCategory.print,
      remarks: '保証一覧',
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [warrantyPrintLog];
    const error = await sendLogData(json, token);
    if (error) {
      setErrorText("csvログの記録に失敗しました。");
    }
  };

  // 検索かけた場合、その結果のDOMを返す
  if (searched) {

    // 登録された順番に変更
    if (sortType === 0) {
      // 登録新しい順番
      searchResult.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else {
      // 登録古い順番
      searchResult.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    };

    if (checkedIsExported) {
      // CSV出力済にcheckがついてる場合
      checkboxFiltered = searchResult.filter((item) =>(!item.is_exported || item.is_exported === 0));
    }

    localStorage.setItem('warrantySortType', JSON.stringify(sortType)); //　sortTypeをlocalstorageに格納

    return (
      <BaseBodyStyle main ref={printRef}>
        <WarrantyListHeader
          onPrintButtonPressed={onPrintButtonPressed}
          onLabelExportButtonPressed={onLabelExportButtonPressed}
        />
        <WarrantyListSearchField
          selectedDateType={selectedDateType}
          setSelectedDateType={setSelectedDateType}
          startDate={searchStartDate}
          setStartDate={setSearchStartDate}
          endDate={searchEndDate}
          setEndDate={setSearchEndDate}
          onSearchClicked={onSearchClicked}
          onSearchClear={onSearchClear}
          checkedState={checkedState}
          setCheckedState={onUpdateStatus}
          checkedIsExported={checkedIsExported}
          setCheckedIsExported={setCheckedIsExported}
          dateSortSelected={dateSortSelected}
          sortType={sortType}
        />
        <WarrantyTable
          data={checkedIsExported ? checkboxFiltered : searchResult}
          navigation={navigateToWarrantyDetail}
          sortType={sortType}
          isDataProcessing={isLoading}
        />
        <CustomToaster />
      </BaseBodyStyle >
    )
  };

  // 登録された順番に変更
  if (sortType == 0) {
    // 登録新しい順番
    warrantyList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  } else {
    // 登録古い順番
    warrantyList.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  };

  if (checkedIsExported) {
    // CSV出力済にcheckがついてる場合
    checkboxFiltered = warrantyList.filter((item) => (!item.is_exported || item.is_exported === 0));
  }

  return (
    <BaseBodyStyle main ref={printRef}>
      <WarrantyListHeader
        onPrintButtonPressed={onPrintButtonPressed}
        onLabelExportButtonPressed={onLabelExportButtonPressed}
      />
      <WarrantyListSearchField
        selectedDateType={selectedDateType}
        setSelectedDateType={setSelectedDateType}
        startDate={searchStartDate}
        setStartDate={setSearchStartDate}
        endDate={searchEndDate}
        setEndDate={setSearchEndDate}
        onSearchClicked={onSearchClicked}
        onSearchClear={onSearchClear}
        checkedState={checkedState}
        setCheckedState={onUpdateStatus}
        checkedIsExported={checkedIsExported}
        setCheckedIsExported={setCheckedIsExported}
        dateSortSelected={dateSortSelected}
        sortType={sortType}
      />
      <WarrantyTable
        data={checkedIsExported ? checkboxFiltered : warrantyList}
        navigation={navigateToWarrantyDetail}
        sortType={sortType}
        isDataProcessing={isLoading}
      />
      <CustomToaster />
    </BaseBodyStyle >
  )
};

export default WarrantyList;