import styled from "styled-components";
import { BreakPoints, Colors } from "src/configs/StyleConfig";

export const Container = styled.div`
  width: 90%;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 95%;
    overflow-x: auto;
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`

export const BackToSettingButton = styled.button`
  display: flex;
  margin-right: auto;
  width: 16px;
  color: #3f3f3f;
  background-color: #ffffff;
  border: 2px solid transparent;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 16px 8px 8px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #CCCCCC;
    border: 2px solid transparent;
  }
`

export const Table = styled.table`
  max-width: ${BreakPoints.xxl};
  width: 100%;
  border-collapse: collapse;
`

export const ColumnNameWrapper = styled.thead`
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
`

export const Th = styled.th`
  color: #ffffff;
  background-color: #3f3f3f;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.align}px`};
`

export const Tr = styled.tr`
  height: 60px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  cursor: pointer;
  padding: 0px 8px;
  background-color: #ffffff;
  :hover {
    background-color: ${Colors.boxHighlight};
  }
  :after {
    content: "";
    display: block;
  }
`

export const Td = styled.td`
  list-style-type: none;
  text-align: ${props => props.align ? props.align : 'left'};
  padding: 0px 12px;
`

export const MultiLine = styled.pre`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
`

