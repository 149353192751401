import axios from 'axios';
//functions
import GeneralFunctions from '../GeneralFunctions';
//apis
import { MTB_EMPLOYEE_DATA_CHANGE_URL } from 'src/apis';

const requiredInputField = {
  account: 'アカウント名', employee_code: '従業員コード', employee_name: '氏名', mtb_sales_office_id: '所属営業所'
};

/*
従業員設定　従業員の登録/編集クラス
- Parameters in constructor:
 - employeeData: 従業員データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: DBに登録/編集したデータ
*/
class EmployeeFunctions {

  constructor(props) {
    const { employeeData, token } = props;
    this.employeeData = employeeData;
    this.token = token;
    this.response = {error: '', data: {}};
  };

  /*
    private method
    パスワードvalidation
  */
  _passwordValidation() {
    //パスワードのnull, 空チェック、文字数validation
    const data = {...this.employeeData};
    if (GeneralFunctions.isNullOrEmpty(data['password']) && GeneralFunctions.isNullOrEmpty(data['entered_password'])) {
      return 'パスワードを入力してください。';
    }

    if (data['entered_password'].length < 4) {
      return "４文字以上のパスワードを入力してください。";
    }

    //新しく入力されたパスワードをハッシュ化して従業員データのpasswordカラムに格納
    const {entered_password} = data;
    const hashedPassword = GeneralFunctions.cryptHash(entered_password);
    this.employeeData['password'] = hashedPassword;
    return;
  }

  /*
    private method
    パスワード、アカウント名、従業員コード、氏名、所属営業所のvalidation
  */
  _validation() {
    const data = {...this.employeeData};
    let errors = [];

    Object.keys(requiredInputField).forEach((key) => {
      if (key === 'mtb_sales_office_id') {
        if (data[key] === '0') { //未選択時
          errors.push(requiredInputField[key]);
        } else {
          this.employeeData[key] = parseInt(data[key]); //ドロップダウンの返り値はStringなのでIntに直す
        }
      } else {
        if (GeneralFunctions.isNullOrEmpty(data[key])) errors.push(requiredInputField[key]);
      }
    });
    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力・選択してください。";
    };
    const passwordError = this._passwordValidation();
    
    if (passwordError) {
      return passwordError;
    }

    return;
  }

  /*
    private method
  　従業員データとAPIトークンを付随してAPIを呼び出し
  */
  async _send() {
    try {
      const json = [this.employeeData];
      this.response.data = this.employeeData;
      await axios.post(MTB_EMPLOYEE_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error('bad request');
          }
        }).then(({ data }) => {
          if (data[0]) {
            this.response.data = data[0];
            return;
          } else {
            throw new Error('アカウント名とパスワードが一致しませんでした。');
          }
        })
    } catch (error) {
      return error.message;
    }
  }
  
  /*
    public method
  　外部から呼び出すメソッド
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._send();
    if (error) {
      this.response.error = error;
    }
    this.response.error = "";

    return this.response;
  };

}

export default EmployeeFunctions;