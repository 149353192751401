import axios from 'axios';
//apis
import {
  ENTERPRISE_DATA_URL,
  SALES_OFFICE_DATA_URL,
  PARTNER_DATA_URL,
  PAYMENT_METHOD_URL,
  PRODUCT_URL,
  TAX_URL,
  STORE_URL,
  EMPLOYEE_URL,
  CUSTOMER_SUPPORT_URL,
  PRODUCT_FAVORITE_URL
} from 'src/apis';
//actions
import { setErrorMessage } from './ErrorMessageHandler';

export const SET_ENTERPRISE_DATA = "SET_ENTERPRISE_DATA";
export const SET_SALES_OFFICE_DATA = "SET_SALES_OFFICE_DATA";
export const SET_PARTNER_DATA = "SET_PARTNER_DATA";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";
export const SET_TAX_DATA = "SET_TAX_DATA";
export const SET_EMPLOYEE_DATA = "SET_EMPLOYEE_DATA";
export const SET_MASTER_DATA_FAILED = "SET_MASTER_DATA_FAILED";
export const SET_STORE_DATA = "SET_STORE_DATA";
export const REMOVE_ALL_MASTER_DATA = "REMOVE_ALL_MASTER_DATA";
export const SET_CUSTOMER_SUPPORT_MASTER_SELECTION = "SET_CUSTOMER_SUPPORT_MASTER_SELECTION";
export const SET_CUSTOMER_SUPPORT_MASTER_SELECTION_FAILED = "SET_CUSTOMER_SUPPORT_MASTER_SELECTION_FAILED";
export const REMOVE_CUSTOMER_SUPPORT_MASTER_SELECTION = "REMOVE_CUSTOMER_SUPPORT_MASTER_SELECTION";


/*
* 企業データを引っ張ってくる関数
* store/masterの enterpriseDataに格納する
*/
export const setEnterpriseData = () => async (dispatch, getState) => {
  try {
    const { mtb_enterprise_id, token } = getState().auth;
    let url = `${ENTERPRISE_DATA_URL}?id=${mtb_enterprise_id}`;

    if (mtb_enterprise_id) {
      await axios.get(ENTERPRISE_DATA_URL, {
        params: {
          id: mtb_enterprise_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error("Could not get the enterprise data.");
        })
        .then(({ data }) => {
          const _data = arrayToObject(data, "id");
          dispatch({
            type: SET_ENTERPRISE_DATA,
            payload: { enterpriseData: _data }
          })
        })
    } else {
      throw new Error("enterprise id not found");
    }
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("action setEnterPrise", error));
  }
}

/*
* 営業所データを引っ張ってくる関数
* store/masterの salesOfficeDataに格納する
*/
export const setSalesOfficeData = () => async (dispatch, getState) => {
  try {
    const { mtb_enterprise_id, mtb_sales_office_id, token } = getState().auth;

    if (mtb_enterprise_id) {
      await axios.get(SALES_OFFICE_DATA_URL, {
        params: {
          id: mtb_sales_office_id,
          mtb_enterprise_id: mtb_enterprise_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error("Could not get the enterprise data.");
        })
        .then(({ data }) => {
          const _data = arrayToObject(data, "id");
          dispatch({
            type: SET_SALES_OFFICE_DATA,
            payload: { salesOfficeData: _data }
          })
        })
    } else {
      throw new Error("sales office id not found");
    }
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("action setSalesOfficeData", error));
  }
}

/*
setPartnerData：提携先をフェッチしてくる際に呼び出す関数
店舗設定一覧：MtbStoreList.js　ログインユーザーの企業Idでフェッチ
企業設定詳細：MtbEnterpriseDetail.js　選択された企業Idでフェッチ
工事詳細：ConstructionDetail.js　ログインユーザーの企業Idでフェッチ
にて呼び出している
*/
export const setPartnerData = (selectedEnterpriseId) => async (dispatch, getState) => {
  try {
    let mtb_enterprise_id = selectedEnterpriseId;
    const { token } = getState().auth;
    if (!mtb_enterprise_id) {
      mtb_enterprise_id = getState().auth.mtb_enterprise_id;
    }
    if (mtb_enterprise_id) {
      await axios.get(PARTNER_DATA_URL, {
        params: {
          mtb_enterprise_id: mtb_enterprise_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error("Could not get the enterprise data.");
        })
        .then(({ data }) => {
          const _data = arrayToObject(data, "id");
          dispatch({
            type: SET_PARTNER_DATA,
            payload: { partnerData: _data }
          })
        })
    } else {
      throw new Error("sales office id not found");
    }
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("action setPartnerData", error));
  }
}

/*
* 支払い方法データを引っ張ってくる関数
* store/masterの paymentMethodsに格納する
*/
export const setPaymentMethods = () => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;
    await axios.get(PAYMENT_METHOD_URL, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("Could not get payment methods");
      })
      .then(({ data }) => {
        const paymentMethods = arrayToObject(data, "id")
        dispatch({
          type: SET_PAYMENT_METHODS,
          payload: { paymentMethods: paymentMethods }
        });
      })
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("setPaymentMethods", error));
  }
}

/*
* 商品データを引っ張ってくる関数
* store/masterの productDataに格納する
*/
export const setProduct = () => async (dispatch, getState) => {
  try {
    const { token, mtb_sales_office_id } = getState().auth;

    const response = await axios.get(PRODUCT_FAVORITE_URL, {
      params: {
        mtb_sales_office_id: mtb_sales_office_id
      },
      headers: { "Authorization": `Bearer ${token}` }
    });
    if (response.status !== 200) {
      throw new Error("Could not get product data with status code : ", response.status);
    }
    const favoriteData = response.data;

    await axios.get(PRODUCT_URL, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("Could not get product data with status code : ", response.status);
      })
      .then(({ data }) => {
        const preprocessedData = preprocessProductData(data, favoriteData);
        const _data = arrayToObject(preprocessedData, "id");
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: { productData: _data }
        })
      })
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("setProduct", error));
  }
};

const preprocessProductData = (product, productFavorite) => {
  if (productFavorite && productFavorite.length > 0) {
    //お気に入り登録済みユーザー
    return product.map((p) => {
      const favorite = productFavorite.filter((fav) => fav.mtb_product_id === p.id).pop();
      if (!favorite) {
        return { ...p, mtb_product_favorite_id: null, is_favorite: 0 };
      }
      return { ...p, mtb_product_favorite_id: favorite.id, is_favorite: favorite.is_favorite };
    })
  } else {
    //お気に入り未登録ユーザー
    return product.map((p) => {
      return { ...p, mtb_product_favorite_id: null, is_favorite: 0 };
    });
  }
}

/*
* 消費税率データを引っ張ってくる関数
* store/masterの taxDataに格納する
*/
export const setTax = () => async (dispatch, getState) => {
  try {
    const { token } = getState().auth;
    await axios.get(TAX_URL, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("Could not get tax data with status code : ", response.status);
      })
      .then(({ data }) => {
        const _data = arrayToObject(data, "id");
        dispatch({
          type: SET_TAX_DATA,
          payload: { taxData: _data }
        })
      })
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("setTax", error));
  }
}

const arrayToObject = (data, key) => {
  if (data.length === 0) return {};
  const obj = {};
  data.forEach((row) => {
    let _data = { ...row };
    // delete _data[key];
    obj[row[key]] = _data;
  });
  return obj;
}

/*
* 従業員データを引っ張ってくる関数
* store/masterの employeeListに格納する
*/
export const setEmployee = () => async (dispatch, getState) => {
  try {
    const { mtb_enterprise_id, token } = getState().auth;
    if (mtb_enterprise_id) {
      await axios.get(EMPLOYEE_URL, {
        params: {
          mtb_enterprise_id: mtb_enterprise_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error("Could not get the employee data.");
        })
        .then(({ data }) => {
          dispatch({
            type: SET_EMPLOYEE_DATA,
            payload: { employeeList: data }
          })
        });
    }
  } catch (error) {
    dispatch({
      type: SET_MASTER_DATA_FAILED,
    });
    dispatch(setErrorMessage("setEmployee", error));
  }
};

/*
* ログアウト時に呼び出される
*/
export const removeAllMasterData = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_MASTER_DATA
  })
};

export const setStoreData = () => async (dispatch, getState) => {
  // 工事詳細ページのconstructionInfo.jsで提携先決定時にmtb_partner_idを元にDBからの店舗データを格納する
  // 各種設定の店舗設定ページでmtb_partner_idを元にDBからの店舗データを格納する
  try {
    const { token, mtb_enterprise_id } = getState().auth;
    await axios.get(STORE_URL, {
      params: {
        mtb_enterprise_id: mtb_enterprise_id
      }
      ,
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("could not get store data");
      })
      .then(({ data }) => {
        const stores = arrayToObject(data, "id");
        dispatch({
          type: SET_STORE_DATA,
          payload: { storeData: stores }
        });
      })
  } catch (error) {
    console.log(error);
  }
};

/*
* 顧客対応履歴の内容、詳細１、詳細２のマスタデータをフェッチしてくる関
* storeのcustomerSupportDataに格納される
*/
export const setCustomerSupportMasterSelectionData = (mtb_sales_office_id, category) => async (dispatch, getState) => {
  try {
    const errorText = "Customer support master data could not be obtained."
    const { token } = getState().auth;
    await axios.get(CUSTOMER_SUPPORT_URL, {
      params: {
        mtb_sales_office_id: mtb_sales_office_id,
        category: category
      },
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error(errorText);
      })
      .then(({ data }) => {
        const mtbCustomerSupport = {};
        if (data.length > 0) {
          let category =  data[0].category;
          mtbCustomerSupport[category] = customerSupportDataPreprocessing(data);
        }
        dispatch({
          type: SET_CUSTOMER_SUPPORT_MASTER_SELECTION,
          payload: { data: mtbCustomerSupport }
        });
      })
  } catch (error) {
    dispatch({
      type: SET_CUSTOMER_SUPPORT_MASTER_SELECTION_FAILED,
    });
    dispatch(setErrorMessage({ where: "setCustomerSupportMasterSelectionData", msg: error.message }));
  }
}

const customerSupportDataPreprocessing = (data) => {
  if (data.length === 0) return [];
  let supportData = [];
  data.forEach((supportOption) => {
    const json = { ...supportOption };
    supportData.push(json);
  });
  return supportData;
}

export const removeCustomerSupportMasterSelectionData = () => (dispatch) => {
  dispatch({
    type: REMOVE_CUSTOMER_SUPPORT_MASTER_SELECTION
  })
}
