import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector } from 'react-redux';
//functions
import { getPartnerData } from 'src/functions/mtbFunctions/PartnerFunctions';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';

const columnNames = {
  partner_code: { label: '提携先コード', width: '33%', align: 'left' },
  partner_name: { label: '提携先名', width: '33%', align: 'left' },
  label: { label: '指定工事会社表記', width: '34%', align: 'left' }
};

/*
  提携先一覧表示コンポーネント
  各行からからその提携先詳細ページへ推移
  新規登録ボタンから登録ページへ
*/
const MtbPartnerList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { token } = useSelector(state => state.auth);
  const [data, setData] = useState({});

  useEffect(() => {
    const initAllPartnerData = async () => {
      //DBから提携先データをフェッチ
      const _partnerData = await getPartnerData(token);
      setData(_partnerData);
    }

    if (token) {
      initAllPartnerData();
    }
  }, [token]);

  const navigateToDetail = useCallback((id) => {
    history.push({ pathname: `${pathname}/${id}`, state: { data: data[id] } });
  }, [history, pathname, data]);

  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <CustomTable>
        <ColumnNameWrapper>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </ColumnNameWrapper>
        <tbody>
          {
            Object.keys(data).length > 0 && Object.keys(data).map((key) => {
              return <Row data={data[key]} id={key} key={data[key].id} />
            })
          }
        </tbody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  align-self: center;
  width: 70%;
  min-width: 600px;
  max-width: 800px;
`

export default MtbPartnerList;