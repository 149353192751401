import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//components
import {TextHalfNumberInput} from 'src/common/Inputs';
import DateSelector from 'src/common/DateSelector';
//configs and styles
import { Colors } from 'src/configs/StyleConfig';
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';

/*
  消費税情報テキスト表示、編集の子コンポーネント
*/
const TaxInput = (props) => {

  const { data, isEditing, errorText, onChange, onEditButtonPressed, onSaveButtonPressed, onCancelButtonPressed, onDeleteButtonPressed } = props;
  const { start_date, tax_rate } = data;

  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                適用開始日
              </SettingList>
              <DateSelector
                isEnable={isEditing}
                date={start_date}
                onChange={(val) => onChange('start_date', val)}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                消費税率
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={tax_rate} onChange={(val) => onChange('tax_rate', val.target.value)} />
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`

TaxInput.propTypes = {
  data: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

TaxInput.defaultProps = {
  data: {},
  isEditing: false,
  errorText: '',
  onChange: () => { },
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default TaxInput;
