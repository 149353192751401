import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';


/*
電話番号チェッククラス
引数の電話番号が指定の日本のフォーマットに沿っているか確認
確認後日本のフォーマットに変換
変換された電話番号を返す
*/
class PhoneNumberFunctions {

  /*
    private method
    日本の電話番号チェック
  */
  _phoneNumberNoneProblem(val) {
    const parsedPhoneNumber = parsePhoneNumber('+81' + val, 'JA');
    if (parsedPhoneNumber) {
      return parsedPhoneNumber.isValid();
    }
    return false;
  }

  /*
    private method
    日本の電話番号のフォーマットへ変更
  */
  _formatTel(val) {
    return new AsYouType('JP').input(val);
  }

  /*
    public method
    外部から呼び出す関数
    日本のフォーマットに変換された電話番号を返す
  */
  phoneValidation(phone) {

    let response = { error: '', validPhoneNumber: null};
    const errorText = `電話番号の形式が正しくありません`;

    if (!this._phoneNumberNoneProblem(phone)) {
      response.error = errorText;
      return response;
    }
    const formattedPhoneNumber = this._formatTel(phone);
    const isHyphen = RegExp('-');
    if (!isHyphen.test(formattedPhoneNumber)) {
      response.error = errorText;
      return response;
    }

    response.validPhoneNumber = formattedPhoneNumber;
    return response;
  }

};

export default new PhoneNumberFunctions();