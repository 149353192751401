import axios from "axios";
//functions
import GeneralFunctions from "../GeneralFunctions";
//apis
import { MTB_PARTNER_DATA_CHANGE_URL, IMAGE_UPLOAD_URL, IMAGE_URL, ALL_PARTNER_DATA_URL } from 'src/apis';

const inputRequiredField = {
  partner_code: '提携先コード', partner_name: '提携先名'
}


/*
提携先設定　提携先の登録/編集クラス
- Parameters in constructor:
 - partnerData: 提携先データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: 登録/編集した提携先データ
*/
class PartnerFunctions {

  constructor(props) {
    const { partnerData, token } = props;
    this.partnerData = partnerData;
    this.token = token;
    this.response = { error: '', data: {} };
  }

  /*
    private method
    必須項目チェック
  */
  _validation() {
    const data = { ...this.partnerData };
    let errors = [];

    Object.keys(inputRequiredField).forEach((key) => {
      if (GeneralFunctions.isNullOrEmpty(data[key])) {
        errors.push(inputRequiredField[key]);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力・選択してください。";
    };

    return;
  }

  /*
    private method
    アップロードされた提携先画像blob array からimage Fileに直す関数（S3に送る用）
  */
  async _getImageFileObject(blobURL, file_name, partner_code) {
    const config = { responseType: 'blob' };
    const response = await axios.get(blobURL, config); // token 不必要
    const fileUrl = new Date().valueOf().toString() + "_" + partner_code;
    const file = new File([response.data], fileUrl, { type: "image/jpeg" });
    const json = { image_file: file, file_name: file_name };
    return json;
  };

  /*
    private method
    image FileをS3に送る関数
    画像のURLと画像名等を返す
  */
  async _uploadImage(imageObj) {
    const params = new FormData();
    params.append(imageObj.file_name, imageObj.image_file);
    return (await axios.post(IMAGE_UPLOAD_URL, params,
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },

      })).data;
  };

  /*
    private method
    _uploadImageから受け取った画像名（signed_url）と入力された提携先データを付随し
    提携先APIを呼び出す関数
  */
  async _send() {
    try {
      const data = { ...this.partnerData };
      let signed_url = data.signed_url;
      if (data.has_image_changed) {
        const imageObject = await this._getImageFileObject(data.signed_url, data.partner_name, data.partner_code);
        const imageUploadResult = await this._uploadImage(imageObject);
        const image = imageUploadResult[0];
        signed_url = image.originalname;
      }
      let json = {
        id: data.id,
        partner_code: data.partner_code,
        partner_name: data.partner_name,
        is_deleted: data.is_deleted,
        label: data.label,
        logo_url: signed_url,
        created_employee_name: data.created_employee_name,
        updated_employee_name: data.updated_employee_name
      };

      this.response.data = data;
      const sendingData = [json];
      await axios.post(MTB_PARTNER_DATA_CHANGE_URL, sendingData, { headers: { "Authorization": `Bearer ${this.token}` } })
        .then((response) => {
          if (response.status === 200) {
            return;
          }
          throw new Error('提携先情報を登録/編集できませんでした。');
        })
    } catch (error) {
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
    登録/編集した提携先データもしくはエラーを返す
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    error = await this._send();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }
}

export default PartnerFunctions;

/*
  提携先一覧で表示する全ての提携先データを引っ張ってくる関数
*/
export const getPartnerData = async (token) => {
  try {
    const response = await axios.get(ALL_PARTNER_DATA_URL, { headers: { "Authorization": `Bearer ${token}` } });
    const { data } = response;
    const preprocessedData = await preprocessing(data, token);
    return preprocessedData;
  } catch (error) {
    return [];
  }
};

/*
  一つ一つの提携先データの画像URLを取ってきてsigned_urlとして格納する関数
*/
const preprocessing = async (data, token) => {
  if (data.length === 0) return [];

  const promises = data.map(async (partner) => {
    let json = { ...partner };
    json['signed_url'] = await getSignedUrl(json.logo_url, token);
    return json;
  });

  return Promise.all(promises);
}

/*
  提携先画像名（logo_url）を元に著名付き画像URLを発行し返す関数
*/
const getSignedUrl = async (logo_url, token) => {
  try {
    if (!logo_url) return '';
    const response = await axios.get(IMAGE_URL, {
      params: {
        filename: logo_url,
        originalFilename: logo_url
      },
      headers: { "Authorization": `Bearer ${token}` }
    });
    if (response.status !== 200) return '';
    const { data } = response;
    return data;
  } catch (error) {
    return '';
  }
}