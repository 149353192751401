import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { Colors } from 'src/configs/StyleConfig';
//components
import Checkbox from 'src/common/Checkbox';
import { SubmitButton, CancelButton } from 'src/common/StylesComponents';
import Modal from 'react-modal';

const modalStyles = {
  content: {
    width: '50%',
    minWidth: '320px',
    maxWidth: '600px',
    height: '30%',
    minHeight: '360px',
    maxHeight: '400px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)"
  },
};


const ConstructionPrintModal = (props) => {
  
  const { isPrintModalOpen, onCancelButtonPressed, onPrintButtonPressed } = props;

  const [isFirstOption, setIsFirstOption] = useState(0); //true = 企業名＋営業所名, false = フマキラー出力時の設定

  return (
    <Modal
      style={modalStyles}
      isOpen={isPrintModalOpen}
    >
      <h3 style={{padding: '20px 0 0 20px'}}>印刷する営業所情報を選択</h3>
      <div style={{ display: 'flex', width: '70%', margin: '40px auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <CheckBoxWrapper>
          <Checkbox
            isChecked={isFirstOption === 0}
            changeStatus={() => setIsFirstOption(0)}
            label={'企業名＋営業所名'}
            fontSize={16}
            type={'radio'} // radio or checkbox
          />
        </CheckBoxWrapper>
        <CheckBoxWrapper>
          <Checkbox
            isChecked={isFirstOption === 1}
            changeStatus={() => setIsFirstOption(1)}
            label={'企業名＋営業所名（表面のみ）'}
            fontSize={15}
            type={'radio'} // radio or checkbox
          />
        </CheckBoxWrapper>
        <CheckBoxWrapper>
          <Checkbox
            isChecked={isFirstOption === 2}
            changeStatus={() => setIsFirstOption(2)}
            label={'フマキラー出力時の設定'}
            fontSize={15}
            type={'radio'} // radio or checkbox
          />
        </CheckBoxWrapper>
        <CheckBoxWrapper>
          <Checkbox
            isChecked={isFirstOption === 3}
            changeStatus={() => setIsFirstOption(3)}
            label={'フマキラー出力時の設定（表面のみ）'}
            fontSize={15}
            type={'radio'} // radio or checkbox
          />
        </CheckBoxWrapper>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }}>
        <CancelButton onClick={() => onCancelButtonPressed()}>キャンセル</CancelButton>
        <SubmitButton style={{width: '140px'}} onClick={() => onPrintButtonPressed(isFirstOption)}>印刷データ作成</SubmitButton>
      </div>
    </Modal>
  )
};

const CheckBoxWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: flex-start;
`

ConstructionPrintModal.propTypes = {
  isPrintModalOpen: PropTypes.bool.isRequired,
  modalButtonPressed: PropTypes.func.isRequired,
};
ConstructionPrintModal.defaultProps = {
  isPrintModalOpen: false,
  modalButtonPressed: () => { }
};


export default ConstructionPrintModal;