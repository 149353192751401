import React from 'react';
//components
import {BaseBodyStyle} from 'src/common/StylesComponents';

const NotFound = () => {

  return (
    <BaseBodyStyle>
      <h1>The page is not found</h1>
    </BaseBodyStyle>
  )
};

export default NotFound;