import axios from "axios";
//apis
import { MTB_PRODUCT_FAVORITE_DATA_CHANGE_URL, PRODUCT_FAVORITE_URL } from "src/apis";


/*
お気に入り商品　登録/編集クラス
  mtb_product_favorite(中間テーブル, 企業ごとに登録可)に登録、編集する関数ブロック
- Parameters in constructor:
 - productData: 商品データ
 - productFavoriteData: お気に入りデータ
 - mtb_sales_office_id: 営業所Id
 - employee_name: 従業員名
 - token: APIアクセストークン
*/
class ProductFavoriteFunctions {

  constructor(props) {
    const { productData, productFavoriteData, mtb_sales_office_id, employee_name, token } = props;
    this.productData = productData;
    this.productFavoriteData = productFavoriteData;
    this.mtb_sales_office_id = mtb_sales_office_id;
    this.token = token;
    this.employee_name = employee_name;
  }

  /*
    private method
    product favorite DBに送るデータの準備
    mtb_product の追加カラムmtb_product_favorite_idがNullの時（mtb_product_favoriteに行が存在しない、もしくはis_deleted=1）:
    (1) mtb_product_favoriteにid=nullで行追加（mtb_product_favoriteのis_favoriteには渡されたproductDataのis_favoriteを代入） 
    mtb_product の追加カラムmtb_product_favorite_idがNullでない時：
    (2) mtb_product_favoriteにidをmtb_product_favorite_idでis_favoriteをUpdate
  */
  _getSendingData() {
    const products = { ...this.productData };
    const favorites = { ...this.productFavoriteData };

    let updatedProductFavoriteData = [];
    Object.keys(products).forEach((key) => {
      const product = products[key];
      if (product.mtb_product_favorite_id === null) { // (1) 
        // product favoriteに新規行登録（idをnull, is_favoriteをユーザーの商品毎の選択結果に）
        const json = {
          id: null,
          mtb_sales_office_id: this.mtb_sales_office_id,
          mtb_product_id: product.id,
          is_favorite: product.is_favorite,
          created_employee_name: this.employee_name,
          updated_employee_name: this.employee_name
        };
        updatedProductFavoriteData.push(json);
      } else { //(2)
        //product favoriteをアップデート
        let json = favorites[product.id];
        json["is_favorite"] = product.is_favorite;
        json["updated_employee_name"] = this.employee_name;
        updatedProductFavoriteData.push(json);
      }
    });

    return updatedProductFavoriteData;
  };

  /*
    private method
    お気に入り商品API送信メソッド
    成功時：
    None
    失敗時：
    error を返す
  */
  async _send(sendingData) {
    try {
      await axios.post(MTB_PRODUCT_FAVORITE_DATA_CHANGE_URL, sendingData, { headers: { "Authorization": `Bearer ${this.token}` } })
        .then((response) => {
          if (response.status === 200) {
            return;
          } else {
            throw new Error("Could not update product favorite data");
          }
        });
    } catch (error) {
      console.log(error.message);
      return "商品をお気に入り登録できませんでした。";
    }
  }

  /*
    public method
    外部から呼び出す関数
  */
  async sendData() {
    const sendingProductFavoriteData = this._getSendingData();
    const error = await this._send(sendingProductFavoriteData);
    if (error) {
      return error;
    }

    return;
  }
};

export default ProductFavoriteFunctions;


/*
  営業所Idを元にお気に入り商品フェッチ関数
  MtbProductFavoriteEditで呼び出す
- Parameters:
 - token: トークン
 - mtb_sales_office_id: 営業所ID
- Return:
 - 営業所Idでフィルタリングされたお気に入り商品
*/
export const getFavoriteProducts = async (mtb_sales_office_id, token) => {
  try {
    const response = await axios.get(PRODUCT_FAVORITE_URL, {
      params: {
        mtb_sales_office_id: mtb_sales_office_id
      },
      headers: { "Authorization": `Bearer ${token}` }
    });
    if (response.status === 200) {
      const data = arrayToObjectByProductKey(response.data);
      return data;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

const arrayToObjectByProductKey = (productFavoriteData) => {
  const data = [...productFavoriteData];
  let productFavoriteObj = {};
  data.forEach((product) => {
    productFavoriteObj[product.mtb_product_id] = product;
  });
  return productFavoriteObj;
};
