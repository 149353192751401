// ドメイン名
export const DOMAIN_NAME = 'fts-anshin.jp';
export const BACKEND_DOMAIN_NAME = 'backend.fts-anshin.jp';

// API接続先
//プロダクションでAPI接続先をbackend.fts-anshin.jpに変更
let BASE_URL = 'http://localhost:8081';
if (process.env.NODE_ENV === 'production') {
    BASE_URL = `https://${BACKEND_DOMAIN_NAME}`;
}

//auth
const AUTH_URL = BASE_URL + '/auth';
export const LOGIN_URL = AUTH_URL + '/signin'; //ユーザーログイン

//fetch -- master data ( all GET method )
export const FETCH_URL = BASE_URL + '/fetch';
export const CUSTOMER_DATA_URL = FETCH_URL + '/mtb_customer';
export const ENTERPRISE_DATA_URL = FETCH_URL + '/mtb_enterprise';
export const SALES_OFFICE_DATA_URL = FETCH_URL + '/mtb_sales_office';
export const PARTNER_DATA_URL = FETCH_URL + '/mtb_partner'; //mtb_enterprise_id
export const ALL_PARTNER_DATA_URL = FETCH_URL + '/mtb_partner_all'; // PartnerFunctions.js で使用
export const PAYMENT_METHOD_URL = FETCH_URL + '/mtb_payment_method';
export const PRODUCT_URL = FETCH_URL + '/mtb_product';
export const STORE_URL = FETCH_URL + '/mtb_store'; //mtb_partner_id src/views/construction/common/ConstructionInfo.js で使用
export const TAX_URL = FETCH_URL + '/mtb_tax';
export const EMPLOYEE_URL = FETCH_URL + '/mtb_employee'; //mtb_enterprise_id
export const PRODUCT_FAVORITE_URL = FETCH_URL + '/mtb_product_favorite'; // mtb_sales_office_id;

export const TAX_RATE_URL = FETCH_URL + '/tax_rate'; //date

//fetch -- db data
export const CUSTOMER_CODE_QUERY_URL = FETCH_URL + '/customer_code'; //mtb_enterprise_id & mtb_sales_office_id
export const CUSTOMER_SUPPORT_DATA_URL = FETCH_URL + '/dtb_customer_support' //mtb_customer_id
export const ALL_CONSTRUCTION_DATA_URL = FETCH_URL + '/dtb_construction'; // src/redux/actions/ConstructionDataHandler で使用
export const CONSTRUCTION_DETAIL_DATA_URL = FETCH_URL + '/dtb_construction_detail'; // dtb_construction_id　src/redux/actions/ConstructionDataHandler で使用　工事詳細ページの工事内訳で表示
export const WARRANTY_URL = FETCH_URL + '/dtb_warranty'; //dtb_construction_id src/redux/actions/WarrantyHandler.jsで使用 Warranty.jsにて表示
export const ALL_WARRANTY_URL = FETCH_URL + '/dtb_warranty_all';
export const CUSTOMER_SUPPORT_URL = FETCH_URL + '/mtb_customer_support'; //mtb_sales_office_id, category 
export const IMPORT_FILE_URL = FETCH_URL + '/dtb_import_file'; //dtb_construction_id src/functions/FileUploadFunctions.jsで使用 FileUpload.jsにて表示
export const SALES_AGGREGATION_URL = FETCH_URL + "/sales_aggregation"; // query_start_date, query_end_date, mtb_customer_id, mtb_enterprise_id, mtb_sales_office_id 売上集計ページにて使用

export const LOG_URL = FETCH_URL + '/dtb_logs'; //mtb_enterprise_id

//insert or replace
const INSERT_OR_REPLACE_URL = BASE_URL + '/insert_or_replace';
export const CUSTOMER_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_customer';
export const CONSTRUCTION_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/dtb_construction';
export const CONSTRUCTION_DETAIL_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/dtb_construction_detail';
export const WARRANTY_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/dtb_warranty'; // constructionFunctions.jsにて使用
export const CUSTOMER_SUPPORT_CHANGE_URL = INSERT_OR_REPLACE_URL + '/dtb_customer_support';
export const IMPORT_FILE_CHANGE_URL = INSERT_OR_REPLACE_URL + '/dtb_import_file'; // constructionFunctions.jsにて使用

export const LOG_CHANGE_URL = INSERT_OR_REPLACE_URL + '/dtb_logs';

//mtb data
export const MTB_SALES_OFFICE_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_sales_office'; // SalesOfficeFunctions.js にて使用
export const MTB_EMPLOYEE_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_employee'; //  EmployeeFunctions.js にて使用
export const MTB_PAYMENT_METHOD_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_payment_method'; // PaymentMethodFunctions.js にて使用
export const MTB_PRODUCT_FAVORITE_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_product_favorite'; // ProductFavoriteFunctions.js にて使用
export const MTB_ENTERPRISE_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_enterprise'; // EnterpriseFunctions.js にて使用
export const MTB_PRODUCT_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_product'; //ProductFunctions.js にて使用
export const MTB_TAX_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_tax'; //TaxFunctions.js にて使用
export const MTB_STORE_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_store' //StoreFunctions.js にて使用
export const MTB_PARTNER_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_partner' //PartnerFunctions.js にて使用
export const MTB_BUSINESS_PARTNER_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_business_partner';
export const MTB_CUSTOMER_SUPPORT_DATA_CHANGE_URL = INSERT_OR_REPLACE_URL + '/mtb_customer_support'; // CustomerSupportContentFunctions.js にて使用

//post image files
export const IMAGE_UPLOAD_URL = BASE_URL + "/upload"; //画像ファイルアップロードURL POST files
//get image files
export const IMAGE_URL = BASE_URL + "/signedurl"; //画像ファイル取得URL GET filename

//delete
export const DELETE_URL = BASE_URL + '/delete';

//query -- master data ( all GET method )
export const QUERY_URL = BASE_URL + '/query';
export const SEARCH_CUSTOMER_URL = QUERY_URL + '/mtb_customer_search';
export const GET_SELECTED_CUSTOMER_URL = QUERY_URL + '/mtb_selected_customer';
export const GET_SELECTED_CONSTRUCTION_URL = QUERY_URL + '/dtb_selected_construction';
export const GET_SELECTED_CUSTOMER_CONSTRUCTION_URL = QUERY_URL + '/mtb_selected_customer_construction';
export const SEARCH_CONSTRUCTION_URL = QUERY_URL + '/dtb_construction_search';
export const SEARCH_WARRANTY_URL = QUERY_URL + '/dtb_warranty_search';
export const GET_CONSTRUCTION_CSV_URL = QUERY_URL + '/dtb_construction_csv'; // used to fetch construction data for csv export
export const GET_CUSTOMER_CSV_URL = QUERY_URL + '/mtb_customer_csv_by_ids'; //ConstructionList.jsで顧客のCSV出力に使用

export const ADDRESS_SEARCH_URL = 'https://api.zipaddress.net/';