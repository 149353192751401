import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
//styles and configs
import { FontSize, BreakPoints } from 'src/configs/StyleConfig';
import toast from 'react-hot-toast';

const Sidebar = () => {

  const history = useHistory();
  const { role } = useSelector(state => state.auth);

  /*
    サイドバーにて選択されたページへ推移する関数
  */
  const handleNavigation = useCallback((navigateTo) => {
    let pathName = '';
    switch (navigateTo) {
      case "顧客一覧":
        pathName = '/customer-list';
        break;
      case "工事一覧":
        pathName = '/construction-list';
        break;
      case "保証一覧":
        pathName = '/warranty-list';
        break;
      case "売上集計":
        pathName = '/sales-aggregation';
        break;
      case "各種設定":
        pathName = '/settings';
        break;
      case "変更履歴":
        pathName = '/logs';
        break;
      default:
        return;
    };
    toast.dismiss();
    history.push({ pathname: pathName });
  }, [history]);

  return (
    <Wrapper>
      <div style={{ minHeight: '12px' }} />
      <Section onClick={() => handleNavigation("顧客一覧")}>
        <Icon className="fa fa-user" />
        顧客一覧
      </Section>
      <Section onClick={() => handleNavigation("工事一覧")}>
        <Icon className="fa fa-wrench" />
        工事一覧
      </Section>
      <Section onClick={() => handleNavigation("保証一覧")}>
        <Icon className="fa fa-check" />
        保証一覧
      </Section>
      <Section onClick={() => handleNavigation("売上集計")}>
        <Icon className="fa fa-file-text-o" />
        売上集計
      </Section>
      <Section onClick={() => handleNavigation("各種設定")}>
        <Icon className="fa fa-gear" />
        各種設定
      </Section>
      {role === 1 &&
        <Section onClick={() => handleNavigation("変更履歴")}>
          <Icon className="fa fa-history" />
          変更履歴
        </Section>
      }
    </Wrapper >
  )
};

const Wrapper = styled.div`
  width: 200px;
  position: absolute;
  top: 70px;
  left: 0;
  background-color:  #f3f3f3;
  color: #3f3f3f;
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
  @media screen and (max-width: ${BreakPoints.xl}) {
    width: 100%;
    left: 0;
    right: 0;
    top: calc(100% - 70px);
    bottom: 0;
    height: 70px;
    justify-content: space-between;
    flex-direction: row;
    z-index: 100;
  }
`
const Section = styled.button`
  min-height: 50px;
  width: 200px;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  font-size: ${FontSize.lg};
  font-weight: bold;
  background-color:  #f3f3f3;
  color: #3f3f3f;
  :hover {
    background-color: #CCCCCC;
  }
  @media screen and (max-width: ${BreakPoints.xl}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${FontSize.sm};
  }
`
const Icon = styled.i`
  padding-right: 16px;
  font-size: ${FontSize.xl};
  @media screen and (max-width: ${BreakPoints.xl}) {
    padding: 4px 0;
  }
`
export default Sidebar;