import {
  SET_ENTERPRISE_DATA,
  SET_SALES_OFFICE_DATA,
  SET_PARTNER_DATA,
  SET_PAYMENT_METHODS,
  SET_TAX_DATA,
  SET_PRODUCT_DATA,
  SET_EMPLOYEE_DATA,
  SET_MASTER_DATA_FAILED,
  SET_STORE_DATA,
  REMOVE_ALL_MASTER_DATA,
  SET_CUSTOMER_SUPPORT_MASTER_SELECTION,
  SET_CUSTOMER_SUPPORT_MASTER_SELECTION_FAILED,
  REMOVE_CUSTOMER_SUPPORT_MASTER_SELECTION,
} from 'src/redux/actions/MasterDataHandler';

const initialState = {
  enterpriseData: {},
  salesOfficeData: {},
  partnerData: {},
  paymentMethods: {},
  taxData: {},
  productData: {},
  employeeList: [],
  storeData: {},
  customerSupportData: {},
};

const CustomerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTERPRISE_DATA:
      return {
        ...state,
        enterpriseData: action.payload.enterpriseData,
      };
    case SET_SALES_OFFICE_DATA:
      return {
        ...state,
        salesOfficeData: action.payload.salesOfficeData,
      };
    case SET_PARTNER_DATA:
      return {
        ...state,
        partnerData: action.payload.partnerData,
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload.productData,
      };
    case SET_TAX_DATA:
      return {
        ...state,
        taxData: action.payload.taxData
      };
    case SET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeList: action.payload.employeeList
      };
    case SET_MASTER_DATA_FAILED:
      return  {
        ...state
      };
    case SET_STORE_DATA:
      return {
        ...state,
        storeData: action.payload.storeData
      };
    case SET_CUSTOMER_SUPPORT_MASTER_SELECTION:
      return {
        ...state,
        customerSupportData: {...state.customerSupportData, ...action.payload.data},
      }
    case SET_CUSTOMER_SUPPORT_MASTER_SELECTION_FAILED:
      return {
        ...state
      }
    case REMOVE_CUSTOMER_SUPPORT_MASTER_SELECTION:
      return {
        ...state,
        customerSupportData: {},
      }
    case REMOVE_ALL_MASTER_DATA:
      return {
        enterpriseData: {},
        salesOfficeData: {},
        partnerData: {},
        paymentMethods: {},
        taxData: {},
        productData: {},
        employeeList: [],
        customerSupportData: {},
        storeData: {},
      }
    default:
      return state;
  }
};

export default CustomerDataReducer;