import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setPartnerData, setStoreData } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';

const columnNames = {
  store_code: { label: '店舗コード', width: '12%', align: 'left' },
  partner_name: { label: '提携先名', width: '10%', align: 'left' },
  store_name: { label: '店舗名', width: '12%', align: 'left' },
  store_kana: { label: 'ふりがな', width: '10%', align: 'left' },
  postal_code: { label: '郵便番号', width: '12%', align: 'left' },
  address: { label: '住所', width: '26%', align: 'left' },
  phone: { label: '電話番号', width: '18%', align: 'left' }
};


/*
  店舗設定リスト表示コンポーネント
  各行クリック：店舗詳細ページへ推移
  新規登録ボタン：店舗新規登録ページへ推移
*/
const MtbStoreList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const { partnerData, storeData } = useSelector(state => state.master);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  /*
    DBから提携先データをmtb_enterprise_idでフェッチ
  */
  useEffect(() => {
    dispatch(setPartnerData());
    dispatch(setStoreData());
  }, [dispatch]);

  /*
    提携先データ取得後、店舗データに提携先名を付随してstateに格納
  */
  const initStoreData = async () => {

    const attachPartnerData = (storeData) => {
      const partnerAttachedStoreData = storeData && Object.keys(storeData).map((id) => {
        let json = { ...storeData[id], "partner_name": partnerData[storeData[id].mtb_partner_id].partner_name };
        return json;
      });
      return partnerAttachedStoreData;
    }

    if (partnerData && storeData && token) {
      const _storeData = attachPartnerData(storeData);
      setData(_storeData);
      setIsLoading(false);
    }
  };
  useMemo(initStoreData, [partnerData, storeData, token]);

  /*
    各行からの詳細ページ推移関数
  */
  const navigateToDetail = useCallback((id) => {
    const selectedData = data.filter((store) => store.id === id).pop();
    if (selectedData) {
      history.push({ pathname: `${pathname}/${id}`, state: { data: selectedData } });
    }
  }, [history, pathname, data]);

  /*
    新規登録ボタンから店舗データ登録ページへ推移
  */
  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  if (isLoading) return <Container />

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <CustomTable>
        <ColumnNameWrapper>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </ColumnNameWrapper>
        <tbody>
          {
            data.length > 0 && data.map((store) => {
              return <Row data={store} id={store.id} key={store.id} />
            })
          }
        </tbody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  align-self: center;
  width: 80%;
  min-width: 700px;
  max-width: 900px;
`

export default MtbStoreList;