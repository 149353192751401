import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//components
import { BaseHeaderStyle, HeaderTitle, HeaderRight } from 'src/common/StylesComponents';

const ConstructionListHeader = (props) => {

  const { onCSVExportButtonPressed, onPostalCardExportButtonPressed, onPrintButtonPressed, onCustomerCSVExportButtonPressed, 
    isConstructionCSVExporting, isCustomerCSVExporting } = props;
  return (
    <BaseHeaderStyle>
      <HeaderTitle>
        工事一覧
      </HeaderTitle>
      <HeaderRight>
        <PrintButton onClick={onPrintButtonPressed}><i className="fa fa-print" style={{ paddingRight: '8px', fontSize: '12px' }} />印刷</PrintButton>
        <PrintButton onClick={onPostalCardExportButtonPressed} style={{ width: '132px' }}><i className="fa fa-print" style={{ paddingRight: '8px', fontSize: '12px' }} />はがき出力</PrintButton>
        <ExportButton disabled={isCustomerCSVExporting} onClick={onCustomerCSVExportButtonPressed}><i className="fa fa-download" style={{ paddingRight: '8px', fontSize: '12px' }} />顧客CSV</ExportButton>
        <ExportButton disabled={isConstructionCSVExporting} onClick={onCSVExportButtonPressed}><i className="fa fa-download" style={{ paddingRight: '8px', fontSize: '12px' }} />工事CSV</ExportButton>
      </HeaderRight>
    </BaseHeaderStyle>
  )
};

ConstructionListHeader.propTypes = {
  onCustomerCSVExportButtonPressed: PropTypes.func.isRequired,
  onCSVExportButtonPressed: PropTypes.func.isRequired,
  onPostalCardExportButtonPressed: PropTypes.func.isRequired,
  onPrintButtonPressed: PropTypes.func,
  isCustomerCSVExporting: PropTypes.bool,
  isConstructionCSVExporting: PropTypes.bool,

};

ConstructionListHeader.defaultProps = {
  onCustomerCSVExportButtonPressed: () => { },
  onCSVButtonPressed: () => { },
  onPostalCardExportButtonPressed: () => { },
  onPrintButtonPressed: () => { },
  isCustomerCSVExporting: false,
  isConstructionCSVExporting: false
};

const ConstructionRegisterButton = styled.button`
  color: #ffffff;
  background-color: #70c66b;
  border: 2px solid #70c66b;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 20px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #70c66b;
    background-color: #ffffff;
    border: 2px solid #70c66b;
  }
`

export const PrintButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export const ExportButton = styled.button`
  width: 120px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
  :disabled {
    color: gray;
    background-color: #3f3f3f;
    border: 2px solid #3f3f3f;
    cursor: default;
  }
`


export default ConstructionListHeader;