import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Overlay } from 'src/common/StylesComponents';
import { Colors, FontSize } from "src/configs/StyleConfig";
import {TextInput} from "src/common/Inputs";


const PasswordConfirmationModal = (props) => {

  const { modalRequired, closeConfirmModal, confirmedPassword, changeConfirmedPassword, onConfirmButtonPressed} = props;
  return (
    <>
      {modalRequired && (
        <Overlay>
          <ModalWrapper>
            <CustomModalBody>
              <div>{'記入している従業員アカウントのパスワードを入力してください。'}</div>
            </CustomModalBody>
            <div style={{width: '320px', alignSelf: 'center'}}>
              <TextInput textType={'password'} value={confirmedPassword} onChange={changeConfirmedPassword} />
            </div>
            <ButtonWrapper>
              <ModalButton  onClick={closeConfirmModal}>閉じる</ModalButton>
              <ModalButton Color={Colors.modalButton} onClick={onConfirmButtonPressed}>確認</ModalButton>
            </ButtonWrapper>
          </ModalWrapper>
        </Overlay>
      )}
  </>
  )
};


const ModalWrapper = styled.div`
  width: 420px;
  height: 160px;
  border-radius: 12px;
  padding: 20px;
  background-color: white;
  font-size: ${FontSize.md};
  color: ${Colors.textColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 20;
`
const CustomModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
  font-weight: 500;
  padding-left: 8px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  button + button {
    margin-left: 1rem;
  }
`
const ModalButton = styled.button`
  width: 100px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: ${props => props.Color ? props.Color : 'white'};
  color: ${props => props.Color ? 'white' : Colors.textColor};
`
PasswordConfirmationModal.propTypes = {
  modalRequired: PropTypes.bool.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  confirmedPassword: PropTypes.string.isRequired,
  changeConfirmedPassword: PropTypes.func.isRequired,
  onConfirmButtonPressed: PropTypes.func.isRequired
};
PasswordConfirmationModal.defaultProps = {
  modalRequired: false,
  closeConfirmModal: () => {},
  confirmedPassword: '',
  changeConfirmedPassword: () => {},
  onConfirmButtonPressed: () => {}
};

export default PasswordConfirmationModal;