import React from 'react';
import Router from './views/Router';
import styled from 'styled-components';

function App() {

  return (
    <Wrapper>
      <Router />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  overflow: scroll;
`

export default App;
