import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ConstructionDataReducer from './ConstructionDataReducer';
import CustomerDataReducer from './CustomerDataReducer';
import MasterDataReducer from './MasterReducer';
import ErrorMessageReducer from './ErrorMessageReducer';
import CustomerSupportDataReducer from './CustomerSupportDataReducer';
import WarrantyReducer from './WarrantyReducer';

const allReducer = combineReducers({
  auth: AuthReducer,
  construction: ConstructionDataReducer,
  warranty: WarrantyReducer,
  customerSupport: CustomerSupportDataReducer,
  customer: CustomerDataReducer,
  master: MasterDataReducer,
  error: ErrorMessageReducer,
});

export default allReducer;