import {
  SET_CUSTOMER_SUPPORT_DATA,
  SET_CUSTOMER_SUPPORT_DATA_FAILED,
  SET_SELECTED_CUSTOMER_SUPPORT_DATA,
  SET_SELECTED_CUSTOMER_SUPPORT_DATA_FAILED,
  UPDATE_SELECTED_CUSTOMER_SUPPORT_DATA,
  ADD_REGISTERING_CUSTOMER_SUPPORT_DATA,
  REMOVE_SELECTED_CUSTOMER_SUPPORT_DATA,
  REMOVE_CUSTOMER_SUPPORT_DATA
} from "src/redux/actions/CustomerSupportDataHandler";


const initialState = {
  customerSupportList: [],
  selectedCustomerSupportData: {},
  isEditing: false,
};

const CustomerSupportDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_SUPPORT_DATA:
      return {
        ...state,
        customerSupportList: action.payload.data
      };
    case SET_CUSTOMER_SUPPORT_DATA_FAILED:
      return {
        customerSupportList: [],
        isEditing: false,
      };
    case REMOVE_SELECTED_CUSTOMER_SUPPORT_DATA:
        return {
          ...state,
          customerSupportList: []
        }
    case SET_SELECTED_CUSTOMER_SUPPORT_DATA:
      return {
        ...state,
        selectedCustomerSupportData: action.payload.selectedCustomerSupportData,
      };
    case SET_SELECTED_CUSTOMER_SUPPORT_DATA_FAILED:
      return {
        ...state,
        selectedCustomerSupportData: {},
      };
    case UPDATE_SELECTED_CUSTOMER_SUPPORT_DATA:
      return {
        ...state,
        customerSupportList: action.payload.updatedCustomerSupportData,
        selectedCustomerSupportData: action.payload.selectedCustomerSupportData
      };
    case ADD_REGISTERING_CUSTOMER_SUPPORT_DATA:
      return {
        ...state,
        customerSupportList: action.payload.updatedCustomerSupportData,
        selectedCustomerSupportData: action.payload.selectedCustomerSupportData
      }
    case REMOVE_CUSTOMER_SUPPORT_DATA:
      return {
        customerSupportList: [],
        selectedCustomerSupportData: {},
        isEditing: false,
      };
    default:
      return state;
  }
};

export default CustomerSupportDataReducer;