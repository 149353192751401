import React from 'react';
//components
import {BaseBodyStyle} from 'src/common/StylesComponents';

const NoAuthority = () => {

  return (
    <BaseBodyStyle>
      <h2>権限がない為閲覧できません</h2>
    </BaseBodyStyle>
  )
};

export default NoAuthority;