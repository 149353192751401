import { SET_AUTH_STATUS, SET_AUTH_STATUS_FAILED, REMOVE_AUTH_STATUS, REMOVE_AUTH_STATUS_FAILED } from 'src/redux/actions/AuthHandler';

const initialState = {
  mtb_employee_id: '',
  role: null,
  isLoggedIn: false,
  employee_name: '',
  account: '',
  mtb_enterprise_id: '',
  mtb_sales_office_id: '',
  token: ''
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_STATUS:
      const { mtb_employee_id, role, employee_name, account, mtb_enterprise_id, mtb_sales_office_id, token } = action.payload;
      return {
        mtb_employee_id: mtb_employee_id,
        role: role,
        isLoggedIn: true,
        employee_name: employee_name,
        account: account,
        mtb_enterprise_id: mtb_enterprise_id,
        mtb_sales_office_id: mtb_sales_office_id,
        token: token,
      };
    case REMOVE_AUTH_STATUS:
      return {
        mtb_employee_id: '',
        role: null,
        isLoggedIn: false,
        employee_name: '',
        account: '',
        mtb_enterprise_id: '',
        mtb_sales_office_id: '',
        token: '',
      };
    case SET_AUTH_STATUS_FAILED:
      return state;
    case REMOVE_AUTH_STATUS_FAILED:
      return state;
    default:
      return state;
  }
};

export default AuthReducer;