import axios from 'axios';
//apis
import { CUSTOMER_DATA_URL, GET_SELECTED_CUSTOMER_URL } from 'src/apis';
//actions
import { setErrorMessage } from './ErrorMessageHandler';
//functions
import DateFunctions from 'src/functions/DateFunctions';
//actions
import { removeSelectedCustomersConstructionData } from 'src/redux/actions/ConstructionDataHandler';
import { removeSelectedCustomersSupportData } from 'src/redux/actions/CustomerSupportDataHandler';

export const SET_CUSTOMER_LIST_DATA = "SET_CUSTOMER_LIST_DATA";
export const SET_CUSTOMER_LIST_DATA_FAILED = "SET_CUSTOMER_LIST_DATA_FAILED";
export const SET_SELECTED_CUSTOMER_DATA = "SET_SELECTED_CUSTOMER_DATA";
export const SET_SELECTED_CUSTOMER_DATA_FAILED = "SET_SELECTED_CUSTOMER_DATA_FAILED";
export const SET_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA = "SET_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA";
export const SET_SELECTED_CUSTOMER_ID = "SET_SELECTED_CUSTOMER_ID";
export const UPDATE_SELECTED_CUSTOMER_DATA = "UPDATE_SELECTED_CUSTOMER_DATA";
export const SET_PROCESSED_CUSTOMER_DATA = "SET_PROCESSED_CUSTOMER_DATA";
export const REMOVE_PROCESSED_CUSTOMER_DATA = "REMOVE_PROCESSED_CUSTOMER_DATA";
export const REMOVE_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA = "REMOVE_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA";

export const REMOVE_CUSTOMER_DATA = "REMOVE_CUSTOMER_DATA";

const errorLocation = "customer";

export const getCustomerListData = () => async (dispatch, getState) => {
  //顧客一覧に表示する顧客リストの取得
  try {
    const { mtb_enterprise_id, token } = getState().auth;
    const { enterpriseData, salesOfficeData, partnerData } = getState().master;

    if (mtb_enterprise_id && token) {
      await axios.get(CUSTOMER_DATA_URL, {
        params: {
          mtb_enterprise_id: mtb_enterprise_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then(({ data }) => {
          const processedData = dataPreprocessing(data, enterpriseData, salesOfficeData, partnerData);
          dispatch({
            type: SET_CUSTOMER_LIST_DATA,
            payload: { data: processedData }
          });
        })
    } else {
      throw new Error("sales office id not found");
    }
  } catch (error) {
    dispatch({
      type: SET_CUSTOMER_LIST_DATA_FAILED,
    });
    dispatch(setErrorMessage({ where: errorLocation, msg: "顧客データを取得できませんでした。" }));
  }
}

export const dataPreprocessing = (data, enterpriseData, salesOfficeData, partnerData) => {
  //顧客一覧に表示する顧客リストのデータの表示のための処理
  const _data = [...data];
  _data.map((customerData) => {
    const { mtb_enterprise_id, mtb_sales_office_id, mtb_partner_id } = customerData;
    const enterpriseName = enterpriseData[mtb_enterprise_id] ? enterpriseData[mtb_enterprise_id].enterprise_name : "";
    const salesOfficeName = salesOfficeData[mtb_sales_office_id] ? salesOfficeData[mtb_sales_office_id].sales_office_name : "";
    const partnerName = partnerData[mtb_partner_id] ? partnerData[mtb_partner_id].partner_name : "";
    customerData['status'] = stringStatusToArray(customerData.status);
    customerData["contract_date"] = DateFunctions.ISOStringDateToLocalDate(customerData.contract_date);
    customerData["construction_end_date"] = DateFunctions.ISOStringDateToLocalDate(customerData.construction_end_date);
    customerData["enterprise"] = enterpriseName;
    customerData["sales_office"] = salesOfficeName;
    customerData["business_partner"] = partnerName;
  });
  return _data;
}

export const stringStatusToArray = (status) => {
  //顧客一覧に表示する顧客リストの各顧客ステータスをStringからリストに型変更　DBからのデータ： 1, 3, 5 --> 表示データ：[1,3,5]
  if (typeof status === "string" && status !== '') {
    return status.split(",").map((s) => parseInt(s));
  }
  return [];
}

export const setSelectedDetailedCustomerData = (mtb_customer_id) => async (dispatch, getState) => {
  //顧客詳細に表示する選択された顧客をIdから抽出する処理
  try {
    if (!mtb_customer_id) throw new Error("could not find the customer id.");
    const { mtb_enterprise_id, token } = getState().auth;
    const { enterpriseData, salesOfficeData, partnerData } = getState().master;

    if (mtb_enterprise_id && token) {
      await axios.get(GET_SELECTED_CUSTOMER_URL, {
        params: {
          mtb_enterprise_id: mtb_enterprise_id,
          mtb_customer_id: mtb_customer_id,
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then(({ data }) => {
          const processedData = dataPreprocessing(data, enterpriseData, salesOfficeData, partnerData);
          dispatch({
            type: SET_SELECTED_CUSTOMER_DATA,
            payload: { selectedCustomerData: processedData.pop(), selectedCustomerID: mtb_customer_id }
          });
        })
    } else {
      throw new Error("given customer code, could not find the customer data.");
    }
  } catch (error) {
    dispatch({
      type: SET_SELECTED_CUSTOMER_DATA_FAILED,
    });
    dispatch(setErrorMessage({ where: errorLocation, msg: "顧客詳細データを取得できませんでした。" }));
  }
}

export const updateSelectedDetailedCustomerData = (customerData) => (dispatch, getState) => {
  //選択された顧客詳細を編集した際にその顧客データにおけるStoreのアップデート関数
  const { customerList, selectedCustomerData, selectedCustomerId } = getState().customer;
  const { enterpriseData, salesOfficeData, partnerData } = getState().master;
  const preprocessedData = dataPreprocessing([customerData], enterpriseData, salesOfficeData, partnerData);
  const updatingCustomerData = { ...selectedCustomerData, ...preprocessedData[0] };
  const updatingCustomerList = customerList.map((customer) => {
    if (customer.id === selectedCustomerId) {
      return updatingCustomerData;
    }
    return customer;
  });
  dispatch({
    type: UPDATE_SELECTED_CUSTOMER_DATA,
    payload: { selectedCustomerData: updatingCustomerData, customerList: updatingCustomerList },
  })
}

export const setSelectedCustomerId = (customer_id) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CUSTOMER_ID,
    payload: { customer_id: customer_id }
  })
}

export const setConstructionAndCustomerSupportData = () => (dispatch, getState) => {
  //顧客詳細ページにて表示している顧客に紐づく顧客対応履歴データ（DB登録日）と工事履歴データ（契約日）を降順にソートし一つのリストにまとめる関数
  const state = getState();
  const { customerSupportList } = state.customerSupport;
  const { customerConstructionList } = state.construction;
  const _data = processTableData(customerSupportList, customerConstructionList);
  dispatch({
    type: SET_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA,
    payload: { data: _data }
  })
};

const processTableData = (customerSupportList, customerConstructionList) => {
  const _tableData = [];
  if (customerSupportList instanceof Array && customerSupportList.length !== 0) {
    customerSupportList && customerSupportList.map((row) => {
      const _data = {
        dtb_customer_support_id: row.id,
        status: '対応履歴',
        business_partner: row.business_partner,
        support_date: DateFunctions.ISOStringDateToLocalDate(row.support_date),
        support_complete_date: DateFunctions.ISOStringDateToLocalDate(row.support_complete_date),
        construction_end_date: null,
        content: row.content,
        total: "",
        payment_method: "",
        destination_postal_code: row.destination_postal_code,
        destination_address: row.destination_address,
        support_employee: row.support_employee_name1,
        remarks: row.remarks,
        date: row.created_at
      };
      _tableData.push(_data);
    });
  }

  if (customerConstructionList instanceof Array && customerConstructionList.length !== 0) {
    customerConstructionList.map((row) => {
      const _data = {
        dtb_construction_id: row.id,
        status: '工事',
        business_partner: row.business_partner,
        contract_date: DateFunctions.ISOStringDateToLocalDate(row.support_date),
        support_date: DateFunctions.ISOStringDateToLocalDate(row.contract_date),
        construction_end_date: DateFunctions.ISOStringDateToLocalDate(row.construction_end_date),
        content: row.content,
        total: row.total,
        payment_method: row.payment_method,
        destination_postal_code: row.destination_postal_code,
        destination_address: row.destination_address,
        support_employee: row.sales_employee_name1,
        remarks: row.remarks,
        date: row.contract_date,
        sales_employee_name1: row.sales_employee_name1,
        sales_employee_name2: row.sales_employee_name2,
        sales_employee_name3: row.sales_employee_name3,
        sales_employee_name4: row.sales_employee_name4,
        construction_employee_name1: row.construction_employee_name1,
        construction_employee_name2: row.construction_employee_name2,
        construction_employee_name3: row.construction_employee_name3,
        construction_employee_name4: row.construction_employee_name4,
      };
      _tableData.push(_data);
    });
  }
  const dateSortedTableData = [..._tableData].sort((a, b) => {
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  });

  return dateSortedTableData;
};

export const removeConstructionAndCustomerSupportData = () => (dispatch) => {
  //顧客に紐づく工事データと対応履歴データをremoveする処理
  dispatch(removeSelectedCustomersConstructionData());
  dispatch(removeSelectedCustomersSupportData());
  dispatch({
    type: REMOVE_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA
  })
};

export const removeCustomerData = () => (dispatch) => {
  //全ての顧客データをstoreから消去する処理（ログアウト時に呼び出し）
  dispatch({
    type: REMOVE_CUSTOMER_DATA
  })
};
