import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { Colors, BreakPoints, FontSize } from 'src/configs/StyleConfig';
//mixins
import PriceFunctions from 'src/functions/PriceFunctions';
import GeneralFunctions from 'src/functions/GeneralFunctions';

/**
 * もし顧客履歴に表示しているカラムを変更したい場合
 * 1. CustomerDataHandler.js のsetConstructionAndCustomerSupportDataのprocessTableDataの内容を変更
 * 2. columnNamesのキーを変更
 */

const columnNames = {
  status: { label: '', width: '6%', align: 'left' },
  business_partner: { label: '提携先', width: '10%', align: 'left' },
  contract_date: { label: '対応日/契約日/完了日', width: '14%', align: 'left' },
  content: { label: '内容', width: '10%', align: 'left' },
  total: { label: '工事代金', width: '10%', align: 'right' },
  payment_method: { label: '支払い方法', width: '10%', align: 'center' },
  destination_postal_code: { label: '施工先住所/郵便番号', width: '14%', align: 'left' },
  sales_employee_name: { label: '営業担当者', width: '8%', align: 'left' },
  construction_employee_name: { label: '施工担当者', width: '8%', align: 'left' },
  remarks: { label: '備考', width: '10%', align: 'left' },
};
const keyPairs = {
  contract_date: ['support_date', 'contract_date', 'construction_end_date'],
  destination_postal_code: ['destination_postal_code', 'destination_address'],
  sales_employee_name: ['sales_employee_name1', 'sales_employee_name2', 'sales_employee_name3', 'sales_employee_name4'],
  construction_employee_name: ['construction_employee_name1', 'construction_employee_name2', 'construction_employee_name3', 'construction_employee_name4'],
};

const ConstructionLog = (props) => {
  const { data, navigation } = props;

  const Row = (props) => {
    const { data, index } = props;
    return (
      <Tr onClick={() => navigation(index)}>
        {
          Object.keys(columnNames).map((columns) => {
            if (columns === "contract_date") {
              if (data['status'] === '工事') {
                const key = keyPairs[columns];
                return (
                  <Td width={columnNames[columns].width} key={columns}>
                    <MultiLine>
                      {data[key[0]] ? data[key[0]] : " "}
                    </MultiLine>
                    <MultiLine>
                      {data[key[1]] ? data[key[1]] : " "}
                    </MultiLine>
                    <MultiLine>
                      {data[key[2]] ? data[key[2]] : " "}
                    </MultiLine>
                  </Td>
                )
              } //対応履歴
              return (
                <Td width={columnNames[columns].width} key={columns}>
                  <MultiLine>
                    {data['support_date'] ? data['support_date'] : " "}
                  </MultiLine>
                  <MultiLine>
                    {data['support_complete_date'] ? data['support_complete_date'] : " "}
                  </MultiLine>
                </Td>
              )
            } else if (columns === "total") {
              return (
                <Td width={columnNames[columns].width} key={columns} align={columnNames[columns].align}>
                  {PriceFunctions.intPriceToStringPrice(data[columns])}
                </Td>
              )
            } else if (columns === 'remarks') {
              return (
                <Td width={columnNames[columns].width} key={columns}>
                  {GeneralFunctions.trimLongText(data[columns], 11)}
                </Td>
              )
            } else if (columns === 'payment_method') {
              return (
                <Td width={columnNames[columns].width} align={'center'} key={columns}>
                  {data[columns]}
                </Td>
              )
            } else if (columns === 'destination_postal_code') {
              const key = keyPairs[columns];
              return (
                <Td width={columnNames[columns].width} key={columns}>
                  <MultiLine>
                    {data[key[0]]}
                  </MultiLine>
                  <MultiLine>
                    {data[key[1]]}
                  </MultiLine>
                </Td>
              )
            } else if (columns === 'sales_employee_name' || columns === 'construction_employee_name') {
              const key = keyPairs[columns];
              return (
                <Td width={columnNames[columns].width} key={columns}>
                  <MultiLine>
                    {data[key[0]]}
                  </MultiLine>
                  <MultiLine>
                    {data[key[1]]}
                  </MultiLine>
                  <MultiLine>
                    {data[key[2]]}
                  </MultiLine>
                  <MultiLine>
                    {data[key[3]]}
                  </MultiLine>
                </Td>
              )
            }
            return (
              <Td width={columnNames[columns].width} key={columns}>
                {data[columns]}
              </Td>
            )
          })
        }
      </Tr>
    )
  }

  return (
    <Container>
      <Table>
        <Thead>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th align={columnNames[cName].align} width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </Thead>
        <TableBody>
          {
            data.map((row, index) => {
              return <Row data={row} index={index} key={index.toString()} />
            })
          }
        </TableBody>
      </Table>
    </Container>
  )
};

const Container = styled.div`
  width: 95%;
  margin: 28px 0;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${BreakPoints.md}) {
    display: inline-block;
  }
`
const Table = styled.table`
  max-width: 1400px;
  min-width: ${BreakPoints.md};
  width: 100%;
  border-collapse: collapse;
`
const Th = styled.th`
  color: ${Colors.textColor};
  font-size: ${FontSize.md};
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
`
const Thead = styled.thead`
  width: 100%;
  table-layout: fixed;
  display: table;
`
const TableBody = styled.tbody`
  border: 1px solid gray;
  width: 100%;
  height: 56vh; 
  overflow: auto;
  display: block;
`
const Tr = styled.tr`
  width: 100%;
  height: 60px;
  table-layout: fixed;
  display: table;
  font-size: 14px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  :hover {
    background-color: ${Colors.boxHighlight};
  }
  :after {
    content: "";
    display: block;
  }
`
const Td = styled.td`
  text-align: left;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}`};
`
const MultiLine = styled.pre`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  text-wrap: wrap;
`
ConstructionLog.propTypes = {
  data: PropTypes.array.isRequired,
  navigation: PropTypes.func.isRequired
};
ConstructionLog.defaultProps = {
  data: [],
  navigation: () => { }
};

export default ConstructionLog;
