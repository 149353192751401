import { katakana, characters } from 'src/configs/StyleConfig';
import crypto from 'crypto';

class GeneralFunctions {


  /*
    public method
    sizeで指定された桁数に合わせて０で穴埋めする関数
    例：
    dtb_construction_id : 1 ---> 00000000001
    params:
    - num: Int型の数字
    - size: 桁数　Int型
    return:
    - Int型の数字
  */
  padding(num, size) {
    //mtb_enterprise_id : 1 ---> 0001, mtb_sales_office_id : 1 --> 01, serial_number : 1 ---> 0001
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  /*
    public method
    ハッシュ化関数
  */
  cryptHash(str) {
    return crypto.createHash('sha256').update(str, 'utf8').digest('hex');
  };

  /*
    public method
    長いテキストを切り取る関数
    例：
    trimLongText('something long.', 4) -> 'some...'  
  */
  trimLongText(text, numberOfChars) {
    //長いテキストを引数の数だけ表示
    if (typeof text !== 'string') return '';
    if (text.length < numberOfChars) return text;
    const trimmedText = text.substring(0, numberOfChars) + '...'; 
    return trimmedText;
  };

   /*
    public method
    nullと空のstringチェック関数
  */
  isNullOrEmpty(val) {
    if (val === null || (typeof val === 'string' && val === '')) {
      return true;
    }
    return false;
  };

   /*
    public method
    半角ー＞全角に変更する関数
  */
  halfToFullString(str) {
    //半角ー＞全角
    if (str === null || str === undefined) return '';
    if (typeof str === 'number') str = str.toString();
    str = str.trim();
    let val = str.replace(/[A-Za-z0-9]/g,
      function( tmpStr ) {
        // 文字コードをシフト
        return String.fromCharCode( tmpStr.charCodeAt(0) + 0xFEE0 );
      }
    );
    return val.replace(/”/g, "\"")
    .replace(/'/g, "’")
    .replace(/`/g, "‘")
    .replace(/\\/g, "￥")
    .replace(/ /g, "　")
    .replace(/~/g, "〜")
    .replace(/-/g, "−")
    .replace(/-/g, "ー");
  };

   /*
    public method
    全角ー＞半角に変更する関数
  */
  fullToHalfString(str) {
    //全角ー＞半角
    if (str === null || str === undefined) return '';
    if (typeof str === 'number') str = str.toString();
    str = str.trim();
    let val = str.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g,
      function( tmpStr ) {
        // 文字コードをシフト
        return String.fromCharCode( tmpStr.charCodeAt(0) - 0xFEE0 );
      }
    );
    return val.replace(/”/g, "\"")
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~")
    .replace(/−/g, "-")
    .replace(/。/g, ".")
    .replace(/、/g, ".")
    .replace(/ー/g, "-");
  }
  
  /*
    public method
    csvで出力する銀行指定のフォーマットに沿ったテキストに変更する処理を行う関数
  */
  replaceStrToBankAcceptableStr(str) {
    if (typeof str !== 'string') return;
    str = this.fullToHalfString(str);
    str = str.toUpperCase();
    return str
      .replace(/[ぁ-ゔ]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0x60))
      .replace(/ﾞ/g, '゛')
      .replace(/ﾟ/g, '゜')
      .replace(/(ウ゛)/g, 'ヴ')
      .replace(/(ワ゛)/g, 'ヷ')
      .replace(/(ヰ゛)/g, 'ヸ')
      .replace(/(ヱ゛)/g, 'ヹ')
      .replace(/(ヲ゛)/g, 'ヺ')
      .replace(/(ゝ゛)/g, 'ヾ')
      .replace(/ゝ/g, 'ヽ')
      .replace(/ゞ/g, 'ヾ')
      .replace(/ゕ/g, 'ヵ')
      .replace(/ゖ/g, 'ヶ')
      .split('')
      .map((key) => katakana.get(key) || key)
      .map((key) => characters.get(key) || key)
      .join('')
  }
};

export default new GeneralFunctions();