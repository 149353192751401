import React, { memo } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//configs & styles
import { Colors } from 'src/configs/StyleConfig';
import 'src/index.css';


const DropDownSearchSelection = (props) => {
  const { options, onChange, value, isEnable } = props;

  if (isEnable) {
    return (
      <Container>
        <div style={{ width: '100%' }}>
          <Select
            styles={{
              option: (provided, state) => ({
                ...provided,
                fontSize: "13px",
                fontWeight: "normal",
                color: Colors.textColor,
                padding: 4,
              }),
              control: (provided, state) => ({
                ...provided,
                minHeight: '28px',
                height: '28px',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                height: '28px',
                padding: '0 2px'
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '28px',
              }),
              option: (provided, state) => ({
                ...provided,
                fontSize: "13px",
                fontWeight: "normal",
                color: Colors.textColor,
                padding: 4,
              }),
              singleValue: base => ({
                ...base,
                fontSize: "13px",
                fontWeight: "normal",
                color: Colors.textColor,
              }),
              input: base => ({
                ...base,
                fontSize: "13px",
                color: Colors.textColor,
              }),
            }}
            isClearable={true}
            value={value}
            isSearchable={true}
            options={options}
            onChange={onChange}
            placeholder={""}
          >
          </Select>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Selection placeholder={'提携先'} onChange={onChange} value={value.id} disabled>
          {
            [
              <Option value="" key=""></Option>,
              options && options.map((item, index) => {
                if (index === 0) {
                  return (
                    <Option color={Colors.grayText} value={item.id} key={item.id}>{item.label}</Option>
                  )
                }
                return (
                  <Option value={item.id} key={item.id}>{item.label}</Option>
                )
              })
            ]
          }
        </Selection>
      </div>
    </Container>
  );
};


const Container = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  font-weight: 500;
  flex-direction: 'column';
  margin-bottom: 12px;
`
const Selection = styled.select`
  width: 100%;
  height: 28px;
  border: 1px solid ${Colors.border};
  border-radius: 6px;
  color: ${Colors.textColor};
  :disabled {
    background-color: white;
    border-radius: 2px;
    border: 1px solid ${Colors.textColor};
  }
`
const Option = styled.option`
  color: ${props => props.color ? props.color : Colors.textColor};
`

DropDownSearchSelection.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  isEnable: PropTypes.bool,
};

DropDownSearchSelection.defaultProps = {
  options: [],
  onChange: () => { },
  value: '0',
  isEnable: true,
};

export default memo(DropDownSearchSelection);