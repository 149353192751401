import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//components
import ConstructionCompleteDateSelector from './ConstructionCompleteDateSelector';
import DropDownSelection from 'src/common/DropDownSelection';
import { InputLabelStyle, InputAndLabelContainer } from 'src/common/StylesComponents';
import Button from 'src/common/Button';
//redux
import { useSelector } from 'react-redux';
//config
import { Colors } from 'src/configs/StyleConfig';


const SearchField = (props) => {

  const { searchingState, onChangeSearchingState, onResetButtonClicked, onSearchButtonPressed } = props;

  const { enterpriseData, salesOfficeData, partnerData } = useSelector(state => state.master);
  const [hasOptionsSet, setHasOptionSet] = useState(false);
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [salesStoreOptions, setSalesStoreOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);

  useEffect(() => {
    if (!hasOptionsSet) {
      if (enterpriseData && salesOfficeData && partnerData) {
        const _enterpriseOptions = getOptions(enterpriseData, 'enterprise_name');
        const _salesOptions = getOptions(salesOfficeData, 'sales_office_name');
        const _partnerOptions = getOptions(partnerData, 'partner_name');
        setEnterpriseOptions(_enterpriseOptions);
        setSalesStoreOptions(_salesOptions);
        setPartnerOptions(_partnerOptions);
        setHasOptionSet(true);
      }
    }
  }, [enterpriseData, salesOfficeData, partnerData, hasOptionsSet]);

  const getOptions = (ObjectData, label) => {
    let options = [];
    const empty = { value: 0, label: '' };
    options.push(empty);
    if (!ObjectData) return options;

    Object.keys(ObjectData).forEach((key) => {
      const json = {
        value: parseInt(key),
        label: ObjectData[key][label]
      };
      options.push(json);
    });
    return options;
  };

  return (
        <Container>
        <InputWrapper style={{ marginBottom: '12px' }}>
          <InputLabelStyle>
            工事完了日:
          </InputLabelStyle>
          <InputStyle date>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <DateSelectionWrapper>
              <ConstructionCompleteDateSelector
                isStart={true}
                isOneLine={true}
                date={searchingState.construction_end_date_start}
                onChange={(val) => onChangeSearchingState('construction_end_date_start', val)}
                calendarPosition={"top-start"}
              />
            </DateSelectionWrapper>
            <div style={{ alignSelf: 'center', marginRight: 8, marginLeft: 8 }}>
              ~
            </div>
            <DateSelectionWrapper>
              <ConstructionCompleteDateSelector
                isStart={false}
                isOneLine={true}
                date={searchingState.construction_end_date_end}
                onChange={(val) => onChangeSearchingState('construction_end_date_end', val)}
                calendarPosition={"top-start"}
              />
            </DateSelectionWrapper>
          </div>
          </InputStyle>
        </InputWrapper>
        <InputWrapper>
          <InputLabelStyle>
            企業:
          </InputLabelStyle>
          <InputStyle>
            <DropDownSelection
              options={enterpriseOptions}
              value={searchingState.mtb_enterprise_id && searchingState.mtb_enterprise_id.toString()}
              onChange={(val) => onChangeSearchingState('mtb_enterprise_id', val.target.value)}
              isEnable={true}
            />
          </InputStyle>
        </InputWrapper>
        <InputWrapper>
          <InputLabelStyle>
            営業所:
          </InputLabelStyle>
          <InputStyle>
            <DropDownSelection
              options={salesStoreOptions}
              value={searchingState.mtb_sales_office_id && searchingState.mtb_sales_office_id.toString()}
              onChange={(val) => onChangeSearchingState('mtb_sales_office_id', val.target.value)}
              isEnable={true}
            />
          </InputStyle>
        </InputWrapper>
        <InputWrapper>
          <InputLabelStyle>
            提携先:
          </InputLabelStyle>
          <InputStyle>
            <DropDownSelection
              options={partnerOptions}
              value={searchingState.mtb_partner_id && searchingState.mtb_partner_id.toString()}
              onChange={(val) => onChangeSearchingState('mtb_partner_id', val.target.value)}
              isEnable={true}
            />
          </InputStyle>
        </InputWrapper>
        <InputWrapper style={{ margin: '24px 0', padding: 12, display: 'flex', flexDirection: 'row' }}>
            <Button text={"リセット"} onClick={onResetButtonClicked} />
              <div style={{ width: 36 }}></div>
            <Button text={"検索"} onClick={onSearchButtonPressed} />
        </InputWrapper>
      </Container>
  );
};

const Container = styled.div`
  max-width: 360px;
  min-width: 260px;
  margin: 10px;
  border-radius: 6px;
  margin-right: auto;
  margin-left: 28px;
  padding: 16px 0px;
  border: 1px solid ${Colors.borderColor};
`
const DateSelectionWrapper = styled.div`
  width: 50%;
  margin-right: 8px;
`

const InputWrapper = styled(InputAndLabelContainer)`
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
`
const InputStyle = styled.div`
  width: ${props => props.date ? '50%' : '35%'};
`

SearchField.propTypes = {
  searchingState: PropTypes.object.isRequired, 
  onChangeSearchingState: PropTypes.func.isRequired,
  onResetButtonClicked: PropTypes.func.isRequired,
  onSearchButtonPressed: PropTypes.func.isRequired,
};

SearchField.defaultProps = {
  searchingState: {
    queryDate: {
      construction_end_date_start: '',
      construction_end_date_end: '',
    },
    mtb_enterprise_id: 0,
    mtb_sales_office_id: 0,
    mtb_partner_id: 0,
  },
  onChangeSearchingState: () => {},
  onResetButtonClicked: () => {},
  onSearchButtonPressed: () => {},
};

export default SearchField;