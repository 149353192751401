import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontSize, Colors } from 'src/configs/StyleConfig';

export const statuses = [
  { id: 0, name: '対応不可', color: Colors.statusRed },
  { id: 1, name: '信販不可', color: Colors.statusRed },
  { id: 2, name: '転居', color: Colors.statusBlue },
  { id: 3, name: 'クレーム', color: Colors.statusBlue }
]

const StatusMapping = (props) => {
  const { statusList, oneLine } = props;
  if (statusList === null || statusList.length === 0) return null

  if (oneLine) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {
          statusList.map((status) => {
            if (status < 4) {
              return (
                <Container width={80} style={{ marginRight: '4px' }} color={statuses[status].color} key={statuses[status].id.toString()}>
                  {statuses[status].name}
                </Container>
              )
            }
          })
        }
      </div>
    );
  }
  return (
    <>
      {
        statusList.map((status) => {
          if (status < 4) {
            return (
              <Container color={statuses[status].color} key={statuses[status].id.toString()}>
                {statuses[status].name}
              </Container>
            )
          }
        })
      }
    </>
  );
};

const Container = styled.div`
  width: ${props => props.width ? `${props.width}px` : '90%'};
  border-radius: 2px;
  font-size: ${FontSize.xs};
  font-weight: 500;
  text-align: center;
  border: 1.5px solid ${props => props.color && props.color};
  color: ${props => props.color && props.color};
  border-radius: 2px;
`

StatusMapping.propTypes = {
  statusList: PropTypes.array.isRequired,
  oneLine: PropTypes.bool
};

StatusMapping.defaultProps = {
  statusList: [],
  oneLine: false
};

export default StatusMapping;