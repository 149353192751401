import {
  SET_ERROR_MESSAGE,
  REMOVE_ERROR_MESSAGE
} from 'src/redux/actions/ErrorMessageHandler';

const initialState = {
  msg: "",
  where: "",
};

const ErrorMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return {
        msg: action.payload.msg,
        where: action.payload.where
      };
    case REMOVE_ERROR_MESSAGE:
    default:
      return {
        msg: "",
        where: "",
      };
  }
};

export default ErrorMessageReducer;