import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PostalBackPDF from 'src/static/post_back.pdf';
import PostalFrontPDF from 'src/static/post_front.pdf';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import JaFontUrl from 'src/static/fonts/ZenOldMincho-Black.ttf';
import NotoSansJPMedium from 'src/static/fonts/NotoSansJP-Medium.otf';
import Button from './Button';
import GeneralFunctions from 'src/functions/GeneralFunctions';

import { IMAGE_URL } from 'src/apis';
import { useSelector } from 'react-redux';

const viewer = {
  width: '100%',
  height: '100%'
}

const Canvas = () => {

  const [pdf, setPdf] = useState(null);
  const [secondPdf, setSecondPdf] = useState(null);
  const componentRef = useRef();
  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    const getPDF = async () => {
      try {
        const config = {
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
          headers: {
            'Accept': 'application/pdf'
          }
        };
        const fontBytes = await fetch(NotoSansJPMedium).then((res) => res.arrayBuffer());
        const phoneFontBytes = await fetch(JaFontUrl).then((res) => res.arrayBuffer());

        const mergedPdf = await PDFDocument.create();
        const back = await axios.get(PostalBackPDF, config);
        const front = await axios.get(PostalFrontPDF, config);
        const backData = back.data;
        const frontData = front.data;
        const pdfA = await PDFDocument.load(frontData);
        const pdfB = await PDFDocument.load(backData);
        pdfA.registerFontkit(fontkit);
        pdfB.registerFontkit(fontkit);

        const fontA = await pdfA.embedFont(fontBytes);
        const fontB = await pdfB.embedFont(fontBytes);
        const phoneFontA = await pdfA.embedFont(phoneFontBytes);
        // const phoneFontB = await pdfB.embedFont(phoneFontBytes);
        // pdfDoc.registerFontkit(fontkit);
        // const font = await pdfDoc.embedFont(fontBytes)
        // Get the first page of the document
        const pdfAPages = pdfA.getPages();
        const firstPage = pdfAPages[0];
        const pdfBPages = pdfB.getPages();
        const secondPage = pdfBPages[0];
        // Get the width and height of the first page
        const { width, height } = secondPage.getSize();
        const data = {
          examination_content: "床のきしみ・湿気・カビ・シロアリの薬剤\n床下状況確認・断熱材等の状況確認・リフォームの箇所確認",
          examination_duration: "約30分〜１時間ぐらいです",
          holiday: "月曜日・火曜日",
          reception_time: "10:00 ~ 17:00",
          fts_enterprise_sales_office_name: "フマキラー・トータル矢板店",
          fts_phone: "0120-0420-42042",
          enterprise_name: "三井マネジメント株式会社",
          sales_office_name: '矢板営業所',
          enterprise_address: "秋田県北秋田郡上小阿仁村大字沖田面字小蒲野下夕川原2389-12-7",
          enterprise_phone: "0287-47-5533",
          enterprise_postal_code: "329-2135",
          postal_code: "329-2190",
          customer_address: "秋田県北秋田郡上小阿仁村大字沖田面字小蒲野下夕川原2389-12",
          customer_name: "山田　太郎",
          customer_id: "0000000000001",
          sales_office_postal_code: "329-1111",
          sales_office_address: "秋田県矢板市中157-7",
          sales_office_phone: "0120-0420-42042",
        };
        const {
          enterprise_postal_code, enterprise_address, enterprise_name, sales_office_name,
          examination_content, examination_duration, holiday, reception_time, sales_office_postal_code, sales_office_address, sales_office_phone,
          fts_enterprise_sales_office_name, fts_phone,
        } = data;
        const isEnterpriseAndSalesOfficeSelected = false; //true = 企業名＋営業所名, false = フマキラー出力時の設定

        const showingEnterpriseName = isEnterpriseAndSalesOfficeSelected ? enterprise_name : fts_enterprise_sales_office_name;
        const showingPhoneNumber = isEnterpriseAndSalesOfficeSelected ? sales_office_phone : fts_phone;


        const customerData = {
          customer_name: "山田　太郎",
          customer_address: "秋田県北秋田郡上小阿仁村大字沖田面字小蒲野下夕川原2389-12",
          customer_postal_code: "3922190",
          customer_code: "00000000001"
        };
        const { customer_name, customer_address, customer_postal_code, customer_code } = customerData;

        //embed image
        const logo_url = '1634798915568_1';
        const response = await axios.get(IMAGE_URL, {
          params: {
            filename: logo_url,
            originalFilename: logo_url
          },
          headers: { "Authorization": `Bearer ${token}` }
        });
        const imageData = response.data;
        const jpgImageBytes = await fetch(imageData).then((res) => res.arrayBuffer());
        const logoJpgImageA = await pdfA.embedJpg(jpgImageBytes);
        // const logoJpgImageB = await pdfB.embedJpg(jpgImageBytes);
        const scaledLogoA = logoJpgImageA.scaleToFit(180, 35);
        // const scaledLogoB = logoJpgImageB.scaleToFit(180, 35);
        // Draw a string of text diagonally across the first page

        //config
        const fontSize = 7;
        const leftSideStart = 94;
        const rightSideStart = 310;
        const postalCodeStart = leftSideStart - 12;
        const halfPageWidth = width / 2;
        const logoStartHeight = 55;
        const smallFontSize = fontSize + 2;
        const mediumFontSize = fontSize + 3;
        const largeFontSize = fontSize + 6;
        const postalFontSize = 22;

        // 郵便番号
        if (enterprise_postal_code) {
          let postalCodeArr = enterprise_postal_code.split('').filter((num) => {
            if (num !== '-') return num
          });
          const postalCodeY = height - 63;
          let postalCodeStartX = postalCodeStart;
          postalCodeArr.slice(0, 3).forEach((num) => {
            postalCodeStartX = postalCodeStartX + 22;
            firstPage.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: fontB,
              color: rgb(0, 0, 0),
            });
          });
          postalCodeStartX = postalCodeStartX + 6;
          postalCodeArr.slice(3, 7).forEach((num) => {
            postalCodeStartX = postalCodeStartX + 22;
            firstPage.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: fontB,
              color: rgb(0, 0, 0),
            });
          });
        }
        //企業住所
        if (enterprise_address) {
          const _enterprise_address = GeneralFunctions.fullToHalfString(enterprise_address);
          const enterpriseAddressFirstLine = _enterprise_address.slice(0, 17);
          const enterpriseAddressSecondLine = _enterprise_address.slice(17, 40);;
          firstPage.drawText(enterpriseAddressFirstLine, {
            x: leftSideStart,
            y: height - 135,
            size: mediumFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
          firstPage.drawText(enterpriseAddressSecondLine, {
            x: leftSideStart,
            y: height - 149,
            size: mediumFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //企業名
        if (enterprise_name) {
          const _enterprise_name = GeneralFunctions.fullToHalfString(enterprise_name);
          firstPage.drawText(_enterprise_name, {
            x: leftSideStart,
            y: height - 180,
            size: largeFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //営業所名
        if (sales_office_name) {
          let _sales_office_name = GeneralFunctions.fullToHalfString(sales_office_name);
          _sales_office_name = _sales_office_name + "      行";
          firstPage.drawText(_sales_office_name, {
            x: leftSideStart,
            y: height - 194,
            size: largeFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //検査内容
        if (examination_content) {
          const _examination_content = GeneralFunctions.fullToHalfString(examination_content);
          const examination_content_arr = _examination_content.split("\n");
          firstPage.drawText(examination_content_arr[0], {
            x: rightSideStart,
            y: 216,
            size: smallFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
          if (examination_content_arr.length > 1) {
            firstPage.drawText(examination_content_arr[1], {
              x: rightSideStart,
              y: 204,
              size: smallFontSize,
              font: fontB,
              color: rgb(0, 0, 0),
            });
          }
        }
        if (this.isFirstOptionSelected === 0 || this.isFirstOptionSelected === 1) {
          //検査時間
          if (examination_duration) {
            const _examination_content = GeneralFunctions.fullToHalfString(examination_duration);
            firstPage.drawText(_examination_content, {
              x: rightSideStart,
              y: 170,
              size: smallFontSize,
              font: fontB,
              color: rgb(0, 0, 0),
            });
          }
          //定休日
          if (holiday) {
            const _holiday = GeneralFunctions.fullToHalfString(holiday);
            firstPage.drawText(_holiday, {
              x: rightSideStart,
              y: 138,
              size: smallFontSize,
              font: fontB,
              color: rgb(0, 0, 0),
            });
          }
          //電話受付時間
          if (reception_time) {
            const _reception_time = GeneralFunctions.fullToHalfString(reception_time);
            firstPage.drawText(_reception_time, {
              x: rightSideStart,
              y: 106,
              size: mediumFontSize + 1,
              font: fontB,
              color: rgb(0, 0, 0),
            });
          }
        }


        const rightHalfWidth = halfPageWidth / 2 + halfPageWidth;
        //右下ロゴ以下
        //ロゴ
        if (logoJpgImageA) {
          firstPage.drawImage(logoJpgImageA, {
            x: rightHalfWidth - scaledLogoA.width / 2,
            y: logoStartHeight,
            width: scaledLogoA.width,
            height: scaledLogoA.height,
          });
        }
        //企業名
        if (showingEnterpriseName) {
          const enterpriseNameTextWidth = fontA.widthOfTextAtSize(showingEnterpriseName, fontSize + 8);
          firstPage.drawText(showingEnterpriseName, {
            x: rightHalfWidth - enterpriseNameTextWidth / 2,
            y: logoStartHeight - 15,
            size: fontSize + 8,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //営業所住所
        if (sales_office_postal_code && sales_office_address) {
          let _sales_office_postal_code = "〒" + GeneralFunctions.fullToHalfString(sales_office_postal_code);
          _sales_office_postal_code = _sales_office_postal_code + " " + GeneralFunctions.fullToHalfString(sales_office_address);
          const salesOfficeTextWidth = fontA.widthOfTextAtSize(_sales_office_postal_code, fontSize + 2);
          firstPage.drawText(_sales_office_postal_code, {
            x: rightHalfWidth - salesOfficeTextWidth / 2,
            y: logoStartHeight - 25,
            size: fontSize + 2,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //営業所電話番号
        if (showingPhoneNumber) {
          const _showingPhoneNumber = "TEL." + showingPhoneNumber;
          const salesOfficePhoneTextWidth = fontA.widthOfTextAtSize(_showingPhoneNumber, fontSize + 2);
          firstPage.drawText(_showingPhoneNumber, {
            x: rightHalfWidth - salesOfficePhoneTextWidth / 2 - 20,
            y: logoStartHeight - 40,
            size: fontSize + 6,
            font: phoneFontA,
            color: rgb(0, 0, 0),
          });
        }


        // ---------------------------------------------------

        // 顧客郵便番号
        if (customer_postal_code) {
          let postalCodeArr = enterprise_postal_code.split('').filter((num) => {
            if (num !== '-') return num
          });
          const postalCodeY = height - 63;
          let postalCodeStartX = postalCodeStart;
          postalCodeArr.slice(0, 3).forEach((num) => {
            postalCodeStartX = postalCodeStartX + 22;
            secondPage.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: fontA,
              color: rgb(0, 0, 0),
            });
          });
          postalCodeStartX = postalCodeStartX + 6;
          postalCodeArr.slice(3, 7).forEach((num, ind) => {
            postalCodeStartX = postalCodeStartX + 22;
            secondPage.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: fontA,
              color: rgb(0, 0, 0),
            });
          });
        }
        //顧客住所
        if (customer_address) {
          const _customer_address = GeneralFunctions.fullToHalfString(customer_address);
          const customerAddressFirstLine = _customer_address.slice(0, 17);
          const customerAddressSecondLine = _customer_address.slice(17, 32);
          secondPage.drawText(customerAddressFirstLine, {
            x: leftSideStart,
            y: height - 135,
            size: mediumFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
          secondPage.drawText(customerAddressSecondLine, {
            x: leftSideStart,
            y: height - 149,
            size: mediumFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //顧客名
        if (customer_name) {
          let _customer_name = GeneralFunctions.fullToHalfString(customer_name);
          _customer_name = _customer_name + "  様"
          secondPage.drawText(_customer_name, {
            x: leftSideStart,
            y: height - 178,
            size: largeFontSize,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }

        //左下ロゴ以下

        //ロゴ
        if (logoJpgImageA) {
          secondPage.drawImage(logoJpgImageA, {
            x: halfPageWidth / 2 - scaledLogoA.width / 2,
            y: logoStartHeight,
            width: scaledLogoA.width,
            height: scaledLogoA.height,
          });
        }
        //企業名
        if (showingEnterpriseName) {
          const enterpriseNameTextWidth = fontA.widthOfTextAtSize(showingEnterpriseName, fontSize + 8);
          secondPage.drawText(showingEnterpriseName, {
            x: halfPageWidth / 2 - enterpriseNameTextWidth / 2,
            y: logoStartHeight - 15,
            size: fontSize + 8,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //営業所住所
        if (sales_office_postal_code && sales_office_address) {
          let _sales_office_postal_code = "〒" + GeneralFunctions.fullToHalfString(sales_office_postal_code);
          _sales_office_postal_code = _sales_office_postal_code + " " + GeneralFunctions.fullToHalfString(sales_office_address);
          const salesOfficeTextWidth = fontA.widthOfTextAtSize(_sales_office_postal_code, fontSize + 2);
          secondPage.drawText(_sales_office_postal_code, {
            x: halfPageWidth / 2 - salesOfficeTextWidth / 2,
            y: logoStartHeight - 25,
            size: fontSize + 2,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }
        //営業所電話番号
        if (showingPhoneNumber) {
          const _showingPhoneNumber = "TEL." + showingPhoneNumber;
          const salesOfficePhoneTextWidth = fontA.widthOfTextAtSize(_showingPhoneNumber, fontSize + 2);
          secondPage.drawText(_showingPhoneNumber, {
            x: halfPageWidth / 2 - salesOfficePhoneTextWidth / 2 - 20,
            y: logoStartHeight - 40,
            size: fontSize + 6,
            font: phoneFontA,
            color: rgb(0, 0, 0),
          });
        }

        //right hand side
        //顧客ID
        if (customer_code) {
          secondPage.drawText(customer_code, {
            x: rightSideStart + 80,
            y: height - 100,
            size: fontSize + 10,
            font: fontB,
            color: rgb(0, 0, 0),
          });
        }



        // const pdfFrontByte = await pdfA.save();
        const pdfBackByte = await pdfB.save();
        // let front_url =  URL.createObjectURL(new Blob([pdfFrontByte], { type: 'application/pdf' }));
        let back_url = URL.createObjectURL(new Blob([pdfBackByte], { type: 'application/pdf' }));


        const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
        copiedPagesA.forEach((page) => mergedPdf.addPage(page));
        const copiedPagesB = await mergedPdf.copyPages(pdfB, pdfB.getPageIndices());
        copiedPagesB.forEach((page) => mergedPdf.addPage(page));
        const mergedPdfFile = await mergedPdf.save();
        const mergedPDFUrl = URL.createObjectURL(new Blob([mergedPdfFile], { type: 'application/pdf' }));
        setPdf(mergedPDFUrl);
        setSecondPdf(back_url);
      } catch (error) {
        return error.message
      }
    };

    getPDF();
  }, [token]);

  const handlePrint = () => {
    window.open(pdf, "PRINT", "height=400,width=600");
  }

  const onDocumentLoadSuccess = () => {
  }

  return (
    <div style={{ width: '100%', height: 'auto', backgroundColor: 'lightgray' }}>
      <Document
        style={viewer}
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={1} />
      </Document>
      <div style={{ marginTop: 10 }} />
      <Document
        style={viewer}
        file={secondPdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={1} />
      </Document>
      {
        null &&
        <PrintComponent pdf={pdf} ref={componentRef} />
      }
      <Button text={"PRINT"} onClick={handlePrint} />
    </div>
  )
}

const PrintComponent = React.forwardRef((props, ref) => {
  const { pdf } = props;

  return (
    <object ref={ref} data={pdf} type="application/pdf" width="100%" height="100%">
      <p>Alternative text - include a link <a href={pdf} >to the PDF!</a></p>
    </object>
  )
})

export default Canvas; 