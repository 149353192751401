import axios from 'axios';
//functions
import PhoneNumberFunctions from 'src/functions/PhoneNumberFunctions';
import GeneralFunctions from "../GeneralFunctions";
//apis
import { MTB_SALES_OFFICE_DATA_CHANGE_URL } from 'src/apis';

const requiredInputField = {
  sales_office_code: '営業所コード', sales_office_name: '営業所名', postal_code: '郵便番号', address: '住所', phone: '電話番号', label: '郵便局名',
  examination_content: '検査内容', examination_duration: '検査時間', holiday: '定休日', reception_time: '電話受付時間', fts_enterprise_sales_office_name: 'フマキラー出力時会社名・営業所名', fts_phone: 'フマキラー出力時電話番号'
};

/*
営業所設定　営業所の登録/編集クラス
- Parameters in constructor:
 - salesOfficeData: 営業所データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセス時のエラー
 - data: DBに登録/編集したデータ
*/
class SalesOfficeFunctions {

  constructor(props) {
    const { salesOfficeData, token } = props;
    this.salesOfficeData = salesOfficeData;
    this.token = token;
    this.response = {error: '', data: {}};
  };

  /*
    private method
    (1) 必須項目チェック:  sales_office_code: '営業所コード', sales_office_name: '営業所名', postal_code: '郵便番号', address: '住所', phone: '電話番号', label: '郵便局名',
                        examination_content: '検査内容', examination_duration: '検査時間', holiday: '定休日', reception_time: '電話受付時間',
                        fts_enterprise_sales_office_name: 'フマキラー出力時会社名・営業所名', fts_phone: 'フマキラー出力時電話番号'
    (2) 営業所電話番号、fts出力時の電話番号チェック
  */
  _validation() {
    const data = {...this.salesOfficeData};
    let errors = [];
    //(1)
    Object.keys(requiredInputField).forEach((key) => { 
      if (GeneralFunctions.isNullOrEmpty(data[key])) errors.push(requiredInputField[key]);
    });
    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を記入してください。";
    };

    //(2)
    const phoneResponse = PhoneNumberFunctions.phoneValidation(data['phone']);
    if (phoneResponse.error) {
      return phoneResponse.error;
    }

    this.salesOfficeData['phone'] = phoneResponse.validPhoneNumber;

    const ftsPhoneResponse = PhoneNumberFunctions.phoneValidation(data['fts_phone']);
    if (ftsPhoneResponse.error) {
      return ftsPhoneResponse.error;
    }

    this.salesOfficeData['fts_phone'] = ftsPhoneResponse.validPhoneNumber;
    return;
  }

  /*
    private method
    APIアクセスメソッド
  */
  async _send() {
    try {
      const json = [this.salesOfficeData];
      this.response.data = this.salesOfficeData;
      await axios.post(MTB_SALES_OFFICE_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error('bad request');
          }
        }).then(({ data }) => {
          if (data[0]) {
            this.response.data = data[0];
            return;
          } else {
            throw new Error('アカウント名とパスワードが一致しませんでした。');
          }
        })
    } catch (error) {
      return error.message;
    }
  }
  
  /*
    public method
    外部から呼び出す関数
    登録/編集した営業所データもしくはエラーを返す
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._send();
    if (error) {
      this.response.error = error;
    }
    this.response.error = "";

    return this.response;
  };

}

export default SalesOfficeFunctions;