import { Toaster } from "react-hot-toast";

export default function CustomToaster() {
  return (
    <Toaster toastOptions={{
      // Define default options
      className: '',
      duration: 3000,
      style: {
        fontSize: '1.2rem',
      },
      error: {
        duration: 5000,
        style: {
          background: '#FADBDA',
        }
      }
    }} />
  )
}