import moment from "moment";

/*
　等システムで使っている日付に関する関数をまとめたクラス
*/
class DateFunctions {

  
  /*
    public method
    ISOフォーマットからLocalに変更時に呼び出す
    フォーマット：
    2021-03-21T06:00:000Z ＝＝＞ 2021/03/21
  */
  ISOStringDateToLocalDate = (date) => {
    if (date) {
      return new moment.utc(date).format('YYYY/MM/DD');
    }
    return '';
  };

  /*
    public method
    csv出力の際にISOフォーマットからLocalに変更時に呼び出す
    フォーマット：
    2021-03-21T06:00:000Z ＝＝＞ 20210321
  */
  ISOStringDateToLocalDateCSVFormat = (date) => {
    if (date) {
      return new moment(date).format('YYYYMMDD');
    }
    return '';
  };

  /*
    public method
    渡された日付に指定された年数追加する関数
  */
  addYears(date, yearToAdd) {
    const _date = date.valueOf();
    if (!_date) return null;
    if (typeof yearToAdd !== "number") return null;

    let yearAddedDate = new Date(_date);
    yearAddedDate.setFullYear(yearAddedDate.getFullYear() + yearToAdd);
    return yearAddedDate.toISOString();
  };

  /*
    public method
    渡された日付に指定された日にちマイナスする関数
  */
    subtractDays(date, daysToSubtract) {
      const _date = date.valueOf();
      if (!_date) return null;
      if (typeof daysToSubtract !== "number") return null;
      let daysSubtractedDate = new Date(_date.valueOf());
      daysSubtractedDate.setDate(daysSubtractedDate.getDate() - daysToSubtract);
      return daysSubtractedDate.toISOString();
    };
  
  /*
    public method
    渡された日付に指定された日にち追加する関数
  */
  addDays(date, daysToAdd) {
    const _date = date.valueOf();
    if (!_date) return null;
    if (typeof daysToAdd !== "number") return null;
    let daysAddedDate = new Date(_date.valueOf());
    daysAddedDate.setDate(daysAddedDate.getDate() + daysToAdd);
    return daysAddedDate.toISOString();
  }
}

export default new DateFunctions();