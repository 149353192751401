import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//configs & styles
import { Colors } from 'src/configs/StyleConfig';
import 'src/index.css';


const DropDownSelection = (props) => {
  const { options, onChange, value, isEnable } = props;

  if (isEnable) {
    return (
      <Container>
        <div style={{ width: '100%' }}>
          <Selection placeholder={'提携先'} onChange={onChange} value={value}>
            {
              options && options.map((item, index) => {
                if (index === 0) {
                  return (
                    <Option color={Colors.grayText} value={item.value} key={item.value}>{item.label}</Option>
                  )
                }
                return (
                  <Option value={item.value} key={item.value}>{item.label}</Option>
                )
              })
            }
          </Selection>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Selection placeholder={'提携先'} onChange={onChange} value={value} disabled>
          {
            options && options.map((item, index) => {
              if (index === 0) {
                return (
                  <Option color={Colors.grayText} value={item.value} key={item.value}>{item.label}</Option>
                )
              }
              return (
                <Option value={item.value} key={item.value}>{item.label}</Option>
              )
            })
          }
        </Selection>
      </div>
    </Container>
  );
};


const Container = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  font-weight: 500;
  flex-direction: 'column';
  margin-bottom: 12px;
`
const Selection = styled.select`
  width: 100%;
  height: 28px;
  border: 1px solid ${Colors.border};
  border-radius: 6px;
  color: ${Colors.textColor};
  :disabled {
    background-color: white;
    border-radius: 2px;
    border: 1px solid ${Colors.textColor};
  }
`
const Option = styled.option`
  color: ${props => props.color ? props.color : Colors.textColor};
`

DropDownSelection.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  isEnable: PropTypes.bool,
};

DropDownSelection.defaultProps = {
  options: [],
  onChange: () => { },
  value: '0',
  isEnable: true,
};

export default memo(DropDownSelection);