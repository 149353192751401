import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { FontSize } from 'src/configs/StyleConfig';

const constructionColumnNames = {
  support_date: '対応日',
  contract_date: '契約日',
  contract_no: '契約書No',
  serial_no: '管理番号',
  remarks: '備考',
  destination_name: '施工先名',
  destination_postal_code: '施工先郵便番号',
  destination_address: '施工先住所',
  destination_phone: '施工先電話番号',
  mtb_customer_id: '顧客名',
  mtb_sales_office_id: '営業所',
  mtb_partner_id: '提携先',
  mtb_store_id: '店舗',
  construction_start_date: '工事年月日',
  construction_end_date: '工事終了年月日',
  mtb_payment_method_id: '支払方法',
  approval_number: 'ローン承認番号',
  is_agreement_form: '同意書の有無',
  is_maintenance: '保守点検の有無',
  has_other_construction: 'その他工事の有無',
  sales_employee_name1: '営業担当者1',
  sales_employee_name2: '営業担当者2',
  sales_employee_name3: '営業担当者3',
  sales_employee_name4: '営業担当者4',
  construction_employee_name1: '施工担当者1',
  construction_employee_name2: '施工担当者2',
  construction_employee_name3: '施工担当者3',
  construction_employee_name4: '施工担当者4',
  tax_rate: '消費税率',
  tax_division: '消費税設定',
  rounding_division: '小数点処理',
  stamp_price: '印紙合計金額',
  stamp_amount: '印紙合計枚数',
  memo: 'メモ',
  subtotal: '小計',
  tax_exclude: '外税',
  tax_include: '内税',
  tax_price: '消費税額',
  total: '合計金額',
  margin: 'マージン',
  is_exported: 'CSV出力有無',
  exported_at: 'CSV出力時間',
  exported_employee_name: 'CSV出力者',
  mtb_enterprise_id: '企業'
};

const booleanColumn = ['is_agreement_form', 'is_maintenance', 'has_other_construction', 'is_exported'];

const ConstructionLog = (props) => { 

  const { dataBeforeChange, dataAfterChange } = props;

  return (
    <div>
      {
        Object.keys(dataBeforeChange).length > 0 && Object.keys(dataBeforeChange).map((column) => {
          if (booleanColumn.includes(column)) {
            const boolBefore = dataBeforeChange[column] === 0 || !dataBeforeChange[column] ? '無' : '有'; 
            const boolAfter = dataAfterChange[column] === 0 || !dataAfterChange[column] ? '無' : '有'; 
            return (
              <div style={{ display: 'flex', flexDirection: 'row', minWidth: 380, width: '80%'}} key={column}>
                <Column>{constructionColumnNames[column]}</Column>
                <Column>{boolBefore}</Column>
                <Column>{boolAfter}</Column>
              </div>
            )
          }
          return (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: 380, width: '80%'}} key={column}>
              <Column>{constructionColumnNames[column]}</Column>
              <Column>{dataBeforeChange[column]}</Column>
              <Column>{dataAfterChange[column]}</Column>
            </div>
          )
        })
      }
    </div>
  )
};

const Column = styled.span`
  width: 200px;
  font-size: ${FontSize.md};
  margin: 12px 0;
`

ConstructionLog.propTypes = {
  dataBeforeChange: PropTypes.object.isRequired,
  dataAfterChange: PropTypes.object.isRequired
};
ConstructionLog.defaultProps = {
  dataBeforeChange: { },
  dataAfterChange: { }
};

export default ConstructionLog;