import axios from 'axios';
//apis
import { CUSTOMER_DATA_CHANGE_URL, CUSTOMER_CODE_QUERY_URL, GET_SELECTED_CUSTOMER_URL } from 'src/apis';
//functions
import StatusFunctions from 'src/functions/StatusFunctions';
import DeletingFunctions from 'src/functions/DeletingFunctions';
import { logCategory, logTableType, sendLogData } from 'src/functions/LogFunctions';
import GeneralFunctions from './GeneralFunctions';
import PhoneNumberFunctions from './PhoneNumberFunctions';

const customerProperties = ["id", "customer_code", "status", "customer_kana", "customer_name", "customer_postal_code", "customer_address1", "customer_address2", "customer_phone1", "customer_phone2",
  "memo", "mtb_enterprise_id", "mtb_sales_office_id", "created_employee_name", "updated_employee_name"
];

// 施主の必須項目
const customerRequiredForm = { customer_kana: "施主のかな", customer_name: "施主の氏名", customer_postal_code: "施主の郵便番号", customer_address1: "施主の住所", customer_phone1: "施主の電話番号" };

/*
顧客データ登録/編集クラス
- Parameters in constructor:
 - customerInfo: 顧客データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: DBに登録/編集した顧客データ
 - logs: 登録した顧客データのInsertIdなどを含むログ
*/
class CustomerFunction {

  constructor(props) {
    const { customerInfo, token } = props;
    this.customerInfo = customerInfo;
    this.token = token;
    this.response = { error: '', data: {}, logs: {} };
  };

  /*
    private method
    電話番号チェック
  */
  _phoneValidation(phone) {
    const errorText = '施主の電話番号の形式が正しくありません';
    const phoneResponse = PhoneNumberFunctions.phoneValidation(phone);
    if (phoneResponse.error) {
      return errorText;
    }
    this.customerInfo.customer_phone1 = phoneResponse.validPhoneNumber;

    return;
  }

  /*
    private method
    必須項目、電話番号のチェック
    customer_kana: "施主のかな", customer_name: "施主の氏名", customer_postal_code: "施主の郵便番号", customer_address1: "施主の住所", customer_phone1: "施主の電話番号"
  */
  _validateForm() {
    const customer = this.customerInfo;
    let error = [];

    //empty form check
    Object.keys(customerRequiredForm).filter((inputForm) => !customer[inputForm] && error.push(customerRequiredForm[inputForm]));
    if (error.length > 0) {
      return `${error[0]}を記入してください。`;
    }
    const customerPhone = customer.customer_phone1;

    //phone number check
    const customerError = customerPhone ? this._phoneValidation(customerPhone) : null;
    if (customerError) {
      return customerError;
    }
    return null;
  }


  /*
    private method
    顧客コード生成メソッド
    顧客コード＝連番＋１
  */
  _codeProcessing(code) {
    // 連番にプラス１
    const { mtb_enterprise_id, mtb_sales_office_id } = this.customerInfo;
    let serialNumber = code + 1;
    let _customer_code = "";
    // _customer_code = GeneralFunctions.padding(mtb_enterprise_id, 4); //企業コード
    // _customer_code = _customer_code + GeneralFunctions.padding(mtb_sales_office_id, 2); //営業所コード
    // _customer_code = _customer_code + GeneralFunctions.padding(serialNumber, 4); // 連番

    // issue#600で10桁連番に仕様変更
    _customer_code = GeneralFunctions.padding(serialNumber, 10); // 連番

    return _customer_code;
  }

  /*
    private method
    直近の顧客Noをとってくるメソッド
  */
  async _fetchCustomerCode() {
    const response = await axios.get(CUSTOMER_CODE_QUERY_URL, {
      headers: { "Authorization": `Bearer ${this.token}` }
    });
    if (response.status === 200) {
      const { data } = response;
      const { count } = data[0];
      return count;
    }
    return;
  }

  /*
    private method
    （１）直近の顧客Noをとってきた後、
    （２）新しい顧客コードを生成するメソッド
  */
  async _getCustomerCode() {
    try {
      const _customerCode = await this._fetchCustomerCode(); //（１）直近の顧客Noをとってくる
      if (typeof _customerCode !== 'number') {
        throw new Error('顧客Noを取得できませんでした。')
      }
      const newCustomerCode = this._codeProcessing(_customerCode); //（２）新しい顧客コードを生成するメソッド
      if (!newCustomerCode) {
        throw new Error('顧客Noを取得できませんでした。')
      }

      this.customerInfo["customer_code"] = newCustomerCode;
      return;
    } catch (error) {
      return error.message;
    }
  }

  /*
    private method
    入力された顧客データを付随してAPIを呼び出す処理
    登録した顧客データのInsertIdなどを含むログを格納
  */
  async _handleSubmitInput() {
    try {
      const status = StatusFunctions.arrayStatusToString(this.customerInfo["status"]);
      const data = {
        ...this.customerInfo,
        status: status,
      };
      const json = [data];

      this.response.data = { ...data };
      await axios.post(CUSTOMER_DATA_CHANGE_URL, json, { headers: { "Authorization": `Bearer ${this.token}` } })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error('データを送信できませんでした。')
        })
        .then(({ data }) => {
          this.response.logs = data;
          return;
        })
    } catch (error) {
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
    CustomerRegisterにて呼び出し
  */
  async registerData() {
    let error = this._validateForm();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._getCustomerCode();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._handleSubmitInput();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }

  /*
    public method
    外部から呼び出す関数
    CustomerEditにて呼び出し
  */
  async updateData() {
    let error = this._validateForm();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._handleSubmitInput();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }
}

export default CustomerFunction;

/*
顧客一覧からのcsv出力クラス
- 流れ：
 -　顧客一覧からデータの受け取り 
 -　顧客一覧データからCSV出力データ（内容とヘッダー）を抽出し返す
 -　（クラス呼び出しファイルにてCSV出力）
- Parameters in constructor:
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: CSV出力データ
*/
class CustomerCSVFunctions {

  constructor(props) {
    const { customerData } = props;
    this.customerData = customerData;
    this.response = { data: {}, error: '' };
  }

  /*
    public method
    外部から呼び出す関数
    顧客一覧データからcsv出力用のデータを抽出し返す
  */
  getCSVData() {
    if (this.customerData.length === 0) {
      this.response.error = '顧客データが見つかりませんでした。';
      return this.response;
    }
    const csvData = this.customerData.map((data) => {
      const {
        customer_code,
        customer_kana,
        customer_name,
        customer_address1,
        customer_address2,
        customer_phone1,
        customer_postal_code
      } = data;

      const _customer_address2 = parseInt(customer_address2) ? '="' + GeneralFunctions.halfToFullString(customer_address2) + '"' : customer_address2;
      return {
        enterprise_code: '706900', //会社コード
        customer_code: customer_code, //取引先コード
        customer_kana: GeneralFunctions.replaceStrToBankAcceptableStr(customer_kana), //取引先名（カナ)
        customer_name: GeneralFunctions.halfToFullString(customer_name), //取引先名（漢字）
        customer_address1: GeneralFunctions.halfToFullString(customer_address1), //住所1
        customer_address2: _customer_address2, //住所2
        customer_address3: '', //住所3
        customer_phone: customer_phone1, //取引先電話番号
        customer_postal_code: customer_postal_code, //郵便番号
        destination_title: '1', //宛先の敬称
        deposit_category_bank_transfer: '1', //入金区分（銀行振込）
        deposit_category_post: '1', //入金区分（郵政)
        deposit_category_convenience_store: '1', //入金区分（コンビニ）
        has_used_bank_account: 0, //仮想口座使用フラグ
        shipment_phone_number: '03-5297-8545' //発送元電話番号
      }
    });

    const csvHeader = {
      enterprise_code: '会社コード',
      customer_code: '取引先コード',
      customer_kana: '取引先名（カナ)',
      customer_name: '取引先名（漢字）',
      customer_address1: '住所1',
      customer_address2: '住所2',
      customer_address3: '住所3',
      customer_phone: '電話番号',
      customer_postal_code: '郵便番号',
      destination_title: '宛先の敬称',
      deposit_category_bank_transfer: '入金区分（銀行振込）',
      deposit_category_post: '入金区分（郵政)',
      deposit_category_convenience_store: '入金区分（コンビニ）',
      has_used_bank_account: '仮想口座使用フラグ',
      shipment_phone_number: '発送元電話番号'
    };
    const data = { csvHeader: csvHeader, csvData: csvData };
    this.response['data'] = data;
    this.response['error'] = '';
    return this.response;
  }
}

export { CustomerCSVFunctions }

/*
顧客データ消去関数
- Parameters in constructor:
 - customer: 顧客データ
 - employee_name: ログインユーザー名
 - mtb_enterprise_id: 企業Id
 - token: APIアクセストークン
*/
class CustomerDeleteFunction {

  constructor(props) {
    const { customer, employee_name, mtb_enterprise_id, token } = props;
    this.customer = customer;
    this.employee_name = employee_name;
    this.mtb_enterprise_id = mtb_enterprise_id;
    this.token = token;
    this.log_id = null;
  }

  /*
    private method
    mtb_customerから選択された顧客データの消去
  */
  async _handleDelete() {
    const { id } = this.customer;
    const tableName = '/mtb_customer';
    const deletingFunction = new DeletingFunctions({ tableName: tableName, id: id, token: this.token });
    const error = await deletingFunction.delete();
    return error;
  }

  /*
    private method
    mtb_customer_logに消去される顧客データの登録
  */
  async _registerDeletingCustomerLog() {
    const data = {};

    customerProperties.forEach((key) => {
      if (Object.keys(this.customer).includes(key)) {
        if (key === 'status') {
          const status = this.customer['status'].join(',');
          data[key] = status;
        } else {
          data[key] = this.customer[key];
        }
      }
    });

    const json = [data];
    try {
      await axios.post(CUSTOMER_DATA_CHANGE_URL, json, { headers: { "Authorization": `Bearer ${this.token}` } })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error('データを送信できませんでした。')
        })
        .then(({ data }) => {
          const { insertLogId } = data;
          this.log_id = insertLogId;
          return;
        })
    } catch (error) {
      return error.message;
    }
  }

  /*
    private method
    dtb_log全体ログに顧客データ変更（消去）の登録
  */
  async _registerLog() {
    const { customer_name } = this.customer;
    const customerPrintLog = {
      id: null,
      category: logCategory.delete,
      remarks: `顧客一覧: ${customer_name}`,
      mtb_enterprise_id: this.mtb_enterprise_id,
      table_type: logTableType.customer,
      log_id: this.log_id,
      created_employee_name: this.employee_name,
      updated_employee_name: this.employee_name
    };
    const json = [customerPrintLog];
    const error = await sendLogData(json, this.token);
    return error;
  }

  /*
    public method
    外部から呼び出す関数
    CustomerDetailにて呼び出し
  */
  async delete() {

    let error = await this._registerDeletingCustomerLog();
    if (error) {
      return error;
    }

    error = await this._handleDelete();
    if (error) {
      return error;
    }

    error = await this._registerLog();

    return error;
  }
}

export { CustomerDeleteFunction }

/**
 * 顧客詳細データの顧客名取得関数（WarrantyList.js, WarrantyListEdit.jsでのみ呼び出す）
 * @param {number} mtb_customer_id 
 * @param {number} mtb_enterprise_id 
 * @param {string} token 
 * @returns {string} customer_name
 */
export const getCustomerNameFromId = async (mtb_customer_id, mtb_enterprise_id, token) => {

  if (!mtb_customer_id || !token || !mtb_enterprise_id) return "";

  try {
    const response = await axios.get(GET_SELECTED_CUSTOMER_URL, {
      params: {
        mtb_enterprise_id: mtb_enterprise_id,
        mtb_customer_id: mtb_customer_id,
      },
      headers: { "Authorization": `Bearer ${token}` }
    });

    if (response.status === 200) {
      const { data } = response;
      const customerData = data.pop();
      if (customerData) {
        return customerData.customer_name;
      }
    }
    throw new Error("could not get customer data.");
  } catch (error) {
    console.log(error);
    return "";
  }
};