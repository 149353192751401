import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
//styles & configs
import { BaseBodyStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
//components
import { GeneralWarrantyEdit, DamageWarrantyInput } from 'src/views/warranty/warrantiesEdit/children';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { editSelectedWarrantyData, removeSelectedWarrantyByIndex } from 'src/redux/actions/WarrantyHandler';
//style and configs
import { FontSize } from 'src/configs/StyleConfig';
import { warrantyCategoryMap } from 'src/views/warranty/warranty/Warranty';

/*
  損害賠償保証証の編集コンポーネント
  reduxのstore、warranty内のselectedWarranty（保証証リスト表示画面で選択した際に格納された）をローカルのstate内に格納
  ユーザーが編集後、保存ボタンでreduxのstoreに再格納。→編集ページに推移
  キャンセルで選択された保証データの情報を破棄し編集ページへ推移
*/
const DamageWarrantyEdit = () => {

  const history = useHistory();
  const location = useLocation();
  const { isEditing } = location.state;
  const dispatch = useDispatch();
  const { warranties, selectedWarranty } = useSelector(state => state.warranty);
  const [warranty, setWarranty] = useState({});
  const [warrantyName, setWarrantyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedWarranty) {
      const _warrantyName = warrantyCategoryMap[selectedWarranty.category];
      setWarrantyName(_warrantyName);
      setWarranty({ ...selectedWarranty });
      setIsLoading(false);
    }
  }, [selectedWarranty]);


  /*
    選択された保証書データを編集する関数
  */
  const changeWarrantyInfo = useCallback((key, value) => {
    let updatedWarranty = { ...warranty };
    updatedWarranty[key] = value;
    if (key === "construction_type") {
      updatedWarranty["construction_other"] = "";
    }
    setWarranty(updatedWarranty);
  }, [warranty]);

  /*
    保存ボタンでreduxのstoreに編集された保証データを再格納。その後、編集ページに推移
    （この時DBにデータは送っていない。編集ページにて編集された保証証データのみDBに送信）
  */
  const onSaveButtonPressed = useCallback(() => {
    dispatch(editSelectedWarrantyData(warranty));
    history.goBack();
  }, [warranty, history, dispatch]);

  /*
    キャンセルで選択された保証データの情報を破棄し編集ページへ推移
  */
  const onCancelButtonPressed = useCallback(() => {
    if (typeof isEditing === "boolean" && !isEditing) {
      //新規追加でキャンセルの際、その保証情報をデリート
      const deletingIndex = warranties.length - 1;
      dispatch(removeSelectedWarrantyByIndex(deletingIndex));
    }
    history.goBack();
  }, [history, dispatch, isEditing, warranties.length]);


  if (isLoading) {
    <BaseBodyStyle />
  }
  return (
    <BaseBodyStyle>
      <TitleWrapper>
        <span style={{ fontSize: FontSize.xl, fontWeight: 500 }}>
          {warrantyName}編集
        </span>
      </TitleWrapper>
      <InputSection>
        <GeneralWarrantyEdit generalWarrantyInfo={warranty} changeGeneralWarrantyInfo={changeWarrantyInfo} isDamage={true} />
        <DamageWarrantyInput damageWarranty={warranty} changeDamageWarranty={changeWarrantyInfo} />
      </InputSection>
      <ButtonWrapper>
        <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
        <div style={{ width: 40 }} />
        <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
      </ButtonWrapper>
    </BaseBodyStyle>
  )
};

export const TitleWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 4px;
`

export const InputSection = styled.div`
  width: 100%;
  display: block;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  margin: auto;
`

export default DamageWarrantyEdit;
