import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import EnterpriseInputs from './children/EnterpriseInputs';
import DialogMessage from 'src/common/DialogMessage';
//functions
import EnterpriseFunctions, { getPartnerForEnterprise } from 'src/functions/mtbFunctions/EnterpriseFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  企業登録コンポーネント
  保存ボタンからDBに送信
  キャンセルで入力情報を破棄して一覧へ戻る
*/
const MtbEnterpriseRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, token } = useSelector(state => state.auth);
  const [enterprise, setEnterprise] = useState({});
  const [partners, setPartners] = useState([]);
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = async () => {
      const { state } = location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const baseEnterpriseData = {
        id: null,
        enterprise_code: '',
        enterprise_name: '',
        postal_code: '',
        address: '',
        phone: '',
        is_deleted: 0,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      const basePartnerData = await getPartnerForEnterprise(null, token); // 全て未選択の提携先データのリストを返す
      setEnterprise({...baseEnterpriseData});
      setPartners([...basePartnerData]);
    };
    if (employee_name) {
      getData();
    }
  }, [employee_name, token, location]);

  const changeEnterpriseData = useCallback((key, value) => {
    const _enterprise = {...enterprise};
    if (value !== null) {
      _enterprise[key] = value;
      setEnterprise(_enterprise);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [enterprise, modalRequired]);

  const changePartnerData = useCallback((key, index, value) => {
    if (value === null) return;

    const _partner = partners[index];
    if (key === 'is_selected') {
      _partner[key] = _partner[key] === 0 ? 1 : 0; //フリップ選択ステータス
      if (_partner[key] === 0) _partner['margin'] = null; //未選択でマージンを初期化
      partners[index] = _partner;
      if (!modalRequired.current) modalRequired.current = true;
    } else { // margin update
      _partner[key] = value;
      partners[index] = _partner;
    }
    setPartners([...partners]);
  }, [partners, modalRequired]);

  const onSaveButtonPressed = useCallback(async () => {
    const enterpriseFunctions = new EnterpriseFunctions({ enterpriseData: enterprise, partnerData: partners, token: token });
    const response = await enterpriseFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current});
    }
  }, [enterprise, partners, token, history, modalRequired, previousPath]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <EnterpriseInputs 
        data={enterprise}
        isEditing={true}
        partners={partners}
        errorText={errorText}
        changePartnerData={changePartnerData}
        changeData={changeEnterpriseData}
        onSaveButtonPressed={onSaveButtonPressed}
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbEnterpriseRegister;