import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Checkbox = (props) => {
  const { isChecked, changeStatus, label, fontSize, type } = props;
  return (
    <Container>
      <input type={type} checked={isChecked} onChange={changeStatus} style={CheckboxInputStyle} />
      <span style={{ fontSize: fontSize, fontWeight: 500 }}>{label}</span>
    </Container>
  )
};

const Container = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CheckboxInputStyle = {
  width: 20,
  height: 20,
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  fontSize: PropTypes.number,
  type: PropTypes.oneOf(['checkbox', 'radio']),
};

Checkbox.defaultProps = {
  label: '',
  isChecked: false,
  changeStatus: () => { },
  fontSize: 14,
  type: 'checkbox',
};

export default Checkbox;