import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//components
import { TextInput, TextHalfNumberInput, PostalCodeInput } from 'src/common/Inputs';
import Checkbox from 'src/common/Checkbox';
//styles and config
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { Colors } from 'src/configs/StyleConfig';

const EnterpriseInputs = (props) => {

  const { data, changeData, partners, changePartnerData, isEditing, errorText, onEditButtonPressed, onSaveButtonPressed, onCancelButtonPressed, onDeleteButtonPressed } = props;
  const { enterprise_code, enterprise_name, postal_code, address, phone } = data;

  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                企業コード
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={enterprise_code} onChange={(val) => changeData('enterprise_code', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                企業名
              </SettingList>
              <TextInput isEnable={isEditing} value={enterprise_name} onChange={(val) => changeData('enterprise_name', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                郵便番号
              </SettingList>
              <PostalCodeInput
                isEnable={isEditing}
                value={postal_code}
                onChange={(val) => changeData('postal_code', val.target.value)}
                onChangeAddress={(address) => changeData('address', address)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                住所
              </SettingList>
              <TextInput isEnable={isEditing} value={address} onChange={(val) => changeData('address', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                電話番号
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={phone} onChange={(val) => changeData('phone', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <Partner>
                提携先
              </Partner>
              <CheckBoxesContainer>
                {
                  partners.length > 0 && partners.map((partner, index) => {
                    const isEnable = isEditing && partner.is_selected;
                    return (
                      <CheckList key={partner.partner_name}>
                        <div style={{display: 'flex', width: 180}}>
                          <Checkbox
                            isChecked={partner.is_selected === 1 ? true : false}
                            label={partner.partner_name}
                            changeStatus={(val) => changePartnerData('is_selected', index, val.target.value)}
                          />
                        </div>
                        <div style={{width: 40}}>
                          <TextHalfNumberInput isEnable={isEnable} value={partner.margin ? partner.margin : ""} onChange={(val) => changePartnerData('margin', index, val.target.value)}  />
                        </div>
                        <div style={{width: 28, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          %
                        </div>
                      </CheckList>
                    )
                  })
                }
              </CheckBoxesContainer>
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`

const Partner = styled.div`
  width: 24%;
  margin: 8px 0;
`
const CheckBoxesContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const CheckList = styled.div`
  display: flex;
  flex-direction: row;
`


EnterpriseInputs.propTypes = {
  data: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  partners: PropTypes.array,
  changeData: PropTypes.func,
  changePartnerData: PropTypes.func,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

EnterpriseInputs.defaultProps = {
  data: {},
  isEditing: false,
  errorText: '',
  partners: [],
  changeData: () => { },
  changePartnerData: () => { },
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default EnterpriseInputs;