import Encoding from 'encoding-japanese';
import { zipObject } from 'lodash';

/*
CSV出力クラス
- Parameters in constructor:
 - headers: CSVヘッダー
 - items: CSVの内容
 - fileName: CSVファイル名
*/
class CSVFunctions {

  constructor(headers, items, fileName) {
    this.headers = headers;
    this.items = items;
    this.fileName = fileName;
  };

  /*
    public method
    リストデータのカラムをcsvのフォーマットに直し、
    日本語対応のエンコーダーを使ってbyte arrayに変更後、csv出力を行う関数
  */
  exportCSVData = () => {
    try {
      const headerKeys = Object.keys(this.items[0]);
      const rowHeader = this.headers || zipObject(headerKeys, headerKeys);
      const rows = [rowHeader, ...this.items];
      const csv = this._convertRowsToCSV(rows);
      const shiftJISArray = this._toShiftJIS(csv);
      const csvFileName = this.fileName + '.csv' || 'export.csv';
      this._initiateDownload(shiftJISArray, csvFileName);
      return;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }

  /*
    private method
    リストの一行一行をcsvフォーマットに変更するメソッド
  */
  _convertRowsToCSV = rowData => {
    return rowData.map(data => Object.values(data).join(',')).join('\r\n');
  };

  /*
    private method
    日本語対応のエンコーダー
  */
  _toShiftJIS(utf8Array) {
    const detected = Encoding.detect(utf8Array)
    const unicodeList = []
    for (let i = 0; i < utf8Array.length; i += 1) {
      unicodeList.push(utf8Array.charCodeAt(i))
    }
    const sjisArray = Encoding.convert(unicodeList, {
      to: 'SJIS',
      from: detected,
    })
    return new Uint8Array(sjisArray)
  };

  /*
    private method
    csvファイルダウンロードメソッド
  */
  _initiateDownload = (csvData, csvFileName) => {
    const blob = new Blob([csvData], { type: 'text/csv' });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, csvFileName); // IE 10+
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', csvFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

export default CSVFunctions;