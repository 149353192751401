import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
//statics, styles and configs
import FTS_ICON from 'src/static/FTS_icon.png';
import { Colors, FontSize } from 'src/configs/StyleConfig';
//components
import { SubmitButton, CancelButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { removeAuthenticationStatus } from 'src/redux/actions/AuthHandler';

const modalStyles = {
  content: {
    width: '20%',
    height: '20%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    border: 'none',
    overlay: {
      backgroundColor: '#fff',
    }
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)"
  },
};

const Header = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { employee_name, mtb_enterprise_id } = useSelector(state => state.auth);
  const { enterpriseData } = useSelector(state => state.master);
  const [enterpriseName, setEnterpriseName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);



  useEffect(() => {

  const getEnterpriseData = () => {
    //ヘッダーrender後に企業データからログインユーザーの企業IDを元に企業名を返す関数
    if (Object.keys(enterpriseData).length > 0) {
      return enterpriseData[mtb_enterprise_id].enterprise_name;
    }
  };

    if (mtb_enterprise_id) {
      const _enterpriseName = getEnterpriseData();
      setEnterpriseName(_enterpriseName);
    }
  }, [mtb_enterprise_id, enterpriseData]);

  /*
  ヘッダーのftsアイコンをタップ後に呼び出される関数
  result：顧客リストページへ推移
  */
  const onFTSIconPressed = useCallback(() => {
    history.push({ pathname: '/customer-list' });
  }, [history]);

  /*
  ヘッダーのログアウトボタンプレス後、表示されるモダルにてログアウトボタンを選択後に呼び出される関数
  reduxのログインユーザーにまつわるAuthデータを消去後にログインページへ推移
  */
  const onLogoutButtonPressed = useCallback(() => {
    dispatch(removeAuthenticationStatus());
    history.push("/login");
  }, [history, dispatch]);

  return (
    <HeaderStyle>
      <ImageWrapper onClick={onFTSIconPressed}>
        <img src={FTS_ICON} style={{ width: '100%', height: '100%' }} alt={"logo"}/>
      </ImageWrapper>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ height: '70px', width: '40%', minWidth: '200px', maxWidth: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <UserInfo>
            {enterpriseName && enterpriseName}
          </UserInfo>
          <UserInfo>
            {employee_name && employee_name}
          </UserInfo>
        </div>
        <LogoutButton onClick={() => setModalIsOpen(true)}>
          <i className="fa fa-sign-out " style={{ paddingRight: '8px', fontSize: '16px' }} />ログアウト
        </LogoutButton>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyles}
        contentLabel="Modal"
      >
        <h3 style={{ marginBottom: '40px' }}>本当にログアウトしますか？</h3>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CancelButton onClick={() => setModalIsOpen(false)}>キャンセル</CancelButton>
          <SubmitButton onClick={() => onLogoutButtonPressed()}>ログアウト</SubmitButton>
        </div>
      </Modal>
    </HeaderStyle>
  )
};

const HeaderStyle = styled.div`
  background-color: #3F3F3F;
  /* position: fixed; */
  left:0;
  top:0;
  height: 70px;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  border: none;
  flex-direction: row;
  justify-content: space-between;
`
const ImageWrapper = styled.div`
  height: 50px;
  width: 120px;
  cursor: pointer;
  padding: 40px;
`
const LogoutButton = styled.button`
  height: 70px;
  width: 120px;
  background-color: ${Colors.logoutButtonColor};
  color: #ffffff;
  font-weight: bold;
  border: none;
  font-size: ${FontSize.md};
  transition: 0.3s;
  cursor: pointer;
  :hover {
    color: #3F3F3F;
    background-color: #ffffff;
    border: 1px solid #3F3F3F;
  }
`
const UserInfo = styled.span`
  font-size: ${FontSize.md};
  color: #ffffff;
  font-weight: bold;
`

export default Header;