import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
//components
import Button from 'src/common/Button';
//styles
import { BreakPoints } from 'src/configs/StyleConfig';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedWarranty, setWarrantyData } from 'src/redux/actions/WarrantyHandler';
//functions
import DateFunctions from 'src/functions/DateFunctions';
import { getCustomerNameFromId } from 'src/functions/CustomerFunctions';
import { logCategory, sendLogData } from 'src/functions/LogFunctions';
import { WarrantyPrintFunction } from 'src/functions/WarrantyFunctions';


export const warrantyCategoryMap = {
  0: "シロアリ防除施工保証書",
  1: "防腐・防カビ保証書",
  2: "損害賠償保証書"
}

/*
  保証書リスト表示のコンポーネント
  reduxのstoreから選択された工事データに基づく保証証データのリストを取ってくる
  各行の詳細と印刷ボタンからそれぞれの関数を呼び出す
  編集ボタンで保証証データ編集ページへ移動
  工事詳細へ戻るボタンで工事詳細画面へ戻る
*/
const WarrantyList = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedConstructionData } = useSelector(state => state.construction);
  const { warranties } = useSelector(state => state.warranty);
  const { token, mtb_enterprise_id, employee_name } = useSelector(state => state.auth);

  const [isLoading, setIsLoading] = useState(true);
  const constructionData = useRef({ dtb_construction_id: null, certificate_no: null, contract_no: null, guarantor: null });
  const selectedConstructionId = useRef(null);

  useEffect(() => {
    if (selectedConstructionData) {
      //工事Idを元に保証証データをフェッチ
      const dtb_construction_id = selectedConstructionData.id;
      dispatch(setWarrantyData(dtb_construction_id));
      setConstructionGeneralData(dtb_construction_id);
      selectedConstructionId.current = dtb_construction_id;
      setIsLoading(false);
    }
  }, [selectedConstructionData]);


  /*
    保証情報の共通項目の設定
    値がある際は値をとり、ない時（工事登録時）はundefinedで工事登録の際に返り値の工事idと工事情報入力のその他項目（被保証人等）を設定する
  */
  const setConstructionGeneralData = async (dtb_construction_id) => {

    const contract_no = selectedConstructionData.contract_no;
    const mtb_customer_id = selectedConstructionData.mtb_customer_id;
    const guarantor = await getCustomerNameFromId(mtb_customer_id, mtb_enterprise_id, token); //顧客情報から顧客名を取ってきて補償者としておく
    constructionData.current.guarantor = guarantor;
    constructionData.current.dtb_construction_id = dtb_construction_id;
    constructionData.current.certificate_no = contract_no;
    constructionData.current.contract_no = contract_no;
  };

  /*
    各行の詳細ボタンから選択された保証データの詳細ページへ推移
  */
  const showWarrantyDetail = useCallback((index) => {

    const navigateToWarrantyDetail = (warrantyType, index) => {
      const currentPath = location.pathname;
      if (warrantyType === 2) {
        history.push({ pathname: `${currentPath}/${index}/damage-warranty-detail` });
      } else {
        history.push({ pathname: `${currentPath}/${index}/termite-antifungal-warranty-detail` });
      }
    };

    const selectedWarranty = warranties && warranties[index];
    const warrantyType = selectedWarranty.category;
    const isEditing = true;
    dispatch(setSelectedWarranty(selectedWarranty, index));
    navigateToWarrantyDetail(warrantyType, index, isEditing);
  }, [warranties, dispatch, location.pathname, history]);

  /*
    編集ボタンから保証証データ編集ページへ推移
  */
  const onEditButtonPressed = useCallback(() => {

    const { pathname } = location;
    history.push(`${pathname}/edit`);
  }, [history, location]);

  /*
    各行の印刷ボタンから選択された保証情報のプリント画面を別Windowsで開く
  */
  const printOutWarranty = useCallback(async (index) => {

    const registerPrintLog = async (selectedWarranty) => {
      const warrantyCategory = warrantyCategoryMap[selectedWarranty.category];
      const warrantyPrintLog = {
        id: null,
        category: logCategory.print,
        remarks: `${warrantyCategory}`,
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: null,
        log_id: null,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      const json = [warrantyPrintLog];
      await sendLogData(json, token); //when success, return null, otherwise returning error
    };

    const selectedWarranty = warranties[index];
    const pdfFunction = new WarrantyPrintFunction({ warranty: selectedWarranty });
    const response = await pdfFunction.print();
    if (response.error) {
      return;
    }
    
    window.open(response.data, "PRINT", "height=400,width=600");
    // registerPrintLog(selectedWarranty);
  }, [warranties, mtb_enterprise_id, employee_name, token]);

  /*
    工事詳細へ戻る関数
  */
  const navigateToConstructionDetail = useCallback(() => {

    history.push(`/construction-list/${selectedConstructionId.current}`);
  }, [history, selectedConstructionId]);

  if (isLoading) {
    return (
      <Container>
        <div style={{ padding: '16px' }}>
          <Title>
            保証書
          </Title>
        </div>
      </Container>
    )
  }
  return (
    <Container>
      <div style={{ padding: '16px' }}>
        <Title>
          保証書
        </Title>
        <Add>
          <div>
            <Button text="編集" onClick={onEditButtonPressed} />
          </div>
        </Add>
        <ListBody>
          {
            warranties && warranties.map((warranty, index) => {
              return (
                <Row style={{ 'position': 'relative' }} key={index.toString()}>
                  <ContentWrapper>
                    <div>
                      <p>証書番号：{warranty.certificate_no}</p>
                      <p>作成年月日：{DateFunctions.ISOStringDateToLocalDate(warranty.create_date)}</p>
                      <p>作業実施面積：{warranty.work_area}</p>
                    </div>
                    <div>
                      <p>保証期間：{DateFunctions.ISOStringDateToLocalDate(warranty.warranty_start_date)}　から  {warranty.warranty_period}年間</p>
                      <p>{DateFunctions.ISOStringDateToLocalDate(warranty.warranty_end_date)}　まで</p>
                    </div>
                  </ContentWrapper>
                  <ButtonWrapper>
                    <span style={{ 'display': 'flex', 'justifyContent': 'flex-end', 'padding': '8px' }}>
                      {warrantyCategoryMap[warranty.category]}
                    </span>
                    <div style={{ 'display': 'flex', 'flexDirection': 'row'}}>
                      <Button text="詳細" onClick={() => showWarrantyDetail(index)} />
                      <PrintButton onClick={() => printOutWarranty(index)}><i className="fa fa-print" style={{ paddingRight: '4px', fontSize: '12px' }} />印刷</PrintButton>
                    </div>
                  </ButtonWrapper>
                </Row>
              )
            })
          }
        </ListBody>
      </div>
      <div style={{ 'width': '100%', 'display': 'flex', 'justifyContent': 'center', 'padding': '12px 0' }}>
        <Button text={"工事詳細へ戻る"} onClick={navigateToConstructionDetail} />
      </div>
    </Container>
  )
};

const Container = styled.div`
  max-width: 1200px;
  min-width: ${BreakPoints.md};
  width: 90%;
  margin: 20px auto;
`

const Title = styled.div`
  text-align: left;
  font-size: 24px;
`

const Add = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`

const ContentWrapper = styled.div`
  width: 50%;
  min-width: 400px;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: 0px; 
  width: 220px;
`

const ListBody = styled.div`
  border: 1px solid gray;
  width: 100%;
  height: 100%;
  min-height: 300px;
  overflow: auto;
  display: block;
`
const Row = styled.li`
  width: 98%;
  height: 60px;
  table-layout: fixed;
  display: table;
  font-size: 14px;
  margin: 0 auto;
  border-bottom: 1px solid lightgray;
`

export const PrintButton = styled.button`
  width: 120px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 0px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export default WarrantyList;
