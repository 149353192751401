import React from 'react';
import PropTypes from "prop-types";
//components
import {BaseHeaderStyle, HeaderTitle} from 'src/common/StylesComponents';

const CustomerSupportHeader = () => {

  return (
    <BaseHeaderStyle>
      <HeaderTitle>
        対応履歴
      </HeaderTitle>
    </BaseHeaderStyle>
  )
};

CustomerSupportHeader.propTypes = {
};

CustomerSupportHeader.defaultProps = {
};
export default CustomerSupportHeader;