import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import toast from "react-hot-toast";
import axios from 'axios';
//components
import { BaseBodyStyle } from 'src/common/StylesComponents';
import { ConstructionListHeader, ConstructionListSearchField, ConstructionTable, ConstructionPrintModal } from "./children";
import ConstructionSearchModal from './children/ConstructionSearchModal';
import CustomToaster from 'src/common/CustomToaster';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllConstructionListData, setSelectedConstructionId, resetConstructionFetchFlag, removeSelectedConstructionDetailData } from 'src/redux/actions/ConstructionDataHandler';
import { removeErrorMessage } from 'src/redux/actions/ErrorMessageHandler';
import { dataPreprocessing } from 'src/redux/actions/CustomerDataHandler';
import { removeConstructionWarrantyData } from 'src/redux/actions/WarrantyHandler';
import { setSalesOfficeData } from 'src/redux/actions/MasterDataHandler';
//functions
import { ConstructionCSVFunctions, ConstructionPostcardPrint } from 'src/functions/ConstructionFunctions';
import { CustomerCSVFunctions } from 'src/functions/CustomerFunctions';
import CSVFunctions from 'src/functions/CSVFunctions';
import { logCategory, sendLogData } from 'src/functions/LogFunctions';
//apis
import { GET_CUSTOMER_CSV_URL } from 'src/apis';

const initialCustomerInfo = {
  customer_name: String(),
  customer_kana: String(),
  customer_address1: String(),
  customer_postal_code: String(),
  customer_phone1: String(),
  enterprise_name: String(),
  sales_office_name: String(),
  business_partner: String(),
  mtb_store_id: String(),
  remarks: String(),
  memo: String(),
  sales_employee_name1: String(),
  construction_employee_name1: String(),
  contract_no: String(),
  serial_no: String(),
  payment_method: String(),
  approval_number: String(),
  is_agreement_form: String(),
  is_maintenance: String(),
  has_other_construction: String(),
  construction_end_date: String(),
  customer_code: String(),
  destination_name: String(),
  destination_address: String(),
  destination_postal_code: String(),
  destination_phone: String(),
};

const ConstructionList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let { allConstructionList, hasDataSet } = useSelector(state => state.construction);
  const actionError = useSelector(state => state.error);
  const { customerList } = useSelector(state => state.customer);
  const { role, mtb_enterprise_id, mtb_sales_office_id, token, employee_name } = useSelector(state => state.auth);
  const { enterpriseData, salesOfficeData, partnerData } = useSelector(state => state.master);
  const [searched, setSearched] = useState(false);
  const [searchResult, setSearchResult] = useState(new Array); // 検索結果
  const [checkedState, setCheckedState] = useState(false);
  const [checkedIsExported, setCheckedIsExported] = useState(false);
  let checkboxFiltered = []; // 絞り込みされたみずほ用データ
  const [errorText, setErrorText] = useState('');
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sortType, setSortType] = useState(0);
  const [customerInfo, setCustomerInfo] = useState(initialCustomerInfo);
  const [selectedDateType, setSelectedDateType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilteringQueryFound, setIsFilteringQueryFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [csvStatus, setCSVStatus] = useState({
    isCustomerCSVExporting: false,
    isConstructionCSVExporting: false
  }); // 顧客CSV出力ボタンと工事CSV出力ボタンを処理中：enable => disable、処理終了後：disable => enable にするstate
  const printRef = useRef(null);

  useEffect(() => {
    const searchQuery = JSON.parse(localStorage.getItem("constructionSearchQuery")); // local storageから前回の検索入力情報をとってくる
    const searchCustomerInfo = searchQuery ? searchQuery.customerInfo : {};
    const searchSelectedDateType = searchQuery ? searchQuery.selectedDateType : "";
    const searchStartDate = searchQuery ? searchQuery.startDate : null;
    const searchEndDate = searchQuery ? searchQuery.endDate : null;
    const savedSortType = JSON.parse(localStorage.getItem("constructionSortType")); // local storageからsortTypeをとってくる

    if ((searchCustomerInfo && Object.keys(searchCustomerInfo).length) || searchSelectedDateType || searchStartDate || searchEndDate) { // local storageに検索結果がある場合は、それを表示する。
      setSearched(false);
      setCustomerInfo(searchCustomerInfo);
      setSelectedDateType(searchSelectedDateType);
      setStartDate(searchStartDate ? new Date(searchStartDate) : null);
      setEndDate(searchEndDate ? new Date(searchEndDate) : null);
      setSortType(parseInt(savedSortType) ? parseInt(savedSortType) : 0);
      setIsFilteringQueryFound(true);
    } else {
      dispatch(removeConstructionWarrantyData());
      dispatch(removeSelectedConstructionDetailData());
      dispatch(setSalesOfficeData()); //  はがき出力のため
      initialConstructionQuery();
    }
  }, []);

  useEffect(() => {
    if ((allConstructionList instanceof Array && allConstructionList.length !== 0) || hasDataSet) {
      if (isLoading) {
        dispatch(resetConstructionFetchFlag()); //DBから工事データをフェッチしてくるさいローダーを見せるフラグ
        setIsLoading(false);
      }
    }

  }, [allConstructionList]);

  /**
   * toastによるエラー表示（csvログ出力エラーなど）
   */
  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
    }
  }, [errorText]);

  /**
   * toastによるエラー表示（工事データ取得エラーなど）
   */
  useEffect(() => {
    // console.log(actionError)
    if (actionError.where === "construction") {
      toast.error(actionError.msg);
      dispatch(removeErrorMessage());
      setIsLoading(false);
    }
  }, [actionError, dispatch]);

  const SortSelected = (e) => {
    setSortType(e.target.value ? parseInt(e.target.value) : 0);
  };

  // 検索クリアー（全て表示）
  const onSearchClear = () => {
    setSearchResult(() => []); // 検索初期化
    localStorage.removeItem('constructionSearchQuery'); // local storageを空にする
    localStorage.removeItem('constructionSortType'); // local storageを空にする
    // 支払い方法checkboxを全てfalseにする
    setCheckedState(false);
    setCheckedIsExported(false);
    setSearched(false);
    setCustomerInfo({});
    setSelectedDateType("");
    setStartDate(null);
    setEndDate(null);
  };

  const initialConstructionQuery = () => {
    if (typeof role === "number" && role === 1) {
      dispatch(setAllConstructionListData({}));
    } else if (typeof role === "number" && role === 2) {
      dispatch(setAllConstructionListData({ mtb_enterprise_id: mtb_enterprise_id }));
    } else if (typeof role === "number" && role === 3) {
      dispatch(setAllConstructionListData({ mtb_enterprise_id: mtb_enterprise_id, mtb_sales_office_id: mtb_sales_office_id }));
    }
  };

  const setCSVStatusOnSearchResult = (time) => {
    const _searchResult = searchResult.map((row) => {
      if (row.is_exported === 0) {
        return {
          ...row,
          is_exported: 1,
          exported_at: time,
          exported_employee_name: employee_name
        };
      }
      return row;
    });
    setSearchResult(_searchResult);
  };

  const registerCSVLog = async () => {
    const csvConstructionLog = {
      id: null,
      category: logCategory.csvExport,
      remarks: 'CSV出力：工事一覧',
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [csvConstructionLog];
    const error = await sendLogData(json, token);
    setErrorText(error);
  };

  const onCSVExportButtonPressed = useCallback(async () => {
    if (typeof searched !== 'boolean') return;
    setCSVStatus({ ...csvStatus, isConstructionCSVExporting: true });
    let constructionCSVFunctions = null;
    let response = null;
    if (searched) {
      if (checkedState) {
        constructionCSVFunctions = new ConstructionCSVFunctions({ constructionListData: checkboxFiltered, token: token, loginEmployeeName: employee_name });
        response = await constructionCSVFunctions.getConstructionDetailForCSV(checkboxFiltered);
      } else {
        constructionCSVFunctions = new ConstructionCSVFunctions({ constructionListData: searchResult, token: token, loginEmployeeName: employee_name });
        response = await constructionCSVFunctions.getConstructionDetailForCSV(searchResult);
      };
    } else {
      if (checkedState) {
        constructionCSVFunctions = new ConstructionCSVFunctions({ constructionListData: checkboxFiltered, token: token, loginEmployeeName: employee_name });
        response = await constructionCSVFunctions.getConstructionDetailForCSV(checkboxFiltered);
      } else {
        constructionCSVFunctions = new ConstructionCSVFunctions({ constructionListData: allConstructionList, token: token, loginEmployeeName: employee_name });
        response = await constructionCSVFunctions.getConstructionDetailForCSV(allConstructionList);
      };
    }
    if (response.error) {
      setErrorText(response.error);
    } else {
      const { csvHeader, csvData } = response.data;
      const fileName = "工事詳細";
      new CSVFunctions(csvHeader, csvData, fileName).exportCSVData();
      if (role === 1) {
        // FTS 権限の場合ー＞dtb_constructionのCSV出力ステータス(is_exported)変更
        const time = new Date().toISOString();
        await constructionCSVFunctions.updateCSVExportStatus();
        if (searched) {
          //フィルターされたリストの場合、そのリスト内のis_exportをアップデート
          setCSVStatusOnSearchResult(time);
        }
      }
      //その他権限ではCSV出力ステータスを変更しない
      registerCSVLog();
      setErrorText('');
      setCSVStatus({ ...csvStatus, isConstructionCSVExporting: false });
      initialConstructionQuery();
    }
  }, [searched, searchResult, allConstructionList, employee_name, token, role, checkedState, csvStatus, setCSVStatus]);

  /**
   * 顧客データCSV出力
   * 表示している工事データの顧客IDを元にCSV出力する顧客データの取得関数
   * 検索なし＆チェックボックスなしの場合：
   *  - 表示している工事データの
   * searched & checkedState -> checkboxFiltered
   * !searched & checkedState -> checkboxFiltered
   * searched & !checkedState -> searchResult
   * !searched & !checkedState -> all customer list
   * @param:
   *  - customerIds: array of customer ids
   * @returns:
   *  - array of customer data
   */
  const onCustomerCSVExportButtonPressed = useCallback(async () => {

    const getFilteredCustomerListData = async (customerIds) => {
      //backendから顧客IDを元にデータを取得
      let results = [];
      if (customerIds.length === 0) return results;
      const json = {
        mtb_enterprise_id: mtb_enterprise_id,
        mtb_customer_id_array: customerIds
      };

      await axios.post(GET_CUSTOMER_CSV_URL, json, { headers: { "Authorization": `Bearer ${token}` } })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error("顧客CSVデータを取得できませんでした。");
        })
        .then(({ data }) => {
          results = dataPreprocessing(data, enterpriseData, salesOfficeData, partnerData);
        })
        .catch(() => {
          setErrorText("顧客CSVデータを取得できませんでした。");
        });
      return results;
    };

    setCSVStatus({ ...csvStatus, isCustomerCSVExporting: true });
    let customerCSVList = [];
    if (!searched && !checkedState) {
      customerCSVList = customerList
    } else {
      let customerIds = [];
      if (checkedState) {
        customerIds = checkboxFiltered.map((item) => item.mtb_customer_id);
      } else {
        customerIds = searchResult.map((item) => item.mtb_customer_id);
      }
      customerCSVList = await getFilteredCustomerListData(customerIds); // 顧客csv export用のデータarray
    }

    const customerFunctions = new CustomerCSVFunctions({ customerData: customerCSVList });
    const response = customerFunctions.getCSVData();
    if (typeof response.error === 'string' && response.error === '') {
      const { csvHeader, csvData } = response.data;
      const fileName = "顧客一覧";
      new CSVFunctions(csvHeader, csvData, fileName).exportCSVData();
      registerCSVLog();
    }

    setCSVStatus({ ...csvStatus, isCustomerCSVExporting: false });
  }, [searched, checkboxFiltered, customerList, searchResult, checkedState, csvStatus,
    enterpriseData, mtb_enterprise_id, salesOfficeData, partnerData, token, setCSVStatus, registerCSVLog]);

  const navigateToDetail = useCallback((id) => {
    const detailPath = `${location.pathname}/${id}`;
    dispatch(setSelectedConstructionId(id));
    history.push({ pathname: detailPath, searchedResult: searchResult });
  }, [history, searchResult]);

  const onPostalCardExportButtonPressed = () => {
    setIsPrintModalOpen(true);
  };

  /*
   はがき出力関数
   企業名＋営業所名とフマキラー出力時の設定の選択を元にはがきデータを作成、出力
  */
  const handlePrinting = async (isFirstOptionSelected) => {
    let constructionPostcardPrint = null;

    if (searched) {
      //検索結果を元にはがき作成
      constructionPostcardPrint = new ConstructionPostcardPrint({
        postcardData: searchResult,
        isFirstOptionSelected: isFirstOptionSelected,
        salesOfficeData: salesOfficeData,
        loginSalesOfficeId: mtb_sales_office_id,
        token: token
      });
    } else {
      //全データを元にはがき作成
      constructionPostcardPrint = new ConstructionPostcardPrint({
        postcardData: allConstructionList,
        isFirstOptionSelected: isFirstOptionSelected,
        salesOfficeData: salesOfficeData,
        loginSalesOfficeId: mtb_sales_office_id,
        token: token
      });
    }

    await constructionPostcardPrint.print();

    setIsPrintModalOpen(false);
  };

  const onPrintButtonPressed = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => registerPrintLog(),
  });

  const registerPrintLog = async () => {
    const customerPrintLog = {
      id: null,
      category: logCategory.print,
      remarks: '工事一覧',
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [customerPrintLog];
    await sendLogData(json, token);
  };

  // 検索かけた場合、その結果のDOMを返す
  if (searched) {

    // 登録された順番に変更
    if (sortType === 0) {
      // 作成日新しい順番
      searchResult.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (sortType === 1) {
      // 作成日古い順番
      searchResult.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    } else if (sortType === 2) {
      // 対応新しい順番
      searchResult.sort((a, b) => new Date(b.support_date) - new Date(a.support_date));
    } else if (sortType === 3) {
      // 対応古い順番
      searchResult.sort((a, b) => new Date(a.support_date) - new Date(b.support_date));
    } else if (sortType === 4) {
      // 契約新しい順番
      searchResult.sort((a, b) => new Date(b.contract_date) - new Date(a.contract_date));
    } else if (sortType === 5) {
      // 契約古い順番
      searchResult.sort((a, b) => new Date(a.contract_date) - new Date(b.contract_date));
    } else if (sortType === 6) {
      // 工事完了新しい順番
      searchResult.sort((a, b) => new Date(b.construction_end_date) - new Date(a.construction_end_date));
    } else if (sortType === 7) {
      // 工事完了古い順番
      searchResult.sort((a, b) => new Date(a.construction_end_date) - new Date(b.construction_end_date));
    } else if (sortType === 8) {
      // 最終更新日新しい順番
      searchResult.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    } else if (sortType === 9) {
      // 最終更新日古い順番
      searchResult.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
    };

    // みずほ用データのみ絞込のみにcheckがついてる場合
    if (checkedState && !checkedIsExported) {
      searchResult.filter((item, index) => {
        if (item.payment_method === 'コンビニ払い' || item.payment_method === '銀行振込') {
          checkboxFiltered.push(item);
        };
      });
    } else if (!checkedState && checkedIsExported) {
      // CSV出力済のみ絞込のみにcheckがついてる場合
      searchResult.filter((item, index) => {
        if (item.is_exported === 0) {
          checkboxFiltered.push(item);
        };
      });
    } else if (checkedState && checkedIsExported) {
      // みずほ用データとCSV出力済のみ絞込両方にcheckがついてる場合
      searchResult.filter((item, index) => {
        if (item.is_exported === 0 && (item.payment_method === 'コンビニ払い' || item.payment_method === '銀行振込')) {
          checkboxFiltered.push(item);
        };
      });
    };

    if (checkedState || checkedIsExported) {
      localStorage.setItem('constructionSortType', JSON.stringify(sortType)); //　sortTypeをlocalstorageに格納

      return (
        <BaseBodyStyle main id={'divToPrint'} ref={printRef}>
          <ConstructionListHeader
            onPrintButtonPressed={onPrintButtonPressed}
            onPostalCardExportButtonPressed={onPostalCardExportButtonPressed}
            onCSVExportButtonPressed={onCSVExportButtonPressed}
            onCustomerCSVExportButtonPressed={onCustomerCSVExportButtonPressed}
          />
          <ConstructionListSearchField
            onSearchClear={onSearchClear}
            SortSelected={SortSelected}
            sortType={sortType}
            setModalIsOpen={setModalIsOpen}
            setCheckedState={setCheckedState}
            checkedState={checkedState}
            setCheckedIsExported={setCheckedIsExported}
            checkedIsExported={checkedIsExported}
          />
          <ConstructionTable data={checkboxFiltered} navigation={navigateToDetail} sortType={sortType} isDataProcessing={isLoading} />
          <ConstructionSearchModal
            setIsLoading={setIsLoading}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
            selectedDateType={selectedDateType}
            setSelectedDateType={setSelectedDateType}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setSearchResult={setSearchResult}
            setSearched={setSearched}
            isFilteringQueryFound={isFilteringQueryFound}
          />
          <ConstructionPrintModal isPrintModalOpen={isPrintModalOpen} onPrintButtonPressed={handlePrinting} onCancelButtonPressed={() => setIsPrintModalOpen(false)} />
          <CustomToaster />
        </BaseBodyStyle >
      )
    };

    localStorage.setItem('constructionSortType', JSON.stringify(sortType)); //　sortTypeをlocalstorageに格納

    return (
      <BaseBodyStyle main id={'divToPrint'} ref={printRef}>
        <ConstructionListHeader
          onPrintButtonPressed={onPrintButtonPressed}
          onPostalCardExportButtonPressed={onPostalCardExportButtonPressed}
          onCSVExportButtonPressed={onCSVExportButtonPressed}
          onCustomerCSVExportButtonPressed={onCustomerCSVExportButtonPressed}
          isCustomerCSVExporting={csvStatus.isCustomerCSVExporting}
          isConstructionCSVExporting={csvStatus.isConstructionCSVExporting}
        />
        <ConstructionListSearchField
          onSearchClear={onSearchClear}
          SortSelected={SortSelected}
          sortType={sortType}
          setModalIsOpen={setModalIsOpen}
          setCheckedState={setCheckedState}
          checkedState={checkedState}
          setCheckedIsExported={setCheckedIsExported}
          checkedIsExported={checkedIsExported}
          searchResult={searchResult}
        />
        <ConstructionTable data={searchResult} navigation={navigateToDetail} sortType={sortType} isDataProcessing={isLoading} />
        <ConstructionSearchModal
          setIsLoading={setIsLoading}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          customerInfo={customerInfo}
          setCustomerInfo={setCustomerInfo}
          selectedDateType={selectedDateType}
          setSelectedDateType={setSelectedDateType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setSearchResult={setSearchResult}
          setSearched={setSearched}
          isFilteringQueryFound={isFilteringQueryFound}
        />
        <ConstructionPrintModal isPrintModalOpen={isPrintModalOpen} onPrintButtonPressed={handlePrinting} onCancelButtonPressed={() => setIsPrintModalOpen(false)} />
        <CustomToaster />
      </BaseBodyStyle >
    )

    // 検索されてない場合
  } else {
    // 登録された順番に変更
    if (sortType === 0) {
      // 登録新しい順番
      allConstructionList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (sortType === 1) {
      // 登録古い順番
      allConstructionList.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    } else if (sortType === 2) {
      // 対応新しい順番
      allConstructionList.sort((a, b) => new Date(b.support_date) - new Date(a.support_date));
    } else if (sortType === 3) {
      // 対応古い順番
      allConstructionList.sort((a, b) => new Date(a.support_date) - new Date(b.support_date));
    } else if (sortType === 4) {
      // 契約新しい順番
      allConstructionList.sort((a, b) => new Date(b.contract_date) - new Date(a.contract_date));
    } else if (sortType === 5) {
      // 契約古い順番
      allConstructionList.sort((a, b) => new Date(a.contract_date) - new Date(b.contract_date));
    } else if (sortType === 6) {
      // 工事完了新しい順番
      allConstructionList.sort((a, b) => new Date(b.construction_end_date) - new Date(a.construction_end_date));
    } else if (sortType === 7) {
      // 工事完了古い順番
      allConstructionList.sort((a, b) => new Date(a.construction_end_date) - new Date(b.construction_end_date));
    } else if (sortType === 8) {
      // 最終更新日新しい順番
      allConstructionList.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    } else if (sortType === 9) {
      // 最終更新日古い順番
      allConstructionList.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
    };

    // みずほ用データのみ絞込のみにcheckがついてる場合
    if (checkedState && !checkedIsExported) {
      allConstructionList.filter((item, index) => {
        if (item.payment_method === 'コンビニ払い' || item.payment_method === '銀行振込') {
          checkboxFiltered.push(item);
        };
      });
    } else if (!checkedState && checkedIsExported) {
      // CSV出力済のみ絞込のみにcheckがついてる場合
      allConstructionList.filter((item, index) => {
        if (item.is_exported === 0) {
          checkboxFiltered.push(item);
        };
      });
    } else if (checkedState && checkedIsExported) {
      // みずほ用データとCSV出力済のみ絞込両方にcheckがついてる場合
      allConstructionList.filter((item, index) => {
        if (item.is_exported === 0 && (item.payment_method === 'コンビニ払い' || item.payment_method === '銀行振込')) {
          checkboxFiltered.push(item);
        };
      });
    };

    if (checkedState || checkedIsExported) {

      return (
        <BaseBodyStyle main id={'divToPrint'} ref={printRef}>
          <ConstructionListHeader
            onPrintButtonPressed={onPrintButtonPressed}
            onPostalCardExportButtonPressed={onPostalCardExportButtonPressed}
            onCSVExportButtonPressed={onCSVExportButtonPressed}
            onCustomerCSVExportButtonPressed={onCustomerCSVExportButtonPressed}
            isCustomerCSVExporting={csvStatus.isCustomerCSVExporting}
            isConstructionCSVExporting={csvStatus.isConstructionCSVExporting}
          />
          <ConstructionListSearchField
            onSearchClear={onSearchClear}
            SortSelected={SortSelected}
            sortType={sortType}
            setModalIsOpen={setModalIsOpen}
            setCheckedState={setCheckedState}
            checkedState={checkedState}
            setCheckedIsExported={setCheckedIsExported}
            checkedIsExported={checkedIsExported}
          />
          <ConstructionTable data={checkboxFiltered} navigation={navigateToDetail} sortType={sortType} isDataProcessing={isLoading} />
          <ConstructionSearchModal
            setIsLoading={setIsLoading}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
            selectedDateType={selectedDateType}
            setSelectedDateType={setSelectedDateType}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setSearchResult={setSearchResult}
            setSearched={setSearched}
            isFilteringQueryFound={isFilteringQueryFound}
          />
          <ConstructionPrintModal isPrintModalOpen={isPrintModalOpen} onPrintButtonPressed={handlePrinting} onCancelButtonPressed={() => setIsPrintModalOpen(false)} />
          <CustomToaster />
        </BaseBodyStyle >
      )
    };

    return (
      <BaseBodyStyle main id={'divToPrint'} ref={printRef}>
        <ConstructionListHeader
          onPrintButtonPressed={onPrintButtonPressed}
          onPostalCardExportButtonPressed={onPostalCardExportButtonPressed}
          onCSVExportButtonPressed={onCSVExportButtonPressed}
          onCustomerCSVExportButtonPressed={onCustomerCSVExportButtonPressed}
          isCustomerCSVExporting={csvStatus.isCustomerCSVExporting}
          isConstructionCSVExporting={csvStatus.isConstructionCSVExporting}
        />
        <ConstructionListSearchField
          onSearchClear={onSearchClear}
          SortSelected={SortSelected}
          sortType={sortType}
          setModalIsOpen={setModalIsOpen}
          setCheckedState={setCheckedState}
          checkedState={checkedState}
          setCheckedIsExported={setCheckedIsExported}
          checkedIsExported={checkedIsExported}
        />
        <ConstructionTable data={allConstructionList} navigation={navigateToDetail} sortType={sortType} isDataProcessing={isLoading} />
        <ConstructionSearchModal
          setIsLoading={setIsLoading}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          customerInfo={customerInfo}
          setCustomerInfo={setCustomerInfo}
          selectedDateType={selectedDateType}
          setSelectedDateType={setSelectedDateType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setSearchResult={setSearchResult}
          setSearched={setSearched}
          isFilteringQueryFound={isFilteringQueryFound}
        />
        <ConstructionPrintModal isPrintModalOpen={isPrintModalOpen} onPrintButtonPressed={handlePrinting} onCancelButtonPressed={() => setIsPrintModalOpen(false)} />
        <CustomToaster />
      </BaseBodyStyle>
    )
  };
};

export default withRouter(ConstructionList);
