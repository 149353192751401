import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//configs & styles
import { Colors } from 'src/configs/StyleConfig';
import 'src/index.css';

//選択肢とテキストインプット両方賄えるコンポーネント
const TextInputDropDownSelection = (props) => {
  const { options, onChange, value, isEnable, id } = props;

  return (
    <Container>
      <div style={{width: '95%'}}>
        <TextInput disabled={!isEnable} type="text" list={id} value={value} onChange={onChange} />
          <datalist id={id}>
            {options.map((item, index) => {
              return <option  data-text={item.label} value={item.value} key={index} />
            }
          )}
        </datalist>
      </div>
    </Container>
  );
};


const Container = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  font-weight: 500;
  flex-direction: 'column';
  margin-bottom: 12px;
`
const TextInput = styled.input`
  width: 100%;
  padding: 4px;
  height: 20px;
  :disabled {
    background-color: white;
    border-radius: 2px;
    border: 1px solid ${Colors.textColor};
    color: ${Colors.textColor};
  }
`
TextInputDropDownSelection.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isEnable: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

TextInputDropDownSelection.defaultProps = {
  options: [],
  onChange: () => {},
  value: '',
  isEnable: true,
  id: 'data',
};

export default TextInputDropDownSelection;