import React from 'react';
//components
import { BaseHeaderStyle, HeaderTitle } from 'src/common/StylesComponents';

const ConstructionDetailHeader = () => {

  return (
    <BaseHeaderStyle>
      <HeaderTitle>
        工事情報
      </HeaderTitle>
    </BaseHeaderStyle>
  )
};

export default ConstructionDetailHeader;