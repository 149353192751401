import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
//components
import Button from 'src/common/Button';
import { BaseHeaderStyle, HeaderTitle } from 'src/common/StylesComponents';
//redux
import { useSelector } from 'react-redux';
//functions
import { getLogData } from 'src/functions/LogFunctions';
//styles and configs
import { BreakPoints } from 'src/configs/StyleConfig';
import DateFunctions from 'src/functions/DateFunctions';

const columnNames = {
  category: { label: '分類', width: '10%', align: 'center' },
  updated_employee_name: { label: '変更/出力者', width: '25%', align: 'left' },
  created_at: { label: '変更/出力日時', width: '20%', align: 'left' },
  remarks: { label: '変更/出力内容', width: '25%', align: 'left' },
  other: { label: '', width: '20%', align: 'left' }
};

const logCategoryMapping = {
  0: '新規登録',
  1: '更新',
  2: '削除',
  3: '印刷'
}

const ChangingLogList = () => {

  const history = useHistory();
  const location = useLocation();

  const { mtb_enterprise_id, token } = useSelector(state => state.auth);
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getLogs = async () => {
      const logData = await getLogData(mtb_enterprise_id, token);
      setLogs(logData);
      setIsLoading(false);
    };

    if (mtb_enterprise_id && token) {
      getLogs();
    }

  }, [mtb_enterprise_id, token]);

  const onUpdateContentButtonPressed = useCallback((data) => {
    const currentPath = location.pathname;
    history.push({ pathname: `${currentPath}/${data.id}`, state: { data: data } })
  }, [history, location]);

  if (isLoading) {
    return (
      <><div style={{ padding: '60px 60px' }} /></>
    )
  }

  return (
    <>
      <BaseHeaderStyle>
        <HeaderTitle>
        変更履歴
        </HeaderTitle>
      </BaseHeaderStyle>
      <div style={{ padding: '80px 60px 20px 60px' }}>
        <Table>
          <ColumnNameWrapper>
            <tr>
              {
                Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
              }
            </tr>
          </ColumnNameWrapper>
          <tbody>
            {
              logs.length > 0 && logs.map((row, index) => {
                return (
                  <Tr key={index.toString()}>
                    {
                      Object.keys(columnNames).map((columnName) => {
                        if (columnName === 'created_at') {
                          return (
                            <Td key={columnName} width={columnNames[columnName].width}>
                              {DateFunctions.ISOStringDateToLocalDate(row[columnName])}
                            </Td>
                          )
                        } else if (columnName === 'category') {
                          return (
                            <Td key={columnName} width={columnNames[columnName].width}>
                              {logCategoryMapping[row[columnName]]}
                            </Td>
                          )
                        } else if (columnName === 'other') {
                          return (
                            <Td key={columnName} width={columnNames[columnName].width}>
                              {
                                row['category'] === 1 ?
                                  <Button text={'変更内容'} onClick={() => onUpdateContentButtonPressed(row)} />
                                  :
                                  null
                              }
                            </Td>
                          )
                        }
                        return (
                          <Td key={columnName} width={columnNames[columnName].width}>
                            {row[columnName]}
                          </Td>
                        )
                      })
                    }
                  </Tr>
                )
              })
            }
          </tbody>
        </Table>
      </div >
    </>
  )
};

const Table = styled.table`
  max-width: ${BreakPoints.xxl};
  min-width: ${BreakPoints.md};
  width: 100%;
  min-height: 150px;
  max-height: 700px;
  height: 80%;
  border-collapse: collapse;
  @media screen and (max-width: ${BreakPoints.md}) {
    display: inline-block;
    overflow-x: auto;
  }
`
const ColumnNameWrapper = styled.thead`
  border-bottom: 2px solid #000000;
  background-color: #3f3f3f;
  color: #ffffff;
`
const Th = styled.th`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 4px;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.align}px`};
`
const Tr = styled.tr`
  height: 60px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  background-color: white;
`
const Td = styled.td`
  list-style-type: none;
  text-align: ${props => props.align ? props.align : 'left'};
  padding-left: 4px;
`

export default ChangingLogList;
