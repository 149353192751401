import React, {useEffect, useState, useCallback} from 'react';
import { useHistory } from 'react-router';
//styles & configs
import { BaseBodyStyle } from 'src/common/StylesComponents';
//components
import { GeneralWarrantyEdit } from 'src/views/warranty/warrantiesEdit/children';
import Button from 'src/common/Button';
//redux
import { useSelector } from 'react-redux';
//style and configs
import {FontSize} from 'src/configs/StyleConfig';
import {warrantyCategoryMap} from 'src/views/warranty/warranty/Warranty';
import {TitleWrapper, ButtonWrapper} from 'src/views/warranty/warrantiesEdit/TermiteAntifungalWarrantyEdit';


/*
  シロアリ防除、防かび保証証の詳細表示コンポーネント
*/
const TermiteAntifungalWarrantyDetail = () => {

  const history = useHistory();
  const { selectedWarranty } = useSelector(state => state.warranty);
  const [warranty, setWarranty] = useState({});
  const [warrantyName, setWarrantyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedWarranty) {
      const _warrantyName = warrantyCategoryMap[selectedWarranty.category];
      setWarrantyName(_warrantyName);
      setWarranty({...selectedWarranty});
      setIsLoading(false);
    }

  }, [selectedWarranty]);

  const onBackButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isLoading) {
    <BaseBodyStyle />
  }
  return (
    <BaseBodyStyle>
      <TitleWrapper>
        <span style={{fontSize: FontSize.xl, fontWeight: 500}}>
          {warrantyName}
        </span>
      </TitleWrapper>
      <GeneralWarrantyEdit generalWarrantyInfo={warranty} isEditable={false} />
      <ButtonWrapper>
        <Button text={"戻る"} onClick={onBackButtonPressed}/>
      </ButtonWrapper>
    </BaseBodyStyle>
  )
};

export default TermiteAntifungalWarrantyDetail;
