import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//components
import { BaseHeaderStyle, HeaderTitle, HeaderRight } from 'src/common/StylesComponents';

const CustomerListHeader = (props) => {
  const { onRegisterCustomerButtonPressed, onLabelExportButtonPressed, onPrintButtonPressed } = props;

  return (
    <BaseHeaderStyle>
      <HeaderTitle>
        顧客一覧
      </HeaderTitle>
      <HeaderRight>
        <PrintButton onClick={onPrintButtonPressed}><i className="fa fa-print" style={{ paddingRight: '8px', fontSize: '12px' }} />印刷</PrintButton>
        <ExportButton onClick={onLabelExportButtonPressed}><i className="fa fa-download" style={{ paddingRight: '8px', fontSize: '12px' }} />CSV出力</ExportButton>
        <CustomerRegisterButton onClick={onRegisterCustomerButtonPressed}>新規顧客登録</CustomerRegisterButton>
      </HeaderRight>
    </BaseHeaderStyle>
  )
};

CustomerListHeader.propTypes = {
  onRegisterCustomerButtonPressed: PropTypes.func.isRequired,
  onLabelExportButtonPressed: PropTypes.func.isRequired,
  onPrintButtonPressed: PropTypes.func,
};

CustomerListHeader.defaultProps = {
  onRegisterCustomerButtonPressed: () => { },
  onLabelExportButtonPressed: () => { },
  onPrintButtonPressed: () => { }
};

const CustomerRegisterButton = styled.button`
  color: #ffffff;
  background-color: #70c66b;
  border: 2px solid #70c66b;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 20px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #70c66b;
    background-color: #ffffff;
    border: 2px solid #70c66b;
  }
`

export const PrintButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export const ExportButton = styled.button`
  width: 120px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export default CustomerListHeader;