import styled from 'styled-components';
import { BreakPoints } from 'src/configs/StyleConfig';
/// ここには複数のコンポーネントで共通して使う汎用性の高いスタイリングを宣言する

export const CommonCardStyle = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  background-color: white;
  color: #3f3f3f;
  margin: 10px;
  z-index:1;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  :after {
    content: "";
    display: block;
  }
`

////header -----------
export const BaseHeaderStyle = styled.div`
  position: absolute;
  left:0;
  top:0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
`
export const HeaderTitle = styled.h1`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50% , -50%);
  color: #3f3f3f;
  font-size: 20px;
  @media screen and (max-width: ${BreakPoints.lg}) {
    left: 8%;
  }
`
export const HeaderLeft = styled.div`
  color: #3f3f3f;
  position: absolute;
  left: 12px;
  top: 12px;
  display: flex;
  flex-direction: row;
`
export const HeaderRight = styled.div`
  color: #3f3f3f;
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LabelStyle = styled.div`
  min-width: 80px;
  max-width: 180px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
`

export const BaseBodyStyle = styled.div`
  padding: ${props => props.main ? '0px' : '30px 60px'};
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.main ? 'center' : 'none'};
  color: #3f3f3f;
  background-color: #ffffff;
`

/// List search field style -----------------

export const SearchFieldContainer = styled.div`
  width: 98%;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  color: #3f3f3f;
`

export const SearchContainer = styled.div`
  color: #3f3f3f;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  background-color: white;
  padding: 20px;
  margin: 10px;
  border: 1px solid #dedede;
  border-radius: 6px;
  @media screen and (max-width: ${BreakPoints.md}) {
    justify-content: center;
  }
`

export const SearchInputContainer = styled.div`
  color: #3f3f3f;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
`

export const CheckboxContainer = styled.div`
  height: 40px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  color: #3f3f3f;
`
export const IsolatedCheckboxContainer = styled(CheckboxContainer)`
  padding-left: 60px;
  color: #3f3f3f;
  min-width: 80px;
  @media screen and (max-width: ${BreakPoints.md}) {
    padding-left: 8%;
  }
`

// input label ------------------
export const InputLabelStyle = styled.div`
  width: ${props => props.Selection ? '88px' : '120px'}; 
  text-align: ${props => props.align ? props.align : 'end'};
  padding-right: 8px;
`
export const InputAndLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 4px;
`
export const InputContainer = styled.div`
  width: 50%;
`

//Save and Cancel button wrapper ---------

export const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 0;
  @media screen and (max-width: ${BreakPoints.lg}) {
    margin-bottom: 40px;
  }
`

// Overlay style ----------------------

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index:1000;
  background-color: rgba(0, 0, 0, .75);
  display: flex;
  align-items: center;
  justify-content: center;
`

// mtb common styles
export const MtbContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const MtbButtonMenu = styled.div`
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-top: 16px;
`

// Button
export const CancelButton = styled.button`
  width: 120px;
  color: #ffffff;
  background-color: #C83200;
  border: 2px solid #C83200;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #C83200;
    background-color: #ffffff;
    border: 2px solid #C83200;
  }
`

export const SubmitButton = styled.button`
  width: 124px;
  color: #ffffff;
  background-color: #70c66b;
  border: 2px solid #70c66b;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #70c66b;
    background-color: #ffffff;
    border: 2px solid #70c66b;
  }
`