import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import StoreInputs from './children/StoreInputs';
import DeleteConfirmModal from 'src/views/settings/children/DeleteConfirmModal';
//functions
import DeletingFunctions from 'src/functions/DeletingFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  店舗詳細表示コンポーネント
  編集ボタンから店舗詳細編集ページへ推移
  消去ボタンから消去確認モダル表示後に確認で店舗データを消去、リストに戻る
*/
const MtbStoreDetail = (props) => {

  const history = useHistory();
  const location = useLocation();
  const { token } = useSelector(state => state.auth);
  const [store, setStore] = useState({});
  const [errorText, setErrorText] = useState('');
  const [modalRequired, setModalRequired] = useState(false);

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const data = state && state.data;
      return data;
    };
    const _store = getData();
    setStore({..._store});
  }, [props]);

  /*
    編集ボタンから店舗詳細編集ページへ推移
  */
  const onEditButtonPressed = useCallback(() => {
    const {pathname, state} = location;
    history.push({pathname: `${pathname}/edit`, state: {data: state.data, prevPath: pathname}});
  }, [history, location]);

  /*
    消去ボタンから消去確認モダル表示
  */
  const onDeleteButtonPressed = useCallback(() => {
    setModalRequired(true);
  }, []);

  /*
    モダル内で確認ボタンから店舗データを消去、リストに戻る
  */
  const deleteSelectedItem = useCallback(async () => {
    const { id } = store;
    const tableName = '/mtb_store';
    const deletingFunctions = new DeletingFunctions({tableName: tableName, id: id, token: token});
    const error = await deletingFunctions.delete();
    if (error) {
      setErrorText(error);
    } else {
      setErrorText('');
      history.replace('/settings/store-list');
    }
  }, [history, token, store]);

  return (
    <>
      <DeleteConfirmModal
        modalRequired={modalRequired}
        onCloseModalButtonPressed={() => setModalRequired(false)}
        onConfirmButtonPressed={deleteSelectedItem}
      />
      <StoreInputs data={store} isEditing={false} errorText={errorText} onEditButtonPressed={onEditButtonPressed} onDeleteButtonPressed={onDeleteButtonPressed} />
    </>
  )
};

export default MtbStoreDetail;