import axios from 'axios';
import { PDFDocument, rgb, grayscale } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import JaFontUrl from 'src/static/fonts/HinaMincho-Regular.ttf';
import TermitePDF from 'src/static/termite.pdf';
import DamagePDF from 'src/static/damage.pdf';
import FungalPDF from 'src/static/fungal.pdf';

import GeneralFunctions from 'src/functions/GeneralFunctions';
import DateFunctions from './DateFunctions';

import {  
  WARRANTY_DATA_CHANGE_URL, 
} from 'src/apis';

/*
保証書の登録/編集クラス
- Parameters in constructor:
 - warranty: 選択された工事データに基づく保証書データ
 - employee_name: 選択された工事データに基づく顧客名
 - dtb_construction_id: 選択された工事データに基づく工事Id
 - contract_no: 選択された工事データに基づく契約書No
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセス時のエラー
 - warranties: DBに登録/編集した保証書データのリスト
*/
class WarrantyFunctions {

  constructor(props) {
    const { warranty, employee_name, dtb_construction_id, contract_no, token } = props;
    this.warranty = warranty;
    this.employee_name = employee_name;
    this.dtb_construction_id = dtb_construction_id;
    this.contract_no = contract_no;
    this.token = token;
    this.response = {error: '', warranties: []};
  }

  /*
    private method
    APIアクセスメソッド
  */
  async _sendData() {
    const warranty = [...this.warranty];
    const sendingWarrantyData = [];
    if (warranty.length === 0) return; 
    warranty.map((row) => {
      let registeringWarranty = {...row};
      if (!registeringWarranty.id) { //新規登録
        registeringWarranty["dtb_construction_id"] = this.dtb_construction_id;
        registeringWarranty["certificate_no"] = this.contract_no;
        registeringWarranty["contract_no"] = this.contract_no;
        registeringWarranty['created_employee_name'] = this.employee_name;
        registeringWarranty['is_exported'] = 0;
      }
      if (registeringWarranty.category === 2) {
        registeringWarranty["construction_type"] = registeringWarranty["construction_type"] === -1 ? null : registeringWarranty["construction_type"];
        registeringWarranty["construction_division"] = registeringWarranty["construction_division"] === -1 ? null : registeringWarranty["construction_division"];
        registeringWarranty["termite_type"] = registeringWarranty["termite_type"] === -1 ? null : registeringWarranty["termite_type"];
        registeringWarranty["measures"] = registeringWarranty["measures"] === -1 ? null : registeringWarranty["measures"];
      }
      
      registeringWarranty['updated_employee_name'] = this.employee_name;
      sendingWarrantyData.push(registeringWarranty);
    });
    
    this.response.warranties = [...sendingWarrantyData];
    try {
      await axios.post(WARRANTY_DATA_CHANGE_URL, sendingWarrantyData, { headers: {"Authorization" : `Bearer ${this.token}`} })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("保証情報を登録できませんでした。");
      })
      .then(() => {
        return;
      })
    } catch (error) {
      return error.message;
    }
  };

  /*
    public method
    外部から呼び出す関数
    登録/編集した保証書データのリストもしくはエラーを返す
  */
  async sendWarranties() {
    if (!this.dtb_construction_id || !this.employee_name) {
      this.response.error = "該当する工事データが確認できませんでした。";
      return this.response;
    }

    const error = await this._sendData();
    if (error) {
      this.response.error = "保証データを保存できませんでした。";
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }
};

export default WarrantyFunctions;


/*
保証書印刷クラス
カテゴリによって印刷するpdfファイルを変更
シロアリ、防かび、損害賠償それぞれでデータを生成
- Parameters in constructor:
 - warranty:　選択された保証書データ
*/
class WarrantyPrintFunction {

  constructor(props) {
    const { warranty } = props;
    this.warranty = warranty;
    this.category = warranty.category;
    this.response = { error: "", data: null};
  }

  /*
    private method
    シロアリのpdfデータに選択された保証書データを配置し印刷データを生成し印刷出力
  */
  async _printTermite() {

    try {
      const config = {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: {
          'Accept': 'application/pdf'
        }
      };
      const res = await axios.get(TermitePDF, config);
      const existingPdfBytes = res.data;
      const fontBytes = await fetch(JaFontUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);
      const font = await pdfDoc.embedFont(fontBytes)
      // Get the first page of the document
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      // Get the width and height of the first page
      const { height } = firstPage.getSize();
  
      //シロアリ保証書データの取得
      const { create_date, guarantor, contract_no, certificate_no, work_area, building_area_unit, warranty_start_date, warranty_end_date, warranty_period} = this.warranty;
      const today = new Date(create_date);
      const year = today.getUTCFullYear().toString();
      const month = String(today.getMonth() + 1); //0~11
      const date = today.getDate().toString();
      const fontSize = 12;
      // Draw a string of text diagonally across the first page
      // 作生年月日の挿入
      firstPage.drawText(year, {
        x: 175,
        y: height / 2 + 247,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(month, {
        x: 238,
        y: height / 2 + 247,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date, {
        x: 285,
        y: height / 2 + 247,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      // 被保証人の挿入
      if (guarantor) {
        const _guarantor = GeneralFunctions.fullToHalfString(guarantor);
        firstPage.drawText(_guarantor, {
          x: 170,
          y: height / 2 + 205,
          size: fontSize + 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 証書番号の挿入
      if (certificate_no) {
        firstPage.drawText(certificate_no.toString(), {
          x: 438,
          y: height / 3 + 478,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 契約書Noの挿入
      if (contract_no) {
        firstPage.drawText(contract_no.toString(), {
          x: 180,
          y: height / 3 + 106,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 作業実施面積の挿入
      if (work_area) {
        firstPage.drawText(work_area.toString(), {
          x: 400,
          y: height / 3 + 106,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //建面積 単位
      if (building_area_unit) {
        firstPage.drawText(building_area_unit.toString(), {
          x: 426,
          y: height / 3 + 106,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 保証期間開始日の挿入
      if (warranty_start_date) {
        const start = new Date(warranty_start_date);
        const startYear = start.getFullYear().toString();
        const startMonth = String(start.getMonth() + 1); //0~11
        const startDate = start.getDate().toString();
        firstPage.drawText(startYear, {
          x: 115,
          y: height / 3 + 68,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(startMonth, {
          x: 173,
          y: height / 3 + 68,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(startDate, {
          x: 201,
          y: height / 3 + 68,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 保証期間終了日の挿入
      if (warranty_end_date) {
        const end = new Date(warranty_end_date);
        const endYear = end.getFullYear().toString();
        const endMonth = String(end.getMonth() + 1); //0 ~ 11
        const endDate = end.getDate().toString();
        firstPage.drawText(endYear, {
          x: 280,
          y: height / 3 + 68,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(endMonth, {
          x: 337,
          y: height / 3 + 68,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(endDate, {
          x: 370,
          y: height / 3 + 68,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 保証期間の挿入
      if (warranty_period) {
        firstPage.drawText(warranty_period.toString(), {
          x: 430,
          y: height / 3 + 67,
          size: fontSize + 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      const pdfBytes = await pdfDoc.save();
      this.response.data =  URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
    } catch (error) {
      return error.message
    }
  }

  /*
    private method
    防かびのpdfデータに選択された保証書データを配置し印刷データを生成し印刷出力
  */
  async _printFungal() {

    try {
      const config = {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: {
          'Accept': 'application/pdf'
        }
      };
      const res = await axios.get(FungalPDF, config);
      const existingPdfBytes = res.data;
      const fontBytes = await fetch(JaFontUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);
      const font = await pdfDoc.embedFont(fontBytes)
      // Get the first page of the document
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      // Get the width and height of the first page
      const { height } = firstPage.getSize()
  
      //防かび保証書データの取得
      const { create_date, guarantor, contract_no, certificate_no, work_area, building_area_unit, warranty_start_date, warranty_end_date, warranty_period} = this.warranty;
      const today = new Date(create_date);
      const year = today.getUTCFullYear().toString();
      const month = String(today.getMonth() + 1); //0 ~ 11
      const date = today.getDate().toString();
      const fontSize = 12;
      // Draw a string of text diagonally across the first page
      // 作生年月日の挿入
      firstPage.drawText(year, {
        x: 175,
        y: height / 2 + 247,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(month, {
        x: 238,
        y: height / 2 + 247,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date, {
        x: 285,
        y: height / 2 + 247,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      // 被保証人の挿入
      if (guarantor) {
        const _guarantor = GeneralFunctions.fullToHalfString(guarantor);
        firstPage.drawText(_guarantor, {
          x: 170,
          y: height / 2 + 205,
          size: fontSize + 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 証書番号の挿入
      if (certificate_no) {
        firstPage.drawText(certificate_no.toString(), {
          x: 438,
          y: height / 3 + 478,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 契約書Noの挿入
      if (contract_no) {
        firstPage.drawText(contract_no.toString(), {
          x: 180,
          y: height / 3 + 120,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 作業実施面積の挿入
      if (work_area) {
        firstPage.drawText(work_area.toString(), {
          x: 400,
          y: height / 3 + 120,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 建面積 単位の挿入
      if (building_area_unit) {
        firstPage.drawText(building_area_unit.toString(), {
          x: 426,
          y: height / 3 + 120,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 保証期間開始日の挿入
      if (warranty_start_date) {
        const start = new Date(warranty_start_date);
        const startYear = start.getFullYear().toString();
        const startMonth = String(start.getMonth() + 1);
        const startDate = start.getDate().toString();
        firstPage.drawText(startYear, {
          x: 95,
          y: height / 3 + 77,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(startMonth, {
          x: 150,
          y: height / 3 + 77,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(startDate, {
          x: 180,
          y: height / 3 + 77,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 保証期間終了日の挿入
      if (warranty_end_date) {
        const end = new Date(warranty_end_date);
        const endYear = end.getFullYear().toString();
        const endMonth = String(end.getMonth() + 1);
        const endDate = end.getDate().toString();
        firstPage.drawText(endYear, {
          x: 262,
          y: height / 3 + 77,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(endMonth, {
          x: 319,
          y: height / 3 + 77,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(endDate, {
          x: 348,
          y: height / 3 + 77,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      // 保証期間の挿入
      if (warranty_period) {
        firstPage.drawText(warranty_period.toString(), {
          x: 410,
          y: height / 3 + 77,
          size: fontSize + 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      
      const pdfBytes = await pdfDoc.save();
      this.response.data =  URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
    } catch (error) {
      return error.message
    }
  }

  /*
    private method
    損害賠償pdfデータに選択された保証書データを配置し印刷データを生成し印刷出力
  */
  async _printDamage() {
    try {
      const config = {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: {
          'Accept': 'application/pdf'
        }
      };
      const res = await axios.get(DamagePDF, config);
      const existingPdfBytes = res.data;
      const fontBytes = await fetch(JaFontUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);
      const font = await pdfDoc.embedFont(fontBytes)
      // Get the first page of the document
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      // Get the width and height of the first page
      const { height } = firstPage.getSize()
  
      //損害賠償保証書データの取得
      const { 
        create_date, guarantor, certificate_no, warranty_start_date, warranty_end_date, warranty_period,
        store_contact, store_address, store_phone, store_name, customer_name, customer_address, customer_phone, 
        construction_address, survey_date, construction_date, termite_type, construction_type, construction_division,
        construction_other, measures, building_area, building_area_unit
      } = this.warranty;

      const fontSize = 12;
      const today = new Date(create_date);
      const year = today.getUTCFullYear().toString();
      const month = String(today.getMonth() +1);
      const date = today.getDate().toString();

      // Draw a string of text diagonally across the first page
      // 作成年月日
      firstPage.drawText(year, {
        x: 383,
        y: height / 2 + 277,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(month, {
        x: 427,
        y: height / 2 + 277,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(date, {
        x: 453,
        y: height / 2 + 277,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      //証書番号
      if (certificate_no) {
        firstPage.drawText(certificate_no.toString(), {
          x: 400,
          y: height / 2 + 259,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //被保証人(お客様氏名)
      if (guarantor) {
        const _guarantor = GeneralFunctions.fullToHalfString(guarantor);
        firstPage.drawText(_guarantor, {
          x: 165,
          y: height / 2 + 145,
          size: fontSize + 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //保証期間 開始日
      if (warranty_start_date) {
        const start = new Date(warranty_start_date);
        const startYear = start.getFullYear().toString();
        const startMonth = String(start.getMonth() + 1); //0~11
        const startDate = start.getDate().toString();
        firstPage.drawText(startYear, {
          x: 132,
          y: height / 3 + 12,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(startMonth, {
          x: 179,
          y: height / 3 + 12,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(startDate, {
          x: 200,
          y: height / 3 + 12,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //保証期間 終了日
      if (warranty_end_date) {
        const end = new Date(warranty_end_date);
        const endYear = end.getFullYear().toString();
        const endMonth = String(end.getMonth() + 1); //0~11
        const endDate = end.getDate().toString();
        firstPage.drawText(endYear, {
          x: 254,
          y: height / 3 + 12,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(endMonth, {
          x: 299,
          y: height / 3 + 12,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(endDate, {
          x: 321,
          y: height / 3 + 12,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //保証期間
      if (warranty_period) {
        firstPage.drawText(warranty_period.toString(), {
          x: 378,
          y: height / 3 + 12,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }

      //on the right side
      //取次店舗 連絡先
      if (store_contact) {
        const _store_contact = GeneralFunctions.fullToHalfString(store_contact);
        firstPage.drawText(_store_contact.toString(), {
          x: 780,
          y: height / 2 + 321,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //取次店舗 住所
      if (store_address) {
        const _store_address = GeneralFunctions.fullToHalfString(store_address);
        firstPage.drawText(_store_address.toString(), {
          x: 780,
          y: height / 2 + 304,
          size: fontSize - 5,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //取次店舗 電話番号
      if (store_phone) {
        firstPage.drawText(store_phone.toString(), {
          x: 780,
          y: height / 2 + 298,
          size: fontSize - 5,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //取次店舗 店舗名
      if (store_name) {
        const _store_name = GeneralFunctions.fullToHalfString(store_name);
        firstPage.drawText(_store_name.toString(), {
          x: 780,
          y: height / 2 + 273,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //施主 住所
      if (customer_address) {
        const _customer_address = GeneralFunctions.fullToHalfString(customer_address);
        firstPage.drawText(_customer_address.toString(), {
          x: 700,
          y: height / 2 + 237,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //施主 電話番号
      if (customer_phone) {
        const phoneArray = customer_phone.split('-');
        firstPage.drawText(phoneArray[0].toString(), {
          x: 862,
          y: height / 2 + 221,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(phoneArray[1].toString(), {
          x: 897,
          y: height / 2 + 221,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(phoneArray[2].toString(), {
          x: 943,
          y: height / 2 + 221,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //施主 氏名
      if (customer_name) {
        const _customer_name = GeneralFunctions.fullToHalfString(customer_name);
        firstPage.drawText(_customer_name.toString(), {
          x: 780,
          y: height / 2 + 188,
          size: fontSize + 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //施工場所 住所
      if (construction_address && construction_address !== customer_address) {
        const _construction_address = GeneralFunctions.fullToHalfString(construction_address);
        firstPage.drawText(_construction_address.toString(), {
          x: 690,
          y: height / 2 + 159,
          size: fontSize - 2,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //調査年月日
      if (survey_date) {
        const survey = new Date(survey_date);
        const year = survey.getFullYear().toString();
        const month = String(survey.getMonth() + 1); //0~11
        const date = survey.getDate().toString();
        firstPage.drawText(year, {
          x: 720,
          y: height / 2 + 143,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(month, {
          x: 777,
          y: height / 2 + 143,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(date, {
          x: 814,
          y: height / 2 + 143,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //施工年月日
      if (construction_date) {
        const c_date = new Date(construction_date);
        const year = c_date.getFullYear().toString();
        const month = String(c_date.getMonth() + 1); //0 ~ 11
        const date = c_date.getDate().toString();
        firstPage.drawText(year, {
          x: 720,
          y: height / 2 + 123,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(month, {
          x: 777,
          y: height / 2 + 123,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(date, {
          x: 814,
          y: height / 2 + 123,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      }
      //シロアリの種類
      if (typeof termite_type === "number") {
        const type = parseInt(termite_type);
        if (type === 0) { //ヤマトシロアリ
          firstPage.drawEllipse({
            x: 888,
            y: height / 2 + 127,
            xScale: 33,
            yScale: 9,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 1) { //イエシロアリ
          firstPage.drawEllipse({
            x: 950,
            y: height / 2 + 127,
            xScale: 28,
            yScale: 9,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        }
      }
      //施工建築物の種類
      if (typeof construction_type === 'number') {
        const type = parseInt(construction_type);
        if (type === 0) { //住宅
          firstPage.drawEllipse({
            x: 697,
            y: height / 2 + 107,
            xScale: 12,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 1) { //店舗
          firstPage.drawEllipse({
            x: 724,
            y: height / 2 + 107,
            xScale: 12,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 2) { //公共建築物
          firstPage.drawEllipse({
            x: 766,
            y: height / 2 + 107,
            xScale: 22,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 3) { //神社仏閣
          firstPage.drawEllipse({
            x: 816,
            y: height / 2 + 107,
            xScale: 22,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 4) { //その他
          firstPage.drawEllipse({
            x: 858,
            y: height / 2 + 107,
            xScale: 17,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
          if (construction_other) {
            firstPage.drawText(construction_other.toString(), {
              x: 888,
              y: height / 2 + 104,
              size: fontSize - 2,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
        }
      }
      //施工の区分
      if (typeof construction_division === 'number') {
        const type = parseInt(construction_division);
        if (type === 0) { //新築
          firstPage.drawEllipse({
            x: 720,
            y: height / 2 + 87,
            xScale: 18,
            yScale: 8,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 1) { //改築
          firstPage.drawEllipse({
            x: 766,
            y: height / 2 + 87,
            xScale: 18,
            yScale: 8,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 2) { //既設
          firstPage.drawEllipse({
            x: 811,
            y: height / 2 + 87,
            xScale: 18,
            yScale: 8,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        }
      }
      // 予防駆除
      if (typeof measures === 'number') {
        const type = parseInt(measures);
        if (type === 0) { //予防
          firstPage.drawEllipse({
            x: 889,
            y: height / 2 + 87,
            xScale: 18,
            yScale: 8,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (type === 1) { //駆除
          firstPage.drawEllipse({
            x: 936,
            y: height / 2 + 87,
            xScale: 18,
            yScale: 8,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        }
      }
      //建面積
      if (building_area) {
        firstPage.drawText(building_area.toString(), {
          x: 734,
          y: 198,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        })
      }
      if (building_area_unit) {
        if (building_area_unit === '坪') {
          firstPage.drawEllipse({
            x: 794,
            y: 201,
            xScale: 8,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        } else if (building_area_unit === '㎡' || building_area_unit === 'm2') {
          firstPage.drawEllipse({
            x: 776,
            y: 201,
            xScale: 8,
            yScale: 7,
            borderWidth: 1,
            borderColor: grayscale(0),
            color: rgb(0, 0, 0),
            opacity: 0.0,
            borderOpacity: 0.75,
          })
        }
      }

      const pdfBytes = await pdfDoc.save();
      this.response.data =  URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
    } catch (error) {
      return error.message
    }
  }

  /*
    private method
    カテゴリ別でそれぞれの印刷データを生成
  */
  async _handlePrinting() {
    
    if (this.category === 0) {
      // シロアリ防除施工保証書
      const termiteError = await this._printTermite(); 
      return termiteError;
    } else if (this.category === 1) {
      // 防腐・防カビ保証書
      const fungalError = await this._printFungal(); 
      return fungalError;
    } else {
      // 損害賠償保証書
      const damageError = await this._printDamage();
      return damageError;
    }
  }

  /*
    public method
    外部から呼び出す関数
    WarrantyListにて使用
  */
  async print() {
    let error = await this._handlePrinting();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }

};

export { WarrantyPrintFunction };

/*
保証一覧からのcsv出力クラス
- 流れ：
 -　保証一覧からデータの受け取り 
 -　保証一覧データからCSV出力データ（内容とヘッダー）を抽出し返す
 -　（クラス呼び出しファイルにてCSV出力）
- Parameters in constructor:
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: CSV出力データ
*/
class WarrantyCSVFunctions {

  constructor(props) {
    const { warrantyData, token, loginEmployeeName } = props;
    this.warrantyData = warrantyData;
    this.token = token;
    this.loginEmployeeName = loginEmployeeName;
    this.response = { data: {}, error: '' };
  }

  /*
    public method
    外部から呼び出す関数
    顧客一覧データからcsv出力用のデータを抽出し返す
  */
  getCSVData() {
    if (this.warrantyData.length === 0) {
      this.response.error = '顧客データが見つかりませんでした。';
      return this.response;
    }
    
    const downloadDate = DateFunctions.ISOStringDateToLocalDateCSVFormat(new Date());
    const csvData = this.warrantyData.map((data) => {
      const {
        category,
        create_date,
        certificate_no,
        construction_date,
        building_area,
        mtb_customer_name,
        customer_name,
        termite_type_name, 
        chemical_name1,
        chemical_name2,
        chemical_name3,
        chemical_name4,
        chemical_name5,
        chemical_used1,
        chemical_used2,
        chemical_used3,
        chemical_used4,
        chemical_used5,
        store_name,
        partner_name, 
        enterprise_name, 
        sales_office_name, 

        warranty_start_date,
        warranty_end_date,
        warranty_period,

        work_area
      } = data;

      const createDate = String(create_date).replaceAll('/', ''); // 2022/12/16 -> 20221216
      const constructionDate = DateFunctions.ISOStringDateToLocalDateCSVFormat(construction_date);
      const warrantyStartDate = DateFunctions.ISOStringDateToLocalDateCSVFormat(warranty_start_date);
      const warrantyEndDate = DateFunctions.ISOStringDateToLocalDateCSVFormat(warranty_end_date);

      if (category === 2) {
        // 損害賠償
        const woodenChemicalName = [chemical_name1, chemical_name2, chemical_name3]
          .filter((item) => item !== "").join("/");
        const woodenChemicalUsed = chemical_used1 + chemical_used2 + chemical_used3;
        const soilChemicalName = [chemical_name4, chemical_name5]
        .filter((item) => item !== "").join("/");
        const soilChemicalUsed = chemical_used4 + chemical_used5;

        return {
          download_date: downloadDate, // 入力月 (1) DL日
          create_date: createDate, // 作成年月日 (2) 作成年月日
          certificate_no: certificate_no || "", // 保証番号 (3) 証書番号
          construction_date: constructionDate, // 施工年月日 (4) 施工年月日
          building_area: building_area, // 建面積 (5) 建面積
          customer_name: customer_name, // 顧客名 (6) 施主氏名
          termite_type: termite_type_name, // シロアリの種類 (7) シロアリの種類
          wooden_chemical_names: woodenChemicalName, // 使用薬剤(木部材) (8) 木部材 侵漬/木部材 吹付け/木部材 穿孔
          wooden_chemical_used: woodenChemicalUsed, // 使用量(木部) (9) 使用量(ℓ)
          soil_chemical_names: soilChemicalName,// 使用薬剤(土壌) (10) 土壌 散布/土壌 加圧
          soil_chemical_used: soilChemicalUsed,// 使用量(土壌) (11) 使用量(ℓ)
          store_contact: partner_name, // ご連絡先 (12) 提携先
          store_name: store_name, // 店名 (13) 取次店舗 店舗名
          guarantor: enterprise_name, // 保証人 (14) 企業名
          sales_office_name: sales_office_name,// 支店名 (15) 営業所名
          warranty_start_date: warrantyStartDate,// 保証開始日 (16) 保証開始日
          warranty_end_date: warrantyEndDate, // 保証終了日 (17) 保証終了日
          warranty_price: '300', // 保証金額 (18) 保証金額
          warranty_period: warranty_period, // 保証期間 (19) 保証期間
        }
      }

      // シロアリ、防腐
      return {
        download_date: downloadDate, // 入力月 (1) DL日
        create_date: createDate, // 作成年月日 (2) 作成年月日
        warranty_code: certificate_no || "", // 保証番号 (3) 証書番号
        construction_date: warrantyStartDate, // 施工年月日 (4) 保証開始日
        building_area: work_area, // 建面積 (5) 作業実地面積
        customer_name: mtb_customer_name, // 顧客名 (6) 施主氏名
        termite_type: '', // シロアリの種類 (7) 省略
        chemical_names: '', // 使用薬剤(木部材) (8) 省略
        chemical_used: '', // 使用量(木部) (9) 省略
        soil_chemical_names: '',// 使用薬剤(土壌) (10) 省略
        soil_chemical_used: '',// 使用量(土壌) (11) 省略
        store_contact: '', // ご連絡先 (12) 省略
        store_name: '', // 店名 (13) 省略
        guarantor: enterprise_name, // 保証人 (14) 企業名
        sales_office_name: sales_office_name,// 支店名 (15) 営業所名
        warranty_start_date: warrantyStartDate,// 保証開始日 (16) 保証開始日
        warranty_end_date: warrantyEndDate, // 保証終了日 (17) 保証終了日
        warranty_price: '300', // 保証金額 (18) 保証金額
        warranty_period: warranty_period, // 保証期間 (19) 保証期間
      }
    });

    const csvHeader = {
      download_date: '入力月', 
      create_date: '作成年月日',
      warranty_code: '保証番号',
      construction_date: '施工年月日',
      building_area: '建面積',
      customer_name: '顧客名',
      termite_type: 'シロアリの種類', 
      chemical_names: '使用薬剤', 
      chemical_used: '使用量(木部)',
      soil_chemical_names: '使用薬剤(土壌)',
      soil_chemical_used: '使用量(土壌)',
      store_contact: 'ご連絡先', 
      store_name: '店名', 
      guarantor: '保証人',
      sales_office_name: '支店名',
      warranty_start_date: '保証開始日',
      warranty_end_date: '保証終了日', 
      warranty_price: '保証金額', 
      warranty_period: '保証期間',
    };
    const data = { csvHeader: csvHeader, csvData: csvData };
    this.response['data'] = data;
    this.response['error'] = '';
    return this.response;
  }

  //csv status update　-----------------------

  /*
    private method
    CSV出力済みフラグを変更する為CSV出力された尚且つ以前出力されてない保証一覧を返す処理を行う内部メソッド
    （csv出力する保証データのis_exportedがnullか0のデータのみAPIに付随してis_exportedを1にする処理）
  */
  _getUpdateWarrantyData() {
    let updateWarrantyData = [];
    const today = new Date().toISOString();

    this.warrantyData.map((row) => {
      let registeringWarranty = { ...row };
      if (!row.is_exported || row.is_exported === 0) { //未出力のデータのみ
        registeringWarranty['is_exported'] = 1;
        registeringWarranty['exported_at'] = today;
        registeringWarranty['exported_employee_name'] = this.loginEmployeeName;
      }
      updateWarrantyData.push(registeringWarranty);
    });
  
    return updateWarrantyData;
  }

  /*
    public method
    外部から呼び出す関数
    ConstructionListにて呼び出し
    工事データのCSV出力済みフラグを１にする処理を行うメソッド
  */
  async updateCSVExportStatus() {
    const updatedWarrantyData = this._getUpdateWarrantyData();
    if (updatedWarrantyData.length === 0) return;//すでに全てのデータがcsv出力済み
    try {
      await axios.post(WARRANTY_DATA_CHANGE_URL, updatedWarrantyData, { headers: { "Authorization": `Bearer ${this.token}` } });
      return;
    } catch (error) {
      console.log(error.message);
      return;
    }
  }
}

export { WarrantyCSVFunctions }