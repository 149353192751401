import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setTax } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';
//functions
import DateFunctions from 'src/functions/DateFunctions';

const columnNames = {
  tax_rate: { label: '消費税率', width: '40%', align: 'center' },
  start_date: { label: '適応開始日', width: '60%', align: 'center' }
};

/*
  消費税率リストを表示するコンポーネント
  新規登録ボタン→ 消費税登録ページへ
  各行クリック→選択された消費税詳細、編集ページへ
*/
const MtbTaxList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { taxData } = useSelector(state => state.master);
  const [data, setData] = useState({});

  useEffect(() => {
    //DBから消費税データをフェッチ
    dispatch(setTax());
  }, [dispatch]);

  const initSalesData = () => {
    if (taxData) {
      setData({ ...taxData });
    }
  };
  useMemo(initSalesData, [taxData]);

  /*
    各行クリック→選択された消費税詳細、編集ページへ
  */
  const navigateToDetail = useCallback((id) => {
    history.push({ pathname: `${pathname}/${id}`, state: { data: data[id] } });
  }, [history, pathname, data]);

  /*
    新規登録ボタン→ 消費税登録ページへ
  */
  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            if (columnName === 'start_date') {
              return (
                <Td key={columnName} width={columnNames[columnName].width} align={'center'}>
                  {DateFunctions.ISOStringDateToLocalDate(data[columnName])}
                </Td>
              )
            }
            return (
              <Td key={columnName} width={columnNames[columnName].width} align={'center'}>
                {data[columnName]}%
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <CustomTable>
        <ColumnNameWrapper>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName} align={'center'}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </ColumnNameWrapper>
        <tbody>
          {
            Object.keys(data).length > 0 && Object.keys(data).map((key) => {
              return <Row data={data[key]} id={key} key={data[key].id} />
            })
          }
        </tbody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  align-self: center;
  width: 50%;
  min-width: 400px;
  max-width: 700px;
`

export default MtbTaxList;