import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setEnterpriseData } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';

const columnNames = {
  enterprise_code: { label: '企業コード', width: '10%', align: 'center' },
  enterprise_name: { label: '営業所名', width: '25%', align: 'left' },
  postal_code: { label: '郵便番号', width: '20%', align: 'left' },
  address: { label: '住所', width: '25%', align: 'left' },
  phone: { label: '電話番号', width: '20%', align: 'left' }
};

/*
  企業一覧コンポーネント
  各行からその企業詳細ページへ
  新規登録ボタンから登録ページへ推移
*/
const MtbEnterpriseList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { enterpriseData } = useSelector(state => state.master);
  const [data, setData] = useState({});

  useEffect(() => {
    //DBから企業データをフェッチ
    dispatch(setEnterpriseData());
  }, [dispatch]);

  const initSalesData = () => {
    if (enterpriseData) {
      setData({ ...enterpriseData });
    }
  };
  useMemo(initSalesData, [enterpriseData]);

  const navigateToDetail = useCallback((id) => {
    history.push({ pathname: `${pathname}/${id}`, state: { data: data[id] } });
  }, [history, pathname, data]);

  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>

      </ButtonWrapper>
      <Table>
        <ColumnNameWrapper>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </ColumnNameWrapper>
        <tbody>
          {
            Object.keys(data).length > 0 && Object.keys(data).map((key) => {
              return <Row data={data[key]} id={key} key={data[key].id} />
            })
          }
        </tbody>
      </Table>
    </Container>
  )
};

export default MtbEnterpriseList;