import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
//styles & configs
import { BaseBodyStyle } from 'src/common/StylesComponents';
//components
import { ConstructionDetailHeader, ConstructionStatus } from './children';
import { ConstructionInfo, ConstructionContract, ConstructionBreakdown } from 'src/views/construction/common';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { getConstructionBreakDownData, getConstructionDetailData } from 'src/redux/actions/ConstructionDataHandler';
import { setPartnerData } from 'src/redux/actions/MasterDataHandler';
//functions
import { getTaxRate } from 'src/functions/ConstructionFunctions';
import { setSelectedCustomerId } from 'src/redux/actions/CustomerDataHandler';

const ConstructionDetail = () => {

  const dispatch = useDispatch();
  const { selectedConstructionId, isSelectedConstructionDetailReady, isSelectedConstructionBreakdownDataReady, selectedConstructionData, selectedConstructionBreakdown } = useSelector(state => state.construction);
  const { mtb_enterprise_id, employee_name, token, mtb_sales_office_id, role } = useSelector(state => state.auth);
  const { partnerData } = useSelector(state => state.master);
  const [constructionData, setConstructionData] = useState(
    {
      "契約情報": {
        support_date: null, contract_date: null, contract_no: '', serial_no: '', remarks: '',
        destination_name: '', destination_postal_code: '', destination_address: '', destination_phone: ''
      },
      "工事情報": {
        mtb_partner_id: '0', mtb_store_id: '0', construction_start_date: null, construction_end_date: null,
        mtb_payment_method_id: '0', payment_description: '', approval_number: '', is_agreement_form: 0, is_maintenance: 0, has_other_construction: 0,
        sales_employee_name1: '', sales_employee_name2: '', sales_employee_name3: '', sales_employee_name4: '',
        construction_employee_name1: '', construction_employee_name2: '', construction_employee_name3: '', construction_employee_name4: '',
        mtb_customer_id: '0', mtb_sales_office_id: '0'
      },
      "工事内訳": {
        tableContents: {
          breakdown: [],
          breakdownTotal: {
            subtotal: { label: '小計', value: 0 },
            tax_price: { label: '消費税額', value: 0 },
            total: { label: '税込額', value: 0 },
            subtotal_margin: { label: '小計 x マージン', value: 0 },
          }
        },
        taxInfo: {
          tax_rate: 0, tax_division: 0, rounding_division: 0, stamp_price: 0, stamp_amount: 0, memo: '', tax_exclude: 0, tax_include: 0
        }
      },
      "その他情報": {
        mtb_enterprise_id: null, created_employee_name: '', created_at: '', updated_employee_name: '', updated_at: '', margin: 0, is_exported: 0, exported_at: null, exported_employee_name: null
      }
    }
  );
  const [customerInfo, setCustomerInfo] = useState(
    {
      id: null, customer_code: '', enterprise_name: '', sales_office_name: '', customer_kana: '', customer_name: '',
      customer_address1: '', customer_address2: '', customer_postal_code: '', customer_phone1: '', customer_phone2: '',
      mtb_enterprise_id: null, mtb_sales_office_id: null,
      status: [
        { id: 0, status: '対応不可', isSelected: false },
        { id: 1, status: '信販不可', isSelected: false },
        { id: 2, status: '転居', isSelected: false },
        { id: 3, status: 'クレーム', isSelected: false }
      ]
    }
  );
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const allowMarginUpdate = useRef(false); //提携先マージンの変更による小計xマージンデータのアップデートフラグ

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  /**
   * 工事詳細データ取得関数を走らせる
   */
  useEffect(() => {
    if (selectedConstructionId) {
      dispatch(getConstructionDetailData(selectedConstructionId));
      dispatch(getConstructionBreakDownData(selectedConstructionId));
      dispatch(setPartnerData());
    }
  }, [selectedConstructionId, dispatch]);

  /**
   * 工事詳細データに紐づく顧客データ（ステータスなど）の初期化関数
   */
  const initConstructionStatus = () => {
    let initialCustomerInfo = { ...customerInfo };
    Object.keys(initialCustomerInfo).map((key) => {
      if (key === 'id') {
        initialCustomerInfo[key] = selectedConstructionData['mc_id']
      } else if (key === 'status') {
        const statusList = selectedConstructionData[key];
        const status = customerInfo['status'];
        const initialStatus = status.map((row) => {
          if (statusList.includes(row.id)) {
            return {
              ...row,
              isSelected: true
            };
          }
          return { ...row };
        });
        initialCustomerInfo[key] = initialStatus;
      } else {
        initialCustomerInfo[key] = selectedConstructionData[key]
      }
    });
    setCustomerInfo(initialCustomerInfo);
  };

  /**
   * 工事詳細データの初期化関数
   */
  const initOtherInfo = (initialConstructionData) => {
    const { margin, created_at, created_employee_name, is_exported, exported_at, exported_employee_name } = selectedConstructionData;
    initialConstructionData["その他情報"].margin = margin;
    initialConstructionData["その他情報"].created_at = created_at;
    initialConstructionData["その他情報"].created_employee_name = created_employee_name;
    initialConstructionData["その他情報"].updated_employee_name = employee_name;
    initialConstructionData["その他情報"].mtb_enterprise_id = mtb_enterprise_id;
    initialConstructionData["その他情報"].is_exported = is_exported;
    initialConstructionData["その他情報"].exported_at = exported_at;
    initialConstructionData["その他情報"].exported_employee_name = exported_employee_name;
  };

  /**
   * 工事詳細データの契約情報初期化関数
   */
  const initContractInfo = (initialConstructionData) => {
    Object.keys(initialConstructionData["契約情報"]).map((key) => initialConstructionData["契約情報"][key] = selectedConstructionData[key]);
  };

  /**
   * 工事詳細データの工事情報初期化関数
   */
  const initConstructionDetail = (initialConstructionData) => {
    Object.keys(initialConstructionData["工事情報"]).map((key) => {
      if (selectedConstructionData[key]) {
        initialConstructionData["工事情報"][key] = selectedConstructionData[key];
      }
    });
    initialConstructionData["工事情報"]["id"] = selectedConstructionData.id;
  };


  /**
   * 工事詳細データの初期化関数を呼び出す関数
   * 工事詳細データと工事詳細データの内訳データが取得された時に呼び出される。
   */
  useEffect(() => {
    if (isSelectedConstructionDetailReady && isSelectedConstructionBreakdownDataReady) {
      let initialConstructionData = { ...constructionData };
      initConstructionStatus();
      initOtherInfo(initialConstructionData);
      initContractInfo(initialConstructionData);
      initConstructionDetail(initialConstructionData);
      setConstructionData(initialConstructionData);
    }
  }, [isSelectedConstructionDetailReady, isSelectedConstructionBreakdownDataReady]);

  /**
   * 提携先のマージンアップデートチェックと
   * 工事内訳合計などの計算初期化関数
   */
  useEffect(() => {

    if (partnerData && isSelectedConstructionBreakdownDataReady && isSelectedConstructionDetailReady) {
      //提携先のマージンアップデートチェック
      const { mtb_partner_id } = selectedConstructionData;
      const { margin } = partnerData[mtb_partner_id];
      if (parseInt(margin) !== selectedConstructionData.margin && !allowMarginUpdate.current) {
        allowMarginUpdate.current = true;
      }

      let initialConstructionData = { ...constructionData };
      initConstructionBreakdown(initialConstructionData);
      setConstructionData(initialConstructionData);
      const _isEditable = checkConstructionEditable();
      setIsEditable(_isEditable)
      setIsLoading(false);
    }
  }, [selectedConstructionBreakdown, selectedConstructionData, partnerData]);

  /**
   *　工事内訳合計等の初期化
   */
  const initConstructionBreakdown = async (initialConstructionData) => {
    initialConstructionData["工事内訳"].tableContents.breakdown = [...selectedConstructionBreakdown];
    let isTaxRateNull = false;

    //工事内訳合計等の初期化
    Object.keys(initialConstructionData["工事内訳"].tableContents.breakdownTotal).map((key) => {
      if (key === 'subtotal_margin') {
        const subtotal_margin = selectedConstructionData.subtotal * selectedConstructionData.margin * 0.01;
        initialConstructionData["工事内訳"]["tableContents"]["breakdownTotal"][key].value = subtotal_margin;
      } else {
        initialConstructionData["工事内訳"]["tableContents"]["breakdownTotal"][key].value = selectedConstructionData[key];
      }
    });
    //税率は0％もあるため、nullか数値ありかで分ける
    //数値ありの場合、そのままtax_rateに格納
    //nullの場合、mtb_taxから今日の日付で税率を取得し格納
    Object.keys(initialConstructionData["工事内訳"].taxInfo).map((key) => {
      if (selectedConstructionData[key]) {
        initialConstructionData["工事内訳"]["taxInfo"][key] = selectedConstructionData[key]
      } else {
        if (key === "tax_rate") isTaxRateNull = true;
        initialConstructionData["工事内訳"]["taxInfo"][key] = constructionData["工事内訳"]["taxInfo"][key]; // null を 0 に初期化
      }
    });
    if (isTaxRateNull) {
      const taxRate = await getTaxRate(token);
      initialConstructionData["工事内訳"]["taxInfo"]["tax_rate"] = taxRate;
    }
  };

  const checkConstructionEditable = () => {
    if (role === 1) return true;
    const selectedSalesOfficeId = constructionData["工事情報"].mtb_sales_office_id;
    if (mtb_sales_office_id === selectedSalesOfficeId) {
      return true;
    }
    return false;
  }

  const onEditButtonPressed = useCallback(() => {
    history.push({ pathname: `${currentPath}/edit`, state: { data: constructionData, customerData: customerInfo, margin: constructionData["その他情報"].margin, allowMarginUpdate: allowMarginUpdate.current } });
  }, [history, currentPath, constructionData, customerInfo, allowMarginUpdate]);

  const navigateToCustomer = useCallback(() => {
    // 顧客履歴への推移
    const customerId = constructionData["工事情報"].mtb_customer_id;
    dispatch(setSelectedCustomerId(customerId));
    history.push(`/customer-list/${customerId}`);
  }, [history, dispatch, constructionData]);

  const handleNavigation = useCallback((navigationKey) => {
    if (navigationKey === "FILE_UPLOAD") {
      //ファイル取り込みページへ推移
      history.push({ pathname: '/file-upload', state: { isEditable: false } });
    } else {
      history.push({ pathname: `${currentPath}/warranty`, state: { isEditable: false } });
    }
  }, [history]);

  if (isLoading) {
    return (
      <BaseBodyStyle main >
        <ConstructionDetailHeader />
      </BaseBodyStyle>
    )
  }
  return (
    <BaseBodyStyle main>
      <ConstructionDetailHeader />
      <ConstructionStatus customerInfo={customerInfo} />
      <div style={{ width: '100%' }}>
        <ConstructionContract
          isEditable={false}
          isEditButtonDisabled={isEditable}
          contractInfo={constructionData["契約情報"]}
          onEditButtonPressed={onEditButtonPressed}
          navigation={navigateToCustomer}
        />
        <ConstructionInfo isEditable={false} constructionInfo={constructionData["工事情報"]} mtb_enterprise_id={constructionData['その他情報'].mtb_enterprise_id} />
        <ConstructionBreakdown
          isEditable={false}
          isButtonEnabled={isEditable}
          isConstructionEditable={isEditable}
          tableContents={constructionData["工事内訳"].tableContents}
          taxInfo={constructionData["工事内訳"].taxInfo}
          partnerMargin={constructionData["その他情報"].margin}
          handleNavigation={handleNavigation}
        />
      </div>
    </BaseBodyStyle>
  )
};

export default ConstructionDetail;