import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//components
import DatePicker, { registerLocale } from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
//calendar config
import { locale } from 'src/common/DateSelector';
import moment from "moment";
import ja from 'date-fns/locale/ja';

registerLocale('ja', ja) // カレンダーを日本語対応させるための準備

const ConstructionCompleteDateSelector = (props) => {
  const { isEnable, date, onChange, isStart, calendarPosition } = props;

  const changeDateType = (date) => {
    // Wed May 19 2021 11:20:35 GMT+0900 (Japan Standard Time) ---> 2021-05-19T00:00:00.000Z (isStart=true)
    // Wed May 19 2021 11:20:35 GMT+0900 (Japan Standard Time) ---> 2021-05-19T23:59:59.000Z (isStart=false)
    const hour = isStart ? 0 : 23;
    const min = isStart ? 0 : 59;
    const sec = isStart ? 0 : 59;
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hour, min, sec)).toISOString();
    onChange(date);
  };

  const showDate = useCallback((date) => {
    if (date) {
      //defaultで近い方の日付になってしまうため（2021-05-19T23:59:59.000Z -> 2021/05/20）日付優先
      return moment.utc(date).startOf('day').toDate();
    }
    return '';
  }, []);

  return (
    <Container>
      <div >
        <DatePicker //style is in index.css
          readOnly={!isEnable}
          selected={showDate(date)}
          dateFormat="yyyy/MM/dd"
          onChange={changeDateType}
          locale={"ja"}
          popperPlacement={calendarPosition}
        />
      </div>
    </Container >
  );
};

const Container = styled.div`
  font-size: 14px;
  width: 100%;
  padding: 4px;
  display: flex;
  font-weight: 500;
  flex-direction: row;
`

ConstructionCompleteDateSelector.propTypes = {
  label: PropTypes.string.isRequired,
  isOneLine: PropTypes.bool.isRequired,
  isEnable: PropTypes.bool,
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isStart: PropTypes.bool.isRequired,
  calendarPosition: PropTypes.string
};

ConstructionCompleteDateSelector.defaultProps = {
  label: '',
  isOneLine: false,
  isEnable: true,
  date: '',
  onChange: () => { },
  isStart: true,
  calendarPosition: "bottom-start",
};

export default memo(ConstructionCompleteDateSelector);