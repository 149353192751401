import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// styles & config
import { BaseBodyStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { Colors } from 'src/configs/StyleConfig';
import { ButtonMenu, IMG } from './FileUpload';
//components
import Button from 'src/common/Button';
import ConfirmModal from 'src/common/ConfirmModal';
import DialogMessage from 'src/common/DialogMessage';
//redux
import { useSelector } from 'react-redux';
//functions
import DateFunctions from 'src/functions/DateFunctions';
import { FileUploadFunctions } from 'src/functions/FileUploadFunctions';
//static files
import PDF_icon from 'src/static/pdf.png';

const FileUploadEdit = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, token } = useSelector(state => state.auth);

  const [uploadedFileList, setUploadedFileList] = useState([]); // 表示兼アップロード,isNewEntry=trueでDBに送る
  const [deletedFileList, setDeletedFileList] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const uploadLimit = 2000000; //2MB

  const sendingConstructionData = useRef({ dtb_construction_id: null, employee_name: null, mtb_customer_id: null });
  const fileInput = useRef(null);
  const selectedDeletingIndex = useRef({ 'deletingIndex': null });
  const previousPath = useRef(null);
  const modalRequired = useRef(false);

  useEffect(() => {
    const initFileUploadData = () => {
      const { state } = location;
      const { uploadedFileList, constructionData, prevPath } = state;
      setUploadedFileList([...uploadedFileList]);
      sendingConstructionData.current = { ...constructionData };
      previousPath.current = prevPath;
      setIsLoading(false);
    };
    if (employee_name && token) {
      initFileUploadData();
    }
  }, []);

  const onConfirmButtonPressed = useCallback(() => {
    //ユーザの画像消去かキャンセルかの選択に合わせた処理とモダルを隠す処理
    const selectedIndex = selectedDeletingIndex.current.deletingIndex;
    const newUploadingFileList = [];
    const newDeletingFileList = [...deletedFileList];
    uploadedFileList.map((item, index) => {
      if (index === selectedIndex) {
        if (typeof item.is_new_entry === 'boolean' && !item.is_new_entry) newDeletingFileList.push(item);
      } else {
        newUploadingFileList.push(item);
      }
    });
    setUploadedFileList(newUploadingFileList);
    setDeletedFileList(newDeletingFileList);
    setModalState(false);
  }, [selectedDeletingIndex, uploadedFileList, deletedFileList, employee_name]);

  const onDeleteCancelButtonPressed = useCallback(() => {
    setModalState(false);
  }, []);


  const showModal = () => {
    //画像の消去ボタンを押した際に見せるモダルの表示
    if (typeof modalState === "boolean" && modalState) {
      return <ConfirmModal titleText={'選択されたファイルを消去しますか？'} onConfirmButtonPressed={onConfirmButtonPressed} onCancelButtonPressed={onDeleteCancelButtonPressed} modalState={modalState} />
    }
    return null
  };

  const handleSelectingFile = (e) => {
    //ブラウザ画像ファイル選択の処理
    const file = e.target.files[0];
    if (!file) {
      setErrorText("ファイルを取得できませんでした。");
      return;
    }
    const size = file.size;
    if (size > uploadLimit) {
      setErrorText("選択されたファイルは大きすぎるため登録できません。");
      return;
    }
    const fileType = file.type;
    const fileName = file.name;
    const imageURL = URL.createObjectURL(file); // for preview
    const uploadedData = { file_name: fileName, file_url: imageURL, image_file: file, file_type: fileType, created_at: null, is_new_entry: true }; //DBにアップロードするファイル
    const files = [...uploadedFileList, uploadedData];
    setUploadedFileList(files);
    e.target.value = null;
  }

  const onAddButtonPressed = useCallback(() => {
    if (!modalRequired.current) modalRequired.current = true;
    fileInput.current.click();
  }, [fileInput]);

  const onDeleteButtonPressed = useCallback((index) => {
    setModalState(true);
    if (!modalRequired.current) modalRequired.current = true;
    selectedDeletingIndex.current.deletingIndex = index;
  }, []);


  const onSaveButtonPressed = useCallback(async () => {
    //保存ボタンでファイルをDBとストレージに格納
    const fileUploadFunctions = new FileUploadFunctions({
      uploadingFiles: uploadedFileList,
      deletingFiles: deletedFileList,
      employee_name: sendingConstructionData.current.employee_name,
      mtb_customer_id: sendingConstructionData.current.mtb_customer_id,
      dtb_construction_id: sendingConstructionData.current.dtb_construction_id,
      token: token
    })
    const response = await fileUploadFunctions.sendFiles();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({ pathname: previousPath.current });
    }
  }, [history, previousPath, modalRequired, uploadedFileList, deletedFileList, sendingConstructionData, token]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  const Row = memo((props) => {
    const { data, index } = props;
    return (
      <div style={{ listStyle: 'none', height: 100, width: '99%', padding: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px dotted gray' }}>
        <div style={{ width: '60%', display: 'flex', flexDirection: 'row', }}>
          <div style={{ width: 140 }}>
            {
              data.file_type === "application/pdf" ?
                <IMG src={PDF_icon} />
                :
                <IMG src={data.file_url} />
            }
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 4 }}>
            <span style={{ height: '70%', display: 'flex', alignItems: 'center', fontSize: 20, fontWeight: '500', color: Colors.textColor }}>
              {data.file_name}
            </span>
            <span style={{ height: '30%', display: 'flex', alignItems: 'start', fontSize: 12, fontWeight: '500', color: Colors.grayText }}>
              {
                data.created_at ?
                  `アップロード日時：${DateFunctions.ISOStringDateToLocalDate(data.created_at)}`
                  :
                  'まだアップロードしていません。'
              }
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 12 }}>
          <Button text={'削除'} onClick={() => onDeleteButtonPressed(index)} />
        </div>
      </div>
    )
  });

  if (isLoading) {
    <BaseBodyStyle main />
  }
  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
        編集中のデータがあります。\n
        続行すると編集中の内容は全て破棄されます。
      `}
      />
      <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40, }}>
        {showModal()}
        <div style={{ display: 'flex', alignSelf: 'flex-end', paddingBottom: 12 }}>
          <Button text={'追加'} onClick={onAddButtonPressed} />
          <input
            accept="image/jpeg,image/gif,image/png,application/pdf"
            id="contained-button-file"
            type="file"
            ref={fileInput}
            hidden={true}
            onChange={handleSelectingFile}
          />
        </div>
        <div style={{ width: '100%', minHeight: 100, height: 'auto', maxHeight: '80vh', border: '1px solid gray', overflowY: 'auto' }}>
          {
            uploadedFileList.map((row, index) => {
              return (
                <Row data={row} index={index} key={index.toString()} />
              )
            })
          }
        </div>
      </div>
      <span style={{ color: Colors.errorText }}>
        {errorText && errorText}
      </span>
      <ButtonMenu>
        <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
        <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
      </ButtonMenu>
    </BaseBodyStyle>
  )
};

export default FileUploadEdit;