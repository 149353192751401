import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { FontSize } from 'src/configs/StyleConfig';
//components
import StatusMapping from 'src/common/StatusMapping';

const customerColumnNames = {
  status: '顧客ステータス',
  customer_kana: '施主 ふりがな',
  customer_name: '施主 氏名',
  customer_postal_code: '施主 郵便番号',
  customer_address1: '施主 住所1',
  customer_address2: '施主 住所2',
  customer_phone1: '施主 電話番号1',
  customer_phone2: '施主 電話番号2',
  mtb_sales_office_id: '営業所',
  memo: 'メモ'
};



const CustomerLog = (props) => { 

  const { dataBeforeChange, dataAfterChange } = props;
  
  return (
    <div>
      {
        Object.keys(dataBeforeChange).length > 0 && Object.keys(dataBeforeChange).map((column) => {
          if (column === 'status') {
            const statusBefore = dataBeforeChange[column] ? dataBeforeChange[column].split(',').map((num) => parseInt(num)) : [];
            const statusAfter = dataAfterChange[column] ? dataAfterChange[column].split(',').map((num) => parseInt(num))  : [];

            return (
              <div style={{ display: 'flex', flexDirection: 'row', minWidth: 380, width: '80%'}} key={column}>
                <Column>{customerColumnNames[column]}</Column>
                <Column>
                  <div style={{width: 120}}>
                    <StatusMapping statusList={statusBefore} />
                  </div>
                </Column>                
                <Column>
                  <div style={{width: 120}}>
                    <StatusMapping statusList={statusAfter} />
                  </div>
                </Column>
              </div>
            )
          }
          return (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: 380, width: '80%'}} key={column}>
              <Column>{customerColumnNames[column]}</Column>
              <Column>{dataBeforeChange[column]}</Column>
              <Column>{dataAfterChange[column]}</Column>
            </div>
          )
        })
      }
    </div>
  )
};

const Column = styled.span`
  width: 200px;
  font-size: ${FontSize.md};
  margin: 12px 0;
`

CustomerLog.propTypes = {
  dataBeforeChange: PropTypes.object.isRequired,
  dataAfterChange: PropTypes.object.isRequired
};
CustomerLog.defaultProps = {
  dataBeforeChange: { },
  dataAfterChange: { }
};

export default CustomerLog;