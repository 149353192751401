import React from 'react';
import PropTypes from 'prop-types';
//components
import {TextInput} from 'src/common/Inputs';
import styled from 'styled-components';
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';
//styles and configs
import { Colors } from 'src/configs/StyleConfig';

const CustomerSupportInput = (props) => {

  const { data, isEditing, errorText, changeData, onSaveButtonPressed, onCancelButtonPressed, onEditButtonPressed, onDeleteButtonPressed } = props;
  const { text } = data;

  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                内容
              </SettingList>
              <TextInput isEnable={isEditing} value={text} onChange={(val) => changeData('text', val.target.value)} />
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`
CustomerSupportInput.propTypes = {
  data: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  changeData: PropTypes.func,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

CustomerSupportInput.defaultProps = {
  data: {},
  isEditing: false,
  errorText: '',
  changeData: () => { },
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default CustomerSupportInput;