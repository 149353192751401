import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import SalesOfficeInputs from './children/SalesOfficeInputs';
import DialogMessage from 'src/common/DialogMessage';
//functions
import SalesOfficeFunctions from 'src/functions/mtbFunctions/SalesOfficeFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  営業所詳細編集コンポーネント
  テキスト入力後、
  保存ボタンから営業所詳細データをDBに格納
  キャンセルボタンで入力情報を破棄してリストに戻る
*/
const MtbSalesOfficeEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [salesOfficeData, setSalesOfficeData] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  /*
    営業所データに編集者名（ログインユーザー名）を格納
  */
  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath} = state;
      data["updated_employee_name"] = employee_name;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _salesOfficeData = getData();
      setSalesOfficeData({..._salesOfficeData});
    }
  }, [employee_name, props]);

  /*
    stateの編集
  */
  const changeSalesOfficeData = useCallback((key, value) => {
    const _salesOfficeData = {...salesOfficeData};
    if (value !== null) {
      _salesOfficeData[key] = value;
      setSalesOfficeData(_salesOfficeData);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [salesOfficeData, modalRequired]);

  /*
    保存ボタンから営業所データをDBに格納
  */
  const onSaveButtonPressed = useCallback(async () => {
    const salesOfficeFunctions = new SalesOfficeFunctions({ salesOfficeData: salesOfficeData, token: token });
    const response = await salesOfficeFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current, state: {data: response.data}});
    }
  }, [salesOfficeData, token, history, modalRequired, previousPath]);
  
  /*
    キャンセルボタンで入力情報を破棄してリストに戻る
  */
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <SalesOfficeInputs 
        data={salesOfficeData}
        onChange={changeSalesOfficeData}
        isEditing={true}
        errorText={errorText}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbSalesOfficeEdit;