import React, {useEffect, useCallback, useState} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
//components
import DropDownSelection from 'src/common/DropDownSelection';
import TextInputDropDownSelection from 'src/common/TextInputDropDownSelection';
import DateSelector from 'src/common/DateSelector';
import { TextInput, PostalCodeInput, TextHalfNumberInput } from 'src/common/Inputs';
//style and config
import { FontSize, BreakPoints } from 'src/configs/StyleConfig';
//redux
import { useSelector } from 'react-redux';

const dateSelectionList = { support_date: "対応日", support_complete_date: "対応完了日" }
const supportEmployeeKeys = { support_employee_name1: "対応担当者1", support_employee_name2: "対応担当者2", support_employee_name3: "対応担当者3", support_employee_name4: "対応担当者4" };
const employeeKeyList = ["support_employee_name1", "support_employee_name2", "support_employee_name3", "support_employee_name4"];
const supportContentList = { mtb_partner_id: "提携先", mtb_sales_office_id: "営業所", content: "内容", detail1: "詳細1", detail2: "詳細2" }

const CustomerSupportContent = (props) => {

  const { supportInfo, changeSupportInfo, isEditable } = props;
  const { mtb_enterprise_id } = useSelector(state => state.auth);
  const { selectedCustomerData } = useSelector(state => state.customer);
  const { partnerData, employeeList, customerSupportData, salesOfficeData } = useSelector(state => state.master);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [salesOfficeOptions, setSalesOfficeOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const [detail1Options, setDetail1Options] = useState([]);
  const [detail2Options, setDetail2Options] = useState([]);

  useEffect(() => {
    if (partnerData && employeeList && salesOfficeData && customerSupportData) {
      const _partnerOptions = getOptionData(partnerData, "partner_name");　//提携先の選択肢
      const _employeeOptions = getEmployeeOptions();　//対応担当者の選択肢
      const _salesOfficeOptions = getSalesOfficeOptions(); //営業所の選択肢
      const _contentOptions = getArrayToOptions(customerSupportData[0], "text");　//内容の選択肢
      const _detail1Options = getArrayToOptions(customerSupportData[1], "text");　//詳細１の選択肢
      const _detail2Options = getArrayToOptions(customerSupportData[2], "text");　//詳細２の選択肢
      setPartnerOptions(_partnerOptions);
      setEmployeeOptions(_employeeOptions);
      setSalesOfficeOptions(_salesOfficeOptions);
      setContentOptions(_contentOptions);
      setDetail1Options(_detail1Options);
      setDetail2Options(_detail2Options);
    }
  }, [partnerData, employeeList, salesOfficeData, customerSupportData]);

  const getOptionData = (rawOptionData, labelKey) => {
    //　Object型のマスタデータをそれぞれの選択肢に表示する処理(List型)
    if (!rawOptionData) return [];

    const array = [];
    const empty = {value: '0', label: ''}; //　０は空のラベルを持つ（defaultを非表示にする為）
    array.push(empty);
    Object.keys(rawOptionData).map((key) =>{
      const obj = {
        value: key,
        label: rawOptionData[key][labelKey]
      };
      array.push(obj);
    });
    return array;
  };

  const getEmployeeOptions = () => {
    if (employeeList instanceof Array && employeeList.length > 0) {
      const employeeOptions = [];
      const empty = {value: '', label: ''};
      employeeOptions.push(empty);
      employeeList.map((employee) => {
        if (selectedCustomerData.mtb_sales_office_id && employee.mtb_sales_office_id === parseInt(selectedCustomerData.mtb_sales_office_id)) {
          const option = { //名前をDBに送るので、valueも名前
            value: employee.employee_name,
            label: employee.employee_name,
          };
          employeeOptions.push(option);
        }
      });
      return employeeOptions;
    }
  };

  const getSalesOfficeOptions = () => {
    //営業所選択肢の初期化
    const array = [];
    const empty = {value: '0', label: ''}; //０は空のラベルを持つ（defaultを非表示にする為）
    array.push(empty);

    if (Object.keys(salesOfficeData).length === 0) return array;

    Object.keys(salesOfficeData).map((id) => {
      if (mtb_enterprise_id === salesOfficeData[id].mtb_enterprise_id) {
        const obj = {
          value: salesOfficeData[id].id.toString(),
          label: salesOfficeData[id].sales_office_name
        };
        array.push(obj);
      }
    });
    return array;
  }

  const getArrayToOptions = (data, optionKey) => {
    if (!(data instanceof Array) || data.length === 0) return [];
    const options = [];
    data.map((option) => {
      let json = {
        value: option[optionKey],
        label: option[optionKey],
      };
      options.push(json);
    });
    return options;
  }

  const changeEmployeeOptions = useCallback((key, val) => {
    //営業担当者の選択ハンドリン
    const {value} = val.target;
    const updatingKey = getFillingSalesEmployeeSection(supportInfo, key);
    changeSupportInfo(updatingKey, value);
  }, [supportInfo]);

  const getFillingSalesEmployeeSection = (supportInfo, key) => {
    //営業担当者の選択フォームで、先頭から埋めていく処理
    const maxNum = employeeKeyList.findIndex((k) => k === key);
    for (let i=0; i < maxNum; i++) {
      if (supportInfo[employeeKeyList[i]] === '') {
        return employeeKeyList[i];
      }
    }
    return key;
  };

  const switchContentInput = useCallback((key) => {
    if (key === "mtb_partner_id") {
      return (
        <DropDownSelection 
          id={key}
          options={partnerOptions}
          isEnable={isEditable}
          value={supportInfo.mtb_partner_id ? supportInfo.mtb_partner_id : '0'}
          onChange={(val) => changeSupportInfo(key, val.target.value)}
        />
      )
    } else if (key === "mtb_sales_office_id") {
      return (
        <DropDownSelection 
          id={key}
          options={salesOfficeOptions}
          isEnable={isEditable}
          value={supportInfo.mtb_sales_office_id ? supportInfo.mtb_sales_office_id : '0'}
          onChange={(val) => changeSupportInfo(key, val.target.value)}
        />
      )
    } else if (key === "content") {
      return <TextInputDropDownSelection
        id={key}
        options={contentOptions}
        isEnable={isEditable}
        value={supportInfo.content}
        onChange={(val) => changeSupportInfo(key, val.target.value)}
      />
    } else if (key === "detail1") {
      return <TextInputDropDownSelection 
        id={key}
        options={detail1Options}
        isEnable={isEditable}
        value={supportInfo.detail1}
        onChange={(val) => changeSupportInfo(key, val.target.value)}
      />
    } else { // detail2
      return <TextInputDropDownSelection 
        id={key}
        options={detail2Options}
        isEnable={isEditable}
        value={supportInfo.detail2}
        onChange={(val) => changeSupportInfo(key, val.target.value)}
      />
    }
  }, [supportInfo, partnerOptions, contentOptions, detail1Options, detail2Options]);
  
  return (
    <Container>
      <h4>
        対応情報
      </h4>
      <SupportInfoContainer>
        <InfoWrapper>
          <div style={{paddingBottom: '8px'}}>
            { //対応日、対応完了日
              Object.keys(dateSelectionList).map((key) => {
                return (
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '4px 0' }} key={key}>
                    <LabelWrapper>
                      {dateSelectionList[key]}
                    </LabelWrapper>
                    <TextInputWrapper>
                      <DateSelector
                        isEnable={isEditable}
                        date={supportInfo[key]}
                        onChange={(date) => changeSupportInfo(key, date)}
                      />
                    </TextInputWrapper>
                  </div>
                )
              })
            }
          </div>
          {　//対応担当者１〜４
            Object.keys(supportEmployeeKeys).map((key) => {
              return (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={key}>
                  <LabelWrapper>
                    {supportEmployeeKeys[key]}
                  </LabelWrapper>
                  <TextInputWrapper width={"210px"}>
                    <TextInputDropDownSelection
                      options={employeeOptions}
                      isEnable={isEditable}
                      value={supportInfo[key] ? supportInfo[key] : ''}
                      onChange={(val) => changeEmployeeOptions(key, val)}
                    />
                  </TextInputWrapper>
                </div>
              )
            })
          }
        </InfoWrapper>
        <RemarksWrapper>
          <LabelWrapper>
            備考
          </LabelWrapper>
          <TextArea width={'90%'} disabled={!isEditable} value={supportInfo.remarks} onChange={(val) => changeSupportInfo("remarks", val.target.value)}/>
        </RemarksWrapper>
      </SupportInfoContainer>
      <SupportContentWrapper>
        <SupportContentSection>
          { //提携先、内容、詳細１、詳細２
            Object.keys(supportContentList).map((key) => {
              return (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} key={key}>
                  <LabelWrapper>
                    {supportContentList[key]}
                  </LabelWrapper>
                  <SelectionWrapper>
                    {switchContentInput(key)}
                  </SelectionWrapper>
                </div>
              )
            })
          }
        </SupportContentSection>
        <SupportContentSection>
          <DestinationInputWrapper>
            <LabelWrapper>
              施工先名
            </LabelWrapper>
            <SelectionWrapper>
            <TextInput isEnable={isEditable} value={supportInfo.destination_name} onChange={(e) => changeSupportInfo('destination_name', e.target.value)}/>
            </SelectionWrapper>
          </DestinationInputWrapper>
          <DestinationInputWrapper>
            <LabelWrapper>
              施工先郵便番号
            </LabelWrapper>
            <SelectionWrapper>
              <PostalCodeInput 
                isEnable={isEditable}
                value={supportInfo.destination_postal_code} 
                onChange={(val) => changeSupportInfo('destination_postal_code', val.target.value)}
                onChangeAddress={(address) => changeSupportInfo('destination_address', address)}
              />
            </SelectionWrapper>
          </DestinationInputWrapper>
          <DestinationInputWrapper>
            <LabelWrapper>
              施工先住所 
            </LabelWrapper>
            <SelectionWrapper>
            <TextInput isEnable={isEditable} value={supportInfo.destination_address} onChange={(e) => changeSupportInfo('destination_address', e.target.value)}/>
            </SelectionWrapper>
          </DestinationInputWrapper>
          <DestinationInputWrapper>
            <LabelWrapper>
              施工先電話番号
            </LabelWrapper>
            <SelectionWrapper>
            <TextHalfNumberInput isEnable={isEditable} value={supportInfo.destination_phone} onChange={(e) => changeSupportInfo('destination_phone', e.target.value)}/>
            </SelectionWrapper>
          </DestinationInputWrapper>
        </SupportContentSection>
      </SupportContentWrapper>
      <SupportDetailWrapper>
        詳細
        <TextArea width={'90%'} disabled={!isEditable} value={supportInfo.support_detail} onChange={(val) => changeSupportInfo("support_detail", val.target.value)}/>
      </SupportDetailWrapper>
    </Container>
  )
};

const Container = styled.div`
  width: 96%;
  padding: 20px;
`
const TitleWrapper = styled.div`
  width: 100%;
  font-size: ${FontSize.xl};
  font-weight: 600;
` 
const SupportInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: column;
  }
`
const InfoWrapper = styled.div`
  width: 35%;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 65%;
  }
`
const LabelWrapper = styled.div`
  width: 120px;
`
const TextInputWrapper = styled.div`
  width: ${props => props.width ? props.width : '200px'};
`
const RemarksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 60%;
`
const TextArea = styled.textarea`
  width: ${props => props.width ? props.width : '80%'};
  min-height: 120px;
`
const SupportContentWrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: column;
  }
`
const SupportContentSection = styled.div`
  width: 50%;
`
const SelectionWrapper = styled.div`
  width: 300px;
`
const DestinationInputWrapper = styled.div`
  width: 100%; 
  display: flex; 
  flex-direction: row;
  padding: 0 4px 10px 4px;
  align-items: center;
`
const SupportDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
CustomerSupportContent.propTypes = {
  supportInfo: PropTypes.object.isRequired,
  changeSupportInfo: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

CustomerSupportContent.defaultProps = {
  supportInfo: {},
  changeSupportInfo: () => {},
  isEditable: false
};

export default CustomerSupportContent;