import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
//styles & configs
import { Colors } from 'src/configs/StyleConfig';
//components
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import { SubmitButton, CancelButton } from 'src/common/StylesComponents';
import Modal from 'react-modal';
//redux
import { useSelector } from 'react-redux';
import { dataPreprocessing } from 'src/redux/actions/ConstructionDataHandler';

//apis
import { SEARCH_CONSTRUCTION_URL } from 'src/apis';
import toast from 'react-hot-toast';


registerLocale('ja', ja) // カレンダーを日本語対応させるための準備

const modalStyles = {
  content: {
    width: '60%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)"
  },
};

const dateOptions = [
  { value: 'support_date', label: '対応日' },
  { value: 'construction_end_date', label: '完了日' },
  { value: 'contract_date', label: '契約日' }
];

const isAgreementFormOption = [
  { value: "0", label: '無し' },
  { value: "1", label: '有り' },
];

const jstTime = 9 * 60 * 60 * 1000; // 9 hours ahead.

const ConstructionSearchModal = (props) => {
  const { modalIsOpen, setModalIsOpen, customerInfo, setCustomerInfo, selectedDateType, setSelectedDateType, startDate, setStartDate, endDate, setEndDate, setSearchResult, setSearched, isFilteringQueryFound, setIsLoading } = props;
  const { partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData, employeeList } = useSelector(state => state.master);
  const { mtb_enterprise_id, mtb_sales_office_id, role, token } = useSelector(state => state.auth);
  const [salesOfficeList, setSalsesOfficeList] = useState([]);
  const [businessPartner, setBusinessPartner] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [salesEmployeeName, setSalesEmployeeName] = useState([]);
  const [constructionEmployeeName, setConstructionEmployeeName] = useState([]);
  const [enterpriseName, setEnterpriseName] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);

  useEffect(() => {
    createDropDownArray();
  }, [])

  useEffect(() => {
    if (isFilteringQueryFound) {
      narrowDown()
        .then((data) => {
          setSearchResult(data);
        })
        .catch((error) => {
          toast.error(error);
        })
    }
  }, [isFilteringQueryFound]);

  const createSearchCustomerJson = useCallback((text, key) => {
    const json = { ...customerInfo };
    if (key === 'mtb_partner_id') {
      json['mtb_store_id'] = '';
    }
    json[key] = text;
    setCustomerInfo(json);
  }, [customerInfo])

  // 検索用ドロップダウンの生成
  const createDropDownArray = async () => {
    // 企業
    const enterpriseOptionsData = setOptionData(enterpriseData, 'enterprise_name');
    setEnterpriseName(enterpriseOptionsData);

    // 営業所
    const salesOfficeOptionsData = setOptionData(salesOfficeData, 'sales_office_name');
    setSalsesOfficeList(salesOfficeOptionsData);

    // 提携先
    const partnerOptionsData = setOptionData(partnerData, 'partner_name');
    setBusinessPartner(partnerOptionsData);

    // 支払い方法
    const paymentMethodsOptionsData = setOptionData(paymentMethods, 'payment_method');
    setPaymentMethod(paymentMethodsOptionsData);

    // 営業担当
    const salesEmployeeOptionsData = setOptionData(employeeList, 'employee_name');
    setSalesEmployeeName(salesEmployeeOptionsData);

    // 施工担当
    const constructionEmployeeOptionsData = setOptionData(employeeList, 'employee_name');
    setConstructionEmployeeName(constructionEmployeeOptionsData);
  };

  const setOptionData = (data, columnKey) => {
    let _options = [];

    if (Object.keys(data).length === 0) return [];

    Object.keys(data).forEach((key) => {
      const json = {
        value: key,
        label: data[key][columnKey]
      };
      _options.push(json);
    })
    return _options;
  };

  // 選択された日付の種類をstateに保持
  const dateSortSelected = (e) => {
    setSelectedDateType(e.target.value);
  };

  const preprocessStoreOptions = () => {
    //　店舗名データは提携先に依存するため、提携先が選択された時点（customerInfo.mtb_partner_idをリスニング）で、
    //　店舗リストからログインユーザーの企業IDと選択した提携先の外部キーとマッチする全ての店舗データを選択肢に格納する処理
    if (storeData) {
      const mtb_partner_id = parseInt(customerInfo.mtb_partner_id);
      const array = [];
      Object.keys(storeData).map((id) => {
        if ((role === 1 || mtb_enterprise_id === storeData[id].mtb_enterprise_id) &&
          mtb_partner_id === storeData[id].mtb_partner_id) {
          const obj = {
            id: storeData[id].id,
            value: storeData[id].store_kana,
            label: storeData[id].store_name,
            sortKey: storeData[id].store_kana,
          };
          array.push(obj);
        }
      });
      array.sort((a, b) => {
        if (a.sortKey === null) {
          return 1;
        } else if (b.sortKey === null) {
          return -1;
        }
        return a.sortKey.localeCompare(b.sortKey, 'ja-Hiragana');
      });
      setSelectedStore(array);
    }
  }
  useMemo(preprocessStoreOptions, [customerInfo.mtb_partner_id]);

  // 条件絞り込み検索
  const narrowDown = async () => {
    let searchedConstruction = [];
    let errorMsg = "";
    setSearchResult(() => []); // 検索初期化
    setIsLoading(true);
    setModalIsOpen(false); // modalを閉じる。

    const narrowDownResult = Object.assign(customerInfo);
    const new_customerInfo = Object.assign({}, narrowDownResult); // 入力された項目のみで連想配列を再生成 .assignを利用しないと元データも変化してしまう。
    // 入力欄が空白の要素を除外する
    for (let key in new_customerInfo) {
      if (new_customerInfo[key].length === 0) {
        delete new_customerInfo[key]
      };
    };
    
    // UTC にJST＋９時間追加
    const startD = startDate ? new Date(startDate) : new Date();
    const endD = endDate ? new Date(endDate) : new Date();
    startD.setTime(startD.getTime() + jstTime);
    endD.setTime(endD.getTime() + jstTime);

    const startDateString = startD.toISOString();
    const endDateString = endD.toISOString();

    await axios.get(SEARCH_CONSTRUCTION_URL, {
      params: {
        mtb_enterprise_id: mtb_enterprise_id,
        mtb_sales_office_id: mtb_sales_office_id,
        searchItem: JSON.stringify(new_customerInfo),
        selectedDateType: selectedDateType,
        startDate: startDateString,
        endDate: endDateString
      },
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          const processedData = dataPreprocessing(response.data, partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData);
          searchedConstruction = processedData;
        }
      })
      .catch(() => {
        errorMsg = "工事データを取得できませんでした。";
      })

    setSearched(true); // 検索したかどうかを trueにする。
    localStorage.setItem('constructionSearchQuery', JSON.stringify({
      customerInfo: customerInfo,
      selectedDateType: selectedDateType,
      startDate: startDate,
      endDate: endDate
    }));
    setIsLoading(false);

    if (errorMsg) {
      throw new Error(errorMsg).message;
    }
    if (!searchedConstruction.length) {
      throw new Error("該当する工事データを取得できませんでした。").message;
    }

    return searchedConstruction;
  };

  const onSearchButtonPressed = async () => {
    narrowDown()
      .then((data) => {
        setSearchResult(data);
      })
      .catch((error) => {
        toast.error(error);
      })
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyles}
        contentLabel="Modal"
      >
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{}}>
              <select style={{ height: "28px" }} value={selectedDateType} onChange={(e) => dateSortSelected(e)}>
                <option key={"default"} value={""}>--日付の種類--</option>
                {dateOptions.map((key, index) => {
                  return (
                    <option key={index} value={key.value}>{key.label}</option>
                  )
                })}
              </select>
            </div>
            <div style={{ margin: '4px 12px 0 36px', color: '#3f3f3f', fontWeight: 'bold' }}>範囲: </div>
            <div style={{ width: '20%' }}>
              <DatePicker locale="ja" dateFormat="yyyy/MM/dd" selected={startDate} onChange={(date) => setStartDate(date)} />
            </div>
            <div style={{ margin: '4px 8px 0 8px', color: '#3f3f3f', fontWeight: 'bold' }}>
              〜
            </div>
            <div style={{ width: '20%' }}>
              <DatePicker locale="ja" dateFormat="yyyy/MM/dd" selected={endDate} onChange={(date) => setEndDate(date)} />
            </div>
          </div>
          <div>
            <div>
              <div style={{ display: 'flex', marginTop: '32px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>顧客No</div>
                <input type={"text"} placeholder={"顧客No"} style={{ marginRight: '20px' }} value={customerInfo.customer_code} onChange={(e) => createSearchCustomerJson(e.target.value, "customer_code")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>氏名</div>
                <input type={"text"} placeholder={"氏名"} style={{ marginRight: '20px' }} value={customerInfo.customer_name} onChange={(e) => createSearchCustomerJson(e.target.value, "customer_name")}></input>
                <input type={"text"} placeholder={"氏名かな"} value={customerInfo.customer_kana} onChange={(e) => createSearchCustomerJson(e.target.value, "customer_kana")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>住所</div>
                <input type={"text"} placeholder={"住所"} style={{ marginRight: '20px' }} value={customerInfo.customer_address1} onChange={(e) => createSearchCustomerJson(e.target.value, "customer_address1")}></input>
                <input type={"text"} placeholder={"郵便番号"} value={customerInfo.customer_postal_code} onChange={(e) => createSearchCustomerJson(e.target.value, "customer_postal_code")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>電話番号</div>
                <input type={"text"} placeholder={"電話番号"} style={{ marginRight: '20px' }} value={customerInfo.customer_phone1} onChange={(e) => createSearchCustomerJson(e.target.value, "customer_phone1")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '24px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>企業</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.mtb_enterprise_id} onChange={(e) => createSearchCustomerJson(e.target.value, "mtb_enterprise_id")}>
                  <option key={"default"} value={""}>--企業--</option>
                  {enterpriseName.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>施工先名</div>
                <input type={"text"} placeholder={"内容"} style={{ marginRight: '20px' }} value={customerInfo.destination_name} onChange={(e) => createSearchCustomerJson(e.target.value, "destination_name")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>営業所</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.mtb_sales_office_id} onChange={(e) => createSearchCustomerJson(e.target.value, "mtb_sales_office_id")}>
                  <option key={"default"} value={""}>--営業所--</option>
                  {salesOfficeList.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>施工先郵便番号</div>
                <input type={"text"} placeholder={"内容"} style={{ marginRight: '20px' }} value={customerInfo.destination_postal_code} onChange={(e) => createSearchCustomerJson(e.target.value, "destination_postal_code")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>提携先</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.mtb_partner_id} onChange={(e) => createSearchCustomerJson(e.target.value, "mtb_partner_id")}>
                  <option key={"default"} value={""}>--提携先--</option>
                  {businessPartner.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>施工先住所</div>
                <input type={"text"} placeholder={"内容"} style={{ marginRight: '20px' }} value={customerInfo.destination_address} onChange={(e) => createSearchCustomerJson(e.target.value, "destination_address")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>店舗名</div>
                <Select
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: '#767676',
                      minHeight: '24px',
                      height: '24px',
                      width: '152px',
                      borderRadius: '2px',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      height: '24px',
                      padding: '0 3px'
                    }),
                    indicatorsContainer: (provided, state) => ({
                      ...provided,
                      height: '24px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: "13px",
                      fontWeight: "normal",
                      color: Colors.textColor,
                      padding: 4,
                    }),
                    singleValue: base => ({
                      ...base,
                      fontSize: "13px",
                      fontWeight: "normal",
                      color: Colors.textColor,
                    }),
                    input: base => ({
                      ...base,
                      fontSize: "13px",
                      margin: "0px",
                      color: Colors.textColor,
                    }),
                    placeholder: base => ({
                      ...base,
                      fontSize: "13px",
                      margin: '0px',
                      color: Colors.textColor,
                    }),
                  }}
                  isClearable={true}
                  value={customerInfo.mtb_store_id ? selectedStore.find((v) => v.id === customerInfo.mtb_store_id) : null}
                  isSearchable={true}
                  options={selectedStore}
                  onChange={(e) => createSearchCustomerJson(e ? e.id : '', "mtb_store_id")}
                  placeholder="--店舗名--"
                >
                </Select>
                <div style={{ width: '140px', marginLeft: '100px' }}>施工先電話番号</div>
                <input type={"text"} placeholder={"内容"} style={{ marginRight: '20px' }} value={customerInfo.destination_phone} onChange={(e) => createSearchCustomerJson(e.target.value, "destination_phone")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '24px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>契約書No</div>
                <input type={"text"} placeholder={"契約書No"} style={{ marginRight: '20px' }} value={customerInfo.contract_no} onChange={(e) => createSearchCustomerJson(e.target.value, "contract_no")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>管理番号</div>
                <input type={"text"} placeholder={"管理番号"} style={{ marginRight: '20px' }} value={customerInfo.serial_no} onChange={(e) => createSearchCustomerJson(e.target.value, "serial_no")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '24px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>支払い方法</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.mtb_payment_method_id} onChange={(e) => createSearchCustomerJson(e.target.value, "mtb_payment_method_id")}>
                  <option key={"default"} value={""}>--支払い方法--</option>
                  {paymentMethod.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>備考</div>
                <input type={"text"} placeholder={"備考"} style={{ marginRight: '20px' }} value={customerInfo.remarks} onChange={(e) => createSearchCustomerJson(e.target.value, "remarks")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }} />
                <input type={"text"} placeholder={"支払い方法フリー"} style={{ marginRight: '20px' }} value={customerInfo.payment_description} onChange={(e) => createSearchCustomerJson(e.target.value, "payment_description")}></input>
                <div style={{ width: '140px', marginLeft: '80px' }}>メモ</div>
                <input type={"text"} placeholder={"メモの内容"} style={{ marginRight: '20px' }} value={customerInfo.memo} onChange={(e) => createSearchCustomerJson(e.target.value, "memo")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>ローン承認番号</div>
                <input type={"text"} placeholder={"ローン承認番号"} style={{ marginRight: '20px' }} value={customerInfo.approval_number} onChange={(e) => createSearchCustomerJson(e.target.value, "approval_number")}></input>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '20px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>同意書の有無</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.is_agreement_form} onChange={(e) => createSearchCustomerJson(e.target.value, "is_agreement_form")}>
                  <option key={"default"} value={""}>--有無--</option>
                  {isAgreementFormOption.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>営業担当</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.sales_employee_name1} onChange={(e) => createSearchCustomerJson(e.target.value, "sales_employee_name1")}>
                  <option key={"default"} value={""}>--営業担当--</option>
                  {salesEmployeeName.map((key, index) => {
                    return (
                      <option key={index} value={key.label}>{key.label}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>保守点検の有無</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.is_maintenance} onChange={(e) => createSearchCustomerJson(e.target.value, "is_maintenance")}>
                  <option key={"default"} value={""}>--有無--</option>
                  {isAgreementFormOption.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>施工担当</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.construction_employee_name1} onChange={(e) => createSearchCustomerJson(e.target.value, "construction_employee_name1")}>
                  <option key={"default"} value={""}>--施工担当--</option>
                  {constructionEmployeeName.map((key, index) => {
                    return (
                      <option key={index} value={key.label}>{key.label}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ width: '140px', marginRight: '40px' }}>その他工事の有無</div>
                <select style={{ height: "24px", width: '152px' }} value={customerInfo.has_other_construction} onChange={(e) => createSearchCustomerJson(e.target.value, "has_other_construction")}>
                  <option key={"default"} value={""}>--有無--</option>
                  {isAgreementFormOption.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
                <div style={{ width: '140px', marginLeft: '100px' }}>工事内容</div>
                <input type={"text"} placeholder={"内容"} style={{ marginRight: '20px' }} value={customerInfo.content} onChange={(e) => createSearchCustomerJson(e.target.value, "content")}></input>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '32px' }}>
          <CancelButton onClick={() => setModalIsOpen(false)}>キャンセル</CancelButton>
          <SubmitButton onClick={() => onSearchButtonPressed()}>絞り込む</SubmitButton>
        </div>
      </Modal>
    </div >
  )
}

const Column = (props) => {
  const { label } = props;
  return (
    <div style={{ position: 'relative' }}>
      <ColumnLabelStyle>
        {label}
      </ColumnLabelStyle>
      <ColumnContainer>
        {props.children}
      </ColumnContainer>
    </div>
  )
}

Column.propTypes = {
  label: PropTypes.string.isRequired,
};

Column.defaultProps = {
  label: '',
};

const ColumnLabelStyle = styled.div`
  position: absolute;
  top:0;
  left:0;
  min-width: 80px;
  max-width: 180px;
  padding: 0 12px;
  border: 1px solid ${Colors.border};
  background-color: white;
  z-index: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`

const ColumnContainer = styled.div`
  position: absolute;
  top:12px;
  left:0;
  border: 1px solid ${Colors.border};
  padding: 12px;
  width: 100%;
  height: auto;
`

export default ConstructionSearchModal;