/*
テーブルに表示されているInt型の金額をstring型のカンマを含む金額使用にするクラス
*/
class PriceFunctions {

  /*
    public method
    Int型から金額のstring型への変換
  */
  intPriceToStringPrice(intPrice) {
    if (typeof intPrice !== 'number') return "";
    if (typeof intPrice === 'number' && intPrice === 0) return parseInt(intPrice).toString();
    return parseInt(intPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /*
    public method
    DBに格納する用でstring型の金額からInt型への変換
  */
  stringPriceToIntPrice(strPrice) {
    if (typeof strPrice !== "string" || !strPrice) return null;
    const price = strPrice.split(',').join('');
    return parseInt(price);
  }

}

export default new PriceFunctions();