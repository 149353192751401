import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
//components
import {TextInput, TextHalfNumberInput} from 'src/common/Inputs';
import styled from 'styled-components';
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { Colors } from 'src/configs/StyleConfig';
import DropDownSelection from 'src/common/DropDownSelection';
//redux
import { useSelector } from 'react-redux';

const baseRoleOptions = {
  //ログインユーザーがFTS権限の場合
  1: [
    { value: '0', label: ' ' },
    { value: '1', label: 'FTS権限' },
    { value: '2', label: '企業権限' },
    { value: '3', label: '営業所権限' }
  ],
  //ログインユーザーが企業権限の場合
  2: [
    { value: '0', label: ' ' },
    { value: '2', label: '企業権限' },
    { value: '3', label: '営業所権限' }
  ]
}

const MtbEmployeeInputs = (props) => {

  const { data, onChange, isEditing, errorText, onSaveButtonPressed, onCancelButtonPressed, onEditButtonPressed, onDeleteButtonPressed } = props;
  const { account, entered_password, employee_code, employee_name, mtb_enterprise_id, mtb_sales_office_id, role } = data;

  const { salesOfficeData, enterpriseData } = useSelector(state => state.master);
  const loginUserRole = useSelector(state => state.auth.role);
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [salesOfficeOptions, setSalesOfficeOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    const getOptions = (objectData, labelKey) => {
      const options = Object.keys(objectData).map((key) => {
        return {
          value: key.toString(),
          label: objectData[key][labelKey]
        };
      });
      const empty = { value: '0', label: ' ' };
      return [empty, ...options];
    }

    if (salesOfficeData && loginUserRole === 1) {
      const enterpriseOptionsData = getOptions(enterpriseData, 'enterprise_name');
      setEnterpriseOptions(enterpriseOptionsData);
      setRoleOptions(baseRoleOptions[loginUserRole]);
    } else if (enterpriseData && loginUserRole === 2) {
      const salesOfficeOptionData = getOptions(salesOfficeData, 'sales_office_name');
      setSalesOfficeOptions(salesOfficeOptionData);
      setRoleOptions(baseRoleOptions[loginUserRole]);
    }

  }, [salesOfficeData, enterpriseData, loginUserRole]);

  const initSalesOfficeOptions = () => {
    if (mtb_enterprise_id) {
      let options = [];
      const empty = { value: '0', label: ' ' };
      options.push(empty);
      Object.keys(salesOfficeData).forEach((key) => {
        if (salesOfficeData[key].mtb_enterprise_id === parseInt(mtb_enterprise_id)) {
          const json = {
            value: key.toString(),
            label: salesOfficeData[key].sales_office_name
          };
          options.push(json);
        }
      });
      setSalesOfficeOptions(options);
    }
  }
  useMemo(initSalesOfficeOptions, [salesOfficeData, mtb_enterprise_id]);

  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                アカウント名
              </SettingList>
              <TextInput isEnable={isEditing} value={account} onChange={(val) => onChange('account', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                パスワード
              </SettingList>
              {
                isEditing ?
                  <TextInput textType={'password'} isEnable={isEditing} value={entered_password} onChange={(val) => onChange('entered_password', val.target.value)} />
                  :
                  <TextInput textType={'password'} isEnable={isEditing} value={'-------'} />
              }
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                従業員コード
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={employee_code} onChange={(val) => onChange('employee_code', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                氏名
              </SettingList>
              <TextInput isEnable={isEditing} value={employee_name} onChange={(val) => onChange('employee_name', val.target.value)} />
            </SettingFlex>
            {
              loginUserRole === 1 ?
                <SettingFlex>
                  <SettingList>
                    所属企業
                  </SettingList>
                  <DropDownSelection
                    options={enterpriseOptions}
                    value={mtb_enterprise_id}
                    onChange={(val) => onChange('mtb_enterprise_id', val.target.value)}
                    isEnable={isEditing}
                  />
                </SettingFlex>
                :
                null
            }
            <SettingFlex>
              <SettingList>
                所属営業所
              </SettingList>
              <DropDownSelection
                options={salesOfficeOptions}
                value={mtb_sales_office_id}
                onChange={(val) => onChange('mtb_sales_office_id', val.target.value)}
                isEnable={isEditing}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                操作権限
              </SettingList>
              <DropDownSelection
                options={roleOptions}
                value={role}
                onChange={(val) => onChange('role', val.target.value)}
                isEnable={isEditing}
              />
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`

MtbEmployeeInputs.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
  errorText: PropTypes.string,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

MtbEmployeeInputs.defaultProps = {
  data: {},
  onChange: () => { },
  isEditing: true,
  errorText: '',
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default MtbEmployeeInputs;