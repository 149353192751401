import axios from 'axios';
//functions
import GeneralFunctions from '../GeneralFunctions';
import PhoneNumberFunctions from '../PhoneNumberFunctions';
//apis
import { MTB_ENTERPRISE_DATA_CHANGE_URL, MTB_BUSINESS_PARTNER_DATA_CHANGE_URL, PARTNER_DATA_URL } from 'src/apis';

const requiredInputField = {
  enterprise_code: '企業コード', enterprise_name: '企業名', postal_code: '郵便番号', address: '住所', phone: '電話番号'
};

/*
企業設定　企業の登録/編集クラス
- Parameters in constructor:
 - enterpriseData: 従業員データ
 - partnerData: 提携先データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - enterprise: DBに登録/編集した企業データ
 - partners: 提携先データ
*/
class EnterpriseFunctions {

  constructor(props) {
    const { enterpriseData, partnerData, token } = props;
    this.enterpriseData = enterpriseData;
    this.partnerData = partnerData;
    this.token = token;
    this.response = { error: '', enterprise: {}, partners: [] };
  }

  /*
    private method
    必須項目、電話番号、提携先選択とマージンチェック
  */
  _validation() {
    const data = { ...this.enterpriseData };
    const partners = [...this.partnerData];

    let errors = [];
    //必須項目チェック
    Object.keys(requiredInputField).forEach((key) => {
      if (GeneralFunctions.isNullOrEmpty(data[key])) {
        errors.push(requiredInputField[key]);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力・選択してください。";
    };
    //電話番号チェック
    const phoneResponse = PhoneNumberFunctions.phoneValidation(data['phone']);
    if (phoneResponse.error) {
      return phoneResponse.error;
    }
    this.enterpriseData['phone'] = phoneResponse.validPhoneNumber;

    //提携先選択チェック
    partners.forEach((partner) => {
      if (partner.is_selected === 1 && !partner.margin) {
        errors.push(partner.partner_name);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "のマージンを入力してください。";
    };

    return;
  }

  /*
    private method
    企業データ送信メソッド
    成功時：
    insertId　ー＞DBのprimary keyを返す
    失敗時：
    error を返す
  */
  async _sendEnterpriseData() {
    // 企業データをDBに送る処理
    let enterpriseResponse = { error: '', insertId: null };
    try {
      const json = [this.enterpriseData];
      this.response.enterprise = { ...this.enterpriseData };
      const response = await axios.post(MTB_ENTERPRISE_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
      const { data } = response;
      const { insertId } = data;
      if (insertId) {
        enterpriseResponse.insertId = insertId;
      }
      return enterpriseResponse;
    } catch (error) {
      enterpriseResponse.error =  error.message;
      return enterpriseResponse;
    }
  }

  /*
    private method
    企業に基づく提携先データ(mtb_business_partner中間テーブル）の編集/登録メソッド
    mtb_business_partner_idがnullの場合、提携先に基づくmtb_business_partnerを追加
    mtb_business_partner_idが存在する場合、提携先に基づくmtb_business_partnerを編集
    - parameters:
     - mtb_enterprise_id: 企業API呼び出し時に帰ってくる企業Id
  */
  async _sendBusinessPartnerData(mtb_enterprise_id) {
    const { updated_employee_name } = this.enterpriseData;

    const data = [...this.partnerData];
    let sendingPartnerData = [];
    data.forEach((partner) => {
      if (partner.mtb_business_partner_id) {//id, margin, mtb_enterprise_id, mtb_partner_id, is_selected, created_employee_name, updated_employee_name
        //update
        const json = {
          id: partner.mtb_business_partner_id, 
          margin: parseInt(partner.margin),
          mtb_enterprise_id: mtb_enterprise_id, 
          mtb_partner_id: partner.id,
          is_selected: partner.is_selected,
          created_employee_name: partner.mbp_created_employee_name,
          updated_employee_name: updated_employee_name,
        };
        sendingPartnerData.push(json);
      } else {
        //register
        const json = {
          id: null, 
          margin: parseInt(partner.margin),
          mtb_enterprise_id: mtb_enterprise_id, 
          mtb_partner_id: partner.id,
          is_selected: partner.is_selected,
          created_employee_name: updated_employee_name,
          updated_employee_name: updated_employee_name,
        };
        sendingPartnerData.push(json);
      }
    });
    this.response.partners = [...sendingPartnerData];
    try {
      await axios.post(MTB_BUSINESS_PARTNER_DATA_CHANGE_URL, sendingPartnerData, { headers: {"Authorization" : `Bearer ${this.token}`} })
      .then((response) => {
        if (response.status === 200) return;
        throw new Error("提携先データを登録・更新できませんでした。");
      });
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    const response = await this._sendEnterpriseData();
    if (response.error) {
      this.response.error = response.error;
      return this.response;
    }
    const mtb_enterprise_id = response.insertId;
    error = await this._sendBusinessPartnerData(mtb_enterprise_id);
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  }

};

export default EnterpriseFunctions;


/*
全ての提携先を取ってくる関数
MtbEnterpriseRegisterにて呼び出し
- Parameters:
 - mtb_enterprise_id: 企業Id
 - token: APIアクセストークン
- returns:
 - partnerData: 提携先データ
*/
export const getPartnerForEnterprise = async (mtb_enterprise_id, token) => {
  try {
    const response = await axios.get(PARTNER_DATA_URL, {
      params: {
        mtb_enterprise_id: mtb_enterprise_id
      },
      headers: {"Authorization" : `Bearer ${token}`}
    });
    const {data} = response;
    return marginPreprocessing(data);
  } catch (error) {
    return [];
  }
};

const marginPreprocessing = (data) => {
  if (data.length === 0) return [];
  return data.map((d) => {
    if (d.is_selected === 0) {
      return { ...d, margin: null};
    }
    return d;
  })
}