import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
//components
import { BaseBodyStyle } from 'src/common/StylesComponents';
import { SalesHeader, SalesTable, SearchField, QueryDateWarningModal } from './children';
import Modal from 'react-modal';
//functions
import CSVFunctions from 'src/functions/CSVFunctions';
import SalesAggregationFunctions from 'src/functions/SalesAggregationFunctions';
//redux
import { useSelector } from 'react-redux';
//config
import { Colors, FontSize, BreakPoints } from 'src/configs/StyleConfig';

export const calculatingColumns = [
  "total",
  "subtotal",
  "margin_total",
  "margin_subtotal",
  "stamp_amount",
  "stamp_price"
];

export const columnNames = {
  serial_no: { label: '管理番号', width: '7%', align: 'center' },
  construction_end_date: { label: '工事完了日', width: '7%', align: 'center' },
  store_name: { label: '店舗名', width: '6%', align: 'left' },
  customer_name: { label: 'お客様名', width: '6%', align: 'left' },
  content: { label: '契約内容', width: '6%', align: 'left' },
  total: { label: '税込売上金額', width: '7%', align: 'right' },
  subtotal: { label: '税抜売上金額', width: '7%', align: 'right' },
  margin: { label: 'マージン(%)', width: '3%', align: 'right' },
  margin_total: { label: '税込明細金額', width: '7%', align: 'right' },
  margin_subtotal: { label: '税抜明細金額', width: '7%', align: 'right' },
  stamp_amount: { label: '印紙枚数', width: '6%', align: 'right' },
  stamp_price: { label: '印紙金額', width: '6%', align: 'right' },
  is_exported: { label: 'CSV出力済み', width: '3%', align: 'center' },
};

Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
  },
  content: {
    width: '500px',
    height: '340px',
    top: '50%',
    marginTop: '-200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
};
const warningModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
  },
  content: {
    width: '300px',
    height: '220px',
    top: '50%',
    marginTop: '-200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
};


const PrintingContent = React.forwardRef((props, ref) => {

  return (
    <Container ref={ref}>
      {props?.children}
    </Container>
  )
});

const Container = styled.div`
  width: 100%;
`

const SalesAggregation = () => {

  const componentRef = useRef();
  const { token, mtb_enterprise_id, mtb_sales_office_id } = useSelector(state => state.auth);
  const { storeData } = useSelector(state => state.master);
  const [showingSalesData, setShowingSalesData] = useState([]); //テーブルに実際表示しているデータ
  const [csvData, setCSVData] = useState([]);
  const [csvHeader, setCSVHeader] = useState({});
  const [total, setTotal] = useState({});
  const [isDateSelectingWarningModalOpen, setIsDateSelectingWarningModelOpen] = useState(false); //ユーザーが365日以上クエリを出した際に表示されるwarningのモダルフラグ
  const [searchingState, setSearchingState] = useState({
    construction_end_date_start: '',
    construction_end_date_end: '',
    mtb_enterprise_id: 0,
    mtb_sales_office_id: 0,
    mtb_partner_id: 0,
  });
  const [errorText, setErrorText] = useState('');
  const defaultSearchingState = useRef({
    construction_end_date_start: '',
    construction_end_date_end: '',
    mtb_enterprise_id: 0,
    mtb_sales_office_id: 0,
    mtb_partner_id: 0,
  });

  const queryDatesLimit = 365;
  const fileName = '売上集計';

  useEffect(() => {

    const t_1 = new Date();
    const t_2 = new Date(t_1)
    const start = new Date(Date.UTC(t_2.getFullYear(), t_2.getMonth(), 1, 0, 0, 0)).toISOString();
    const end = new Date(Date.UTC(t_1.getFullYear(), t_1.getMonth(), t_1.getDate(), 0, 0, 0)).toISOString();
    const _searchingState = { ...searchingState, mtb_enterprise_id: mtb_enterprise_id, mtb_sales_office_id: mtb_sales_office_id, construction_end_date_start: start, construction_end_date_end: end };
    defaultSearchingState.current = { ..._searchingState };
    setSearchingState(_searchingState);
    queryConstructionDate(start, end, mtb_enterprise_id, mtb_sales_office_id, null);
  }, [mtb_enterprise_id, mtb_sales_office_id]);

  const queryConstructionDate = async (start, end, enterprise_id, sales_office_id, partner_id) => {

    let response = { error: "", data: []};
    
    response = await SalesAggregationFunctions.getConstructionData({query_start_date: start, query_end_date: end, 
      token: token, mtb_enterprise_id: enterprise_id, mtb_sales_office_id: sales_office_id, mtb_partner_id: partner_id, storeData: storeData});
    
    if (response.error) {
      setErrorText(response.error);
    } else {
      //initialize sales data
      if (response.data.length === 0) {
        setShowingSalesData([]);
        return;
      };

      let _data = [];
      response.data.forEach((construction) => {
        let subtotal = construction.subtotal;
        if (construction.tax_include !== 0) {
          subtotal = subtotal - construction.tax_include;
        }
        let d = {
          id: construction.id,
          serial_no: construction.serial_no,
          construction_end_date: construction.construction_end_date,
          store_name: construction.store_name,
          customer_name: construction.customer_name,
          content: construction.content,
          total: construction.total,
          subtotal: subtotal,
          margin: construction.margin,
          margin_total: Math.floor(construction.total * construction.margin * 0.01),
          margin_subtotal: Math.floor(subtotal * construction.margin * 0.01),
          stamp_amount: construction.stamp_amount,
          stamp_price: construction.stamp_price,
          is_exported: construction.is_exported,
          mtb_enterprise_id: construction.mtb_enterprise_id,
          mtb_partner_id: construction.mtb_partner_id,
          mtb_sales_office_id: construction.mtb_sales_office_id,
          has_modified: false,
        };
        _data.push(d);
    });

    setShowingSalesData(_data);
    }
  };

  /*
    query and filtering handler ----------------------
  */

  /*
    工事データを取ってくる期間の変更する関数
  */
  const onChangeSearchingState = useCallback((key, value) => {
    //construction_end_date_start, construction_end_date_end
    const checkValidFilteringDate = (key, value) => {
      if (key === 'construction_end_date_start') {    // construction end date from (start) selected
        if (value < searchingState['construction_end_date_end']) return true;
        if (searchingState['construction_end_date_end'] === '') return true;
        return false;
      };
      // construction end date to (end) selected
      if (searchingState['construction_end_date_start'] < value) return true;
      if (searchingState['construction_end_date_start'] === '') return true;
      return false;
    };

    const _searchingState = { ...searchingState };

    if (key === 'construction_end_date_start' || key === 'construction_end_date_end') {
      //工事完了開始日、終了日
      if (checkValidFilteringDate(key, value)) {
        let selectedDate = value;
        if (value === '1970-01-01T00:00:00.000Z') selectedDate = '';
        _searchingState[key] = selectedDate;
        setErrorText('');
      } else {
        setErrorText('適切な日付を選択してください。');
      }
    } else {
      //企業、営業所、提携先
      const selectedValue = value ? parseInt(value) : 0;
      _searchingState[key] = selectedValue;
    }
    setSearchingState(_searchingState);
  },[setSearchingState, searchingState]);

  /*
    工事データを取ってくるAPIの呼び出しを賄う関数
  */
  const onSearchButtonPressed = useCallback(() => {

    const start = new Date(searchingState.construction_end_date_start);
    const end = new Date(searchingState.construction_end_date_end);
    const oneDay = 1000*60*60*24;
    const result = Math.ceil((end.getTime()-start.getTime())/(oneDay))

    if (result < 0 ) {
      setErrorText('適切な日付を選択してください。');
    } else if (result > queryDatesLimit) { 
      setIsDateSelectingWarningModelOpen(true);
    } else {
      queryConstructionDate(searchingState.construction_end_date_start, searchingState.construction_end_date_end, 
        searchingState.mtb_enterprise_id, searchingState.mtb_sales_office_id, searchingState.mtb_partner_id);
    }
  }, [searchingState, queryConstructionDate, setIsDateSelectingWarningModelOpen]);

  /*
    検索項目をリセットする関数
  */
  const onResetButtonClicked = useCallback(() => {

    setSearchingState({...defaultSearchingState.current});
    queryConstructionDate(defaultSearchingState.current.construction_end_date_start, defaultSearchingState.current.construction_end_date_end,
      defaultSearchingState.current.mtb_enterprise_id, defaultSearchingState.current.mtb_sales_office_id, defaultSearchingState.current.mtb_partner_id);

  }, [defaultSearchingState, setSearchingState]);

  /*
    モダル注意書きからの検索キャンセル
  */
  const onSearchCancelButtonPressed = useCallback(() => {
    setIsDateSelectingWarningModelOpen(false);
  }, [setIsDateSelectingWarningModelOpen]);

  /*
    365日以上の工事データ検索（モダル注意書きからInvoke）
  */
  const onSearchConfirmedButtonPressed = useCallback(() => {
    queryConstructionDate(searchingState.construction_end_date_start, searchingState.construction_end_date_end,
       searchingState.mtb_enterprise_id, searchingState.mtb_sales_office_id, searchingState.mtb_partner_id);
  }, [searchingState, queryConstructionDate]);

  /*
    --------------------------------------------------
  */

  /*
    csv and print data handler ----------------------
  */
  const getCSVData = (salesData, totalPrice) => {
    let headers = {};
    const totalRow = {};
    Object.keys(columnNames).forEach((key) => {
      if (calculatingColumns.includes(key)) {
        totalRow[key] = totalPrice[key].value;
      } else {
        totalRow[key] = null;
      }
      headers[key] = columnNames[key].label
    });
    let _csvData = [];
    salesData.forEach((row) => {
      let json = {};
      Object.keys(columnNames).forEach((key) => {
        if (key === 'content') {
          json[key] = String(row[key]).replaceAll(',', ' ');
        } else {
          json[key] = row[key];
        }
      });
      _csvData.push(json);
    })
    _csvData = [..._csvData, totalRow];
    setCSVData(_csvData)
    setCSVHeader(headers);
  }

  const getTotal = (salesData) => {
    //工事完了日のDateのフォーマットの変更と税込売上等の合計金額の算出
    let _total = {
      total: { label: '税込売上金額', value: 0 },
      subtotal: { label: '税抜売上金額', value: 0 },
      margin_total: { label: '税込明細金額', value: 0 },
      margin_subtotal: { label: '税抜明細金額', value: 0 },
      stamp_amount: { label: '印紙枚数', value: 0 },
      stamp_price: { label: '印紙金額', value: 0 },
    };

    salesData.forEach((salesRow) => {
      Object.keys(salesRow).forEach((columnName) => {
        if (calculatingColumns.includes(columnName)) {
          _total[columnName].value += salesRow[columnName];
        }
      })
    })
    setTotal(_total);
    return _total;
  };

  const updateShowingDataAndCSV = () => {
    //与えられた売上のデータでCSVに出力するでーたのアップデート
    const _total = getTotal(showingSalesData);
    getCSVData(showingSalesData, _total);
  };
  useMemo(updateShowingDataAndCSV, [showingSalesData]);

  const exportCSVData = useCallback(() => {
    new CSVFunctions(csvHeader, csvData, fileName).exportCSVData();
  }, [csvData, csvHeader, fileName]);

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
  });

  /*
    --------------------------------------------------
  */

  return (
    <BaseBodyStyle main>
      <SalesHeader isEditing={false} exportCSVData={exportCSVData} handlePrintOut={handlePrintOut} />
      <Separator />
      <QueryField>
        <SearchField 
          searchingState={searchingState}
          onChangeSearchingState={onChangeSearchingState}
          onResetButtonClicked={onResetButtonClicked}
          onSearchButtonPressed={onSearchButtonPressed}
        />
      </QueryField>
      <Modal isOpen={isDateSelectingWarningModalOpen} style={warningModalStyle}>
        <QueryDateWarningModal //一年以上の検索で表示される注意モダル
          onCancelButtonPressed={onSearchCancelButtonPressed}
          onSearchButtonPressed={onSearchConfirmedButtonPressed}
        />
      </Modal>
      <PrintingContent ref={componentRef}>
        <span style={{ padding: '4px 36px', color: Colors.errorText }}>
          {errorText && errorText}
        </span>
        <SalesTable
          data={showingSalesData}
          total={total}
        />
      </PrintingContent>
    </BaseBodyStyle>
  )
};


const Separator = styled.div`
  min-height: 80px;
  width: 100%;
  margin-top: 32px;
`
const QueryField = styled.div`
  align-self:  flex-start;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Icon = styled.img`
  padding-right: 16px;
  width: ${FontSize.xl};
  height: ${FontSize.xl};
  @media screen and (max-width: ${BreakPoints.xl}) {
    padding: 4px 0;
  }
`

export default SalesAggregation;