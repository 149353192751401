import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
//styles & config
import { Colors } from 'src/configs/StyleConfig';
//components
import Button from 'src/common/Button';
import { InputLabelStyle } from 'src/common/StylesComponents';
import { TextInput,TextHalfNumberInput, PostalCodeInput } from 'src/common/Inputs';
import DateSelector from 'src/common/DateSelector';

const ConstructionContract = (props) => {

  const { isEditable, isEditButtonDisabled, isRegister, contractInfo, changeInfo, onEditButtonPressed, navigation } = props;

  useEffect(() => {
    if (isRegister) {
      // let today = new Date();
      // today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0)).toISOString();
      // changeInfo('contract_date', today);
      // changeInfo('support_date', today);
    }
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <h4 style={{ width: '100px', paddingLeft: '10%' }}>
          契約情報
        </h4>
        {
          isEditable ?
            null
            :
            <Button disabled={!isEditButtonDisabled} text={'編集'} onClick={onEditButtonPressed} />
        }
      </HeaderContainer>
      <ContractContainer>
      <InputContainer>
        <TextContainer>
          <LabelStyle Selection align={'flex-start'}>
            対応日
          </LabelStyle>
          <div style={{width: '50%'}}>
            <DateSelector isEnable={isEditable} date={contractInfo.support_date} onChange={(date) => changeInfo('support_date', date)}/>
          </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle Selection align={'flex-start'}>
            契約日
          </LabelStyle>
          <div style={{width: '50%'}}>
            <DateSelector isEnable={isEditable} date={contractInfo.contract_date} onChange={(date) => changeInfo('contract_date', date)}/>
          </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle Selection align={'flex-start'}>
            契約書No
          </LabelStyle>
          <div style={{width: '50%'}}>
            <TextHalfNumberInput isEnable={isEditable} value={contractInfo.contract_no} onChange={(e) => changeInfo('contract_no', e.target.value)}/>
          </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle align={'flex-start'}>
            管理番号
          </LabelStyle>
          <div style={{width: '70%'}}>
            <TextHalfNumberInput isEnable={isEditable} value={contractInfo.serial_no} onChange={(e) => changeInfo('serial_no', e.target.value)}/>
          </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle align={'flex-start'}>
            施工先名
          </LabelStyle>
          <div style={{width: '70%'}}>
            <TextInput isEnable={isEditable} value={contractInfo.destination_name} onChange={(e) => changeInfo('destination_name', e.target.value)}/>
          </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle align={'flex-start'}>
            施工先郵便番号
          </LabelStyle>
            <div style={{width: '50%'}}>
              <PostalCodeInput 
                isEnable={isEditable}
                value={contractInfo.destination_postal_code} 
                onChange={(val) => changeInfo('destination_postal_code', val.target.value)}
                onChangeAddress={(address) => changeInfo('destination_address', address)}
              />
            </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle align={'flex-start'}>
            施工先住所
          </LabelStyle>
          <div style={{width: '70%'}}>
            <TextInput isEnable={isEditable} value={contractInfo.destination_address} onChange={(e) => changeInfo('destination_address', e.target.value)}/>
          </div>
        </TextContainer>
        <TextContainer>
          <LabelStyle align={'flex-start'}>
            施工先電話番号
          </LabelStyle>
          <div style={{width: '70%'}}>
            <TextHalfNumberInput isEnable={isEditable} value={contractInfo.destination_phone} onChange={(e) => changeInfo('destination_phone', e.target.value)}/>
          </div>
        </TextContainer>
      </InputContainer>
      <NoteContainer>
        <TextContainer style={{alignItems: 'flex-start'}}>
          <LabelStyle>
            備考
          </LabelStyle>
          {
            isEditable ? 
              <NoteTextView value={contractInfo.remarks || ''} onChange={(e) => changeInfo('remarks', e.target.value)} />
              :
              <NoteTextView value={contractInfo.remarks || ''} disabled />
          }
        </TextContainer>
        <ButtonContainer>
          <Button disabled={!isEditButtonDisabled} text={'顧客履歴へ'} onClick={navigation} />
        </ButtonContainer>
      </NoteContainer>
    </ContractContainer>
    </Container>

  )
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HeaderContainer = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ContractContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const InputContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
`
const LabelStyle = styled(InputLabelStyle)`
  font-size: 14px;
  font-weight: 500;
  width: 100px;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 84px;
`
const NoteContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
`
const NoteTextView = styled.input`
  width: 100%;
  height: 60px;
  text-align: left;
  :disabled {
    background-color: white;
    border-radius: 2px;
    border: 1px solid ${Colors.textColor};
    color: ${Colors.textColor};
  }
`
ConstructionContract.propTypes = {
  isEditable: PropType.bool.isRequired, //詳細ページではfalse,　編集ページではtrue
  isEditButtonDisabled: PropType.bool, //role === 3 | 2 は営業所Idによってtrue
  isRegister: PropType.bool.isRequired,
  contractInfo: PropType.object.isRequired,
  changeInfo: PropType.func.isRequired,
  onEditButtonPressed: PropType.func
};

ConstructionContract.defaultProps = {
  isEditable: false,
  isEditButtonDisabled: false,
  isRegister: false,
  contractInfo: {},
  changeInfo: () => { },
  onEditButtonPressed: () => { },
};


export default ConstructionContract;