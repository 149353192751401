import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { Colors, FontSize, BreakPoints } from 'src/configs/StyleConfig';
import { calculatingColumns, columnNames } from '../SalesAggregation';
//images
import CheckMark from 'src/static/checkmark.png';
//functions
import PriceFunctions from 'src/functions/PriceFunctions';
import DateFunctions from 'src/functions/DateFunctions';

const SalesTable = (props) => {
  const { data, total } = props;

  const showDepositStatus = useCallback((is_exported) => {
    if (is_exported) {
      return <img src={CheckMark} alt="Yes" style={{ height: '80%', width: '80%' }} />
    }
    return null;
  }, []);

  const Row = (row) => {
    const data = row.row;
    return (
      <Tr>
        {
          Object.keys(data).map((columns) => {
            if (Object.keys(columnNames).includes(columns)) {
              if (columns === 'is_exported') {
                const isChecked = data['is_exported'] === 1 ? true : false;
                return (
                  <Td width={columnNames[columns].width} align={columnNames[columns].align} isLastColumn={true} key={columns}>
                    <Icon>
                      {showDepositStatus(isChecked)}
                    </Icon>
                  </Td>
                )
              } else if (calculatingColumns.includes(columns)) {
                return (
                  <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                    {PriceFunctions.intPriceToStringPrice(data[columns])}
                  </Td>
                )
              } else if (columns === 'construction_end_date') {
                return (
                  <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                    {DateFunctions.ISOStringDateToLocalDate(data[columns])}
                  </Td>
                )
              } else if (columns === 'serial_no') {
                return (
                  <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                    {data[columns]}
                  </Td>
                )
              }
              return (
                <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                  {data[columns]}
                </Td>
              )
            }
          })
        }
      </Tr >
    )
  };

  return (
    <Container>
      <Table>
        <Thead>
          <tr>
            {
              Object.keys(columnNames).map((cName) => {
                return (
                  <Th width={columnNames[cName].width} align={'center'} key={cName} >{columnNames[cName].label}</Th>
                );
              })
            }
          </tr>
        </Thead>
        <TableBody>
          {
            data && data.map((row) => {
              return <Row row={row} key={row.id.toString()} />
            })
          }
        </TableBody>
        <TableTotalBody>
          <Tr>
            {
              Object.keys(columnNames).map((columnName) => {
                return <TotalSection total={total} col={columnName} key={columnName.toString()} />
              })
            }
          </Tr>
        </TableTotalBody>
      </Table>
    </Container>
  )
};

const TotalSection = (props) => {
  const { col, total } = props;

  if (calculatingColumns.includes(col)) {
    return (
      <Td align={columnNames[col].align} width={columnNames[col].width} colspan="1">
        {total[col] && PriceFunctions.intPriceToStringPrice(total[col].value)}
      </Td>
    )
  } else if (col === 'is_exported') {
    return <Td width={columnNames[col].width} isLastColumn={true} />
  }
  return <Td width={columnNames[col].width} />

};

const Container = styled.div`
  width: 100%;
  min-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
`
const Table = styled.table`
  width: 95%;
  border-collapse: collapse;
  padding: 20px auto;
`
const Th = styled.th`
  color: #ffffff;
  font-size: ${FontSize.sm};
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
  padding: 12px 0px;
  @media screen and (max-width: ${BreakPoints.md}) {
    font-size: 10px;
    font-weight: ${FontSize.xs};
  }
`
const Thead = styled.thead`
  width: 100%;
  table-layout: fixed;
  display: table;
  background-color: #3f3f3f;
  border: 1.5px solid black;
  height: 36px;
`
const TableBody = styled.tbody`
  border: 1px solid gray;
  min-height: 150px;
  width: 100%;
  overflow: auto;
  display: block;
`
const TableTotalBody = styled.tbody`
  border: 1px solid gray;
  border-top: 3px solid gray;
  width: 100%;
  overflow: auto;
  display: block;
`
const Tr = styled.tr`
  width: 100%;
  height: 32px;
  table-layout: fixed;
  display: table;
  border-bottom: 1px solid lightgray;
  font-size: ${FontSize.sm};
  @media screen and (max-width: ${BreakPoints.xl}) {
    font-size: 11px;
  }
  @media screen and (max-width: ${BreakPoints.lg}) {
    font-size: 9px;
  }
  @media screen and (max-width: ${BreakPoints.md}) {
    font-size: 8px;
  }

  @media print {
    font-size: 9px;
  }
`
const Td = styled.td`
  color: ${Colors.textColor};
  font-weight: 500;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
  border-right: ${props => props.isLastColumn ? 'none' : '1px solid lightgray'};
  padding: 0 4px;

`
const Icon = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  @media screen and (max-width: ${BreakPoints.xl}) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: ${BreakPoints.md}) {
    width: 12px;
    height: 12px;
  }
  @media print {
    width: 12px;
    height: 12px;
  }
`

SalesTable.propTypes = {
  data: PropTypes.array.isRequired,
  total: PropTypes.object.isRequired
};
SalesTable.defaultProps = {
  data: [],
  total: {}
};

export default SalesTable;