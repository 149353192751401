import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
//static, styles and configs
import { Container, Table, ColumnNameWrapper, Th, Td, MultiLine, BackToSettingButton } from 'src/views/settings/children/MtbStyles';
import { SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { Colors } from 'src/configs/StyleConfig';
import StarSelected from 'src/static/star.png';
import StarUnselected from 'src/static/starBlack.png';

const columnNames = {
  product_code: { label: '商品コード', width: '20%', align: 'left' },
  product_name: { label: '商品名', width: '24%', align: 'left' },
  unit: { label: '単位', width: '20%', align: 'left' },
  price: { label: '単価', width: '24%', align: 'left' },
  is_favorite: { label: 'お気に入り', width: '12%', align: 'center' }
};
const stackColumns = {
  product_code: ['product_code', 'jan_code']
};

/*
  商品お気に入り登録を表示するテーブルコンポーネント
*/
const ProductFavoriteTable = (props) => {

  const { data, changeData, errorText, isEditing, onEditButtonPressed, onSaveButtonPressed, onCancelButtonPressed } = props
  const history = useHistory();

  const showFavorite = (favoriteStatus) => {
    if (favoriteStatus === 1) {
      return <FavoriteIconContainer><IMG src={StarSelected} alt={"Favorite"} /></FavoriteIconContainer>
    }
    return <FavoriteIconContainer><IMG src={StarUnselected} alt={""} /></FavoriteIconContainer>
  }

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr isEditing={isEditing} onClick={() => changeData(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            if (columnName === 'product_code') {
              const columns = stackColumns[columnName];
              return (
                <Td key={columnName} width={columnNames[columnName].width}>
                  <MultiLine>
                    {data[columns[0]]}
                  </MultiLine>
                  <MultiLine>
                    {data[columns[1]]}
                  </MultiLine>
                </Td>
              )
            } else if (columnName === 'is_favorite') {
              return (
                <Td key={columnName} width={columnNames[columnName].width} align={columnNames[columnName].align}>
                  {showFavorite(data['is_favorite'])}
                </Td>
              )
            }
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <FavoriteEditButtonBox>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </FavoriteEditButtonBox>
          :
          <FavoriteEditButtonBox>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </FavoriteEditButtonBox>
      }
      <CustomTable>
        <CustomColumnNameWrapper>
          {
            Object.keys(columnNames).map((cName) => {
              if (cName === 'product_code') {
                return (
                  <Th width={columnNames[cName].width} key={cName}>
                    <HeaderMultiLine>
                      商品コード
                    </HeaderMultiLine>
                    <HeaderMultiLine>
                      JANコード
                    </HeaderMultiLine>
                  </Th>
                )
              }
              return <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>
            })
          }
        </CustomColumnNameWrapper>
        <TBody>
          {
            Object.keys(data).length > 0 && Object.keys(data).map((key) => {
              return <Row data={data[key]} id={key} key={data[key].id} />
            })
          }
        </TBody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  align-self: center;
  width: 100%;
  min-width: 700px;
  max-width: 900px;
  border: none;
`

const TBody = styled.tbody`
  border-bottom: 1.2px solid lightgray;
`

const CustomColumnNameWrapper = styled(ColumnNameWrapper)`
  border-top: none;
`

const HeaderMultiLine = styled(MultiLine)`
  font-size: 14px;
  font-weight: 600;
`

const FavoriteIconContainer = styled.div`
  width: 20px;
  height: 20px;
  padding-left: 20px;
`

const IMG = styled.img`
  width: 100%;
  height: 100%;
`

const Tr = styled.tr`
  height: 60px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  cursor: ${props => props.isEditing ? 'pointer' : 'default'};
  background-color: white;
  :hover {
    background-color: ${props => props.isEditing ? Colors.boxHighlight : 'white'};
  }
  :after {
    content: "";
    display: block;
  }
`

const FavoriteEditButtonBox = styled.div`
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
`

ProductFavoriteTable.propTypes = {
  data: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired,
  changeData: PropTypes.func.isRequired,
  onSaveButtonPressed: PropTypes.func.isRequired,
  onCancelButtonPressed: PropTypes.func.isRequired,
  onEditButtonPressed: PropTypes.func.isRequired
};

ProductFavoriteTable.defaultProps = {
  data: {},
  isEditing: false,
  errorText: '',
  changeData: () => { },
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { }
};

export default ProductFavoriteTable;
