import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
//styles & configs
import { BaseBodyStyle } from 'src/common/StylesComponents';
//components
import { GeneralWarrantyEdit, DamageWarrantyInput } from 'src/views/warranty/warrantiesEdit/children';
import Button from 'src/common/Button';
//redux
import { useSelector } from 'react-redux';
//style and configs
import { FontSize } from 'src/configs/StyleConfig';
import { warrantyCategoryMap } from 'src/views/warranty/warranty/Warranty';

/*
  損害賠償保証証の詳細表示コンポーネント
*/
const DamageWarrantyDetail = () => {

  const history = useHistory();
  const { selectedWarranty } = useSelector(state => state.warranty);
  const [warranty, setWarranty] = useState({});
  const [warrantyName, setWarrantyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (selectedWarranty) {
      const _warrantyName = warrantyCategoryMap[selectedWarranty.category];
      setWarrantyName(_warrantyName);
      setWarranty({...selectedWarranty});
      setIsLoading(false);
    }
  }, [selectedWarranty]);

  const onBuckButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isLoading) {
    <BaseBodyStyle />
  }
  return (
    <BaseBodyStyle>
      <TitleWrapper>
        <span style={{fontSize: FontSize.xl, fontWeight: 500}}>
          {warrantyName}
        </span>
      </TitleWrapper>
      <InputSection>
        <GeneralWarrantyEdit generalWarrantyInfo={warranty} isEditable={false} isDamage={true} />
        <DamageWarrantyInput damageWarranty={warranty} isEditable={false} />
      </InputSection>
      <ButtonWrapper>
        <Button text={"戻る"} onClick={onBuckButtonPressed}/>
      </ButtonWrapper>
    </BaseBodyStyle>
  )
};
const TitleWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 4px;
`
const InputSection = styled.div`
  width: 100%;
  display: block;
  flex-direction: row;
  justify-content: space-between;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  margin: auto;
`
export default DamageWarrantyDetail;