import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//configs and styles
import { BaseBodyStyle } from 'src/common/StylesComponents';
//functions
import { getUpdatedLogContent } from 'src/functions/LogFunctions';
import DateFunctions from 'src/functions/DateFunctions';
import GeneralFunctions from 'src/functions/GeneralFunctions';
//components
import { CustomerLog, CustomerSupportLog, ConstructionLog } from './children';
//redux
import { useSelector } from 'react-redux';

const dateColumns = ['contract_date', 'construction_start_date', 'construction_end_date', 'support_date', 'support_complete_date'];

const ChangeLogDetail = () => {

  const location = useLocation();
  const { state } = location;
  const { token } = useSelector(state => state.auth);
  const { customerList } = useSelector(state => state.customer);
  const { salesOfficeData, partnerData, paymentMethods, storeData } = useSelector(state => state.master);

  const [title, setTitle] = useState('');
  const [salesOfficeNames, setSalesOfficeNames] = useState(null);
  const [partners, setPartners] = useState(null);
  const [payments, setPayments] = useState(null);
  const [storeNames, setStoreNames] = useState(null);
  const [customerNames, setCustomerNames] = useState(null);
  const [dataBeforeChange, setDataBeforeChange] = useState({});
  const [dataAfterChange, setDataAfterChange] = useState({});
  const [changedDate, setChangedDate] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  const [tableType, setTableType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const initAllSelectionProperties = () => {
      const salesOfficeObj = getIdKeyNameObject(salesOfficeData, 'sales_office_name');
      const partnerObj = getIdKeyNameObject(partnerData, 'partner_name');
      const paymentMethodsObj = getIdKeyNameObject(paymentMethods, 'payment_method');
      const storeObj = getIdKeyNameObject(storeData, 'store_name');
      const customerObj = getIdKeyNameObject(customerList, 'customer_name');
      setSalesOfficeNames(salesOfficeObj);
      setPartners(partnerObj);
      setPayments(paymentMethodsObj);
      setStoreNames(storeObj);
      setCustomerNames(customerObj);
    };


    if (token && state) {
      initAllSelectionProperties();
    }

  }, [token, state, salesOfficeData, partnerData, paymentMethods, storeData, customerList]);

  useEffect(() => {
    const initLogContent = async () => {
      const { data } = state;
      const { table_type, log_id, remarks, created_at, created_employee_name } = data;
      const result = await getUpdatedLogContent(table_type, log_id, token);
      if (result) {
        const {before, after} = result;
        const dataBefore = {};
        const dataAfter = {};
        Object.keys(before).forEach((column) => {
          if (column !== 'id' && column !== 'created_at' && column !== 'updated_at' && column !== 'updated_employee_name') {
            let _before = null;
            let _after = null;
            if (dateColumns.includes(column)) {
              _before = DateFunctions.ISOStringDateToLocalDate(before[column]);
              _after = DateFunctions.ISOStringDateToLocalDate(after[column]);
            } else if (column === 'mtb_sales_office_id') {
              _before = salesOfficeNames[before[column]];
              _after = salesOfficeNames[after[column]];
            } else if (column === 'mtb_partner_id') {
              _before = partners[before[column]];
              _after = partners[after[column]];
            } else if (column === 'mtb_store_id') {
              _before = storeNames[before[column]];
              _after = storeNames[after[column]];
            } else if (column === 'mtb_payment_method_id') {
              _before = payments[before[column]];
              _after = payments[after[column]];
            } else if (column === 'mtb_customer_id') {
              _before = customerNames[before[column]];
              _after = customerNames[after[column]];
            } else {
              _before = typeof before[column] === 'string' ? before[column].trim() : before[column];
              _after = typeof after[column] === 'string' ? after[column].trim() : after[column];
            }
            
            // !== だけだと前後のデータがnull vs ''の時も更新された事になり表示されてしまうので、NullとEmptyでチェック
            // null vs null or '' vs '' --> nullVsEmptyCheck がtrue、 _before !== _after で弾かれる
            // null vs '' ---> nullVsEmptyCheck がtrue、!nullVsEmptyCheckで弾かれる
            const nullVsEmptyCheck = GeneralFunctions.isNullOrEmpty(_before) && GeneralFunctions.isNullOrEmpty(_after) ? true : false;
            if (_before !== _after && !nullVsEmptyCheck) {
              //更新されたデータのみ格納
              dataBefore[column] = _before;
              dataAfter[column] = _after;
            }
          }
        });
        setDataBeforeChange(dataBefore);
        setDataAfterChange(dataAfter);
      }
      setTableType(table_type);
      setChangedDate(created_at);
      setUpdatedBy(created_employee_name);
      setTitle(remarks);
      setIsLoading(false);
    };
    
    if (salesOfficeNames && payments && partners && storeNames && customerNames) {
      initLogContent();
    }
  }, [salesOfficeNames, payments, partners, storeNames, customerNames, state, token]);


  const getIdKeyNameObject = (obj, valKey) => {
    if (obj instanceof Array) {
      if (obj.length === 0) return {};
      let json = {};
      obj.forEach((row) => {
        json[row.id] = row[valKey];
      });
  
      return json;
    } else {
      if (Object.keys(obj).length === 0) return {};
      let json = {};
      Object.keys(obj).forEach((key) => {
        json[obj[key].id] = obj[key][valKey];
      });
      
      return json;
    }
  }


  const showUpdateContent = () => {
    if (!tableType) return

    if (tableType === 1) { //顧客ログ
      return <CustomerLog dataAfterChange={dataAfterChange} dataBeforeChange={dataBeforeChange} />
    } else if (tableType === 2) { //工事ログ
      return <ConstructionLog dataAfterChange={dataAfterChange} dataBeforeChange={dataBeforeChange} />
    } else if (tableType === 3) { //対応履歴ログ
      return <CustomerSupportLog dataAfterChange={dataAfterChange} dataBeforeChange={dataBeforeChange} />
    }
    return null
  };


  if (isLoading) {
    return (
      <BaseBodyStyle main>
      <div style={{ width: '96%', paddingLeft: '40px', display: 'flex', flexDirection: 'column' }}>
        <h2 style={{ paddingBottom: '20px' }}>変更内容</h2>
        Loading...
      </div>
      </BaseBodyStyle>
    )
  }

  return (
    <BaseBodyStyle main>
    <div style={{ width: '96%', paddingLeft: '40px', display: 'flex', flexDirection: 'column' }}>
      <h2 style={{ paddingBottom: '20px' }}>変更内容: {title}</h2>
      {showUpdateContent()}
      <div>
        <span>変更日時：</span><span>{DateFunctions.ISOStringDateToLocalDate(changedDate)}</span>
      </div>
      <div>
        <span>変更者：</span><span>{updatedBy}</span>
      </div>
    </div>
    </BaseBodyStyle>
  )
};



export default ChangeLogDetail;