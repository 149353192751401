import {
  SET_CUSTOMER_LIST_DATA,
  SET_CUSTOMER_LIST_DATA_FAILED,
  SET_SELECTED_CUSTOMER_DATA,
  SET_SELECTED_CUSTOMER_DATA_FAILED,
  SET_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA,
  SET_SELECTED_CUSTOMER_ID,
  UPDATE_SELECTED_CUSTOMER_DATA,
  REMOVE_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA,

  REMOVE_CUSTOMER_DATA
} from 'src/redux/actions/CustomerDataHandler';

const initialState = {
  customerList: [],
  selectedCustomerData: {},
  selectedCustomerId: null,
  constructionAndCustomerSupportDataList: [],
  processedCustomerData: {}, // 対応履歴等に渡すモデルに格納済みの顧客詳細情報
  isEditing: false,
};

const CustomerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_LIST_DATA:
      return {
        ...state, 
        customerList: action.payload.data
      };
    case SET_CUSTOMER_LIST_DATA_FAILED:
      return {
        customerList: [],
        selectedCustomerData: {},
        isEditing: false,
      };
    case SET_SELECTED_CUSTOMER_ID:
      return {
        ...state,
        selectedCustomerId: action.payload.customer_id
      }
    case SET_SELECTED_CUSTOMER_DATA:
      // const { selectedCustomerID, selectedCustomerData } = ;
      return {
        ...state,
        // selectedCustomerId: selectedCustomerID,
        selectedCustomerData: action.payload.selectedCustomerData,
      };
    case SET_SELECTED_CUSTOMER_DATA_FAILED:
      return {
        ...state,
        selectedCustomerData: {},
      };
    case SET_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA:
      return {
        ...state,
        constructionAndCustomerSupportDataList: action.payload.data
      };
    case REMOVE_CONSTRUCTION_AND_CUSTOMER_SUPPORT_DATA:
      return {
        ...state,
        selectedCustomerData: {},
        constructionAndCustomerSupportDataList: []
      };
    case UPDATE_SELECTED_CUSTOMER_DATA:
      const { payload } = action;
      return {
        ...state,
        customerList: [...payload.customerList],
        selectedCustomerData: { ...payload.selectedCustomerData },
      };
    case REMOVE_CUSTOMER_DATA: //logout 時に呼び出す
      return {
        customerList: [],
        selectedCustomerData: {},
        selectedCustomerId: null,
        constructionAndCustomerSupportDataList: [],
        isEditing: false
      };
    default:
      return state;
  }
};

export default CustomerDataReducer;