import axios from 'axios';
import { CUSTOMER_SUPPORT_CHANGE_URL } from 'src/apis';
//functions
import GeneralFunctions from './GeneralFunctions';

const customerSupportProperties = ["id", "support_date", "support_complete_date", "support_employee_name1",
"support_employee_name2", "support_employee_name3", "support_employee_name4", "remarks",
"destination_name", "destination_postal_code", "destination_address", "destination_phone",  "mtb_sales_office_id", "mtb_partner_id", 
"content", "detail1", "detail2", "support_detail", "mtb_customer_id", "created_employee_name", "updated_employee_name"];

const requiredField = {
  mtb_partner_id: "提携先", mtb_sales_office_id: "営業所", support_date: "対応日", 
}

/*
顧客対応データの登録/編集
工事詳細データ（工事内訳）の登録/編集
- Parameters in constructor:
 - customerSupportData: 顧客対応データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: DBに登録/編集/消去した顧客対応データ
 - logs: DBに登録/編集した顧客対応データのinsertIdなどを含むデータ
*/
class CustomerSupportFunctions {

  constructor(props) {
    const { customerSupportData, token } = props;
    this.customerSupportData = customerSupportData;
    this.token = token;
    this.response = {error: '', data: {}, logs: {}};
  };

   /*
    private method
    顧客対応データの必須項目、選択肢チェック
    mtb_partner_id: "提携先", mtb_sales_office_id: "営業所"が０の場合はエラーを返す
    support_date: "対応日"がnullの場合はエラーを返す
  */
  _validation() {
    //顧客対応データの必要な項目チェック
    const supportData = {...this.customerSupportData};
    let errors = [];
    Object.keys(requiredField).map((requiredKey) => {
      if (requiredKey === "mtb_partner_id" || requiredKey === "mtb_sales_office_id") {
        if (supportData[requiredKey] === '0') errors.push(`${requiredField[requiredKey]}を選択してください。`);
      }
      if (GeneralFunctions.isNullOrEmpty(supportData[requiredKey])) {
        errors.push(`${requiredField[requiredKey]}を選択してください。`);
      }
    });
    
    if (errors.length > 0) return errors[0];

    return;
  };

  /*
    private method
    DBに顧客対応データを送信
  */
  async _submitCustomerSupportData(isRegistering) {

    const data = {};
    customerSupportProperties.map((key) => {
      if (key === 'mtb_partner_id' || key === 'mtb_sales_office_id') {
        data[key] = parseInt(this.customerSupportData[key])
      } else {
        data[key] = this.customerSupportData[key]
      }
    });
    if (!isRegistering) {
      const updated_at = new Date().toISOString();
      data["updated_at"] = updated_at;
    }
    const json = [data];
    this.response.data = {...data};
    try {
      await axios.post(CUSTOMER_SUPPORT_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("データを送信できませんでした。");
      })
      .then(({data}) => {
        this.response.logs = data;
        return;
      })
    } catch (error) {
      return error.message;
    }
  };

  /*
    public method
    外部から呼び出すメソッド
    顧客対応データ登録時のみ呼び出す
  */
  async register() {

    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    const isRegistering = true;
    error = await this._submitCustomerSupportData(isRegistering);
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  };

  /*
    public method
    外部から呼び出すメソッド
    顧客対応データ編集時のみ呼び出す
  */
  async update() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    const isRegistering = false;
    error = await this._submitCustomerSupportData(isRegistering);
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;
  };

};

export default CustomerSupportFunctions;