import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
//components
import { BaseHeaderStyle, HeaderTitle, HeaderRight, SubmitButton } from 'src/common/StylesComponents';
import Button from 'src/common/Button';

const CustomerDetailHeader = (props) => {

  const { onPrintButtonPressed, onRegisterButtonPressed } = props;

  return (
    <BaseHeaderStyle>
      <HeaderTitle>
        顧客履歴
      </HeaderTitle>
      <HeaderRight>
        <PrintButton onClick={onPrintButtonPressed}>印刷<i className="fa fa-print" style={{ paddingLeft: '8px', fontSize: '12px' }} /></PrintButton>
        <SubmitButton onClick={onRegisterButtonPressed}>新規顧客登録</SubmitButton>
      </HeaderRight>
    </BaseHeaderStyle>
  )
};

CustomerDetailHeader.propTypes = {
  onPrintButtonPressed: PropTypes.func.isRequired,
  onRegisterButtonPressed: PropTypes.func.isRequired
};

CustomerDetailHeader.defaultProps = {
  onPrintButtonPressed: () => { },
  onRegisterButtonPressed: () => { }
};

export const PrintButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export default CustomerDetailHeader;