import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { FontSize } from 'src/configs/StyleConfig';

const customerSupportColumnNames = {
  support_date: '対応日',
  support_complete_date: '対応完了日',
  support_employee_name1: '対応担当者1',
  support_employee_name2: '対応担当者2',
  support_employee_name3: '対応担当者3',
  support_employee_name4: '対応担当者4',
  remarks: '備考',
  destination_name: '施工先名',
  destination_postal_code: '施工先郵便番号',
  destination_address: '施工先住所',
  destination_phone: '施工先電話番号',
  mtb_sales_office_id: '営業所',
  mtb_partner_id: '提携先',
  content: '内容',
  detail1: '詳細1',
  detail2: '詳細2',
  support_detail: '詳細',
  mtb_customer_id: '顧客'
}

const CustomerSupportLog = (props) => { 

  const { dataBeforeChange, dataAfterChange } = props;

  return (
    <div>
      {
        Object.keys(dataBeforeChange).length > 0 && Object.keys(dataBeforeChange).map((column) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: 380, width: '80%'}} key={column}>
              <Column>{customerSupportColumnNames[column]}</Column>
              <Column>{dataBeforeChange[column]}</Column>
              <Column>{dataAfterChange[column]}</Column>
            </div>
          )
        })
      }
    </div>
  )
};

const Column = styled.p`
  width: 200px;
  font-size: ${FontSize.md};
`

CustomerSupportLog.propTypes = {
  dataBeforeChange: PropTypes.object.isRequired,
  dataAfterChange: PropTypes.object.isRequired
};
CustomerSupportLog.defaultProps = {
  dataBeforeChange: { },
  dataAfterChange: { }
};

export default CustomerSupportLog;