import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
//components
import { BaseBodyStyle } from 'src/common/StylesComponents';
import { CustomerSupportHeader, CustomerSupportStatus, CustomerSupportContent} from 'src/views/customerSupport/common';
import Button from 'src/common/Button';
//redux
import {useSelector} from 'react-redux';


const CustomerSupportDetail = () => {

  const history = useHistory();
  const location = useLocation();
  const { selectedCustomerData } = useSelector(state => state.customer);
  const { selectedCustomerSupportData } = useSelector(state => state.customerSupport);
  const [customerInfo, setCustomerInfo] = useState(
    {
      id: null, customer_code: '', enterprise: '', sales_office: '', customer_kana: '', customer_name: '',
      customer_address1: '', customer_address2: '', customer_postal_code: '', customer_phone1: '', customer_phone2: '',
      mtb_enterprise_id: null, mtb_sales_office_id: null,
      status: [     
      { id: 0, status: '対応不可', isSelected: false },
      { id: 1, status: '信販不可', isSelected: false },
      { id: 2, status: '転居', isSelected: false },
      { id: 3, status: 'クレーム', isSelected: false }
      ]
    }
  );

  useEffect(() => {
    if (selectedCustomerSupportData) {
      initCustomerData();
    }
  }, [selectedCustomerSupportData]);

  const initCustomerData = () => {
    const initialCustomerInfo = {...customerInfo};
    Object.keys(initialCustomerInfo).map((key) => {
      if (key === 'status') {
        const statusList = selectedCustomerData[key];
        const status = customerInfo['status'];
        const initialStatus = status.map((row) => {
          if (statusList.includes(row.id)) {
            return {
              ...row,
              isSelected: true
            };
          }
          return {...row};
        });
        initialCustomerInfo[key] = initialStatus;
      } else {
        initialCustomerInfo[key] = selectedCustomerData[key]
      }
    });
    setCustomerInfo(initialCustomerInfo);
  };

  const onEditButtonPressed = useCallback(() => {
    const pathName = location.pathname;
    history.push({ pathname: `${pathName}/edit`, state: { customerData: customerInfo }});
  }, [history, customerInfo]);


  return (
    <BaseBodyStyle main>
      <CustomerSupportHeader />
      <CustomerSupportStatus customerInfo={customerInfo} />
      <EditButtonWrapper>
        <Button text={"編集"} onClick={onEditButtonPressed}/>
      </EditButtonWrapper>
      <CustomerSupportContent supportInfo={selectedCustomerSupportData} isEditable={false}/>
    </BaseBodyStyle>
  )
};

const EditButtonWrapper = styled.div`
  width: 99%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`

export default CustomerSupportDetail;