import React, { useCallback, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from "react-router-dom";
//styles & configs
import { BaseBodyStyle, ButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';
//components
import { ConstructionInfo, ConstructionContract, ConstructionBreakdown, CustomerStatusSection } from 'src/views/construction/common';
import DialogMessage from 'src/common/DialogMessage';
//functions
import ConstructionFunctions from 'src/functions/ConstructionFunctions';
import CustomerFunction from 'src/functions/CustomerFunctions';
import { logCategory, logTableType, sendLogData } from 'src/functions/LogFunctions';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { removeSelectedConstructionDetailData } from 'src/redux/actions/ConstructionDataHandler';



const ConstructionDetailEdit = () => {

  const location = useLocation();
  const { data, customerData, margin, allowMarginUpdate } = location.state;
  const history = useHistory();
  const dispatch = useDispatch();
  const { partnerData } = useSelector(state => state.master);
  const { token, mtb_enterprise_id, employee_name } = useSelector(state => state.auth);

  const [constructionInfo, setConstructionInfo] = useState(data);
  const [customerInfo, setCustomerInfo] = useState(customerData);
  const [errorText, setErrorText] = useState('');
  const partnerMargin = useRef(margin);
  const modalRequired = useRef(false);
  const hasCustomerStatusChanged = useRef(false);
  const deletingConstructionDetail = useRef([]);

  const getPartnerMargin = useCallback((mtb_partner_id) => {
    // ドロップダウンにて提携先を選択後、マージンに関わる値、（小計xマージン）等を変更し、変数に格納
    if (partnerData) {
      const margin = partnerData[mtb_partner_id].margin ? parseInt(partnerData[mtb_partner_id].margin) : 0;
      const updatedTotal = { ...constructionInfo["工事内訳"].tableContents.breakdownTotal };
      updatedTotal['subtotal_margin']['value'] = updatedTotal['subtotal']['value'] * margin * 0.01;
      const _constructionInfo = { ...constructionInfo };
      _constructionInfo["その他情報"]['margin'] = margin;
      setConstructionInfo(_constructionInfo);
      changeTableInfo('breakdownTotal', updatedTotal);
      partnerMargin.current = margin;
    }
  }, [constructionInfo, partnerData]);

  const onCalculateMargin = useCallback(() => {
    // allowMarginUpdateによる提携先のマージンデータが過去に登録した工事に紐づくマージンデータと比較し、
    // 二つのマージンデータが異なる場合に表示するマージン再計算ボタンをユーザーが押した時に着火する関数
    // マージンデータをupdateする
    const { mtb_partner_id } = constructionInfo['工事情報'];
    getPartnerMargin(mtb_partner_id)
  }, [constructionInfo]);

  const changeContractInfo = useCallback((key, val) => {
    // 契約情報のアップデート
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["契約情報"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  const changeConstructionInfo = useCallback((key, val) => {
    // 工事情報のアップデート
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["工事情報"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  const changeTableInfo = useCallback((key, val) => {
    // 工事内訳テーブルのアップデート関数
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["工事内訳"]["tableContents"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  const changeTaxInfo = useCallback((key, val) => {
    // 工事内訳テーブル横の消費税率アップデート関数
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["工事内訳"]["taxInfo"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  // 顧客のステータス変更関数
  const changeCustomerStatus = useCallback((id) => {
    const _customerInfo = { ...customerInfo };
    _customerInfo['status'][id].isSelected = !_customerInfo["status"][id].isSelected;
    setCustomerInfo(_customerInfo);
    if (!hasCustomerStatusChanged.current) hasCustomerStatusChanged.current = true;
  }, [customerInfo]);

  const handleNavigation = useCallback((navigationKey) => {
    if (navigationKey === "FILE_UPLOAD") {
      //ファイル取り込みページへ推移
      history.push({ pathname: '/file-upload', state: { isEditable: true } });
    } else {
      history.push({ pathname: '/warranty', state: { isEditable: true } });
    }
  }, [constructionInfo]);

  const changeUnsavedStatus = () => {
    if (modalRequired.current) return;
    modalRequired.current = true;
  };
  useMemo(changeUnsavedStatus, [constructionInfo]);

  const addDeletingConstructionDetail = useCallback((deletingId) => {
    //消去する工事詳細の格納
    deletingConstructionDetail.current = [...deletingConstructionDetail.current, deletingId];
  }, [deletingConstructionDetail]);

  const onSaveButtonPressed = useCallback(async () => {
    //顧客ステータスが変更されていたのであれば顧客アップデートAPIと工事編集APIを呼び出す
    //それ以外：工事編集APIのみ呼び出す

    const constructionFunctions = new ConstructionFunctions({ constructionInfo: constructionInfo, deletingConstructionDetail: deletingConstructionDetail.current, token: token });
    const response = await constructionFunctions.update();

    if (typeof response === "object" && response.error) {
      setErrorText(response.error);
    } else {
      const { insertLogId } = response.logs;
      const constructionLog = {
        id: null,
        category: logCategory.update,
        remarks: '工事情報更新',
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: logTableType.construction,
        log_id: insertLogId,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      }
      const json = [constructionLog];
      await sendLogData(json, token);
      modalRequired.current = false;
      setErrorText('');
      if (hasCustomerStatusChanged.current) {
        const customerFunctions = new CustomerFunction({ customerInfo: customerInfo, token: token });
        const customerResponse = await customerFunctions.updateData();
        if (typeof customerResponse === "object" && customerResponse.error) {
          setErrorText(customerResponse.error);
        } else {
          const { insertLogId } = customerResponse.logs;
          const customerLog = {
            id: null,
            category: logCategory.update, //更新ログ
            remarks: '顧客情報更新',
            mtb_enterprise_id: mtb_enterprise_id,
            table_type: logTableType.customer, //顧客ログ
            log_id: insertLogId,
            created_employee_name: employee_name,
            updated_employee_name: employee_name
          };
          const json = [customerLog];
          await sendLogData(json, token);
        }
      }
      dispatch(removeSelectedConstructionDetailData());
      history.goBack();
    }
  }, [constructionInfo, deletingConstructionDetail, hasCustomerStatusChanged, customerInfo, token, history, modalRequired, mtb_enterprise_id, employee_name]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <div style={{ width: '100%' }}>
        <div style={{ width: '83%', display: 'flex', margin: '0 auto' }}>
          <CustomerStatusSection status={customerInfo.status} changeStatus={changeCustomerStatus} />
        </div>
        <ConstructionContract isEditable={true} isRegister={false} contractInfo={constructionInfo["契約情報"]} changeInfo={changeContractInfo} />
        <ConstructionInfo
          isEditable={true}
          constructionInfo={constructionInfo["工事情報"]}
          changeInfo={changeConstructionInfo}
          getPartnerMargin={getPartnerMargin}
          mtb_enterprise_id={constructionInfo['その他情報'].mtb_enterprise_id}
        />
        <ConstructionBreakdown
          isEditable={true}
          tableContents={constructionInfo["工事内訳"].tableContents}
          taxInfo={constructionInfo["工事内訳"].taxInfo}
          changeTableInfo={changeTableInfo}
          changeTaxInfo={changeTaxInfo}
          handleNavigation={handleNavigation}
          partnerMargin={partnerMargin.current}
          onCalculateMargin={onCalculateMargin}
          addDeletingConstructionDetail={addDeletingConstructionDetail}
          allowMarginUpdate={allowMarginUpdate}
        />
        <span style={{ width: '100%', padding: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red' }}>
          {errorText && errorText}
        </span>
        <ButtonMenu>
          <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
        </ButtonMenu>
      </div>
    </BaseBodyStyle>
  )
};

export default ConstructionDetailEdit