import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware, compose } from 'redux';
import allReducer from 'src/redux/reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore, } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
  key: "persistStore",
  storage: localforage,
  whiteList: ["auth", "master", "construction", "customerSupport", "customer", "warranty", "error"],
  blackList: ["customer.customerList", "construction.allConstructionList"]
};

const persistedReducer = persistReducer(persistConfig, allReducer);
const composeEnhancer = process.env.NODE_ENV !== 'production' &&  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancer(applyMiddleware(thunk))
const store = createStore(
  persistedReducer,
  enhancer
);

export const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
