import {
  setEnterpriseData,
  setSalesOfficeData,
  setPartnerData,
  setPaymentMethods,
  setProduct,
  setTax,
  setEmployee,
  removeAllMasterData,
  setStoreData,
} from 'src/redux/actions/MasterDataHandler';
import { removeConstructionData } from 'src/redux/actions/ConstructionDataHandler';
import { removeCustomerData } from 'src/redux/actions/CustomerDataHandler';
import { removeCustomerSupportData } from 'src/redux/actions/CustomerSupportDataHandler';
import { removeWarrantyData } from 'src/redux/actions/WarrantyHandler';

import { persistor } from 'src/';
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";
export const SET_AUTH_STATUS_FAILED = "SET_AUTH_STATUS_FAILED";
export const REMOVE_AUTH_STATUS = "REMOVE_AUTH_STATUS";
export const REMOVE_AUTH_STATUS_FAILED = "REMOVE_AUTH_STATUS_FAILED";


export const setAuthenticationStatus = (data) => (dispatch) => {
  //called when login and sign up

  const { id, role, employee_name, account, mtb_enterprise_id, mtb_sales_office_id, token } = data;
  dispatch({
    type: SET_AUTH_STATUS,
    payload: { mtb_employee_id: id, role: role, employee_name: employee_name, account: account, mtb_enterprise_id: mtb_enterprise_id, mtb_sales_office_id: mtb_sales_office_id, token: token }
  })
  dispatch(setEnterpriseData());
  dispatch(setSalesOfficeData());
  dispatch(setPartnerData());
  dispatch(setPaymentMethods());
  dispatch(setProduct());
  dispatch(setTax());
  dispatch(setEmployee());
  dispatch(setStoreData());
  // sessionStorage.setItem('token', token);
};

export const removeAuthenticationStatus = () => (dispatch) => {
  //called when logout
  persistor.purge();
  dispatch(removeConstructionData());
  dispatch(removeCustomerData());
  dispatch(removeCustomerSupportData());
  dispatch(removeWarrantyData());
  dispatch(removeAllMasterData());
  localStorage.removeItem('constructionSearchQuery'); // local storageを空にする
  localStorage.removeItem('constructionSortType'); // local storageを空にする
  localStorage.removeItem('customerSearchQuery'); // local storageを空にする
  localStorage.removeItem('customerSortType'); // local storageを空にする
  localStorage.removeItem('warrantySearchQuery'); // local storageを空にする
  localStorage.removeItem('warrantySortType'); // local storageを空にする
  dispatch({
    type: REMOVE_AUTH_STATUS,
  });
}