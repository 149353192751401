import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import toast from 'react-hot-toast';
//apis
import { SEARCH_CUSTOMER_URL } from 'src/apis';
//components
import { BaseBodyStyle } from 'src/common/StylesComponents';
import { CustomerListHeader, CustomerListSearchField, CustomerTable } from './children';
import CustomerSearchModal from './children/CustomerSearchModal';
import CustomToaster from 'src/common/CustomToaster';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerListData, dataPreprocessing, setSelectedCustomerId, removeConstructionAndCustomerSupportData } from 'src/redux/actions/CustomerDataHandler';
import { removeErrorMessage } from 'src/redux/actions/ErrorMessageHandler';
//functions
import { CustomerCSVFunctions } from 'src/functions/CustomerFunctions';
import CSVFunctions from 'src/functions/CSVFunctions';
import { logCategory, sendLogData } from 'src/functions/LogFunctions';

const CustomerList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { enterpriseData, salesOfficeData, partnerData } = useSelector(state => state.master);
  const { customerList } = useSelector(state => state.customer);
  const actionError = useSelector(state => state.error);
  const { mtb_enterprise_id, token, employee_name } = useSelector(state => state.auth);

  const [searched, setSearched] = useState(false);
  const [searchText, setSearchText] = useState(''); // 検索テキストフィールド
  const [searchResult, setSearchResult] = useState(new Array([])); // 検索結果
  const [checkedState, setCheckedState] = useState({
    list: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,]
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const printRef = useRef(null);
  const [selectedDateType, setSelectedDateType] = useState(0);
  const [isSearchQueryFound, setIsSearchQueryFound] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //過去に検索、フィルタリングしていたらlocalStorageから検索内容を取ってきてフィルタリング関数を呼び出すフラグを立てる
    const searchQuery = JSON.parse(localStorage.getItem("customerSearchQuery")); // local storageから前回の検索入力情報をとってくる
    const searchText = searchQuery ? searchQuery.searchText : '';
    const searchCheckState = searchQuery ? searchQuery.searchCheckState : [];
    const savedSortType = JSON.parse(localStorage.getItem("customerSortType")); // local storageからsortTypeをとってくる
    const checkedStatus = searchCheckState && searchCheckState.filter(item => item === true);

    if (searchText || checkedStatus.length > 0) { // local storageに検索結果がある場合は、それを表示する。
      setIsLoading(true);
      setSelectedDateType(savedSortType ? parseInt(savedSortType) : 0);
      setSearchText(searchText);
      setCheckedState({
        list: searchCheckState
      });
      setIsSearchQueryFound(true); //直接onSearchCustomersを呼び出せばいいが、stateがアップデートされるのに時間差があるため、フラグを立てる
    } else {
      // DBから顧客一覧フェッチ
      dispatch(getCustomerListData());
      dispatch(removeConstructionAndCustomerSupportData());
      setIsLoading(false);
    }

  }, []);

  /**
   * toastによるエラー表示（csvログなど）
   */
  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
    }
  }, [errorText]);

  /**
   * toastによるエラー表示（顧客データ取得エラーなど）
   */
  useEffect(() => {
    // console.log(actionError)
    if (actionError.where === "customer") {
      toast.error(actionError.msg);
      dispatch(removeErrorMessage());
      setIsLoading(false);
    }
  }, [actionError, dispatch]);

  // チェックマークを入れたときに入れたものを識別するためarrayをupdateする
  const onUpdateStatus = useCallback((i) => {
    setCheckedState(state => {
      const list = state.list.map((item, j) => {
        if (j === i) {
          return !item;
        } else {
          return item;
        }
      });
      return {
        list,
      };
    });
  }, [checkedState.list]);

  const onSearchCustomers = async () => {
    let searchedCustomer = [];
    let checkedStatus = [];
    setSearchResult(() => []); // 検索初期化
    if (searchText.length > 0 || checkedState.list.length > 0) {
      // 全角英数ハイフン => 半角
      let searchedText = searchText.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
      searchedText = searchedText.replace(/[‐－―ー]/g, '-');

      checkedState.list.forEach(function (word, index) {
        if (word === true) {
          checkedStatus.push(index)
        }
      });

      setIsLoading(true);

      await axios.get(SEARCH_CUSTOMER_URL, {
        params: {
          mtb_enterprise_id: mtb_enterprise_id,
          searchedText: searchedText,
          checkedStatus: checkedStatus
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            const processedData = dataPreprocessing(response.data, enterpriseData, salesOfficeData, partnerData);
            searchedCustomer = processedData;
          } else {
            setErrorText("顧客検索に失敗しました。");
          }
        })
      
      setSearchResult(searchedCustomer);
      setSearched(true);
      setIsLoading(false);
    }

    localStorage.setItem('customerSearchQuery', JSON.stringify({
      searchText: searchText,
      searchCheckState: checkedState.list
    }));
  };

  /*
    useEffectでDependencyをリスニングし、もしlocalStorageに検索内容がある場合はフィルタリング関数を呼び出す。
  */
  useEffect(() => {
    if (isSearchQueryFound) {
      onSearchCustomers();
    }
  }, [isSearchQueryFound]);

  //検索ボタンクリック時に発火
  const onSearchClicked = useCallback(() => {
    onSearchCustomers();
  }, [customerList, searchText, searchResult, checkedState.list, enterpriseData, salesOfficeData, partnerData]);

  // 検索クリアー（全て表示）
  const onSearchClear = useCallback(() => {
    setSearchResult(() => []); // 検索初期化
    localStorage.removeItem('customerSearchQuery'); // local storageを空にする
    localStorage.removeItem('customerSortType'); // local storageを空にする
    setSearched(false);
    setSearchText('');
    setCheckedState({
      list: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,]
    });
  }, []);

  const dateSortSelected = (e) => {
    setSelectedDateType(e.target.value ? parseInt(e.target.value) : 0);
  };

  const registerCSVLog = async () => {
    const csvCustomerLog = {
      id: null,
      category: logCategory.csvExport,
      remarks: 'CSV出力：顧客一覧',
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [csvCustomerLog];
    const error = await sendLogData(json, token);
    if (error) {
      setErrorText("csvログの記録に失敗しました。");
    }
  };

  //顧客データCSV出力 if searched -> 検索結果の出力　otherwise -> 全顧客データの出力
  const onLabelExportButtonPressed = useCallback(() => {
    let data = searched ? [...searchResult] : [...customerList];
    const customerFunctions = new CustomerCSVFunctions({ customerData: data });
    const response = customerFunctions.getCSVData();
    if (typeof response.error === 'string' && response.error === '') {
      const { csvHeader, csvData } = response.data;
      const fileName = "顧客一覧";
      new CSVFunctions(csvHeader, csvData, fileName).exportCSVData();
      registerCSVLog();
    }
  }, [searched, customerList, searchResult]);

  const onRegisterCustomerButtonPressed = useCallback(() => {
    history.push({ pathname: '/customer-list/register' });
  }, [history]);

  const navigateToCustomerDetail = useCallback(async (mtb_customer_id) => {
    const detailPath = location.pathname + `/${mtb_customer_id}`;
    dispatch(setSelectedCustomerId(mtb_customer_id));
    history.push({ pathname: detailPath });
  }, [history, dispatch, location.pathname]);

  const onPrintButtonPressed = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => registerPrintLog(),
  });

  const registerPrintLog = async () => {
    const customerPrintLog = {
      id: null,
      category: logCategory.print,
      remarks: '顧客一覧',
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [customerPrintLog];
    const error = await sendLogData(json, token);
    if (error) {
      setErrorText("csvログの記録に失敗しました。");
    }
  };

  // 検索かけた場合、その結果のDOMを返す
  if (searched) {

    // 登録された順番に変更
    if (selectedDateType === 0) {
      // 登録新しい順番
      searchResult.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else {
      // 登録古い順番
      searchResult.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    };

    localStorage.setItem('customerSortType', JSON.stringify(selectedDateType)); //　sortTypeをlocalstorageに格納

    return (
      <BaseBodyStyle main ref={printRef}>
        <CustomerListHeader onPrintButtonPressed={onPrintButtonPressed} onRegisterCustomerButtonPressed={onRegisterCustomerButtonPressed} onLabelExportButtonPressed={onLabelExportButtonPressed} />
        <CustomerListSearchField
          setSearchText={setSearchText}
          searchText={searchText}
          onSearchClicked={onSearchClicked}
          onSearchClear={onSearchClear}
          setCheckedState={onUpdateStatus}
          checkedState={checkedState}
          dateSortSelected={dateSortSelected}
          selectedDateType={selectedDateType}
        />
        <CustomerTable data={searchResult} navigation={navigateToCustomerDetail} selectedDateType={selectedDateType} isDataProcessing={isLoading} />
        <CustomerSearchModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          customerList={customerList}
          setSearchResult={setSearchResult}
          setSearched={setSearched}
        />
        <CustomToaster />
      </BaseBodyStyle >
    )
  };

  // 登録された順番に変更
  if (selectedDateType == 0) {
    // 登録新しい順番
    customerList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  } else {
    // 登録古い順番
    customerList.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  };

  return (
    <BaseBodyStyle main ref={printRef}>
      <CustomerListHeader onPrintButtonPressed={onPrintButtonPressed} onRegisterCustomerButtonPressed={onRegisterCustomerButtonPressed} onLabelExportButtonPressed={onLabelExportButtonPressed} />
      <CustomerListSearchField
        setSearchText={setSearchText}
        searchText={searchText.toString()}
        onSearchClicked={onSearchClicked}
        onSearchClear={onSearchClear}
        setCheckedState={onUpdateStatus}
        checkedState={checkedState}
        dateSortSelected={dateSortSelected}
        selectedDateType={selectedDateType}
      />
      <CustomerTable data={customerList} navigation={navigateToCustomerDetail} selectedDateType={selectedDateType} isDataProcessing={isLoading} />
      <CustomerSearchModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        customerList={customerList}
        setSearchResult={setSearchResult}
        setSearched={setSearched}
      />
      <CustomToaster/>
    </BaseBodyStyle >
  )
};

export default CustomerList;