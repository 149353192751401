import axios from 'axios';
import Big from 'big.js';
//apis
import {
  ALL_CONSTRUCTION_DATA_URL,
  CONSTRUCTION_DETAIL_DATA_URL,
  GET_SELECTED_CONSTRUCTION_URL,
  GET_SELECTED_CUSTOMER_CONSTRUCTION_URL
} from 'src/apis';
//actions
import { setErrorMessage } from 'src/redux/actions/ErrorMessageHandler';
//functions
import { stringStatusToArray } from 'src/redux/actions/CustomerDataHandler';
import GeneralFunctions from 'src/functions/GeneralFunctions';

export const SET_ALL_CONSTRUCTION_LIST = "SET_ALL_CONSTRUCTION_DATA";
export const SET_CUSTOMER_CONSTRUCTION_LIST = "SET_CUSTOMER_CONSTRUCTION_LIST";
export const SET_SELECTED_CONSTRUCTION_ID = "SET_SELECTED_CONSTRUCTION_ID";
export const SET_SELECTED_CONSTRUCTION_DETAIL_DATA = "SET_SELECTED_CONSTRUCTION_DETAIL_DATA";
export const REMOVE_SELECTED_CONSTRUCTION_DETAIL_DATA = "REMOVE_SELECTED_CONSTRUCTION_DETAIL_DATA";
export const SET_CONSTRUCTION_DATA_FAILED = "SET_CONSTRUCTION_DATA_FAILED";
export const SET_CONSTRUCTION_BREAKDOWN_DATA = "SET_CONSTRUCTION_BREAKDOWN_DATA";
export const SET_CONSTRUCTION_BREAKDOWN_DATA_FAILED = "SET_CONSTRUCTION_BREAKDOWN_DATA_FAILED";
export const RESET_CONSTRUCTION_FETCH_FLAG = "RESET_CONSTRUCTION_FETCH_FLAG";
export const REMOVE_SELECTED_CUSTOMER_CONSTRUCTION = "REMOVE_SELECTED_CUSTOMER_CONSTRUCTION";

export const REMOVE_CONSTRUCTION_DATA = "REMOVE_CONSTRUCTION_DATA";

const errorLocation = "construction";

export const setAllConstructionListData = (params) => async (dispatch, getState) => {
  //全ての工事データをフェッチしリストに格納する関数
  //工事一覧ページに表示する際に呼び出す
  //現状はdtb_construction_idとmtb_enterprise_idのみで条件絞ってデータをフェッチしてきている。
  //工事一覧ページの検索機能実装後にmtb_customer_idを顧客情報からフェッチしてきてこの関数に渡し、フィルタリングする。
  try {
    const { mtb_enterprise_id, mtb_sales_office_id } = params;
    const { token } = getState().auth;
    const { partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData } = getState().master;

    await axios.get(ALL_CONSTRUCTION_DATA_URL, {
      params: {
        mtb_enterprise_id: mtb_enterprise_id,
        mtb_sales_office_id: mtb_sales_office_id
      },
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("Could not get construction data");
      })
      .then(({ data }) => {
        const processedData = dataPreprocessing(data, partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData);
        dispatch({
          type: SET_ALL_CONSTRUCTION_LIST,
          payload: { data: processedData }
        });
      })
  } catch (error) {
    dispatch({
      type: SET_CONSTRUCTION_DATA_FAILED
    });
    dispatch(setErrorMessage({ where: errorLocation, msg: "工事データを取得できませんでした。" }));
  }
}

export const setConstructionListData = (mtb_customer_id) => async (dispatch, getState) => {
  //顧客詳細の工事、対応テーブルに表示するある顧客(mtb_customer_id)に基づく工事データを格納する際に必要とする関数
  //バックエンドからのデータのフェッチをこの関数で行う
  //顧客詳細の工事履歴・対応履歴テーブルに表示する
  try {
    const { token } = getState().auth;
    const { partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData } = getState().master;
  
    await axios.get(GET_SELECTED_CUSTOMER_CONSTRUCTION_URL, {
      params: {
        mtb_customer_id: mtb_customer_id
      },
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("Could not get construction data");
      })
      .then(({ data }) => {
        const processedData = dataPreprocessing(data, partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData);
        dispatch({
          type: SET_CUSTOMER_CONSTRUCTION_LIST,
          payload: { data: processedData }
        });
      })
  } catch (error) {
    dispatch({
      type: SET_CONSTRUCTION_DATA_FAILED
    });
    dispatch(setErrorMessage({ where: errorLocation, msg: "顧客の工事履歴を取得できませんでした。" }));
  }
};

export const dataPreprocessing = (data, partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData) => {
  const _data = [...data];
  const collator = new Intl.Collator('ja');

  _data.map((constructionData) => {
    const { mtb_partner_id, mtb_store_id, mtb_payment_method_id, mtb_sales_office_id, mtb_enterprise_id, status, customer_address1, destination_address } = constructionData;
    const partnerName = partnerData[mtb_partner_id] ? partnerData[mtb_partner_id].partner_name : "";
    const paymentMethod = paymentMethods[mtb_payment_method_id] ? paymentMethods[mtb_payment_method_id].payment_method : "";
    const salesOfficeName = salesOfficeData[mtb_sales_office_id] ? salesOfficeData[mtb_sales_office_id].sales_office_name : "";
    const enterpriseName = enterpriseData[mtb_enterprise_id] ? enterpriseData[mtb_enterprise_id].enterprise_name : "";
    const storeName = storeData[mtb_store_id] ? storeData[mtb_store_id].store_name : "";
    constructionData["business_partner"] = partnerName;
    constructionData["payment_method"] = paymentMethod;
    constructionData["sales_office_name"] = salesOfficeName;
    constructionData["enterprise_name"] = enterpriseName;
    constructionData["construction_store_name"] = storeName;
    constructionData["status"] = stringStatusToArray(status);
    const customer_addr = GeneralFunctions.halfToFullString(customer_address1);
    const destination_addr = GeneralFunctions.halfToFullString(destination_address)
    //顧客と施工先の住所を比較
    constructionData["is_customer_address_identical"] = collator.compare(customer_addr, destination_addr) === 0 ? true : false;
  });
  return _data;
}

export const removeSelectedCustomersConstructionData = () => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_CUSTOMER_CONSTRUCTION
  })
}


export const setSelectedConstructionId = (dtb_construction_id) => (dispatch) => {
  //ユーザーが顧客詳細ページまたは工事一覧で選択した工事データのIDをredux storeに格納する関数
  dispatch({
    type: SET_SELECTED_CONSTRUCTION_ID,
    payload: { dtb_construction_id: dtb_construction_id }
  });
};

export const getConstructionDetailData = (dtb_construction_id) => async (dispatch, getState) => {
  //選択された工事idを元に詳細ページや、編集ページで表示する工事データを格納する関数
  //工事idを受け取り、/mtb_selected_construction より工事データをフェッチしてきて、selectedConstructionDataに格納
  try {
    if (!dtb_construction_id) throw new Error("could not get construction detail.");
    const { token } = getState().auth;
    const { partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData } = getState().master;
    
    if (token) {
      await axios.get(GET_SELECTED_CONSTRUCTION_URL, {
        params: {
          dtb_construction_id: dtb_construction_id,
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then(({ data }) => {
          const processedData = dataPreprocessing(data, partnerData, paymentMethods, salesOfficeData, enterpriseData, storeData);
          dispatch({
            type: SET_SELECTED_CONSTRUCTION_DETAIL_DATA,
            payload: { selectedData: processedData.pop() }
          });
        })
    }
  } catch (error) {
    dispatch({
      type: SET_CONSTRUCTION_DATA_FAILED
    });
    dispatch(setErrorMessage({ where: errorLocation, msg: "工事詳細データを取得できませんでした。" }));
  }
};

export const removeSelectedConstructionDetailData = () => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_CONSTRUCTION_DETAIL_DATA
  })
}

export const getConstructionBreakDownData = (dtb_construction_id) => async (dispatch, getState) => {
  //工事詳細データを表示する際に必要となる工事内訳（テーブル内）のデータをdtb_construction_idを元にAPIから取得する関数
  try {
    if (dtb_construction_id) {
      const { token } = getState().auth;

      await axios.get(CONSTRUCTION_DETAIL_DATA_URL, {
        params: {
          dtb_construction_id: dtb_construction_id
        },
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error("could not get construction detail data", response.statusText);
        })
        .then(({ data }) => {
          const preprocessedData = breakdownPreprocessing(data);
          dispatch({
            type: SET_CONSTRUCTION_BREAKDOWN_DATA,
            payload: { breakdown: preprocessedData }
          });
        })
    } else {
      throw new Error("dtb_construction_id is not given.")
    }
  } catch (error) {
    dispatch({
      type: SET_CONSTRUCTION_BREAKDOWN_DATA_FAILED
    })
    dispatch(setErrorMessage({ where: errorLocation, msg: "工事内訳データを取得できませんでした。" }));
  }
}

const breakdownPreprocessing = (data) => {
  //工事内訳の一つ一つの行に表示するデータの初期化　単価価格と金額の変更
  if (data.length === 0) return [];
  const processedData = [];
  data.map((breakdown) => {
    const { price : unit_price, quantity } = breakdown;
    let _breakdown = { ...breakdown };
    delete _breakdown.price;
    Big.RM = Big.roundDown;
    const price = new Big(unit_price).times(quantity).toNumber();
    _breakdown["unit_price"] = unit_price;
    _breakdown["price"] = price;
    processedData.push(_breakdown);
  });
  return processedData;
};

export const removeConstructionData = () => (dispatch) => {
  dispatch({
    type: REMOVE_CONSTRUCTION_DATA
  })
};

export const resetConstructionFetchFlag = () => (dispatch) => {
  dispatch({
    type: RESET_CONSTRUCTION_FETCH_FLAG
  })
};