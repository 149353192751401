export const BreakPoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

export const Colors = {
  border: 'lightgray',
  darkLabel: 'black',
  selectedStatus: '#0071E3',
  unselectedStatus: 'gray',
  textColor: '#1d1d1f',
  grayText: '#86868b',
  errorText: 'red',
  boxHighlight: 'rgba(90, 200, 250, 0.1)',
  buttonBorder: '#3f3f3f',
  buttonNormal: 'white',
  buttonHover: '#F7F7F7',
  delete: 'red',
  buttonDisabledBorder: '#CCCCCC',
  buttonDisabled: 'white',
  logoutButtonColor: '#3f3f3f',
  statusRed: '#ff0000',
  statusBlue: '#0066ff',
  statusGreen: '#309848',
  modalButton: '#1A98FB',
  borderColor: '#dedede',
}

export const FontSize = {
  xs: '12px',
  sm: '13px',
  md: '14px',
  lg: '16px',
  xl: '20px',
  xxl: '24px',
}

//ログインと各種設定/対応履歴設定のタイトルは各ファイルで
export const tabTitles = {
  '/customer-list': "顧客一覧 | FTS顧客管理システム",
  '/customer-list/register': "顧客一覧 登録 | FTS顧客管理システム",
  '/customer-list/:customer_code': '顧客一覧 詳細 | FTS顧客管理システム',
  '/customer-list/:customer_code/edit': '顧客一覧 編集 | FTS顧客管理システム',
  '/construction-list': '工事一覧 | FTS顧客管理システム',
  '/construction-list/register': '工事一覧 登録 | FTS顧客管理システム',
  '/construction-list/:construction_id': '工事一覧 詳細 | FTS顧客管理システム',
  '/construction-list/:construction_id/edit': '工事一覧 編集 | FTS顧客管理システム',
  '/construction-list/:construction_id/warranty/:warranty_id/damage-warranty-detail': '保証情報 詳細 | FTS顧客管理システム',
  '/construction-list/:construction_id/warranty/:warranty_id/damage-warranty-edit': '保証情報 詳細 | FTS顧客管理システム',
  '/construction-list/:construction_id/warranty/:warranty_id/termite-antifungal-warranty-edit': '保証情報 編集 | FTS顧客管理システム',
  '/construction-list/:construction_id/warranty': '保証情報 | FTS顧客管理システム',
  '/file-upload': 'ファイル取込 | FTS顧客管理システム',
  '/support-log': 'ログ| FTS顧客管理システム',
  '/sales-aggregation': '売上集計 | FTS顧客管理システム',
  '/settings': '各種設定 | FTS顧客管理システム',
  '/settings/sales-office-list': '営業所設定 | FTS顧客管理システム',
  '/settings/sales-office-list/register': '営業所設定 登録 | FTS顧客管理システム',
  '/settings/sales-office-list/:sales_office_id': '営業所設定 詳細 | FTS顧客管理システム',
  '/settings/sales-office-list/:sales_office_id/edit': '営業所設定　編集 | FTS顧客管理システム',
  '/settings/employee-list': '従業員設定 | FTS顧客管理システム',
  '/settings/employee-list/register': '従業員設定 登録 | FTS顧客管理システム',
  '/settings/employee-list/:employee_id': '従業員設定 詳細 | FTS顧客管理システム',
  '/settings/employee-list/:employee_id/edit': '従業員設定 編集 | FTS顧客管理システム',
  '/settings/payment-method-list': '支払方法設定 | FTS顧客管理システム',
  '/settings/payment-method-list/register': '支払方法設定 | FTS顧客管理システム',
  '/settings/payment-method-list/:payment_method_id': '支払方法設定 詳細 | FTS顧客管理システム',
  '/settings/payment-method-list/:payment_method_id/edit': '支払方法設定 編集 | FTS顧客管理システム',
  '/settings/product-favorite': '商品お気に入り設定 | FTS顧客管理システム',
  '/settings/product-favorite/edit': '商品お気に入り設定 編集 | FTS顧客管理システム',
  '/settings/product-list': '商品設定 | FTS顧客管理システム',
  '/settings/product-list/register': '商品設定 登録 | FTS顧客管理システム',
  '/settings/product-list/:product_id': '商品設定 詳細 | FTS顧客管理システム',
  '/settings/product-list/:product_id/edit': '商品設定 編集 | FTS顧客管理システム',
  '/settings/tax-list': '消費税設定 | FTS顧客管理システム',
  '/settings/tax-list/register': '消費税設定 登録 | FTS顧客管理システム',
  '/settings/tax-list/:tax_id': '消費税設定 詳細 | FTS顧客管理システム',
  '/settings/tax-list/:tax_id/edit': '消費税設定 編集 | FTS顧客管理システム',
  '/settings/enterprise-list': '企業設定 | FTS顧客管理システム',
  '/settings/enterprise-list/register': '企業設定 登録 | FTS顧客管理システム',
  '/settings/enterprise-list/:enterprise_id': '企業設定 詳細 | FTS顧客管理システム',
  '/settings/enterprise-list/:enterprise_id/edit': '企業設定 編集 | FTS顧客管理システム',
  '/settings/partner-list': '提携先設定 | FTS顧客管理システム',
  '/settings/partner-list/register': '提携先設定 登録 | FTS顧客管理システム',
  '/settings/partner-list/:partner_id': '提携先設定 詳細 | FTS顧客管理システム',
  '/settings/partner-list/:partner_id/edit': '提携先設定 編集 | FTS顧客管理システム',
  '/settings/store-list': '店舗設定 | FTS顧客管理システム',
  '/settings/store-list/register': '店舗設定 登録 | FTS顧客管理システム',
  '/settings/store-list/:store_id': '店舗設定 詳細 | FTS顧客管理システム',
  '/settings/store-list/:store_id/edit': '店舗設定 編集 | FTS顧客管理システム',
};

//カタカナ半角マッピング
export const katakana = new Map([
  ['ア', 'ｱ'],
  ['イ', 'ｲ'],
  ['ウ', 'ｳ'],
  ['エ', 'ｴ'],
  ['オ', 'ｵ'],
  ['カ', 'ｶ'],
  ['キ', 'ｷ'],
  ['ク', 'ｸ'],
  ['ケ', 'ｹ'],
  ['コ', 'ｺ'],
  ['サ', 'ｻ'],
  ['シ', 'ｼ'],
  ['ス', 'ｽ'],
  ['セ', 'ｾ'],
  ['ソ', 'ｿ'],
  ['タ', 'ﾀ'],
  ['チ', 'ﾁ'],
  ['ツ', 'ﾂ'],
  ['テ', 'ﾃ'],
  ['ト', 'ﾄ'],
  ['ナ', 'ﾅ'],
  ['ニ', 'ﾆ'],
  ['ヌ', 'ﾇ'],
  ['ネ', 'ﾈ'],
  ['ノ', 'ﾉ'],
  ['ハ', 'ﾊ'],
  ['ヒ', 'ﾋ'],
  ['フ', 'ﾌ'],
  ['ヘ', 'ﾍ'],
  ['ホ', 'ﾎ'],
  ['マ', 'ﾏ'],
  ['ミ', 'ﾐ'],
  ['ム', 'ﾑ'],
  ['メ', 'ﾒ'],
  ['モ', 'ﾓ'],
  ['ヤ', 'ﾔ'],
  ['ユ', 'ﾕ'],
  ['ヨ', 'ﾖ'],
  ['ラ', 'ﾗ'],
  ['リ', 'ﾘ'],
  ['ル', 'ﾙ'],
  ['レ', 'ﾚ'],
  ['ロ', 'ﾛ'],
  ['ワ', 'ﾜ'],
  ['ヲ', 'ｦ'],
  ['ン', 'ﾝ'],
  ['ァ', 'ｧ'],
  ['ィ', 'ｨ'],
  ['ゥ', 'ｩ'],
  ['ェ', 'ｪ'],
  ['ォ', 'ｫ'],
  ['ッ', 'ｯ'],
  ['ャ', 'ｬ'],
  ['ュ', 'ｭ'],
  ['ョ', 'ｮ'],
  ['ガ', 'ｶﾞ'],
  ['ギ', 'ｷﾞ'],
  ['グ', 'ｸﾞ'],
  ['ゲ', 'ｹﾞ'],
  ['ゴ', 'ｺﾞ'],
  ['ザ', 'ｻﾞ'],
  ['ジ', 'ｼﾞ'],
  ['ズ', 'ｽﾞ'],
  ['ゼ', 'ｾﾞ'],
  ['ゾ', 'ｿﾞ'],
  ['ダ', 'ﾀﾞ'],
  ['ヂ', 'ﾁﾞ'],
  ['ヅ', 'ﾂﾞ'],
  ['デ', 'ﾃﾞ'],
  ['ド', 'ﾄﾞ'],
  ['バ', 'ﾊﾞ'],
  ['ビ', 'ﾋﾞ'],
  ['ブ', 'ﾌﾞ'],
  ['ベ', 'ﾍﾞ'],
  ['ボ', 'ﾎﾞ'],
  ['パ', 'ﾊﾟ'],
  ['ピ', 'ﾋﾟ'],
  ['プ', 'ﾌﾟ'],
  ['ペ', 'ﾍﾟ'],
  ['ポ', 'ﾎﾟ'],
  ['ヴ', 'ｳﾞ'],
  ['ヷ', 'ﾜﾞ'],
  ['ヺ', 'ｦﾞ'],
  ['。', '｡'],
  ['、', '､'],
  ['ー', 'ｰ'],
  ['「', '｢'],
  ['」', '｣'],
  ['・', '･'],
  ['゛', 'ﾞ'],
  ['゜', 'ﾟ'],
]);
//句読点全角ー＞半角マッピング
export const characters = new Map([
  ['。', '.'],
  ['、', '.'],
  ['・', '.'],
  ['。', '.'],
  ['（', '('],
  ['{', '('],
  ['）', ')'],
  ['}', ')'],
  ['／', '/'],
  ['￥', '¥'],
]);
//アルファベット小文字ー＞大文字マッピング
export const alphabets = new Map([
  ['a', 'A'],
  ['b', 'B'],
  ['c', 'C'],
  ['d', 'D'],
  ['e', 'E'],
  ['f', 'F'],
  ['g', 'G'],
  ['h', 'H'],
  ['i', 'I'],
  ['j', 'J'],
  ['k', 'K'],
  ['l', 'L'],
  ['m', 'M'],
  ['n', 'N'],
  ['o', '('],
  ['p', '('],
  ['q', ')'],
  ['r', ')'],
  ['s', '/'],
  ['t', '¥'],
  ['u', '.'],
  ['v', '.'],
  ['w', '.'],
  ['x', '('],
  ['y', '('],
  ['z', ')'],
])