import axios from "axios";
//apis
import { MTB_STORE_DATA_CHANGE_URL } from 'src/apis';
//functions
import GeneralFunctions from "../GeneralFunctions";
import PhoneNumberFunctions from "../PhoneNumberFunctions";

const inputRequiredField = {
  mtb_partner_id: '提携先', mtb_enterprise_id: '企業', store_name: '店舗名', postal_code: '郵便番号', address: '住所', phone: '電話番号'
}

/*
店舗設定　店舗の登録/編集クラス
- Parameters in constructor:
 - storeData: 店舗データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセス時のエラー
 - data: DBに登録/編集したデータ
*/
class StoreFunctions {
  
  constructor(props) {
    const { storeData, token } = props;
    this.storeData = storeData;
    this.token = token;
    this.response = { error: "", data: {} };
  }

  /*
    private method
    (1) 必須項目チェック:  mtb_partner_id: '提携先', mtb_enterprise_id: '企業', store_name: '店舗名', postal_code: '郵便番号', address: '住所', phone: '電話番号'
    (2) 店舗電話番号チェック
  */
  _validation() {
    const data = { ...this.storeData };
    let errors = [];
    //(1)
    Object.keys(inputRequiredField).forEach((key) => {
      if (key === 'mtb_partner_id') {
        if (data[key] === 0 || GeneralFunctions.isNullOrEmpty(data[key])) {
          errors.push(inputRequiredField[key]);
        } else {
          //提携先OKの場合
          this.storeData[key] = parseInt(this.storeData[key]);
        }
      } else if (key === 'mtb_enterprise_id') {
        if (GeneralFunctions.isNullOrEmpty(data[key])) {
          errors.push(inputRequiredField[key]);
        } else {
          //提携先OKの場合
          this.storeData[key] = parseInt(this.storeData[key]);
        }
      } else {
        if (GeneralFunctions.isNullOrEmpty(data[key])) errors.push(inputRequiredField[key]);
      }
    });
    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を記入してください。";
    };

    //(2)電話番号の型チェック
    const phoneResponse = PhoneNumberFunctions.phoneValidation(data['phone']);
    if (phoneResponse.error) {
      return phoneResponse.error;
    }

    this.storeData['phone'] = phoneResponse.validPhoneNumber;
    return;
  }

  /*
    private method
    APIアクセスメソッド
  */
  async _send() {
    try {
      this.response.data = {...this.storeData};
      const data = {...this.storeData};
      delete data.partner_name;

      const json = [data];
      await axios.post(MTB_STORE_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
      .then((response) => {
        if (response.status === 200) {
          return;
        }
        throw new Error("店舗情報を編集・登録できませんでした。");
      })
    } catch (error) {
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
    登録/編集した店舗データもしくはエラーを返す
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    error = await this._send();
    if (error) {
      this.response.error = error;
      return this.response;
    }

    this.response.error = "";
    return this.response;

  }
}

export default StoreFunctions;


