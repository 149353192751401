import React, { useCallback } from 'react';
import jwt_decode from "jwt-decode";
import { Route, Redirect, useHistory } from 'react-router-dom';
//functions
import AuthFunctions from 'src/functions/AuthFunctions';
//components
import NoAuthority from 'src/views/NoAuthority';
//redux
import {useDispatch, useSelector} from 'react-redux';
import { removeAuthenticationStatus } from 'src/redux/actions/AuthHandler';
//configs
import { tabTitles } from 'src/configs/StyleConfig';

const PrivateRouteWrapper = ({ children }) => {
  const isLoggedIn = AuthFunctions.getSessionStorage("isLoggedIn");
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  return <div>{children}</div>;
};

export default PrivateRouteWrapper;


export const PrivateRoute = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, token, role } = useSelector(state => state.auth);
  const {location, path, component: Component, acceptedRoles, ...rest} = props;
  // const role = 3;

  const onNavigation = useCallback(() => {
    const title = tabTitles[path];
    if (title) { 
      //ログインと各種設定/対応履歴設定のタイトルは各ファイルで
      document.title = title;
    }

    if (token) {
      const decoded = jwt_decode(token);
      const { exp } = decoded;
      if (Date.now() >= exp * 1000) {
        //もしトークンの有効期間を超えてしまった場合、強制ログアウト
        dispatch(removeAuthenticationStatus());
        history.replace('/login');
      }
    }
  }, [token, path, dispatch, history]);

  return (
    <Route path={path} {...rest}  render={() => {
      onNavigation()
      if (!isLoggedIn) {
        return <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
      if (acceptedRoles && acceptedRoles.includes(role)) {
        return <Component {...rest} {...props} />
      }
      return <NoAuthority />
    }}
    />
  )
}