import {
  SET_WARRANTY_LIST,
  SET_WARRANTY_LIST_FAILED,
  SET_SELECTED_WARRANTY,
  EDIT_WARRANTY_DATA,
  EDIT_WARRANTY_DATA_FAILED,
  EDIT_SELECTED_WARRANTY_DATA,
  EDIT_SELECTED_WARRANTY_DATA_FAILED,
  REMOVE_SELECTED_WARRANTY_BY_INDEX,
  SET_EDITING_STATE,
  REMOVE_CONSTRUCTION_WARRANTY_DATA,

  SET_WARRANTY_LIST_DATA,
  SET_WARRANTY_LIST_DATA_FAILED,
  REMOVE_WARRANTY_DATA,
} from 'src/redux/actions/WarrantyHandler';

const initialState = {
  warranties: [],
  warrantyList: [],
  selectedWarranty: {}, //ユーザーが選択した保証書の編集の際に表示する一つの保証書データ
  selectedIndex: null,
  warrantyFetchRequired: true, //保証書をDBからフェッチしたかどうかのstate
  isEditingWarranty: false, 
};

const WarrantyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WARRANTY_LIST:
      return {
        ...state,
        warranties: action.payload.warranties,
        warrantyFetchRequired: false,
        isEditingWarranty: false,
      };
    case SET_WARRANTY_LIST_FAILED:
      return {
        ...state,
        warranties: [],
        selectedWarranty: {},
        selectedIndex: null,
        warrantyFetchRequired: true
      };
    case SET_SELECTED_WARRANTY:
      return {
        ...state,
        selectedWarranty: action.payload.selectedWarranty,
        selectedIndex: action.payload.selectedIndex
      }
    case EDIT_WARRANTY_DATA:
      return {
        ...state,
        warranties: action.payload.updatedWarrantyList,
      };
    case EDIT_WARRANTY_DATA_FAILED:
      return state;
    case EDIT_SELECTED_WARRANTY_DATA:
      return {
        ...state,
        warranties: action.payload.updatedWarrantyList,
        selectedWarranty: {},
        selectedIndex: null,
      };
    case EDIT_SELECTED_WARRANTY_DATA_FAILED:
      return state;
    case REMOVE_SELECTED_WARRANTY_BY_INDEX:
      return {
        ...state,
        warranties: action.payload.updatedWarrantyList,
        selectedWarranty: {},
        selectedIndex: null,
      };
    case SET_EDITING_STATE:
      return {
        ...state,
        isEditingWarranty: action.payload.isEditingWarranty
      }
    case REMOVE_CONSTRUCTION_WARRANTY_DATA:
      return {
        ...state,
        warranties: [],
        selectedWarranty: {},
        selectedIndex: null,
        warrantyFetchRequired: true
      }
    case SET_WARRANTY_LIST_DATA:
      return {
        ...state,
        warrantyList: action.payload.data,
      };
    case SET_WARRANTY_LIST_DATA_FAILED:
      return {
        ...state,
        warrantyList: [],
        selectedWarranty: {},
        isEditingWarranty: false,
      };
    case REMOVE_WARRANTY_DATA:
      return {
        warranties: [],
        warrantyList: [],
        selectedWarranty: {},
        selectedIndex: null,
        warrantyFetchRequired: true,
        isEditingWarranty: false,
      };
    default:
      return state;
  }
};

export default WarrantyReducer;