import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from "styled-components";
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setEmployee, setEnterpriseData, setSalesOfficeData } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';
//functions
import DateFunctions from 'src/functions/DateFunctions';

const columnNames = {
  employee_code: { label: '従業員コード', width: '16%', align: 'center' },
  employee_name: { label: '氏名', width: '16%', align: 'left' },
  enterprise_name: { label: '所属企業', width: '34%', align: 'left' },
  sales_office_name: { label: '所属営業所', width: '20%', align: 'left' },
  created_at: { label: '登録日', width: '16%', align: 'left' },
};

/*
  従業員一覧コンポーネント
  各行からその詳細ページへ推移
  新規登録ボタンから登録ページへ推移
*/
const MtbEmployeeList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { employeeList, salesOfficeData, enterpriseData } = useSelector(state => state.master);
  const [data, setData] = useState([]);

  useEffect(() => {
    //DBから営業所データをフェッチ
    dispatch(setEmployee());
    dispatch(setEnterpriseData());
    dispatch(setSalesOfficeData());
  }, [dispatch]);

  const initSalesData = () => {
    
    const getEmployeeWithSalesOfficeAndEnterpriseName = () => {
      //営業所名を従業員データに追加
      const employeeData = employeeList.map((employee) => {
        const office = salesOfficeData[employee.mtb_sales_office_id];
        const enterprise = enterpriseData[employee.mtb_enterprise_id];
        if (office && enterprise) return { ...employee, sales_office_name: office.sales_office_name, enterprise_name: enterprise.enterprise_name };
        return { ...employee, sales_office_name: "", enterprise_name: "" };
      });
      return employeeData;
    }

    if (employeeList && salesOfficeData && enterpriseData) {
      const _data = getEmployeeWithSalesOfficeAndEnterpriseName();
      setData([..._data]);
    }
  };
  useMemo(initSalesData, [employeeList, salesOfficeData, enterpriseData]);

  const navigateToDetail = useCallback((id) => {
    const selectedEmployeeData = data.filter((employee) => employee.id === id);
    if (selectedEmployeeData.length > 0) {
      history.push({ pathname: `${pathname}/${id}`, state: { data: selectedEmployeeData[0] } });
    }
  }, [history, pathname, data]);

  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { prevPath: pathname } });
  }, [history, pathname]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            if (columnName === 'created_at') {
              return (
                <Td key={columnName} width={columnNames[columnName].width}>
                  {DateFunctions.ISOStringDateToLocalDate(data[columnName])}
                </Td>
              )
            }
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <CustomTable>
        <ColumnNameWrapper>
          {
            Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
          }
        </ColumnNameWrapper>
        <tbody>
          {
            data.length > 0 && data.map((employee, index) => {
              return <Row data={employee} id={employee.id} key={index.toString()} />
            })
          }
        </tbody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  align-self: center;
  width: 75%;
  min-width: 700px;
  max-width: 900px;
`

export default MtbEmployeeList;