import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import PartnerInputs from './children/PartnerInputs';
import DialogMessage from 'src/common/DialogMessage';
//functions
import PartnerFunctions from 'src/functions/mtbFunctions/PartnerFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  提携先編集コンポーネント
  保存ボタンからDB送信
  キャンセルボタンから詳細に戻る
*/
const MtbPartnerEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [partner, setPartner] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath} = state;
      data["updated_employee_name"] = employee_name;
      data["has_image_changed"] = false;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _partner = getData();
      setPartner({..._partner});
    }
  }, [employee_name, props]);

  const changePartnerData = useCallback((key, value) => {
    const _partner = {...partner};
    
    if (value !== null) {
      _partner[key] = value;
      if (key === 'signed_url' && !_partner["has_image_changed"]) {
        _partner["has_image_changed"] = true;
      }
      setPartner(_partner);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [partner, modalRequired]);

  const onSaveButtonPressed = useCallback(async () => {
    const partnerFunctions = new PartnerFunctions({ partnerData: partner, token: token });
    const response = await partnerFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current, state: {data: response.data}});
    }
  }, [partner, token, history, modalRequired, previousPath]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <PartnerInputs 
        data={partner}
        isEditing={true}
        errorText={errorText}
        changeData={changePartnerData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbPartnerEdit;