import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { Colors } from 'src/configs/StyleConfig';
//components
import { LabelStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { TextInput, TextHalfNumberInput, PostalCodeInput } from 'src/common/Inputs';
import Modal from 'react-modal';

const modalStyles = {
  content: {
    width: '40%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    border: 'none',
    overlay: {
      backgroundColor: '#fff',
    }
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)"
  },
};

const initialCustomerInfo = {
  customer_code: Number(),
  customer_name: String(),
  customer_kana: String(),
  customer_postal_code: String(),
  customer_address1: String(),
  customer_phone1: String(),
  memo: String(),
};

const customerInfo_Ja = {
  customer_code: '顧客No',
  customer_name: '氏名',
  customer_kana: 'かな',
  customer_postal_code: '郵便番号',
  customer_address1: '住所',
  customer_phone1: '電話番号',
  memo: 'メモ',
};

const Column = (props) => {
  const { label } = props;
  return (
    <div style={{ position: 'relative' }}>
      <ColumnLabelStyle>
        {label}
      </ColumnLabelStyle>
      <ColumnContainer>
        {props.children}
      </ColumnContainer>
    </div>
  )
}

Column.propTypes = {
  label: PropTypes.string.isRequired,
};

Column.defaultProps = {
  label: '',
};

const CustomerSearchModal = (props) => {
  const { modalIsOpen, setModalIsOpen, customerList, setSearchResult, setSearched } = props;
  const [customerInfo, setCustomerInfo] = useState(initialCustomerInfo);

  const changeCustomerInfo = useCallback((text, key) => {
    // const text = e.target.value;
    const json = { ...customerInfo };
    json[key] = text;
    setCustomerInfo(json);
  }, [customerInfo])

  // 条件絞り込み検索
  const narrowDown = () => {
    setSearchResult(() => []); // 検索初期化
    const narrowDownKey = []; // 入力されたもののkeyのみをとってきて格納
    const searchItem = []; // AND検索をするために、全ての項目が一致するかどうかをbinaryで格納。一個でもfalseがある場合は、検索から弾く。
    const new_customerInfo = Object.assign({}, customerInfo); // 入力された項目のみで連想配列を再生成 .assignを利用しないと元データも変化してしまう。

    // 1. 入力欄が空白の要素を除外する
    for (let key in new_customerInfo) {
      if (new_customerInfo[key].length == 0 || new_customerInfo[key] == 0) {
        delete new_customerInfo[key]
      }
    }

    // 2. 入力欄に入力されたもののkeyでarrayを作成
    for (var i in new_customerInfo) {
      narrowDownKey.push(i)
    }

    // 3. customerListをloopさせ、それぞれの要素との一致を見ていく。
    Object.values(customerList).filter((output, index) => {
      // 4. 2で作成した"入力された項目"のみをそれぞれ照らし合わせて検索していく。
      for (var i in narrowDownKey) {
        if ((output[narrowDownKey[i]].includes(new_customerInfo[narrowDownKey[i]]))) {
          searchItem.push(true) // 5. 一致した場合はtrueを入れていく。
        } else {
          return;
        };
      }

      // 6. 5で作ったtrue/falseのarrayで、中身が全部trueだった場合、AND検索で完全一致なので、そのcustomerを検索結果に返す。
      if (!searchItem.includes(false)) {
        setSearchResult(prev => [...prev, output]);
      }

    });
    setSearched(true); // 検索したかどうかを trueにする
    setModalIsOpen(false); // modalを閉じる。
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <TitleContainer>
          <HeaderLabelStyle>
            条件絞り込み
          </HeaderLabelStyle>
        </TitleContainer>
        <InputFieldStyle>
          <TableStyle>
            <Column label={'顧客情報'}>
              {
                Object.keys(customerInfo).map((key) => {
                  if (key === 'customer_postal_code') {
                    return (
                      <CustomerInputContainer key={key}>
                        <CustomerInputLabelStyle>
                          {customerInfo_Ja[key]}
                        </CustomerInputLabelStyle>
                        <PostalCodeInput
                          isEnable={true}
                          value={customerInfo[key]}
                          onChange={(e) => changeCustomerInfo(e.target.value, key)}
                          onChangeAddress={(address) => changeCustomerInfo(address, 'customer_address1')}
                        />
                      </CustomerInputContainer>
                    )
                  } else if (key === 'customer_code' || key === 'customer_phone1') {
                    return (
                      <CustomerInputContainer key={key}>
                        <CustomerInputLabelStyle>
                          {customerInfo_Ja[key]}
                        </CustomerInputLabelStyle>
                        <TextHalfNumberInput value={customerInfo[key]} onChange={(e) => changeCustomerInfo(e.target.value, key)} />
                      </CustomerInputContainer>
                    )
                  }
                  return (
                    <CustomerInputContainer key={key}>
                      <CustomerInputLabelStyle>
                        {customerInfo_Ja[key]}
                      </CustomerInputLabelStyle>
                      <TextInput value={customerInfo[key]} onChange={(e) => changeCustomerInfo(e.target.value, key)} />
                    </CustomerInputContainer>
                  )
                })
              }
            </Column>
          </TableStyle>
        </InputFieldStyle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
          <CancelButton onClick={() => setModalIsOpen(false)}>キャンセル</CancelButton>
          <SubmitButton onClick={() => narrowDown()}>絞り込む</SubmitButton>
        </div>
      </Modal>
    </div >
  )
}

const HeaderLabelStyle = styled(LabelStyle)`
  background-color: #3f3f3f;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  width: 120px;
  margin-left: 8px;
  padding: 4px 12px;
  `
const TitleContainer = styled.div`
  height: 35px;
`
const InputFieldStyle = styled.div`
  height: 350px;
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: center;
  margin-top: 12px;
`
const TableStyle = styled.div`
  height: 100%;
  width: 80%;
  padding: 10px;
  margin-right: auto;
  `
const CustomerInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
`
const CustomerInputLabelStyle = styled.div`
  width: ${props => props.Selection ? '88px' : '120px'};
  text-align: end;
  padding-right: 8px;
`
const ColumnLabelStyle = styled.div`
  position: absolute;
  top:0;
  left:0;
  min-width: 80px;
  max-width: 180px;
  padding: 0 12px;
  border: 1px solid ${Colors.border};
  background-color: white;
  z-index: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`
const ColumnContainer = styled.div`
  position: absolute;
  top:12px;
  left:0;
  border: 1px solid ${Colors.border};
  padding: 12px;
  width: 100%;
  height: auto;
`

export default CustomerSearchModal;