import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { FontSize } from 'src/configs/StyleConfig';
//components
import { TextHalfNumberInput } from 'src/common/Inputs';
import DateSelector from 'src/common/DateSelector';
import DropDownSelection from 'src/common/DropDownSelection';
//functions
import DateFunctions from 'src/functions/DateFunctions';

/*
  シロアリ防除、防かび、損害賠償（上の部分のみ）テキストボックス群表示コンポーネント
  Props:
  isEditable＝True -> 編集可
  isDamage=True　-> 損害賠償の表示（シロアリと防かびとは表示するテキストが異なるため）
*/
const GeneralWarrantyEdit = (props) => {
  
  const { generalWarrantyInfo, changeGeneralWarrantyInfo, isEditable, isDamage } = props;

  const warrantyPeriodOption = [
    {value: 5, label: '5年'},
    {value: 10, label: '10年'},
  ];

  const areaUnitOptions = [
    {value: '', label: ' '},
    {value: '㎡', label: '㎡'},
    {value: '坪', label: '坪'}
  ];

  /*
    保証期間の開始日もしくは保証期間が変わるたび呼び出される関数
    保証期間と開始日より、５年もしくは１０年後の保証期間終了日を計算し格納
  */
  useEffect(() => {

    const updateWarrantyEndDate = (period, startDate) => {
      const _period = parseInt(period);
      let endDate = '';
      if (_period === 5) {
        endDate = DateFunctions.addYears(DateFunctions.subtractDays(startDate, 1), 5);
      } else {
        endDate = DateFunctions.addYears(DateFunctions.subtractDays(startDate, 1), 10);
      }
      changeGeneralWarrantyInfo("warranty_end_date", endDate);
    }

    if (generalWarrantyInfo.warranty_period && generalWarrantyInfo.warranty_start_date) {
      updateWarrantyEndDate(generalWarrantyInfo.warranty_period, generalWarrantyInfo.warranty_start_date);
    }
  }, [generalWarrantyInfo.warranty_period, generalWarrantyInfo.warranty_start_date]);




  return (
    <div style={{ 'padding': 16 }}>
      <InputWrapper>
        <LabelWrapper>
          証書番号：
        </LabelWrapper>
        <TextInputWrapper>
          <TextHalfNumberInput
            value={generalWarrantyInfo.certificate_no ? generalWarrantyInfo.certificate_no : ""}
            onChange={(val) => changeGeneralWarrantyInfo("certificate_no", val.target.value)}
            isEnable={isEditable}
          />
        </TextInputWrapper>
      </InputWrapper>
      <InputWrapper>
        <LabelWrapper>
          作成年月日：
        </LabelWrapper>
        <TextInputWrapper>
          <DateSelector isEnable={isEditable} date={generalWarrantyInfo.create_date} onChange={(val) => changeGeneralWarrantyInfo("create_date", val)}/>
        </TextInputWrapper>
      </InputWrapper>
      {
        isDamage ?
        null
        :
        <InputWrapper>
          <LabelWrapper>
            作業実地面積：
          </LabelWrapper>
          <WorkAreaWrapper>
            <TextHalfNumberInput
              isEnable={isEditable}
              value={generalWarrantyInfo.work_area ? generalWarrantyInfo.work_area : ""}
              onChange={(val) => changeGeneralWarrantyInfo("work_area", val.target.value)}
            />
            <div style={{ height: '32px','width': '10%', 'minWidth': 80, 'padding': '4px' }}>
              <DropDownSelection 
                options={areaUnitOptions}
                value={generalWarrantyInfo.building_area_unit}
                onChange={(val) => changeGeneralWarrantyInfo("building_area_unit", val.target.value)}
                isEnable={isEditable} 
              />          
            </div>
          </WorkAreaWrapper>
        </InputWrapper>
      }
      <div style={{ 'display': 'flex', 'paddingTop': 4 }}>
        <LabelWrapper>
          保証期間：
        </LabelWrapper>
        <div style={{ 'width': '16%', 'minWidth': 200 }}>
          <DateSelectWrapper>
            <DateSelector isEnable={isEditable} date={generalWarrantyInfo.warranty_start_date} onChange={(val) => changeGeneralWarrantyInfo("warranty_start_date", val)}/>
            <LabelInBetweenWrapper>
              から
            </LabelInBetweenWrapper>
          </DateSelectWrapper>
          <DateSelectWrapper>
            <DateSelector isEnable={false} date={generalWarrantyInfo.warranty_end_date}/>
            <LabelInBetweenWrapper>
              まで
            </LabelInBetweenWrapper>
          </DateSelectWrapper>
        </div>
        <div style={{ 'width': '10%', 'minWidth': 120, 'padding': '4px' }}>
          <DropDownSelection 
            options={warrantyPeriodOption}
            value={generalWarrantyInfo.warranty_period}
            onChange={(val) => changeGeneralWarrantyInfo("warranty_period", val.target.value)}
            isEnable={isEditable} 
          />
        </div>
      </div>
    </div>
  )
};

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`
const LabelWrapper = styled.div`
  width: 180px;
  text-align: right;
  font-size: ${FontSize.md};
`
const TextInputWrapper = styled.div`
  max-width: 250px;
  min-width: 180px;
  width: 20%;
`
const WorkAreaWrapper = styled(TextInputWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const LabelInBetweenWrapper = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;

`
const DateSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

GeneralWarrantyEdit.propTypes = {
  generalWarrantyInfo: PropTypes.object.isRequired,
  changeGeneralWarrantyInfo: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isDamage: PropTypes.bool,
};

GeneralWarrantyEdit.defaultProps = {
  generalWarrantyInfo: {},
  changeGeneralWarrantyInfo: () => {},
  isEditable: true,
  isDamage: false,
};



export default GeneralWarrantyEdit;
