import React, { useState, useCallback, useEffect, memo } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
import Big from 'big.js';
//config & styles
import { BreakPoints, Colors, FontSize } from 'src/configs/StyleConfig';
//components
import Button from 'src/common/Button';
import { InputLabelStyle } from 'src/common/StylesComponents';
import { TextHalfNumberInput, TextInput } from 'src/common/Inputs';
import Checkbox from 'src/common/Checkbox';
import DropDownSelection from 'src/common/DropDownSelection';
import ProductSelectionModal from './ProductSelectionModal';
//images
import DeleteButton from 'src/static/delete.png';
//mixins
import PriceFunctions from 'src/functions/PriceFunctions';
//redux
import { useSelector } from 'react-redux';

const baseColumnNames = {
  delete: { label: '', width: '2%', align: 'center' },
  product_code: { label: 'CD', width: '4%', align: 'left' },
  jan_code: { label: 'JAN', width: '8%', align: 'left' },
  content: { label: '内容', width: '12%', align: 'left' },
  is_warranty: { label: '保証書の有無', width: '8%', align: 'left' },
  quantity: { label: '数量', width: '4%', align: 'center' },
  unit: { label: '単位', width: '2.5%', align: 'center' },
  unit_price: { label: '単価価格', width: '6%', align: 'right' },
  price: { label: '金額', width: '8%', align: 'right' },
}

const ConstructionBreakdown = (props) => {

  const { isEditable, isButtonEnabled, tableContents, taxInfo, changeTableInfo, changeTaxInfo, handleNavigation, partnerMargin, onCalculateMargin, addDeletingConstructionDetail, allowMarginUpdate } = props;
  const { productData } = useSelector(state => state.master);
  const [columnNames, setColumnNames] = useState(baseColumnNames);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    initTableColumn();
    if (tableContents.breakdown.length > 0) {
      calculateTotal(tableContents.breakdown);
    }
  }, []);

  const initTableColumn = () => {
    if (typeof isEditable === 'boolean' && !isEditable) { //詳細表示　＝＝＞ デリートボタンを非表示
      const _columnNames = { ...columnNames };
      delete _columnNames.delete;
      setColumnNames(_columnNames);
    }
  }

  const addRow = useCallback(() => {
    //工事内訳の追加ボタンを押した後に空欄の行を追加する処理
    const updatedData = [...tableContents.breakdown];
    const newData = { id: null, product_code: '', jan_code: '', content: '', is_warranty: 0, warranty_period: null, quantity: 1, unit: '', unit_price: 0, price: 0 };
    updatedData.push(newData)
    calculateTotal(updatedData);
    changeTableInfo('breakdown', updatedData);
  }, [tableContents, partnerMargin]);

  const addRowFromProductList = useCallback(() => {
    setIsModalOpen(true);
  }, [tableContents, productData, partnerMargin]);

  const addRowFromList = useCallback((index) => {
    //工事内訳のリストからボタンを押した後に選択されたマスタのデータを格納した行を追加する処理
    const updatedData = [...tableContents.breakdown];
    const initialQuantity = 1;
    const productContent = productData[index];
    const indexFound = updatedData.findIndex((row) => row.content === productContent.product_name);
    if (indexFound === -1) {
      //選択された商品がすでに登録されている工事詳細にない場合に行追加
      const newRow = { id: null, product_code: '', jan_code: productContent.jan_code, content: productContent.product_name, is_warranty: 0, warranty_period: null, quantity: initialQuantity, unit: productContent.unit, unit_price: productContent.price, price: initialQuantity * productContent.price };
      updatedData.push(newRow);
      calculateTotal(updatedData)
      changeTableInfo('breakdown', updatedData);
    }

  }, [tableContents, productData, partnerMargin]);

  const deleteRow = useCallback((index) => {
    //行の消去
    //選択行にIdがある場合（編集）→ 工事詳細消去リストに格納
    //選択行にIdがない場合（未登録）→ そのままtableContents.breakdownから消去
    const updatedData = [...tableContents.breakdown];
    if (updatedData[index].id) {
      addDeletingConstructionDetail(updatedData[index].id);
    }
    updatedData.splice(index, 1);
    calculateTotal(updatedData);
    changeTableInfo('breakdown', updatedData);
  }, [tableContents, partnerMargin]);

  const calculateTotal = (updatedData) => {
    //小計などの値を計算する処理（行の追加、消去、消費税率、消費税設定、小数点処理の変更後再計算される）
    const updatedTotal = { ...tableContents.breakdownTotal };
    const taxRatio = taxInfo.tax_rate / 100;
    const { tax_division, rounding_division } = taxInfo;
    let subtotal = 0; //小計
    let tax_price = 0; //消費税額	
    let total = 0; //税込額
    let subtotal_margin = 0;
    let tax_exclude = 0;
    let tax_include = 0;
    if (updatedData.length > 0) { //明細行が１以上の時の小計の計算
      subtotal = updatedData.map((row) => row.price && row.price).reduce((previousValue, currentValue) => previousValue = previousValue + currentValue);
    }
    subtotal = decimalPointRounder(rounding_division, subtotal); //小計での小数点処理の適応

    if (tax_division === 0) { // 外税 === 0
      const value = subtotal * taxRatio;
      tax_exclude = decimalPointRounder(rounding_division, value);
    } else { // 内税 === 1
      const value = subtotal * taxRatio / (1 + taxRatio);
      tax_include = decimalPointRounder(rounding_division, Math.round(value));
    }
    subtotal_margin = parseInt(subtotal * partnerMargin * 0.01); //マージン＊小計

    tax_price = tax_exclude + tax_include; //消費税額	
    total = subtotal + tax_exclude; //税込額
    updatedTotal['subtotal']['value'] = subtotal //小計
    updatedTotal['tax_price']['value'] = tax_price; //消費税額
    updatedTotal['total']['value'] = parseInt(total); //税込額
    updatedTotal['subtotal_margin']['value'] = subtotal_margin; //マージン＊小計
    changeTaxInfo('tax_exclude', tax_exclude);
    changeTaxInfo('tax_include', tax_include);
    changeTableInfo('breakdownTotal', updatedTotal);
  };

  const decimalPointRounder = (rounding_division, value) => {
    if (rounding_division === 0) {//切り捨て
      return Math.floor(value);
    } else if (rounding_division === 1) {//四捨五入
      return Math.round(value);
    }
    //切り上げ
    return Math.ceil(value);
  }

  const changeTextInput = useCallback((key, val) => {
    //税関係の値の変更を担う関数
    const { value } = val.target;
    if (value) {
      changeTaxInfo(key, value);
      if (key === 'tax_rate') {
        calculateTotal(tableContents.breakdown)
      }
    } else {
      changeTaxInfo(key, "");
    }
  }, [tableContents, partnerMargin]);

  //// value update functions
  const updateTableData = useCallback((updatingIndex, columnName, value) => {
    //テーブル内の値の編集を担う関数

    let copiedData = [...tableContents.breakdown];

    if (columnName === 'unit_price') {
      copiedData[updatingIndex][columnName] = value;
    } else if (columnName === 'quantity') {
      copiedData[updatingIndex][columnName] = value;
    } else if (columnName === 'is_warranty') {
      copiedData[updatingIndex][columnName] = parseInt(value);
      copiedData[updatingIndex]['warranty_period'] = parseInt(value) === 1 ? 5 : null;
    } else if (columnName === 'warranty_period') {
      copiedData[updatingIndex][columnName] = parseInt(value);
    } else {
      copiedData[updatingIndex][columnName] = value;
    }
    changeTableInfo(copiedData);
  }, [tableContents, partnerMargin]);

  const replaceHalfToFull = (str) => {
    //数字の全角ー＞半角切り替えの関数
    if (typeof str === 'number') str = str.toString();

    let val = str.replace(/[！-～]/g,
      function (tmpStr) {
        // 文字コードをシフト
        return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0);
      }
    );

    return val.replace(/”/g, "\"")
      .replace(/’/g, "'")
      .replace(/‘/g, "`")
      .replace(/￥/g, "\\")
      .replace(/　/g, " ")
      .replace(/〜/g, "~")
      .replace(/。/g, ".")
      .replace(/、/g, ".")
      .replace(/−/g, "-")
      .replace(/ー/g, "-");
  }

  const onBlurToCalculateTotal = useCallback((updatingIndex, columnName, value) => {
    //合計金額に関わるカラム、単価価格と数量を変更し、テキストボックスを離れた時に合計金額を計算するロジックが走る
    let copiedData = [...tableContents.breakdown];
    let unit_price = 0;
    let quantity = 0;

    if (columnName === 'unit_price') {
      //全角を半角に変換
      const val = replaceHalfToFull(value);
      unit_price = parseInt(val) ? parseInt(val) : 0;
      quantity = copiedData[updatingIndex]["quantity"];
    } else if (columnName === 'quantity') {
      //全角を半角に変換後、数量をフロートに直す
      const val = replaceHalfToFull(value.toString());
      quantity = parseFloat(val) ? parseFloat(val) : 0.0;
      unit_price = copiedData[updatingIndex]["unit_price"];
    }

    // 単価x数量で価格を出して、合計金額を計算する処理
    Big.RM = Big.roundDown;
    const price = new Big(unit_price).times(quantity).toNumber(); //小数点以下切り捨て (e.g.: 235,870.00,000,000,003 --> 235,870)
    copiedData[updatingIndex]["unit_price"] = unit_price;
    copiedData[updatingIndex]["quantity"] = quantity;
    copiedData[updatingIndex]["price"] = price;
    calculateTotal(copiedData);
  }, [tableContents, partnerMargin])

  const updateToggleGroup = useCallback((key, index, val) => {
    //消費税設定と小数点処理のトグルの変更を担う関数
    //値は一つでそれ以外はoff
    const { value } = val.target;
    if (key === 'tax_division') {
      if (typeof value === 'string' && value === 'on') {
        changeTaxInfo(key, index);
        calculateTotal(tableContents.breakdown)
      }
    } else if (key === 'rounding_division') {
      if (typeof value === 'string' && value === 'on') {
        changeTaxInfo(key, index);
        calculateTotal(tableContents.breakdown)
      }
    }
  }, [tableContents, partnerMargin]);

  const showTaxDivisionState = useCallback((id) => {
    //　外税・内税を表示する
    if (id === taxInfo.tax_division) {
      return true;
    }
    return false;
  }, [taxInfo]);

  const showRoundingDivisionState = useCallback((id) => {
    //  小数点処理の表示
    if (id === taxInfo.rounding_division) {
      return true;
    }
    return false;
  })


  return (
    <Container>
      {
        isModalOpen ?
          <ProductSelectionModal setIsModalOpen={setIsModalOpen} onProductSelected={(index) => addRowFromList(index)} />
          :
          null
      }
      <HeaderContainer>
        <h4>
          工事内訳
        </h4>
        {
          isButtonEnabled ?
            <ButtonContainer>
              <Button text={'保証情報'} disabled={isEditable} onClick={() => handleNavigation("WARRANTY_INFO")} />
              <Button text={'ファイル取込'} disabled={isEditable} onClick={() => handleNavigation("FILE_UPLOAD")} />
            </ButtonContainer>
            :
            <ButtonContainer>
              <Button text={'保証情報'} disabled={true} onClick={() => handleNavigation("WARRANTY_INFO")} />
              <Button text={'ファイル取込'} disabled={true} onClick={() => handleNavigation("FILE_UPLOAD")} />
            </ButtonContainer>
        }

      </HeaderContainer>
      <MainContainer>
        <TableContainer>
          <Table>
            <Thead>
              <tr>
                {
                  Object.keys(columnNames).map((cName) => {
                    return (
                      <Th width={columnNames[cName].width} align={columnNames[cName].align} key={cName} >{columnNames[cName].label}</Th>
                    );
                  })
                }
              </tr>
            </Thead>
            <TableBody>
              {
                ///工事内訳テーブル
                tableContents.breakdown.map((row, index) => {
                  if (isEditable) {
                    return <EditableRow
                      row={row}
                      columnNames={columnNames}
                      index={index}
                      deleteRow={deleteRow}
                      updateTableData={(id, columnName, value) => updateTableData(id, columnName, value)}
                      onBlurToCalculateTotal={(id, columnName, value) => onBlurToCalculateTotal(id, columnName, value)}
                      key={index.toString()}
                    />
                  }
                  return <Row
                    row={row}
                    columnNames={columnNames}
                    key={index.toString()}
                  />
                })
              }
              {
                //テーブル下の行追加
                isEditable ?
                  <Tr>
                    <Td width={'100%'} align={'left'} style={{ padding: '4px 8px' }}>
                      <ButtonStyle onClick={addRow}>+ 追加</ButtonStyle>
                      <ButtonStyle onClick={addRowFromProductList}>リストから選択</ButtonStyle>
                    </Td>
                  </Tr>
                  :
                  null
              }
            </TableBody>
            <TableTotalBody>
              {
                //小計、消費税額などの表示
                Object.keys(tableContents.breakdownTotal).map((row) => {
                  return <TotalSection total={tableContents.breakdownTotal} row={row} key={row.toString()} />
                })
              }
            </TableTotalBody>
          </Table>
        </TableContainer>
        <OtherInfoContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'}>
              消費税率
            </LabelStyle>
            <div style={{ width: '60%', maxWidth: '180px' }}>
              <TextInput value={taxInfo.tax_rate.toString()} textType={'number'} onChange={(val) => changeTextInput("tax_rate", val)} isEnable={isEditable} />
            </div>
          </TextContainer>
          <TextContainer style={{ alignItems: 'flex-start' }}>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 8 }}>
              消費税設定
            </LabelStyle>
            <CheckboxContainer>
              <Checkbox
                isChecked={showTaxDivisionState(0)}
                changeStatus={(val) => updateToggleGroup('tax_division', 0, val)}
                label={'外税'}
                fontSize={12}
                type={'radio'}
              />
              <Checkbox
                isChecked={showTaxDivisionState(1)}
                changeStatus={(val) => updateToggleGroup('tax_division', 1, val)}
                label={'内税'}
                fontSize={12}
                type={'radio'}
              />
            </CheckboxContainer>
          </TextContainer>
          <TextContainer style={{ alignItems: 'flex-start' }}>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 8 }}>
              小数点処理
            </LabelStyle>
            <CheckboxContainer>
              <Checkbox
                isChecked={showRoundingDivisionState(0)}
                changeStatus={(val) => updateToggleGroup('rounding_division', 0, val)}
                label={'切り捨て'}
                fontSize={12}
                type={'radio'}
              />
              <Checkbox
                isChecked={showRoundingDivisionState(1)}
                changeStatus={(val) => updateToggleGroup('rounding_division', 1, val)}
                label={'四捨五入'}
                fontSize={12}
                type={'radio'}
              />
              <Checkbox
                isChecked={showRoundingDivisionState(2)}
                changeStatus={(val) => updateToggleGroup('rounding_division', 2, val)}
                label={'切り上げ'}
                fontSize={12}
                type={'radio'}
              />
            </CheckboxContainer>
          </TextContainer>
          <StampInputContainer>
            <StampLabel Selection align={'flex-start'}>
              印紙
            </StampLabel>
            <StampWrapper>
              <StampContainer>
                <label style={{ fontSize: 12 }}>
                  合計金額
                </label>
                <div style={{ width: '80%', maxWidth: '180px' }}>
                  <TextHalfNumberInput value={taxInfo.stamp_price} onChange={(val) => changeTextInput('stamp_price', val)} textType={'number'} isEnable={isEditable} />
                </div>
              </StampContainer>
              <StampContainer>
                <label style={{ fontSize: 12 }}>
                  合計枚数
                </label>
                <div style={{ width: '80%', maxWidth: '180px' }}>
                  <TextHalfNumberInput value={taxInfo.stamp_amount} onChange={(val) => changeTextInput('stamp_amount', val)} textType={'number'} isEnable={isEditable} />
                </div>
              </StampContainer>
            </StampWrapper>
          </StampInputContainer>
          <MemoContainer>
            <TextContainer style={{ paddingTop: 20 }}>
              <div style={{ width: '100%' }}>
                <LabelStyle Selection align={'flex-start'}>
                  メモ
                </LabelStyle>
              </div>
            </TextContainer>
            <MemoTextArea disabled={!isEditable} value={taxInfo.memo} onChange={(val) => changeTextInput('memo', val)} />
          </MemoContainer>
          <div style={{ width: '100%', padding: '16px 8px' }}>
            {
              isEditable && allowMarginUpdate ?
                <Button text={'マージン再計算'} onClick={onCalculateMargin} />
                :
                null
            }
          </div>
        </OtherInfoContainer>
      </MainContainer>
    </Container>
  )
};


const Row = (props) => {
  const { row, columnNames } = props;

  return (
    <Tr>
      {
        Object.keys(row).map((columns) => {
          if (Object.keys(columnNames).includes(columns)) {
            if (columns === 'is_warranty') { // 保証書の有無
              if (typeof row[columns] === 'number' && row[columns] === 1) {
                //保証あり
                return (
                  <>
                    <Td width={'4%'} align={columnNames[columns].align} key={columns}>
                      {'有り'}
                    </Td>
                    <Td width={'4%'} align={columnNames[columns].align} key={"warranty_period"}>
                      {`${row['warranty_period']}年`}
                    </Td>
                  </>
                )
              } else if (typeof row[columns] === 'number' && row[columns] === 0) {
                //保証なし
                return (
                  <>
                    <Td width={'4%'} align={columnNames[columns].align} key={columns}>
                      {'無し'}
                    </Td>
                    <Td width={'4%'} align={columnNames[columns].align} key={"warranty_period"}>
                      {""}
                    </Td>
                  </>
                )
              } else {
                return <Td width={columnNames[columns].width} key={columns} />
              }
            } else if (columns === 'unit_price' || columns === 'price') { // 単価価格, 金額
              return (
                <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                  {PriceFunctions.intPriceToStringPrice(row[columns])}
                </Td>
              )
            } else if (columns === 'jan_code') { // JAN
              return (
                <Td style={{ fontSize: 12 }} width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                  {row[columns]}
                </Td>
              )
            }
            // CD, 内容, 数量, 単位
            return (
              <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                {row[columns]}
              </Td>
            )
          }
        })
      }
    </Tr>
  )
}

const warrantyOption = [
  { value: 0, label: '無し' },
  { value: 1, label: '有り' },
];

const warrantyDurationOption = [
  { value: 5, label: '5年' },
  { value: 10, label: '10年' },
];

const EditableRow = memo((props) => {
  const { row, index, columnNames, updateTableData, onBlurToCalculateTotal, deleteRow } = props;

  const onNumberInput = (index, columnName, value) => {
    // 数量と単価価格は数字の入力だけ受け入れる
    const re = /^(\s*|[-0-9.。ー–１２３４５６７８９０]+)$/
    if (re.test(value)) {
      updateTableData(index, columnName, value)
    }
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  return (
    <Tr>
      {
        Object.keys(columnNames).map((columns) => {
          if (columns === 'is_warranty') { //保証書の有無
            if (typeof row[columns] === 'number' && row[columns] === 1) {
              //保証あり
              return (
                <>
                  <Td width={'4%'} align={columnNames[columns].align} key={columns}>
                    <div style={{ paddingTop: 4 }}>
                      <DropDownSelection
                        options={warrantyOption}
                        value={`${row[columns]}`}
                        onChange={(val) => updateTableData(index, columns, val.target.value)}
                        isEnable={true}
                      />
                    </div>
                  </Td>
                  <Td width={'4%'} align={columnNames[columns].align} key={"warranty_period"}>
                    <div style={{ paddingTop: 4 }}>
                      <DropDownSelection
                        options={warrantyDurationOption}
                        value={row['warranty_period']}
                        onChange={(val) => updateTableData(index, "warranty_period", val.target.value)}
                        isEnable={true}
                      />
                    </div>
                  </Td>
                </>
              )
            } else if (typeof row[columns] === 'number' && row[columns] === 0) {
              //保証なし
              return (
                <>
                  <Td width={'4%'} align={columnNames[columns].align} key={columns}>
                    <div style={{ paddingTop: 4 }}>
                      <DropDownSelection
                        options={warrantyOption}
                        value={`${row[columns]}`}
                        onChange={(val) => updateTableData(index, columns, val.target.value)}
                        isEnable={true}
                      />
                    </div>
                  </Td>
                  <Td width={'4%'} align={columnNames[columns].align} key={"warranty_period"}>
                    {""}
                  </Td>
                </>
              )
            } else {
              return <Td width={columnNames[columns].width} key={columns} />
            }
          } else if (columns === 'unit_price') { //単価価格	
            return (
              <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                <EditableTd
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  type="text"
                  value={row[columns]}
                  onChange={(e) => onNumberInput(index, columns, e.target.value)}
                  onBlur={() => onBlurToCalculateTotal(index, columns, row[columns])}
                  style={{ textAlign: "right" }}
                  onKeyDown={handleKeyPress}
                />
              </Td>
            )
          } else if (columns === 'price') {//金額
            return (
              <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                {PriceFunctions.intPriceToStringPrice(row[columns])}
              </Td>
            )
          } else if (columns === 'delete') { //行消去ボタン
            return (
              <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                <Icon onClick={() => deleteRow(index)}>
                  <img src={DeleteButton} alt="delete" style={{ height: '60%', width: '100%' }} />
                </Icon>
              </Td>
            )
          } else if (columns === 'quantity') { //数量
            return (
              <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
                <EditableTd
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  type="text"
                  value={row[columns]}
                  onChange={(e) => updateTableData(index, columns, e.target.value)}
                  onBlur={() => onBlurToCalculateTotal(index, columns, row[columns])}
                  onKeyDown={handleKeyPress}
                />
              </Td>
            )
          }
          // CD, JAN, 内容
          return (
            <Td width={columnNames[columns].width} align={columnNames[columns].align} key={columns}>
              <EditableTd value={row[columns]} onChange={(e) => updateTableData(index, columns, e.target.value)} />
            </Td>
          )
        })
      }
    </Tr>
  )
})

const TotalSection = (props) => {
  const { row, total } = props;
  const bgColor = row === 'commission' ? 'lightgray' : '#f2f2f2';
  return (
    <Tr>
      {
        Object.keys(total[row]).map((key) => {
          if (key === 'label') {
            return ( //label
              <Td width={'84%'} align={'right'} style={{ paddingRight: 4, backgroundColor: bgColor }} key={key}>
                {total[row].label}
              </Td>
            )
          } else { //value
            return (
              <Td width={'16%'} align={'right'} key={key}>
                {PriceFunctions.intPriceToStringPrice(total[row].value)}
              </Td>
            )
          }
        })
      }
    </Tr>
  )
};


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HeaderContainer = styled.div`
  width: 80%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const MainContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: column;
  }
`
const TableContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 100%;
  }
`
const OtherInfoContainer = styled.div`
  width: 15%;
  flex-direction: column;
  padding: 12px;
  @media screen and (max-width: ${BreakPoints.lg}) {
    align-self: center;
    width: 50%;
    flex-direction: row;
  }
`
const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 4px;
`
const StampInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 4px;
  @media screen and (max-width: ${BreakPoints.lg}) {
    align-self: center;
    flex-direction: row;
  }
`
const LabelStyle = styled(InputLabelStyle)`
  font-size: ${FontSize.sm};
  font-weight: 500;
`
const CheckboxContainer = styled.div`
  width: 70%;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const StampWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  width: 100%; 
  padding-left: 12px;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 45%;
    min-width: 180px;
    flex-direction: column;
  }
`
const StampLabel = styled(InputLabelStyle)`
  width: 100%;
  font-size: ${FontSize.sm};
  font-weight: 500;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 60px;
  }
`
const StampContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const MemoContainer = styled.div`
  display: flex;
  flex-direction: column; 
  width: 100%; 
  padding-left: 12px;
`
const Table = styled.table`
  width: 95%;
  border-collapse: collapse;
  padding: 20px auto;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 100%;
  }
`
const Th = styled.th`
  color: ${Colors.textColor};
  font-size: 14px;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
  padding: 4px;
`
const Thead = styled.thead`
  width: 100%;
  table-layout: fixed;
  display: table;
`
const TableBody = styled.tbody`
  border: 1px solid gray;
  width: 100%;
  height: 56vh; 
  overflow: auto;
  display: block;
`
const TableTotalBody = styled.tbody`
  border: 1px solid gray;
  width: 100%;
  overflow: auto;
  display: block;
`
const Tr = styled.tr`
  width: 100%;
  height: 40px;
  table-layout: fixed;
  display: table;
  font-size: 14px;
  border-bottom: 1px solid lightgray;
`
const Td = styled.td`
  color: ${Colors.textColor};
  font-weight: 500;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}px`};
  border-right: 1px solid lightgray;
`
const EditableTd = styled.input`
  margin: 0 auto;
  width: 92%;
  height: 96%;
  border: none;
  ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }  
`
const Icon = styled.button`
  border: none;
  width: 25px;
  height: 25px;
  background-color: transparent;
`
const ButtonStyle = styled.button`
  height: 24px;
  font-size: 10px;
  margin-left: 4px;
`
const MemoTextArea = styled.textarea`
  min-height: 30%;
  border: 1px solid black;
  :disabled {
    background-color: white;
  }
`

ConstructionBreakdown.propTypes = {
  isEditable: PropType.bool.isRequired, //詳細ページではtrue,　編集ページではfalse
  isButtonEnabled: PropType.bool, //role === 3 | 2 は営業所Idによってtrue
  tableContents: PropType.object.isRequired,
  taxInfo: PropType.object.isRequired,
  changeTableInfo: PropType.func.isRequired,
  changeTaxInfo: PropType.func.isRequired,
  handleNavigation: PropType.func.isRequired,
  partnerMargin: PropType.number,
  onCalculateMargin: PropType.func,
  addDeletingConstructionDetail: PropType.func,
  allowMarginUpdate: PropType.bool,
};

ConstructionBreakdown.defaultProps = {
  isEditable: false,
  isButtonEnabled: false,
  tableContents: {},
  taxInfo: {},
  changeTableInfo: () => { },
  changeTaxInfo: () => { },
  handleNavigation: () => { },
  partnerMargin: 0,
  onCalculateMargin: () => { },
  addDeletingConstructionDetail: () => { },
  allowMarginUpdate: false
};

export default ConstructionBreakdown;