import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
//components
import { BaseBodyStyle, BaseHeaderStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { CustomerInput, CustomerStatusList } from 'src/views/customer/common';
import DialogMessage from 'src/common/DialogMessage';
//configs & styles
import { BreakPoints, FontSize } from 'src/configs/StyleConfig';
//functions
import CustomerFunction from 'src/functions/CustomerFunctions';
import { sendLogData, logCategory, logTableType } from 'src/functions/LogFunctions';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerListData, setSelectedCustomerId } from 'src/redux/actions/CustomerDataHandler';


const CustomerRegister = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { employee_name, mtb_enterprise_id, mtb_sales_office_id, token } = useSelector(state => state.auth);
  const { salesOfficeData, enterpriseData } = useSelector(state => state.master);
  const [customerInfo, setCustomerInfo] = useState(
    {
      customer_name: '', customer_kana: '', customer_postal_code: '', customer_address1: '', customer_address2: '', customer_phone1: '', customer_phone2: '',
      memo: '', status: [
        { id: 0, status: '対応不可', isSelected: false },
        { id: 1, status: '信販不可', isSelected: false },
        { id: 2, status: '転居', isSelected: false },
        { id: 3, status: 'クレーム', isSelected: false }
      ],
      created_employee_name: '', updated_employee_name: '', mtb_enterprise_id: null, mtb_sales_office_id: null, customer_code: '', id: null
    }
  );
  const [enterpriseName, setEnterpriseName] = useState("");
  const [salesOfficeOption, setSalesOfficeOption] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const modalRequired = useRef(false);

  useEffect(() => {
    if (employee_name && mtb_enterprise_id && mtb_sales_office_id) {
      customerInfoInit();
      setIsLoading(false);
    }

  }, [employee_name, mtb_enterprise_id, mtb_sales_office_id]);

  const customerInfoInit = () => {
    // 会社名、作成者情報の初期化
    const _customerInfo = { ...customerInfo };
    _customerInfo["created_employee_name"] = employee_name;
    _customerInfo["updated_employee_name"] = employee_name;
    _customerInfo["mtb_enterprise_id"] = parseInt(mtb_enterprise_id) && parseInt(mtb_enterprise_id);
    _customerInfo["mtb_sales_office_id"] = parseInt(mtb_sales_office_id) && parseInt(mtb_sales_office_id);
    const _enterpriseName = enterpriseData[mtb_enterprise_id] && enterpriseData[mtb_enterprise_id].enterprise_name;
    setEnterpriseName(_enterpriseName);
    setCustomerInfo(_customerInfo);
    salesOptionInit(mtb_sales_office_id);
  };

  const salesOptionInit = () => {
    // 営業所の選択肢の初期化
    const _options = [];
    if (Object.keys(salesOfficeData).length === 0) return _options;

    Object.keys(salesOfficeData).map((key) => {
      const json = {
        value: key,
        label: salesOfficeData[key].sales_office_name
      };
      _options.push(json);
    });
    setSalesOfficeOption(_options);
  }

  const setStatusList = useCallback((id) => {
    //　対応不可、クレームなどのステータスの変更を行う
    const _customerInfo = { ...customerInfo };
    _customerInfo["status"][id].isSelected = !_customerInfo["status"][id].isSelected;
    setCustomerInfo(_customerInfo);
  }, [customerInfo]);

  const changeCustomerInfo = useCallback((key, value) => {
    // 全てのユーザーインプットの変更を行う関数
    const _customerInfo = { ...customerInfo };
    _customerInfo[key] = value;
    setCustomerInfo(_customerInfo);
  }, [customerInfo]);


  const onChangeSelection = useCallback((e) => {
    //営業所の変更
    const _selectedOption = e.target.value;
    const _customerInfo = { ...customerInfo };
    _customerInfo["mtb_sales_office_id"] = parseInt(_selectedOption) && parseInt(_selectedOption);
    setCustomerInfo(_customerInfo);
  }, [customerInfo]);

  const changeUnsavedStatus = () => {
    if (modalRequired.current) return;
    modalRequired.current = true;
  };
  useMemo(changeUnsavedStatus, [customerInfo]);

  const onSaveButtonPressed = useCallback(async () => {
    const customerFunctions = new CustomerFunction({ customerInfo: customerInfo, token: token });
    const response = await customerFunctions.registerData();
    if (typeof response === "object" && response.error) {
      setError(response.error);
      return;
    } else {
      const { insertLogId, insertId } = response.logs;
      const customerLog = {
        id: null,
        category: logCategory.register, //登録ログ
        remarks: '顧客情報登録',
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: logTableType.customer, //顧客ログ
        log_id: insertLogId,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      
      await dispatch(getCustomerListData());
      dispatch(setSelectedCustomerId(insertId));
      const json = [customerLog];
      const error = await sendLogData(json, token);
      modalRequired.current = false;
      setError('');
      history.replace(`/customer-list/${insertId}`);  
      // history.goBack();
    }
  }, [customerInfo, token, employee_name]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, []);

  if (isLoading) {
    return (
      <BaseBodyStyle main>
        <BaseHeaderStyle>
          <HeaderTitle>
            顧客登録
          </HeaderTitle>
        </BaseHeaderStyle>
      </BaseBodyStyle>
    )
  }
  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <BaseHeaderStyle>
        <HeaderTitle>
          顧客登録
        </HeaderTitle>
      </BaseHeaderStyle>
      <div style={{ width: '100%' }}>
        <CustomerStatusList
          statusList={customerInfo.status}
          setStatusList={setStatusList}
          enterprise={enterpriseName}
          dropdownSelection={salesOfficeOption}
          onChangeSelection={onChangeSelection}
          mtb_sales_office_id={customerInfo.mtb_sales_office_id}
          isEditable={true}
          isRegistering={false}
        />
      </div>
      <InputWrapper>
        <CustomerInput customerInfo={customerInfo} setCustomerInfo={changeCustomerInfo} isRegistering={true} isEditable={true} />
      </InputWrapper>
      <span style={{ color: 'red', fontSize: FontSize.lg, padding: '4px' }}>
        {error}
      </span>
      <ButtonMenu>
        <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
        <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
      </ButtonMenu>
    </BaseBodyStyle>
  );
};

const HeaderTitle = styled.h1`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50% , -50%);
  font-size: 20px;
`

const InputWrapper = styled.form`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.md}) {
    padding: 24px;
  }
`

const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 2px solid;
  padding: 16px 0;
`

export default CustomerRegister;