import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import ProductFavoriteTable from './children/ProductFavoriteTable';
import DialogMessage from 'src/common/DialogMessage';
//redux
import { useSelector } from 'react-redux';
//functions
import ProductFavoriteFunctions, { getFavoriteProducts } from 'src/functions/mtbFunctions/ProductFavoriteFunctions';


/*
  商品お気に入り登録編集コンポーネント
  商品をトグルでお気に入り登録する
  保存ボタンでDBに送信
  キャンセルボタンで表示画面へ推移
*/
const MtbProductFavoriteEdit = (props) => {

  const history = useHistory();

  const { mtb_sales_office_id, employee_name, token } = useSelector(state => state.auth);
  const [products, setProducts] = useState({});
  const [productFavoriteData, setProductFavoriteData] = useState({});
  const [errorText, setErrorText] = useState('');

  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {

    const initProductFavoriteData = async () => {
      const _productFavoriteData = await getFavoriteProducts(mtb_sales_office_id, token);
      setProductFavoriteData(_productFavoriteData);
    }

    const initProducts = () => {
      const { state } = props.location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const data = state && state.data;
      setProducts({...data});
    };

    if (mtb_sales_office_id && token) {
      initProductFavoriteData();
      initProducts();
    }
  }, [props, mtb_sales_office_id, token]);

/*
  トグルで商品をお気に入り＜ー＞非お気に入り
  切り替えする関数
*/
  const onChangeFavoriteStatus = useCallback((id) => {
    const _products = {...products};
    _products[id]['is_favorite'] = _products[id]['is_favorite'] === 0 ? 1 : 0; // stateを逆にする
    setProducts(_products);
    if (!modalRequired.current) modalRequired.current = true;
  }, [products]);

/*
  保存ボタンからDBに送信する関数
  送信後、表示画面へ戻る
*/
  const onSaveButtonPressed = useCallback(async () => {
    const productFavoriteFunction = new ProductFavoriteFunctions({ 
      productData: products,
      productFavoriteData: productFavoriteData,
      mtb_sales_office_id: mtb_sales_office_id, 
      employee_name: employee_name,
      token: token
    });
    const error = await productFavoriteFunction.sendData();
    if (error) {
      setErrorText(error);
    } else {
      modalRequired.current = false;
      history.replace({pathname: previousPath.current});
    }
  }, [history, products, productFavoriteData, mtb_sales_office_id, employee_name, token, modalRequired, previousPath]);
  

/*
  キャンセルボタンで表示画面へ戻る
*/
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <ProductFavoriteTable 
        data={products}
        isEditing={true}
        errorText={errorText}
        changeData={onChangeFavoriteStatus}
        onSaveButtonPressed={onSaveButtonPressed}
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbProductFavoriteEdit;