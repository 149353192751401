import React from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
//styles & config
import { Colors } from 'src/configs/StyleConfig';
//components
import Button from 'src/common/Button';
import Checkbox from 'src/common/Checkbox';

const CustomerStatusSection = (props) => {

  const { status, changeStatus } = props;

  return (
    <Container>
      <HeaderContainer>
        <h4 style={{ paddingLeft: '10%' }}>
          顧客ステータス
        </h4>
      </HeaderContainer>
      <ContractContainer>
        {
          status && status.map(row => {
            if (row.isSelected) {
              return <Checkbox key={row.status} label={row.status} isChecked={true} changeStatus={() => changeStatus(row.id)} />
            }
            return <Checkbox key={row.status} label={row.status} isChecked={false} changeStatus={() => changeStatus(row.id)} />
          })
        }
      </ContractContainer>
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const HeaderContainer = styled.div`
  width: 180px;
  height: 44px;
`
const ContractContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 12px 12px;
`
CustomerStatusSection.propTypes = {
  status: PropType.array.isRequired,
  changeStatus: PropType.func.isRequired
};

CustomerStatusSection.defaultProps = {
  status: [],
  changeStatus: () => { }
};


export default CustomerStatusSection;