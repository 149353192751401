import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//redux
import { useSelector } from 'react-redux';
//components
import {TextInput, TextHalfNumberInput, PostalCodeInput} from 'src/common/Inputs';
import styled from 'styled-components';
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import DropDownSelection from 'src/common/DropDownSelection';
//styles and configs
import { Colors } from 'src/configs/StyleConfig';

/*
  店舗情報テキスト表示、編集の子コンポーネント
*/
const StoreInputs = (props) => {

  const { data, isEditing, isRegistering, errorText, onChangeData, onEditButtonPressed, onSaveButtonPressed, onCancelButtonPressed, onDeleteButtonPressed } = props;
  const { store_code, mtb_partner_id, mtb_enterprise_id, store_name, store_kana, postal_code, address, phone } = data;
  const { partnerData, enterpriseData } = useSelector(state => state.master);
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);

  useEffect(() => {
    const initOptions = () => {
      const partnerOptionsData = setOptionData(partnerData, 'partner_name');
      const enterpriseOptionsData = setOptionData(enterpriseData, 'enterprise_name');
      setPartnerOptions(partnerOptionsData);
      setEnterpriseOptions(enterpriseOptionsData);
    }

    if (partnerData && enterpriseData) {
      initOptions();
    };

  }, [partnerData, enterpriseData]);

  /*
    提携先情報、企業情報のドロップダウン表示の用意
  */
  const setOptionData = (data, columnKey) => {
    let _options = [];
    _options.push({ label: '', value: ' ' });
    
    if (Object.keys(data).length === 0) return [];

    Object.keys(data).forEach((key) => {
      const json = {
        value: key,
        label: data[key][columnKey]
      };
      _options.push(json);
    })
    return _options;
  };


  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                店舗コード
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={store_code} onChange={(val) => onChangeData('store_code', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                企業
              </SettingList>
              <DropDownSelection
                options={enterpriseOptions}
                value={mtb_enterprise_id ? mtb_enterprise_id : ''}
                onChange={(val) => onChangeData('mtb_enterprise_id', val.target.value)}
                isEnable={isEditing}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                提携先
              </SettingList>
              <DropDownSelection
                options={partnerOptions}
                value={mtb_partner_id}
                onChange={(val) => onChangeData('mtb_partner_id', val.target.value)}
                isEnable={isEditing}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                店舗名
              </SettingList>
              <TextInput isEnable={isEditing} value={store_name} onChange={(val) => onChangeData('store_name', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                ふりがな
              </SettingList>
              <TextInput isEnable={isEditing} value={store_kana} onChange={(val) => onChangeData('store_kana', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                郵便番号
              </SettingList>
              <PostalCodeInput
                isEnable={isEditing}
                value={postal_code}
                onChange={(val) => onChangeData('postal_code', val.target.value)}
                onChangeAddress={(address) => onChangeData('address', address)}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                住所
              </SettingList>
              <TextInput isEnable={isEditing} value={address} onChange={(val) => onChangeData('address', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                電話番号
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={phone} onChange={(val) => onChangeData('phone', val.target.value)} />
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`

StoreInputs.propTypes = {
  data: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isRegistering: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  onChangeData: PropTypes.func,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

StoreInputs.defaultProps = {
  data: {},
  isEditing: false,
  isRegistering: false,
  errorText: '',
  onChangeData: () => { },
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default StoreInputs;