import axios from "axios";
//apis
import { CUSTOMER_SUPPORT_DATA_URL } from "src/apis";
//actions
import { setErrorMessage } from './ErrorMessageHandler';

export const SET_CUSTOMER_SUPPORT_DATA = "SET_CUSTOMER_SUPPORT_DATA";
export const SET_CUSTOMER_SUPPORT_DATA_FAILED = "SET_CUSTOMER_SUPPORT_DATA_FAILED";
export const SET_SELECTED_CUSTOMER_SUPPORT_DATA = "SET_SELECTED_CUSTOMER_SUPPORT_DATA";
export const SET_SELECTED_CUSTOMER_SUPPORT_DATA_FAILED = "SET_SELECTED_CUSTOMER_SUPPORT_DATA";
export const UPDATE_SELECTED_CUSTOMER_SUPPORT_DATA = "UPDATE_SELECTED_CUSTOMER_SUPPORT_DATA";
export const ADD_REGISTERING_CUSTOMER_SUPPORT_DATA = "ADD_REGISTERING_CUSTOMER_SUPPORT_DATA";
export const REMOVE_SELECTED_CUSTOMER_SUPPORT_DATA = "REMOVE_SELECTED_CUSTOMER_SUPPORT_DATA";

export const REMOVE_CUSTOMER_SUPPORT_DATA = "REMOVE_CUSTOMER_SUPPORT_DATA";

export const setCustomerSupportData = (mtb_customer_id) => async (dispatch, getState) => {
  // この関数は顧客対応履歴をDBから引っ張ってくる際に呼び出される関数
  // 顧客詳細ページの工事履歴・対応履歴テーブルにて表示される
  try {
    const { partnerData } = getState().master;
    const { token } = getState().auth;

    await axios.get(CUSTOMER_SUPPORT_DATA_URL, {
      params: {
        mtb_customer_id: mtb_customer_id
      },
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("顧客対応データを取得できませんでした。");
      })
      .then(({ data }) => {
        const processedData = dataPreprocessing(data, partnerData);
        dispatch({
          type: SET_CUSTOMER_SUPPORT_DATA,
          payload: { data: processedData }
        })
      })
  } catch (error) {
    dispatch({
      type: SET_CUSTOMER_SUPPORT_DATA_FAILED
    });
    dispatch(setErrorMessage({ where: "getCustomerSupportData", msg: error.message }));
  }
}

const dataPreprocessing = (data, partnerData) => {
  const _data = [...data];
  _data.map((customerSupportData) => {
    const { mtb_partner_id } = customerSupportData;
    const partnerName = mtb_partner_id ? partnerData[mtb_partner_id].partner_name : "";
    customerSupportData["business_partner"] = partnerName;
  });
  return _data;
};

export const removeSelectedCustomersSupportData = () => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_CUSTOMER_SUPPORT_DATA
  })
}

export const setSelectedCustomerSupportData = (id) => (dispatch, getState) => {
  ///顧客詳細データの表示（顧客詳細ページの工事・対応履歴の選択）の際に発火
  try {
    const { customerSupportList } = getState().customerSupport;
    const selectedCustomerSupportData = customerSupportList.filter((supportData) => supportData.id === id);
    if (selectedCustomerSupportData.length === 0) {
      throw new Error("選択された顧客対応データを取得できませんでした。");
    }
    dispatch({
      type: SET_SELECTED_CUSTOMER_SUPPORT_DATA,
      payload: { selectedCustomerSupportData: selectedCustomerSupportData[0] }
    });
  } catch (error) {
    dispatch({
      type: SET_SELECTED_CUSTOMER_SUPPORT_DATA_FAILED
    });
    dispatch(setErrorMessage({ where: "setSelectedCustomerSupportData", msg: error.message }));
  }
};

export const updateSelectedCustomerSupportData = (data) => (dispatch, getState) => {
  //対応履歴編集後にリストをアップデート、選択された対応履歴をアップデート
  const { customerSupportList } = getState().customerSupport;
  const { id } = data;
  const _customerSupportList = [...customerSupportList];
  const updatedCustomerSupportData = _customerSupportList.map((supportData) => {
    if (supportData.id == id) {
      return data;
    }
    return supportData;
  });

  dispatch({
    type: UPDATE_SELECTED_CUSTOMER_SUPPORT_DATA,
    payload: { updatedCustomerSupportData: updatedCustomerSupportData, selectedCustomerSupportData: data }
  });
};

export const addRegisteringCustomerSupportData = (data) => (dispatch, getState) => {
  //対応履歴登録後に対応履歴リストに追加
  const { customerSupportList } = getState().customerSupport;
  const updatedCustomerSupportData = [...customerSupportList];
  updatedCustomerSupportData.push(data);
  dispatch({
    type: ADD_REGISTERING_CUSTOMER_SUPPORT_DATA,
    payload: { updatedCustomerSupportData: updatedCustomerSupportData, selectedCustomerSupportData: data }
  });
}

export const removeCustomerSupportData = () => (dispatch) => {
  //顧客詳細データを離れる際に発火し、ある選択された顧客の顧客対応履歴をクリア
  dispatch({
    type: REMOVE_CUSTOMER_SUPPORT_DATA
  })
}