import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//components
import { CancelButton, SubmitButton } from 'src/common/StylesComponents';


const QueryDateWarningModal = (props) => {

  const { onSearchButtonPressed, onCancelButtonPressed } = props;

  return (
    <Container>
      <h4>
        １年以上の期間の検索は件数が多くなってしまうため、少々お時間がかかりますが宜しいでしょうか？
      </h4>
      <ButtonWrapper>
        <CancelButton onClick={onCancelButtonPressed}>
          キャンセル
        </CancelButton>
        <SubmitButton onClick={onCancelButtonPressed}>
          検索
        </SubmitButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  max-width: 360px;
  min-width: 260px;
  margin: 10px;
  border-radius: 6px;
  margin-right: auto;
  margin-left: auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  width: 100%;
  justify-content: space-around;
`
QueryDateWarningModal.propTypes = {
  onCancelButtonPressed: PropTypes.func.isRequired,
  onSearchButtonPressed: PropTypes.func.isRequired,
};

QueryDateWarningModal.defaultProps = {
  onCancelButtonPressed: () => {},
  onSearchButtonPressed: () => {},
};

export default QueryDateWarningModal;