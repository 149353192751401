import React, { useEffect, useCallback, useState, useMemo } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import PropType from 'prop-types';
//components
import { InputLabelStyle } from 'src/common/StylesComponents';
import { TextHalfNumberInput } from 'src/common/Inputs';
import DropDownSelection from 'src/common/DropDownSelection';
import DropDownSearchSelection from 'src/common/DropDownSearchSelection';
import DateSelector from 'src/common/DateSelector';
import Checkbox from 'src/common/Checkbox';
import TextInputDropDownSelection from 'src/common/TextInputDropDownSelection';
//redux
import { useSelector } from 'react-redux';
import GeneralFunctions from 'src/functions/GeneralFunctions';

const isAgreementFormOption = [
  { value: '0', label: '無し' },
  { value: '1', label: '有り' },
];

const salesEmployeeKeys = ['sales_employee_name1', 'sales_employee_name2', 'sales_employee_name3', 'sales_employee_name4'];
const constructionEmployeeKeys = ['construction_employee_name1', 'construction_employee_name2', 'construction_employee_name3', 'construction_employee_name4'];

//工事情報編集登録画面の工事情報欄を描画するコンポーネント
const ConstructionInfo = (props) => {
  const { isEditable, constructionInfo, changeInfo, getPartnerMargin, mtb_enterprise_id } = props;

  const { mtb_sales_office_id, role } = useSelector(state => state.auth);
  const { partnerData, paymentMethods, employeeList, storeData, salesOfficeData } = useSelector(state => state.master);
  const [salesOfficeOptions, setSalesOfficeOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [hasConstructionCompleted, setHasConstructionCompleted] = useState(false);
  const [salesEmployeeOptions, setSalesEmployeeOptions] = useState([]);
  const [constructionEmployeeOptions, setConstructionEmployeeOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  useEffect(() => {
    //選択肢の初期化
    const _partnerOptions = initPartnerOptionData();
    const _paymentMethodOptions = initPaymentMethodOptions();
    const _salesOfficeOptions = initSalesOfficeOptions();
    setConstructionDate();
    setEmployeeOptions();
    setPartnerOptions(_partnerOptions);
    setPaymentMethodOptions(_paymentMethodOptions);
    setSalesOfficeOptions(_salesOfficeOptions);
  }, [storeData]);

  const setConstructionDate = () => {
    //もし工事年月日が入力されてない場合は今日の日付で初期化（登録時）

    if (!GeneralFunctions.isNullOrEmpty(constructionInfo.construction_end_date)) setHasConstructionCompleted(true);

    // if (GeneralFunctions.isNullOrEmpty(constructionInfo.construction_start_date)
    //   && GeneralFunctions.isNullOrEmpty(constructionInfo.construction_end_date)) {
    //   let startDate = new Date();
    //   startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 12, 0, 0));
    //   changeInfo('construction_start_date', startDate.toISOString());
    // }
  };

  const initPartnerOptionData = () => {
    //提携先選択肢の初期化

    if (!partnerData) return [];
    const array = [];
    const empty = { value: '0', label: '' }; //０は空のラベルを持つ（defaultを非表示にする為）
    array.push(empty);
    Object.keys(partnerData).map((id) => {
      if (partnerData[id].margin) {
        const obj = {
          value: partnerData[id].id.toString(),
          label: partnerData[id].partner_name
        };
        array.push(obj);
      }
    });
    return array;
  }

  const setEmployeeOptions = () => {
    //営業担当者選択肢の初期化
    if (employeeList instanceof Array && employeeList.length > 0) {
      const employeeOptions = [];
      const empty = { value: '', label: '' };
      employeeOptions.push(empty);
      employeeList.map((employee) => {
        if (mtb_sales_office_id && employee.mtb_sales_office_id === parseInt(mtb_sales_office_id)) {
          const option = { //名前をDBに送るので、valueも名前
            value: employee.employee_name,
            label: employee.employee_name,
          };
          employeeOptions.push(option);
        }
      });
      setSalesEmployeeOptions(employeeOptions);
      setConstructionEmployeeOptions(employeeOptions);
    }
  };

  const initPaymentMethodOptions = () => {
    //　Object型のマスタデータをそれぞれの選択肢に表示する処理(List型)
    if (!paymentMethods) return [];
    const array = [];
    const empty = { value: '0', label: '' }; //　０は空のラベルを持つ（defaultを非表示にする為）
    array.push(empty);
    Object.keys(paymentMethods).map((key) => {
      const obj = {
        value: key,
        label: paymentMethods[key]['payment_method']
      };
      array.push(obj);
    });
    return array;
  };

  const initSalesOfficeOptions = () => {
    //営業所選択肢の初期化
    if (!salesOfficeData) return [];
    const array = [];
    const empty = { value: '0', label: '' }; //０は空のラベルを持つ（defaultを非表示にする為）
    array.push(empty);
    Object.keys(salesOfficeData).map((id) => {
      if (role === 1 || mtb_enterprise_id === salesOfficeData[id].mtb_enterprise_id) {
        const obj = {
          value: salesOfficeData[id].id.toString(),
          label: salesOfficeData[id].sales_office_name
        };
        array.push(obj);
      }
    });
    return array;
  };

  const preprocessStoreOptions = () => {
    //　店舗名データは提携先に依存するため、提携先が選択された時点（constructionInfo.mtb_partner_idをリスニング）で、
    //　店舗リストからログインユーザーの企業IDと選択した提携先の外部キーとマッチする全ての店舗データを選択肢に格納する処理
    if (storeData) {
      const mtb_partner_id = parseInt(constructionInfo.mtb_partner_id);
      const array = [];
      Object.keys(storeData).map((id) => {
        if (mtb_enterprise_id === storeData[id].mtb_enterprise_id && mtb_partner_id === storeData[id].mtb_partner_id) {
          const obj = {
            id: storeData[id].id,
            value: storeData[id].store_kana,
            label: storeData[id].store_name,
            sortKey: storeData[id].store_kana,
          };
          array.push(obj);
        }
      });
      array.sort((a, b) => {
        if (a.sortKey === null) {
          return 1;
        } else if (b.sortKey === null) {
          return -1;
        }
        return a.sortKey.localeCompare(b.sortKey, 'ja-Hiragana');
      });
      setStoreOptions(array);
    }
  }
  useMemo(preprocessStoreOptions, [constructionInfo.mtb_partner_id]);

  const changeSelection = useCallback((key, val) => {
    //　提携先の選択が行われたら提携先に登録されているマージンを格納する処理
    const { value } = val.target;
    if (key === 'mtb_partner_id') {
      if (parseInt(value) > 0) {
        getPartnerMargin(value);
      }
      changeInfo('mtb_store_id', null);
    }
    changeInfo(key, value);
  }, [constructionInfo]);

  const changeStoreSelection = useCallback((key, val) => {
    if (val) {
      const { id } = val;
      changeInfo(key, id);
    } else {
      changeInfo(key, null);
    }
  }, [constructionInfo]);

  const constructionSelectedDateValidation = useCallback((key, val) => {
    //　工事年月日の 工事終了日　＞=　工事開始年月日　にする処理
    const { construction_start_date } = constructionInfo;
    if (val >= construction_start_date) {
      changeInfo(key, val);
    }
  }, [constructionInfo]);

  const changeSalesEmployeeOptions = useCallback((key, val) => {
    //営業担当者の選択ハンドリング
    const { value } = val.target;
    if (key === salesEmployeeKeys[0]) {
      changeInfo(key, value);
    } else {
      const updatingKey = getFillingSalesEmployeeSection(key);
      changeInfo(updatingKey, value);
    }
  }, []);

  const changeConstructionEmployeeOptions = useCallback((key, val) => {
    //施工担当者の選択ハンドリング
    const { value } = val.target;
    if (key === constructionEmployeeKeys[0]) {
      changeInfo(key, value);
    } else {
      const updatingKey = getFillingConstructionEmployeeSection(key);
      changeInfo(updatingKey, value);
    }
  }, []);

  const changeApprovalNumber = useCallback((key, val) => {
    const { value } = val.target;
    if (value) {
      changeInfo(key, value);
    } else {
      changeInfo(key, "");
    }
  }, []);

  const getFillingSalesEmployeeSection = (key) => {
    //営業担当者の選択フォームで、先頭から埋めていく処理
    for (let i = 0; i < salesEmployeeKeys.length; i++) {
      if (key === salesEmployeeKeys[i]) {
        for (let j = 0; j < i; j++) {
          if (constructionInfo[salesEmployeeKeys[j]] === '') {
            return salesEmployeeKeys[j];
          }
        }
      }
    }
    return key;
  };

  const getFillingConstructionEmployeeSection = (key) => {
    //施工担当者の選択フォームで、先頭から埋めていく処理
    for (let i = 0; i < constructionEmployeeKeys.length; i++) {
      if (key === constructionEmployeeKeys[i]) {
        for (let j = 0; j < i; j++) {
          if (constructionInfo[constructionEmployeeKeys[j]] === '') {
            return constructionEmployeeKeys[j];
          }
        }
      }
    }
    return key;
  };

  const changeConstructionCompletionStatus = useCallback(() => {
    // 工事完了ボタンのstateアップデート
    // 工事完了日の入力が必須条件. offの時construction_end_dateはnull
    if (!isEditable) return;

    if (hasConstructionCompleted) {
      changeInfo('construction_end_date', null);
    }
    setHasConstructionCompleted(!hasConstructionCompleted);
  }, [hasConstructionCompleted]);

  return (
    <Container>
      <HeaderContainer>
        <h4 style={{ paddingLeft: '10%' }}>
          工事情報
        </h4>
      </HeaderContainer>
      <ContractContainer>
        <GeneralInfoContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
              営業所
            </LabelStyle>
            <DropDownTextContainer>
              {
                role === 3 ? //営業所権限の時は営業所固定
                  <DropDownSelection
                    isEnable={false}
                    options={salesOfficeOptions}
                    value={constructionInfo.mtb_sales_office_id ? constructionInfo.mtb_sales_office_id : "0"}
                    onChange={(val) => changeSelection('mtb_sales_office_id', val)}
                  />
                  :
                  <DropDownSelection
                    isEnable={isEditable}
                    options={salesOfficeOptions}
                    value={constructionInfo.mtb_sales_office_id ? constructionInfo.mtb_sales_office_id : "0"}
                    onChange={(val) => changeSelection('mtb_sales_office_id', val)}
                  />
              }

            </DropDownTextContainer>
          </TextContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
              提携先
            </LabelStyle>
            <DropDownTextContainer>
              <DropDownSelection
                isEnable={isEditable}
                options={partnerOptions}
                value={constructionInfo.mtb_partner_id ? constructionInfo.mtb_partner_id : "0"}
                onChange={(val) => changeSelection('mtb_partner_id', val)}
              />
            </DropDownTextContainer>
          </TextContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
              店舗名
            </LabelStyle>
            <DropDownTextContainer>
              <DropDownSearchSelection
                isEnable={isEditable}
                options={storeOptions}
                value={constructionInfo.mtb_store_id ? storeOptions.find((v) => v.id === constructionInfo.mtb_store_id) : '0'}
                onChange={(val) => changeStoreSelection('mtb_store_id', val)}
              />
            </DropDownTextContainer>
          </TextContainer>
          <TextContainer style={{ marginBottom: 12, alignItems: 'center' }}>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
              工事完了済み
            </LabelStyle>
            <DropDownTextContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Checkbox
                isChecked={hasConstructionCompleted}
                changeStatus={changeConstructionCompletionStatus}

              />
            </DropDownTextContainer>
          </TextContainer>
          <TextContainer style={{ marginTop: -8, marginBottom: 4 }}>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
              工事日
            </LabelStyle>
            <DropDownTextContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <DateSelector
                isEnable={isEditable}
                date={constructionInfo.construction_start_date}
                onChange={(date) => changeInfo('construction_start_date', date)}
              />
            </DropDownTextContainer>
          </TextContainer>
          {
            hasConstructionCompleted ?
              <TextContainer>
                <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
                  工事完了日
                </LabelStyle>
                <DropDownTextContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <DateSelector
                    isEnable={isEditable && hasConstructionCompleted}
                    date={constructionInfo.construction_end_date}
                    onChange={(date) => constructionSelectedDateValidation('construction_end_date', date)}
                  />
                </DropDownTextContainer>
              </TextContainer>

              :
              null
          }
          <TextContainer style={{ margin: '12px 0' }}>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4 }}>
              支払方法
            </LabelStyle>
            <DropDownTextContainer>
              <DropDownSelection
                options={paymentMethodOptions}
                value={constructionInfo.mtb_payment_method_id ? constructionInfo.mtb_payment_method_id : '0'}
                onChange={(val) => changeSelection('mtb_payment_method_id', val)}
                isEnable={isEditable}
              />
            </DropDownTextContainer>
          </TextContainer>
          <TextContainer style={{ margin: '-20px 0 12px 0' }}>
            <LabelStyle Selection align={'flex-start'} />
            <div style={{ width: '47%' }}>
              <TextHalfNumberInput isEnable={isEditable} value={constructionInfo.payment_description} onChange={(val) => changeApprovalNumber("payment_description", val)} />
            </div>
          </TextContainer>
          <TextContainer style={{ marginBottom: '8px' }}>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4, fontSize: 14 }}>
              ローン承認番号
            </LabelStyle>
            <div style={{ width: '47%' }}>
              <TextHalfNumberInput isEnable={isEditable} value={constructionInfo.approval_number} onChange={(val) => changeApprovalNumber("approval_number", val)} />
            </div>
          </TextContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4, fontSize: 14 }}>
              同意書の有無
            </LabelStyle>
            <DropDownTextContainer>
              <DropDownSelection
                options={isAgreementFormOption}
                value={constructionInfo.is_agreement_form ? constructionInfo.is_agreement_form : '0'}
                onChange={(val) => changeSelection('is_agreement_form', val)}
                isEnable={isEditable}
              />
            </DropDownTextContainer>
          </TextContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4, fontSize: 14 }}>
              保守点検の有無
            </LabelStyle>
            <DropDownTextContainer>
              <DropDownSelection
                options={isAgreementFormOption}
                value={constructionInfo.is_maintenance ? constructionInfo.is_maintenance : '0'}
                onChange={(val) => changeSelection('is_maintenance', val)}
                isEnable={isEditable}
              />
            </DropDownTextContainer>
          </TextContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4, fontSize: 12 }}>
              その他工事の有無
            </LabelStyle>
            <DropDownTextContainer>
              <DropDownSelection
                options={isAgreementFormOption}
                value={constructionInfo.has_other_construction ? constructionInfo.has_other_construction : '0'}
                onChange={(val) => changeSelection('has_other_construction', val)}
                isEnable={isEditable}
              />
            </DropDownTextContainer>
          </TextContainer>
        </GeneralInfoContainer>
        <SalesRepresentativeContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4, fontSize: 14 }}>
              営業担当者
            </LabelStyle>
            <DropDownTextContainer style={{ display: 'flex', flexDirection: 'column' }}>
              <TextInputDropDownSelection
                options={salesEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.sales_employee_name1}
                onChange={(val) => changeSalesEmployeeOptions('sales_employee_name1', val)}
              />
              <TextInputDropDownSelection
                options={salesEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.sales_employee_name2}
                onChange={(val) => changeSalesEmployeeOptions('sales_employee_name2', val)}
              />
              <TextInputDropDownSelection
                options={salesEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.sales_employee_name3}
                onChange={(val) => changeSalesEmployeeOptions('sales_employee_name3', val)}
              />
              <TextInputDropDownSelection
                options={salesEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.sales_employee_name4}
                onChange={(val) => changeSalesEmployeeOptions('sales_employee_name4', val)}
              />
            </DropDownTextContainer>
          </TextContainer>
        </SalesRepresentativeContainer>
        <ConstructionRepresentativeContainer>
          <TextContainer>
            <LabelStyle Selection align={'flex-start'} style={{ paddingTop: 4, fontSize: 14 }}>
              施工担当者
            </LabelStyle>
            <DropDownTextContainer style={{ display: 'flex', flexDirection: 'column' }}>
              <TextInputDropDownSelection
                options={constructionEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.construction_employee_name1}
                onChange={(val) => changeConstructionEmployeeOptions('construction_employee_name1', val)}
              />
              <TextInputDropDownSelection
                options={constructionEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.construction_employee_name2}
                onChange={(val) => changeConstructionEmployeeOptions('construction_employee_name2', val)}
              />
              <TextInputDropDownSelection
                options={constructionEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.construction_employee_name3}
                onChange={(val) => changeConstructionEmployeeOptions('construction_employee_name3', val)}
              />
              <TextInputDropDownSelection
                options={constructionEmployeeOptions}
                isEnable={isEditable}
                value={constructionInfo.construction_employee_name4}
                onChange={(val) => changeConstructionEmployeeOptions('construction_employee_name4', val)}
              />
            </DropDownTextContainer>
          </TextContainer>
        </ConstructionRepresentativeContainer>
      </ContractContainer>
    </Container>
  )
};


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HeaderContainer = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ContractContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const GeneralInfoContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
`
const LabelStyle = styled(InputLabelStyle)`
  font-size: 14px;
  font-weight: 500;
  width: 100px;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 4px;
`
const DropDownTextContainer = styled.div`
  width: 50%;
`
const SalesRepresentativeContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`
const ConstructionRepresentativeContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`
const CompletionButton = styled.button`
  width: 180px;
  height: 40px;
  margin: 8px 0;
  color: ${props => props.isChecked ? 'black' : 'lightgray'};
  border: ${props => props.isChecked ? '1px solid black' : '1px solid lightgray'};
  cursor: pointer;
  &:disabled {
    cursor: unset;
  }
`

ConstructionInfo.propTypes = {
  isEditable: PropType.bool.isRequired,
  constructionInfo: PropType.object.isRequired,
  changeInfo: PropType.func.isRequired,
  getPartnerMargin: PropType.func,
  mtb_enterprise_id: PropType.number
};

ConstructionInfo.defaultProps = {
  isEditable: false,
  constructionInfo: {},
  changeInfo: () => { },
  getPartnerMargin: () => { },
  mtb_enterprise_id: null
};

export default ConstructionInfo;