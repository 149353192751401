import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import MtbEmployeeInputs from './children/MtbEmployeeInputs';
import DialogMessage from 'src/common/DialogMessage';
//functions
import EmployeeFunctions from 'src/functions/mtbFunctions/EmployeeFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  従業員登録コンポーネント
  保存ボタンでDBに新規従業員データ挿入
  キャンセルボタンから入力情報を破棄して一覧へ戻る
*/
const MtbEmployeeRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, mtb_enterprise_id, token } = useSelector(state => state.auth);
  const [employeeData, setEmployeeData] = useState({});
  const [errorText, setErrorText] = useState('');

  const modalRequired = useRef(false);
  const previousPath = useRef('');
  
  useEffect(() => {
    const getData = () => {
      const { state } = location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const baseEmployeeData = {
        id: null,
        account: '',
        password: '',
        entered_password: '',
        employee_code: '',
        employee_name: '',
        label: '',
        role: 1,
        is_deleted: 0,
        mtb_sales_office_id: '0',
        mtb_enterprise_id: mtb_enterprise_id,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      return baseEmployeeData;
    };
    if (employee_name && mtb_enterprise_id) {
      const _employeeData = getData();
      setEmployeeData({..._employeeData});
    }
  }, [employee_name, mtb_enterprise_id, location]);

  const changeEmployeeData = useCallback((key, value) => {
    //ステートのアップデート
    const _employeeData = {...employeeData};
    if (value !== null) {
      _employeeData[key] = value;
      setEmployeeData(_employeeData);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [employeeData, modalRequired]);


  const onSaveButtonPressed = useCallback(async () => {
    //従業員の登録、成功時に従業員リストに戻る
    const employeeFunctions = new EmployeeFunctions({ employeeData: employeeData, token: token });
    const response = await employeeFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current});
    }
  }, [employeeData, token, history, modalRequired, previousPath]);

  const onCancelButtonPressed = useCallback(() => {
    //modalRequiredの状態次第でダイアログメッセージが表示される。
    history.goBack();
  }, [history]);

  return (
    <>
    <DialogMessage
      modalRequired={modalRequired}
      text={`
        編集中のデータがあります。\n
        続行すると編集中の内容は全て破棄されます。
      `}
    />
    <MtbEmployeeInputs 
      data={employeeData}
      onChange={changeEmployeeData}
      isEditing={true}
      errorText={errorText}
      onSaveButtonPressed={onSaveButtonPressed}
      onCancelButtonPressed={onCancelButtonPressed}
    />
  </>
  )
};

export default MtbEmployeeRegister;