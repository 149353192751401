import axios from 'axios';
//actions
import { setErrorMessage } from './ErrorMessageHandler';
//functions
import DateFunctions from 'src/functions/DateFunctions';
//apis
import { WARRANTY_URL, ALL_WARRANTY_URL } from 'src/apis';

export const SET_WARRANTY_LIST = "SET_WARRANTY_LIST";
export const SET_WARRANTY_LIST_FAILED = "SET_WARRANTY_LIST_FAILED";
export const SET_SELECTED_WARRANTY = "SET_SELECTED_WARRANTY";
export const EDIT_WARRANTY_DATA = "EDIT_WARRANTY_DATA";
export const EDIT_WARRANTY_DATA_FAILED = "EDIT_WARRANTY_DATA";
export const EDIT_SELECTED_WARRANTY_DATA = "EDIT_SELECTED_WARRANTY_DATA";
export const EDIT_SELECTED_WARRANTY_DATA_FAILED = "EDIT_SELECTED_WARRANTY_DATA_FAILED";
export const REMOVE_SELECTED_WARRANTY_BY_INDEX = "REMOVE_SELECTED_WARRANTY_BY_INDEX";
export const SET_EDITING_STATE = "SET_EDITING_STATE";
export const REMOVE_CONSTRUCTION_WARRANTY_DATA = "REMOVE_CONSTRUCTION_WARRANTY_DATA";

export const SET_WARRANTY_LIST_DATA = "SET_WARRANTY_LIST_DATA";
export const SET_WARRANTY_LIST_DATA_FAILED = "SET_WARRANTY_LIST_DATA_FAILED";
export const REMOVE_WARRANTY_DATA = "REMOVE_WARRANTY_DATA";

const errorLocation = "warranty";

export const warrantyCategoryMap = {
  0: "シロアリ防除施工保証書",
  1: "防腐・防カビ保証書",
  2: "損害賠償保証書"
};

export const warrantyTermiteTypeMap = {
  0: "ヤマトシロアリ",
  1: "イエシロアリ",
};

export const setWarrantyData = (dtb_construction_id) => async (dispatch, getState) => {
  //DBから工事データのdtb_construction_idを元に保証データをフェッチしてきてstoresに格納する関数
  try {
    const { token } = getState().auth;
    await axios.get(WARRANTY_URL, {
      params: {
        dtb_construction_id: dtb_construction_id
      },
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        throw new Error("Could not get warranty data");
      })
      .then(({ data }) => {
        dispatch({
          type: SET_WARRANTY_LIST,
          payload: { warranties: preprocessSelectionColumn(data) }
        })
      })
  } catch (error) {
    dispatch({
      type: SET_WARRANTY_LIST_FAILED
    })
    dispatch(setErrorMessage({ where: 'setWarrantyData', msg: error.message }))
  }
};

const preprocessSelectionColumn = (data) => {
  if (data.length === 0) return [];

  const processed = data.map((warranty) => {
    if (warranty.category === 2) {
      const construction_type = typeof warranty.construction_type === "number" ? warranty.construction_type : -1;
      const construction_division = typeof warranty.construction_division === "number" ? warranty.construction_division : -1;
      const termite_type = typeof warranty.termite_type === "number" ? warranty.termite_type : -1;
      const measures = typeof warranty.measures === "number" ? warranty.measures : -1;
      return {
        ...warranty,
        construction_type: construction_type,
        construction_division: construction_division,
        termite_type: termite_type,
        measures: measures,
      };
    }
    return warranty;
  });
  return processed;
};

export const addWarrantyData = (addingWarrantyData) => (dispatch, getState) => {
  try {
    const { warranties } = getState().warranty;
    if (warranties instanceof Array) {
      let updatedWarrantyList = [...warranties];
      updatedWarrantyList.push(addingWarrantyData);
      dispatch({
        type: EDIT_WARRANTY_DATA,
        payload: { updatedWarrantyList: updatedWarrantyList }
      })
    } else {
      throw new Error("could not find warranty data");
    }
  } catch (error) {
    dispatch({
      type: EDIT_WARRANTY_DATA_FAILED
    })
    dispatch(setErrorMessage({ where: 'addWarrantyData', msg: error.message }))
  }
};

export const setSelectedWarranty = (selectedWarranty, index) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_WARRANTY,
    payload: { selectedWarranty: selectedWarranty, selectedIndex: index }
  })
};

export const removeSelectedWarrantyByIndex = (deletingIndex) => (dispatch, getState) => {
  const { warranties } = getState().warranty;
  const updatedWarrantyList = [...warranties];
  updatedWarrantyList.splice(deletingIndex, 1);
  dispatch({
    type: REMOVE_SELECTED_WARRANTY_BY_INDEX,
    payload: { updatedWarrantyList: updatedWarrantyList }
  })
};

export const editSelectedWarrantyData = (warranty) => (dispatch, getState) => {
  try {
    const updatedWarrantyData = { ...warranty };
    const { warranties, selectedIndex } = getState().warranty;
    const updatedWarrantyList = [...warranties];
    updatedWarrantyList[selectedIndex] = updatedWarrantyData;
    dispatch({
      type: EDIT_SELECTED_WARRANTY_DATA,
      payload: { updatedWarrantyList: updatedWarrantyList }
    });
  } catch (error) {
    dispatch(setErrorMessage({ where: 'editSelectedWarrantyData', msg: error.message }))
    dispatch({
      type: EDIT_SELECTED_WARRANTY_DATA_FAILED
    });
  }
};

export const setWarrantyEditingSate = (isEditing) => (dispatch) => {
  dispatch({
    type: SET_EDITING_STATE,
    payload: { isEditingWarranty: isEditing }
  })
}

export const removeConstructionWarrantyData = () => (dispatch) => {
  dispatch({
    type: REMOVE_CONSTRUCTION_WARRANTY_DATA
  })
}

export const removeWarrantyData = () => (dispatch) => {
  dispatch({
    type: REMOVE_WARRANTY_DATA
  })
}

export const getWarrantyListData = () => async (dispatch, getState) => {
  //保証一覧に表示する保証リストの取得
  try {
    const { token } = getState().auth;

    if (token) {
      await axios.get(ALL_WARRANTY_URL, {
        headers: { "Authorization": `Bearer ${token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            return response;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then(({ data }) => {
          const processedData = dataPreprocessing(data);
          dispatch({
            type: SET_WARRANTY_LIST_DATA,
            payload: { data: processedData }
          });
        })
    } else {
      throw new Error("Could not get warranty data");
    }
  } catch (error) {
    dispatch({
      type: SET_WARRANTY_LIST_DATA_FAILED,
    });
    dispatch(setErrorMessage({ where: errorLocation, msg: "保証データを取得できませんでした。" }));
  }
}

export const dataPreprocessing = (data) => {
  //保証一覧に表示する保証リストのデータの表示のための処理
  const _data = [...data];
  _data.map((warrantyData) => {
    warrantyData['category_name'] = warrantyCategoryMap[warrantyData.category];
    warrantyData['termite_type_name'] = warrantyTermiteTypeMap[warrantyData.termite_type];
    warrantyData["create_date"] = DateFunctions.ISOStringDateToLocalDate(warrantyData.create_date);
  });
  return _data;
}