import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
//components
import { BaseBodyStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { CustomerSupportHeader, CustomerSupportStatus, CustomerSupportContent } from 'src/views/customerSupport/common';
import { CustomerStatusSection } from 'src/views/construction/common';
import DialogMessage from 'src/common/DialogMessage';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { addRegisteringCustomerSupportData } from 'src/redux/actions/CustomerSupportDataHandler';
import { updateSelectedDetailedCustomerData } from 'src/redux/actions/CustomerDataHandler';
import { setCustomerSupportMasterSelectionData } from 'src/redux/actions/MasterDataHandler';
//functions
import CustomerSupportFunctions from 'src/functions/CustomerSupportFunctions';
import CustomerFunction from 'src/functions/CustomerFunctions';
//style and configs
import { Colors } from 'src/configs/StyleConfig';
import { logCategory, logTableType, sendLogData } from 'src/functions/LogFunctions';


const CustomerSupportRegister = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedCustomerData } = useSelector(state => state.customer);
  const { employee_name, token, mtb_enterprise_id, mtb_sales_office_id } = useSelector(state => state.auth);
  const [supportInfo, setSupportInfo] = useState({
    support_date: null, support_complete_date: null, support_employee_name1: "",
    support_employee_name2: "", support_employee_name3: "", support_employee_name4: "",
    remarks: "", mtb_sales_office_id: "0", mtb_partner_id: "0", content: "", detail1: "", detail2: "",
    support_detail: "", mtb_customer_id: 0, created_employee_name: "", updated_employee_name: "",
    destination_name: "", destination_postal_code: "", destination_address: "", destination_phone: ""
  });
  const [customerInfo, setCustomerInfo] = useState(
    {
      id: null, customer_code: '', enterprise: '', sales_office: '', customer_kana: '', customer_name: '',
      customer_address1: '', customer_address2: '', customer_postal_code: '', customer_phone1: '', customer_phone2: '',
      mtb_enterprise_id: null, mtb_sales_office_id: null,
      status: [     
      { id: 0, status: '対応不可', isSelected: false },
      { id: 1, status: '信販不可', isSelected: false },
      { id: 2, status: '転居', isSelected: false },
      { id: 3, status: 'クレーム', isSelected: false }
      ]
    }
  );
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const hasCustomerStatusChanged = useRef(false);

  useEffect(() => {
    initSupportData();
    initCustomerData();
  }, []);

  const initSupportData = () => {
    const _supportInfo = { ...supportInfo };
    const today = new Date().toISOString();
    const mtb_customer_id = selectedCustomerData.id;
    const mtb_sales_office_id = selectedCustomerData.mtb_sales_office_id;

    _supportInfo.support_date = today;
    _supportInfo.mtb_customer_id = mtb_customer_id;
    _supportInfo.mtb_sales_office_id = mtb_sales_office_id; //顧客データに登録済みの営業所Idを初期選択
    _supportInfo.destination_name = selectedCustomerData.customer_name;
    _supportInfo.destination_postal_code = selectedCustomerData.customer_postal_code;
    _supportInfo.destination_address = selectedCustomerData.customer_address1 + " " + selectedCustomerData.customer_address2;
    _supportInfo.destination_phone = selectedCustomerData.customer_phone1;
    _supportInfo.created_employee_name = employee_name;
    _supportInfo.updated_employee_name = employee_name;
    setSupportInfo(_supportInfo);
  };

  const initCustomerData = () => {
    const initialCustomerInfo = {...customerInfo};
    Object.keys(initialCustomerInfo).map((key) => {
      if (key === 'status') {
        const statusList = selectedCustomerData[key];
        const status = customerInfo['status'];
        const initialStatus = status.map((row) => {
          if (statusList.includes(row.id)) {
            return {
              ...row,
              isSelected: true
            };
          }
          return {...row};
        });
        initialCustomerInfo[key] = initialStatus;
      } else {
        initialCustomerInfo[key] = selectedCustomerData[key]
      }
    });
    setCustomerInfo(initialCustomerInfo);
  };

  useEffect(() => {
    if (mtb_sales_office_id) {
      //対応履歴内容を取ってくる関数を呼び出す
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 0));
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 1));
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 2));
    }
  }, [mtb_sales_office_id]);

  //顧客対応データ変更関数
  const changeSupportInfo = useCallback((key, value) => {
    const _supportInfo = { ...supportInfo };
    _supportInfo[key] = value;
    setSupportInfo(_supportInfo);
    if (!modalRequired.current) modalRequired.current = true;
  }, [supportInfo, modalRequired]);

  // 顧客のステータス変更関数
  const changeCustomerStatus = useCallback((id) => {
    const _customerInfo = { ...customerInfo };
    _customerInfo['status'][id].isSelected = !_customerInfo["status"][id].isSelected;
    setCustomerInfo(_customerInfo);
    if (!hasCustomerStatusChanged.current) hasCustomerStatusChanged.current = true;
  }, [customerInfo]);

  const onSaveButtonPressed = useCallback(async () => {
    const customerFunction = new CustomerSupportFunctions({ customerSupportData: supportInfo, token: token });
    const response = await customerFunction.register();
    if (typeof response === "object" && response.error) {
      setErrorText(response.error);
    } else {
      const { insertLogId, insertId } = response.logs;
      const customerSupportLog = {
        id: null,
        category: logCategory.register, //登録ログ
        remarks: '顧客対応登録',
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: logTableType.customerSupport, //顧客対応ログ
        log_id: insertLogId,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      const json = [customerSupportLog];
      const error = await sendLogData(json, token);
      modalRequired.current = false;
      setErrorText('');
      if (hasCustomerStatusChanged.current) {
        const customerFunctions = new CustomerFunction({ customerInfo: customerInfo, token: token });
        const customerResponse = await customerFunctions.updateData();
        if (typeof customerResponse === "object" && customerResponse.error) {
          setErrorText(customerResponse.error);
        } else {
          const { insertLogId } = customerResponse.logs;
          const customerLog = {
            id: null,
            category: logCategory.update, //更新ログ
            remarks: '顧客情報更新',
            mtb_enterprise_id: mtb_enterprise_id,
            table_type: logTableType.customer, //顧客ログ
            log_id: insertLogId,
            created_employee_name: employee_name,
            updated_employee_name: employee_name
          };
          const json = [customerLog];
          const error = await sendLogData(json, token);
          dispatch(updateSelectedDetailedCustomerData(customerResponse.data));
        }
      }      
      const data = {...response.data};
      data['id'] = insertId;
      dispatch(addRegisteringCustomerSupportData(data));
      history.goBack();
    }
  }, [supportInfo, token, history, modalRequired, mtb_enterprise_id, employee_name]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <CustomerSupportHeader />
      <CustomerSupportStatus customerInfo={customerInfo} />
      <div style={{width: '100%'}}>
        <CustomerStatusSection status={customerInfo.status} changeStatus={changeCustomerStatus} />
      </div>
      <CustomerSupportContent supportInfo={supportInfo} changeSupportInfo={changeSupportInfo} isEditable={true} />
      <span style={{ color: Colors.errorText }}>
        {errorText && errorText}
      </span>
      <ButtonMenu>
        <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
        <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
      </ButtonMenu>
    </BaseBodyStyle>
  )
};

export const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`
export default CustomerSupportRegister;