import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
//components
import { BaseBodyStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { CustomerSupportHeader, CustomerSupportStatus, CustomerSupportContent } from 'src/views/customerSupport/common';
import { CustomerStatusSection } from 'src/views/construction/common';
import DialogMessage from 'src/common/DialogMessage';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedCustomerSupportData } from 'src/redux/actions/CustomerSupportDataHandler';
import { updateSelectedDetailedCustomerData } from 'src/redux/actions/CustomerDataHandler';
import { setCustomerSupportMasterSelectionData } from 'src/redux/actions/MasterDataHandler';
//functions
import CustomerSupportFunctions from 'src/functions/CustomerSupportFunctions';
import CustomerFunction from 'src/functions/CustomerFunctions';
//style and configs
import { Colors } from 'src/configs/StyleConfig';
import {ButtonMenu} from 'src/views/customerSupport/customerSupportRegistration/CustomerSupportRegistration';
import { logCategory, logTableType, sendLogData } from 'src/functions/LogFunctions';

const CustomerSupportEdit = () => {

  const history = useHistory();
  const location = useLocation();
  const {state} = location;
  const { customerData } = state;
  const dispatch = useDispatch();
  const { selectedCustomerData } = useSelector(state => state.customer);
  const { selectedCustomerSupportData } = useSelector(state => state.customerSupport);
  const { employee_name, token, mtb_enterprise_id, mtb_sales_office_id } = useSelector(state => state.auth);
  const [supportInfo, setSupportInfo] = useState(selectedCustomerSupportData);
  const [customerInfo, setCustomerInfo] = useState(customerData);
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const hasCustomerStatusChanged = useRef(false);

  useEffect(() => {
    if (selectedCustomerSupportData) {
      initSupportData();
    }
  }, [selectedCustomerSupportData]);

  const initSupportData = () => {
    const _selectedCustomerSupportData = { ...selectedCustomerSupportData };
    _selectedCustomerSupportData["updated_employee_name"] = employee_name;
    setSupportInfo(_selectedCustomerSupportData);
  };

  useEffect(() => {
    if (mtb_sales_office_id) {
      //対応履歴内容を取ってくる関数を呼び出す
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 0));
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 1));
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 2));
    }
  }, [mtb_sales_office_id]);

  const changeSupportInfo = useCallback((key, value) => {
    const _supportInfo = { ...supportInfo };
    _supportInfo[key] = value;
    setSupportInfo(_supportInfo);
    if (!modalRequired.current) modalRequired.current = true;
  }, [supportInfo]);

    // 顧客のステータス変更関数
  const changeCustomerStatus = useCallback((id) => {
    const _customerInfo = { ...customerInfo };
    _customerInfo['status'][id].isSelected = !_customerInfo["status"][id].isSelected;
    setCustomerInfo(_customerInfo);
    if (!hasCustomerStatusChanged.current) hasCustomerStatusChanged.current = true;
  }, [customerInfo]);

  const onSaveButtonPressed = useCallback(async () => {
    const customerFunction = new CustomerSupportFunctions({ customerSupportData: supportInfo, token: token });
    const response = await customerFunction.update();

    if (typeof response === "object" && response.error) {
      setErrorText(response.error);
    } else {
      const { insertLogId } = response.logs;
      const customerSupportLog = {
        id: null,
        category: logCategory.update,//更新ログ
        remarks: '顧客対応更新',
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: logTableType.customerSupport,//顧客対応ログ
        log_id: insertLogId,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      const json = [customerSupportLog];
      const error = await sendLogData(json, token);
      modalRequired.current = false;
      setErrorText('');
      if (hasCustomerStatusChanged.current) {
        const customerFunctions = new CustomerFunction({ customerInfo: customerInfo, token: token });
        const customerResponse = await customerFunctions.updateData();
        if (typeof customerResponse === "object" && customerResponse.error) {
          setErrorText(customerResponse.error);
        } else {
          const { insertLogId } = customerResponse.logs;
          const customerLog = {
            id: null,
            category: logCategory.update, //更新ログ
            remarks: '顧客情報更新',
            mtb_enterprise_id: mtb_enterprise_id,
            table_type: logTableType.customer, //顧客ログ
            log_id: insertLogId,
            created_employee_name: employee_name,
            updated_employee_name: employee_name
          };
          const json = [customerLog];
          const error = await sendLogData(json, token);
          dispatch(updateSelectedDetailedCustomerData(customerResponse.data));
        }
      }
      dispatch(updateSelectedCustomerSupportData(response.data));
      history.goBack();
    }
  }, [supportInfo, token, history, modalRequired, mtb_enterprise_id, employee_name]);


  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <CustomerSupportHeader />
      <CustomerSupportStatus customerInfo={customerInfo} />
      <div style={{width: '100%'}}>
        <CustomerStatusSection status={customerInfo.status} changeStatus={changeCustomerStatus} />
      </div>
      <CustomerSupportContent supportInfo={supportInfo} changeSupportInfo={changeSupportInfo} isEditable={true} />
      <span style={{ color: Colors.errorText }}>
        {errorText && errorText}
      </span>
      <ButtonMenu>
        <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
        <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
      </ButtonMenu>
    </BaseBodyStyle>
  )
};

export default CustomerSupportEdit;