import axios from 'axios';
//functions
import GeneralFunctions from "../GeneralFunctions";
//apis
import { MTB_PAYMENT_METHOD_DATA_CHANGE_URL } from 'src/apis';

const requiredInputField = {
  payment_method: '支払い方法'
};

/*
支払い方法設定　支払い方法の登録/編集クラス
- Parameters in constructor:
 - paymentMethod:　支払いデータ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: DBに登録/編集した支払いデータ
*/
class PaymentMethodFunctions {

  constructor(props) {
    const { paymentMethod, token } = props;
    this.paymentMethod = paymentMethod;
    this.token = token;
    this.response = { error: '', data: {} };
  };

  /*
    private method
    必須項目チェック
  */
  _validation() {
    const data = { ...this.paymentMethod };
    let errors = [];

    Object.keys(requiredInputField).forEach((key) => {
      if (GeneralFunctions.isNullOrEmpty(data[key])) {
        errors.push(requiredInputField[key]);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力・選択してください。";
    };

    return;
  }

  /*
    private method
    支払い方法APIを呼び出す関数
  */
  async _send() {
    try {
      const json = [this.paymentMethod];
      this.response.data = this.paymentMethod;
      
      await axios.post(MTB_PAYMENT_METHOD_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
        .then((response) => {
          if (response.status === 200) {
            return;
          } else {
            throw new Error('支払い方法を登録/編集できませんでした。');
          }
        })
    } catch (error) {
      return error.message;
    }
  }

  /*
    public method
    外部から呼び出す関数
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._send();
    if (error) {
      this.response.error = error;
    }
    this.response.error = "";

    return this.response;
  }

};

export default PaymentMethodFunctions;