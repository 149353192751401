import styled from 'styled-components';

export const CustomerInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  margin-bottom: ${props => props.Selection && '8px'};
`

export const CustomerInputLabelStyle = styled.div`
  width: ${props => props.Selection ? '88px' : '120px'}; 
  text-align: ${props => props.align ? props.align : 'end'};
  padding-right: 8px;
  margin-top: ${props => props.Selection && '8px'}; 
`