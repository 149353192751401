import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
//components
import { BaseBodyStyle, BaseHeaderStyle, HeaderTitle } from 'src/common/StylesComponents';
import { useSelector } from 'react-redux';
//configs and styles
import { BreakPoints } from 'src/configs/StyleConfig';


const role12Settings = {
  '営業所設定': '/sales-office-list', '従業員設定': '/employee-list',
};
const role3Settings = {
  '支払い方法設定': '/payment-method-list', '商品お気に入り登録': '/product-favorite', '対応履歴 内容設定': '/customer-support/content',
  '対応履歴 詳細1設定': '/customer-support/other1', '対応履歴 詳細2設定': '/customer-support/other2',
};
const role1Settings = {
  '企業設定': '/enterprise-list', '店舗設定': '/store-list',
  '提携先設定': '/partner-list', '商品設定': '/product-list', '消費税設定': '/tax-list',
};

const MtbNavigation = () => {

  const history = useHistory();
  const location = useLocation();
  const { role } = useSelector(state => state.auth);
  const { pathname } = location;

  const handleNavigation = useCallback((nextPathname) => {
    history.push(pathname + nextPathname);
  }, [history, pathname]);

  return (
    <BaseBodyStyle main>
      <BaseHeaderStyle>
        <HeaderTitle>
          各種設定
        </HeaderTitle>
      </BaseHeaderStyle>
      <div style={{ width: '96%', paddingTop: '60px',  display: 'flex', flexDirection: 'column' }}>
        <MainSelectionWrapper>
          {//企業以上の権限
              (role === 1 || role === 2) ?
              <ButtonsContainer>
                {
                  Object.keys(role12Settings).map((key) => {
                    return (
                      <ButtonWrapper key={key}>
                        <SettingButton onClick={() => handleNavigation(role12Settings[key])}>{key} <i className="fa fa-angle-right" style={{ fontSize: '16px' }} /></SettingButton>
                      </ButtonWrapper>
                    )
                  })
                }
              </ButtonsContainer>
              :
              null
          }
          <ButtonsContainer>
            {//全ての権限
              Object.keys(role3Settings).map((key) => {
                return (
                  <ButtonWrapper key={key}>
                    <SettingButton onClick={() => handleNavigation(role3Settings[key])}>{key} <i className="fa fa-angle-right" style={{ fontSize: '16px' }} /></SettingButton>
                  </ButtonWrapper>
                )
              })
            }
          </ButtonsContainer>
          <ButtonsContainer>
            {//FTSの権限のみ
              role === 1 && Object.keys(role1Settings).map((key) => {
                return (
                  <ButtonWrapper key={key}>
                    <SettingButton onClick={() => handleNavigation(role1Settings[key])}>{key} <i className="fa fa-angle-right" style={{ fontSize: '16px' }} /></SettingButton>
                  </ButtonWrapper>
                )
              })
            }
          </ButtonsContainer>

        </MainSelectionWrapper>
      </div>
    </BaseBodyStyle>
  )
};

const MainSelectionWrapper = styled.div`
  width: 96%; 
  /* padding-left: 40px;  */
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: column; 
  }
`
const ButtonsContainer = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 24px;
`
const ButtonWrapper = styled.div`
  padding: 0px 8px;
`

export const SettingButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 240px;
  color: #3f3f3f;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-bottom: 1px solid #bababa;
  font-size: 14px;
  padding: 16px 24px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #CCCCCC;
    border: 2px solid #CCCCCC;
    border-bottom: 1px solid #bababa;
  }
`

export default MtbNavigation;