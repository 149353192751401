import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
//styles and configs
import { Colors } from 'src/configs/StyleConfig';

const Button = (props) => {
  const { onClick, text, width, disabled, isDeleting } = props;
  return (
    <div style={{ padding: 2 }}>
      <ButtonStyle isDeleting={isDeleting} onClick={onClick} width={width} disabled={disabled}>
        {text}
      </ButtonStyle>
    </div>
  );
};

const ButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${Colors.buttonBorder};
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  max-width: 280px;
  width: ${props => props.width ? `${props.width}px` : 'auto'};
  min-width: 100px;
  height: 36px;
  padding: ${props => props.width ? '20px 8px' : '4px'};
  cursor: pointer;
  transition: 0.3s;
  ${
    props => props.isDeleting ?
    css`
      color: ${Colors.buttonBorder};
      background-color: #ffffff;
      :hover {
        color: ${Colors.delete};
        background-color: #ffffff;
        border: 2px solid ${Colors.delete};
      }
      :disabled {
        background-color: ${Colors.buttonDisabled};
        border: 1px solid ${Colors.delete};
        color: ${Colors.buttonDisabledBorder};
        cursor: default;
      }
    `
    :
    css`
      color: #ffffff;
      background-color: ${Colors.buttonBorder};
      :hover {
        color: #1d1d1f;
        background-color: #ffffff;
        border: 2px solid ${Colors.buttonBorder};
      }
      :disabled {
        background-color: ${Colors.buttonDisabled};
        border: 1px solid ${Colors.buttonDisabledBorder};
        color: ${Colors.buttonDisabledBorder};
        cursor: default;
      }
    `
  }

`

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  isDeleting: PropTypes.bool,
};

Button.defaultProps = {
  text: '',
  onClick: () => { },
  width: null,
  disabled: false,
  isDeleting: false,
};

export default Button;