import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import MtbPaymentMethodInput from './children/MtbPaymentMethodInput';
import DialogMessage from 'src/common/DialogMessage';
//functions
import PaymentMethodFunctions from 'src/functions/mtbFunctions/PaymentMethodFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  支払い方法編集コンポーネント
  保存ボタンからDB送信後、詳細ページへ戻る
  キャンセルボタンで詳細ページに戻る
*/
const MtbPaymentMethodEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath} = state;
      data["updated_employee_name"] = employee_name;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _paymentMethod = getData();
      setPaymentMethod({..._paymentMethod});
    }
  }, [employee_name, props]);

  const changePaymentMethodData = useCallback((key, value) => {
    const _paymentMethod = {...paymentMethod};
    if (value !== null) {
      _paymentMethod[key] = value;
      setPaymentMethod(_paymentMethod);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [paymentMethod, modalRequired]);

  const onSaveButtonPressed = useCallback(async () => {
    const paymentMethodFunctions = new PaymentMethodFunctions({ paymentMethod: paymentMethod, token: token });
    const response = await paymentMethodFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current, state: {data: response.data}});
    }
  }, [paymentMethod, token, history, modalRequired, previousPath]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <MtbPaymentMethodInput 
        data={paymentMethod}
        isEditing={true}
        errorText={errorText}
        changeData={changePaymentMethodData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbPaymentMethodEdit;