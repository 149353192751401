import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import CustomerSupportInput from './children/CustomerSupportInput';
import DialogMessage from 'src/common/DialogMessage';
//functions
import CustomerSupportContentFunctions from 'src/functions/mtbFunctions/CustomerSupportContentFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  対応履歴（内容、その他１、その他２）共通の新規登録コンポーネント
  一覧ページからカテゴリを受け取り、保存ボタンからDBに新規挿入
  キャンセルで入力内容を破棄し一覧へ戻る
*/
const MtbCustomerSupportRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, mtb_sales_office_id, token } = useSelector(state => state.auth);
  const [supportData, setSupportData] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = () => {
      const { state } = location;
      const { category, prevPath } = state;
      previousPath.current = prevPath;
      if (category === 0) {
        document.title = '対応履歴 内容 登録 | FTS顧客管理システム';
      } else if (category === 1) {
        document.title = '対応履歴 その他1 登録 | FTS顧客管理システム';
      } else {
        document.title = '対応履歴 その他2 登録 | FTS顧客管理システム';
      }
      const basePaymentMethodData = {
        id: null,
        category: category,
        text: '',
        mtb_sales_office_id: mtb_sales_office_id,
        is_deleted: 0,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      return basePaymentMethodData;
    };
    if (employee_name && mtb_sales_office_id) {
      const _supportData = getData();
      setSupportData({..._supportData});
    }
  }, [employee_name, mtb_sales_office_id, location]);

  const changeCustomerSupportData = useCallback((key, value) => {
    const _supportData = {...supportData};
    if (value !== null) {
      _supportData[key] = value;
      setSupportData(_supportData);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [supportData, modalRequired]);

  const onSaveButtonPressed = useCallback(async () => {
    const customerSupportContentFunctions = new CustomerSupportContentFunctions({ supportData: supportData, token: token });
    const response = await customerSupportContentFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current});
    }
  }, [supportData, token, history, modalRequired, previousPath]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <CustomerSupportInput 
        data={supportData}
        isEditing={true}
        errorText={errorText}
        changeData={changeCustomerSupportData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbCustomerSupportRegister;