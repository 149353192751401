import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation} from 'react-router-dom';
//components
import MtbEmployeeInputs from './children/MtbEmployeeInputs';
import PasswordConfirmationModal from './children/PasswordConfirmationModal';
import DeleteConfirmModal from 'src/views/settings/children/DeleteConfirmModal';
//functions
import DeletingFunctions from 'src/functions/DeletingFunctions';
import GeneralFunctions from 'src/functions/GeneralFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  従業員詳細コンポーネント
  編集ボタンから編集ページへ
  消去ボタンから消去確認モダル表示後に確認で従業員データを消去、リストに戻る
*/
const MtbEmployeeDetail = (props) => {

  const history = useHistory();
  const location = useLocation();

  const { token } = useSelector(state => state.auth);
  const [employeeData, setEmployeeData] = useState({});
  const [errorText, setErrorText] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmModalRequired, setConfirmModalRequired] = useState(false);
  const [modalRequired, setModalRequired] = useState(false);

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const data = state && state.data;
      return data;
    };
    const _employeeData = getData();
    setEmployeeData({..._employeeData});
  }, [props]);

  const onEditButtonPressed = useCallback(() => {
    const {pathname, state} = location;
    history.push({pathname: `${pathname}/edit`, state: {data: state.data, prevPath: pathname}});
  }, [history, location]);

  const changeConfirmedPassword = useCallback((val) => {
    const {value} = val.target
    setConfirmedPassword(value);
  }, []);

  const onConfirmButtonPressed = useCallback(() => {
    //確認用パスワード記入ダイアログメッセージでパスワード記入後に確認ボタンを押すと呼び出される関数
    //もし確認用パスワードが記入している従業員のパスワードと一致していたら従業員のデータをアップデート

    const deleteSelectedItem = async () => {
      const { id } = employeeData;
      const tableName = '/mtb_employee';
  
      const deletingFunctions = new DeletingFunctions({tableName: tableName, id: id, token: token});
      const error = await deletingFunctions.delete();
      if (error) {
        setErrorText(error);
      } else {
        setErrorText('');
        history.replace('/settings/employee-list');
      }
    };

    const encryptedPassword = GeneralFunctions.cryptHash(confirmedPassword);
    const {password} = employeeData;
    if (encryptedPassword === password) {
      setErrorText('');
      deleteSelectedItem();
    } else {
      setErrorText('確認用パスワードが一致しませんでした。');
    }
    closePasswordConfirmModal();
  }, [employeeData, confirmedPassword, history, token]);

  const onDeleteButtonPressed = useCallback(() => {
    //選択された従業員データを消去するか確認するモダルの表示
    setModalRequired(true);
  }, []);

  const showPasswordConfirmation = useCallback(() => {
    //消去するか確認するモダルを非表示の後、
    //当従業員のパスワードを入力するモダルの表示
    setConfirmModalRequired(true);
    setModalRequired(false);
  }, []);

  const closePasswordConfirmModal = () => {
    //当従業員のパスワードを入力するモダルの非表示
    setConfirmedPassword('');
    setConfirmModalRequired(false);
  };

  return (
    <>
      <PasswordConfirmationModal
        modalRequired={confirmModalRequired}
        closeConfirmModal={() => closePasswordConfirmModal()}
        confirmedPassword={confirmedPassword}
        changeConfirmedPassword={changeConfirmedPassword}
        onConfirmButtonPressed={onConfirmButtonPressed}
      />
      <DeleteConfirmModal
        modalRequired={modalRequired}
        onCloseModalButtonPressed={() => setModalRequired(false)}
        onConfirmButtonPressed={showPasswordConfirmation}
      />
      <MtbEmployeeInputs data={employeeData} isEditing={false} errorText={errorText} onEditButtonPressed={onEditButtonPressed} onDeleteButtonPressed={onDeleteButtonPressed} />
    </>
  )
};

export default MtbEmployeeDetail;