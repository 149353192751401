import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Overlay } from 'src/common/StylesComponents';
import { Colors, FontSize } from "src/configs/StyleConfig";

/*
  各種設定ページの各詳細ページに表示されている消去ボタンから呼び出されるコンポーネント
  消去確認モダル
*/
const DeleteConfirmModal = (props) => {

  const { modalRequired, onCloseModalButtonPressed, onConfirmButtonPressed } = props;
  return (
    <>
      {modalRequired && (
        <Overlay>
          <ModalWrapper>
            <CustomModalBody>
              <div>選択されたデータを消去しますが宜しいでしょうか。</div>
            </CustomModalBody>
            <ButtonWrapper>
              <ModalButton onClick={onCloseModalButtonPressed}>キャンセル</ModalButton>
              <ModalButton Color={Colors.modalButton} onClick={onConfirmButtonPressed}>続行</ModalButton>
            </ButtonWrapper>
          </ModalWrapper>
        </Overlay>
      )}
    </>
  )
};


const ModalWrapper = styled.div`
  width: 420px;
  height: 160px;
  border-radius: 12px;
  padding: 20px;
  background-color: white;
  font-size: ${FontSize.md};
  color: ${Colors.textColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 20;
`
const CustomModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
  font-weight: 500;
  padding-left: 8px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  button + button {
    margin-left: 1rem;
  }
`
const ModalButton = styled.button`
  width: 100px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: ${props => props.Color ? props.Color : 'white'};
  color: ${props => props.Color ? 'white' : Colors.textColor};
`
DeleteConfirmModal.propTypes = {
  modalRequired: PropTypes.bool.isRequired,
  onCloseModalButtonPressed: PropTypes.func.isRequired,
  onConfirmButtonPressed: PropTypes.func.isRequired
};
DeleteConfirmModal.defaultProps = {
  modalRequired: false,
  onCloseModalButtonPressed: () => { },
  onConfirmButtonPressed: () => { }
};

export default DeleteConfirmModal;