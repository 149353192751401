import {
  SET_ALL_CONSTRUCTION_LIST,
  SET_CUSTOMER_CONSTRUCTION_LIST,
  SET_SELECTED_CONSTRUCTION_DETAIL_DATA,
  SET_SELECTED_CONSTRUCTION_ID,
  REMOVE_SELECTED_CONSTRUCTION_DETAIL_DATA,
  SET_CONSTRUCTION_DATA_FAILED,
  SET_CONSTRUCTION_BREAKDOWN_DATA,
  SET_CONSTRUCTION_BREAKDOWN_DATA_FAILED,
  RESET_CONSTRUCTION_FETCH_FLAG,
  REMOVE_SELECTED_CUSTOMER_CONSTRUCTION,

  REMOVE_CONSTRUCTION_DATA
} from 'src/redux/actions/ConstructionDataHandler';

const initialState = {
  allConstructionList: [], //工事一覧に表示する工事データ
  customerConstructionList: [], //顧客詳細の工事、対応テーブルに表示するある顧客(mtb_customer_id)に基づく工事データ
  selectedConstructionData: {}, //工事詳細に表示する選択された工事データ
  isSelectedConstructionDetailReady: false, //工事詳細データ準備フラグ
  selectedConstructionId: null,
  selectedConstructionBreakdown: [], //工事詳細に表示する選択された工事データのid（dtb_construction_id）に基づく内訳データ
  isSelectedConstructionBreakdownDataReady: false, //工事詳細内訳データ準備フラグ
  editingSelectedConstructionData: {}, //工事編集/登録画面で編集中に別の画面に推移する際、そのstateを保持しておく
  hasDataSet: false, //工事一覧のデータを取得した際に上げるフラグ
};

const ConstructionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CONSTRUCTION_LIST:
      return {
        ...state,
        allConstructionList: action.payload.data,
        editingSelectedConstructionData: {},
        hasDataSet: true
      };
    case RESET_CONSTRUCTION_FETCH_FLAG:
      return {
        ...state,
        hasDataSet: false
      }
    case SET_CUSTOMER_CONSTRUCTION_LIST:
      return {
        ...state,
        customerConstructionList: action.payload.data
      };
    case SET_SELECTED_CONSTRUCTION_ID:
      return {
        ...state,
        selectedConstructionId: action.payload.dtb_construction_id,
      }
    case SET_SELECTED_CONSTRUCTION_DETAIL_DATA:
      return {
        ...state,
        selectedConstructionData: action.payload.selectedData,
        isSelectedConstructionDetailReady: true,
      };
    case REMOVE_SELECTED_CONSTRUCTION_DETAIL_DATA:
      return {
        ...state,
        isSelectedConstructionDetailReady: false,
        isSelectedConstructionBreakdownDataReady: false,
        selectedConstructionData: {},
        selectedConstructionBreakdown: [],
        editingSelectedConstructionData: {}
      }
    case SET_CONSTRUCTION_DATA_FAILED:
      return state;
    case SET_CONSTRUCTION_BREAKDOWN_DATA:
      return {
        ...state,
        selectedConstructionBreakdown: action.payload.breakdown,
        isSelectedConstructionBreakdownDataReady: true
      };
    case SET_CONSTRUCTION_BREAKDOWN_DATA_FAILED:
      return {
        ...state,
        selectedConstructionBreakdown: [],
        isSelectedConstructionBreakdownDataReady: false
      };
    case REMOVE_SELECTED_CUSTOMER_CONSTRUCTION:
      return {
        ...state, 
        customerConstructionList: [],
      };
    case REMOVE_CONSTRUCTION_DATA:
      return {
        allConstructionList: [],
        customerConstructionList: [], 
        selectedConstructionData: {},
        selectedConstructionBreakdown: [],
        editingSelectedConstructionData: {},
        isSelectedConstructionDetailReady: false,
        isSelectedConstructionBreakdownDataReady: false,
        selectedConstructionId: null,
        hasDataSet: false
      };
    default:
      return state;
  }
};

export default ConstructionDataReducer;