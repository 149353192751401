import axios from 'axios';
//functions
import GeneralFunctions from "../GeneralFunctions";
//apis
import { MTB_CUSTOMER_SUPPORT_DATA_CHANGE_URL } from 'src/apis';

const requiredInputField = {
  text: '内容'
};

/*
各種設定　対応履歴内容設定の登録/編集クラス
- Parameters in constructor:
 - supportData: 対応内容
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: DBに登録/編集したデータ
*/
class CustomerSupportContentFunctions {

  constructor(props) {
    const { supportData, token } = props;
    this.supportData = supportData;
    this.token = token;
    this.response = { error: '', data: {} };
  };

  /*
  private
  各種設定　対応履歴内容設定の登録/編集クラス
  */
  _validation() {
    const data = { ...this.customerSupport };
    let errors = [];

    Object.keys(requiredInputField).forEach((key) => {
      if (GeneralFunctions.isNullOrEmpty(data[key])) {
        errors.push(requiredInputField[key]);
      }
    });

    if (errors.length > 0) {
      const errorStr = errors.join(', ');
      return errorStr + "を入力・選択してください。";
    };

    return;
  }

  /*
  private
  渡された対応履歴内容データ付随し、アクセストークンと共にAPIを呼び出す処理
  */
  async _send() {
    try {
      const json = [this.supportData];
      this.response.data = this.supportData;
      await axios.post(MTB_CUSTOMER_SUPPORT_DATA_CHANGE_URL, json, { headers: {"Authorization" : `Bearer ${this.token}`} })
        .then((response) => {
          if (response.status === 200) {
            return;
          } else {
            throw new Error('対応履歴の内容を登録/編集できませんでした。');
          }
        })
    } catch (error) {
      return error.message;
    }
  }
  /*
  public method
  別のファイルから呼び出されるメソッド
  */
  async sendData() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._send();
    if (error) {
      this.response.error = error;
    }
    this.response.error = "";

    return this.response;
  }

};

export default CustomerSupportContentFunctions;