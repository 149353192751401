import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import CustomerSupportInput from './children/CustomerSupportInput';
import DialogMessage from 'src/common/DialogMessage';
//functions
import CustomerSupportContentFunctions from 'src/functions/mtbFunctions/CustomerSupportContentFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  対応履歴（内容、その他１、その他２）共通の編集ページコンポーネント
  保存からDBにcategoryカラムの値だけ変えて送信
  キャンセルボタンから詳細ページへ推移
*/
const MtbCustomerSupportEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [supportData, setSupportData] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');
  const pathCategory = useRef(null);

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath, category} = state;
      pathCategory.current = category;
      if (category === 0) {
        document.title = '対応履歴 内容 編集 | FTS顧客管理システム';
      } else if (category === 1) {
        document.title = '対応履歴 その他1 編集 | FTS顧客管理システム';
      } else {
        document.title = '対応履歴 その他2 編集 | FTS顧客管理システム';
      }
      data["updated_employee_name"] = employee_name;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _supportData = getData();
      setSupportData({..._supportData});
    }
  }, [employee_name, props]);

  const changeCustomerSupportData = useCallback((key, value) => {
    const _supportData = {...supportData};
    if (value !== null) {
      _supportData[key] = value;
      setSupportData(_supportData);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [supportData, modalRequired]);

  const onSaveButtonPressed = useCallback(async () => {
    const customerSupportContentFunctions = new CustomerSupportContentFunctions({ supportData: supportData, token: token });
    const response = await customerSupportContentFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current, state: {data: response.data, category: pathCategory.current}});
    }
  }, [supportData, token, history, modalRequired, previousPath, pathCategory]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <CustomerSupportInput 
        data={supportData}
        isEditing={true}
        errorText={errorText}
        changeData={changeCustomerSupportData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbCustomerSupportEdit;