import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { FontSize, BreakPoints } from 'src/configs/StyleConfig';
//components
import {TextInput, TextHalfNumberInput} from 'src/common/Inputs';
import DateSelector from 'src/common/DateSelector';
import DropDownSelection from 'src/common/DropDownSelection';

const storeInfoKeys = {
  store_address: "取次店舗 住所", store_phone: "取次店舗 電話番号", store_name: "取次店舗 店舗名", customer_name: "施主 氏名",  customer_phone: "施主 電話番号"
}
const constructionInfoKeys = {
  survey_date: "調査年月日", construction_date: "施工年月日", construction_other: "施工建築物の種類その他", construction_type: "施工建築物の種類", construction_division: "施工の区分",
  measures: "予防駆除", termite_type: "シロアリの種類", building_area: "建面積", building_area_unit: "建面積 単位"
}

const chemicalKeys = [
  {chemical_name1: "木部材 侵漬", chemical_used1: "使用量(ℓ)"}, 
  {chemical_name2: "木部材 吹付け", chemical_used2: "使用量(ℓ)"},
  {chemical_name3: "木部材 穿孔", chemical_used3: "使用量(ℓ)"},
  {chemical_name4: "土壌 散布", chemical_used4: "使用量(ℓ)"},
  {chemical_name5:"土壌 加圧", chemical_used5: "使用量(ℓ)"},
  {chemical_name6: "コンクリート 吹付け", chemical_used6:"使用量(ℓ)"}, 
];
// const chemicalNames = ["chemical_name1", "chemical_name2", "chemical_name3", "chemical_name4", "chemical_name5","chemical_name6"];
const chemicalUsed = ["chemical_used1", "chemical_used2","chemical_used3", "chemical_used4", "chemical_used5", "chemical_used6"];

const constructionTypes = [
  {value: -1, label: ""},
  {value: 0, label: "住宅"},
  {value: 1, label: "店舗"},
  {value: 2, label: "公共建築物"},
  {value: 3, label: "神社仏閣"},
  {value: 4, label: "その他"}
]
const constructionDivisions = [
  {value: -1, label: ""},
  {value: 0, label: "新築"},
  {value: 1, label: "改築"},
  {value: 2, label: "既設"}
]
const termiteTypes = [
  {value: -1, label: ""},
  {value: 0, label: "ヤマトシロアリ"},
  {value: 1, label: "イエシロアリ"}
]
const measureTypes = [
  {value: -1, label: ""},
  {value: 0, label: "予防"},
  {value: 1, label: "駆除"}
]

/*
損害賠償テキストボックス群表示コンポーネント
PropsのIsEditable＝trueで編集可
*/
const DamageWarrantyInput = (props) => {
  
  const {damageWarranty, changeDamageWarranty, isEditable} = props;

  const switchInputSection = useCallback((key) => {
    if (key === "construction_date" || key === "survey_date") {
      return <DateSelector 
        date={damageWarranty[key]} 
        onChange={(val) => changeDamageWarranty(key, val)}
        isEnable={isEditable}
      />
    } else if (key === "construction_type") {
      return  (
        <DropDownWrapper>
          <DropDownSelection 
            options={constructionTypes}
            isEnable={isEditable}
            value={damageWarranty.construction_type}
            onChange={(val) => changeDamageWarranty(key, val.target.value)}
          />
        </DropDownWrapper>
      )
    } else if (key === "construction_division") {
      return  (
        <DropDownWrapper>
          <DropDownSelection 
            options={constructionDivisions}
            isEnable={isEditable}
            value={damageWarranty.construction_division}
            onChange={(val) => changeDamageWarranty(key, val.target.value)}
          />
        </DropDownWrapper>
      )
    } else if (key === "termite_type") {
      return  (
        <DropDownWrapper>
          <DropDownSelection 
            options={termiteTypes}
            isEnable={isEditable}
            value={damageWarranty.termite_type}
            onChange={(val) => changeDamageWarranty(key, val.target.value)}
          />
        </DropDownWrapper>
      )
    } else if (key === "measures") {
      return  (
        <DropDownWrapper>
          <DropDownSelection 
            options={measureTypes}
            isEnable={isEditable}
            value={damageWarranty.measures}
            onChange={(val) => changeDamageWarranty(key, val.target.value)}
          />
        </DropDownWrapper>
      )
    } else if (key === "building_area") {
      return  <TextHalfNumberInput
          isEnable={isEditable}
          value={damageWarranty[key] ? damageWarranty[key] : ""}
          onChange={(val) => changeDamageWarranty(key, val.target.value)}
        />
    } else {
      return <TextInput
          isEnable={isEditable}
          value={damageWarranty[key] ? damageWarranty[key] : ""}
          onChange={(val) => changeDamageWarranty(key, val.target.value)}
        />
    }
  }, [damageWarranty, isEditable, changeDamageWarranty])

  return (
    <Container>
      <SectionWrapper>
        <SectionContainer>
          <SectionTitle>
            取引先情報
          </SectionTitle>
          {
            Object.keys(storeInfoKeys).map((warrantyKey) => {
              if (warrantyKey === 'store_phone' || warrantyKey === 'customer_phone') {
                return (
                  <SingleInputWrapper key={warrantyKey}>
                    <LabelWrapper>
                      {`${storeInfoKeys[warrantyKey]} : `}
                    </LabelWrapper>
                    <TextInputWrapper>
                      <TextHalfNumberInput
                        isEnable={isEditable}
                        value={damageWarranty[warrantyKey] ? damageWarranty[warrantyKey] : ""}
                        onChange={(val) => changeDamageWarranty(warrantyKey, val.target.value)}
                      />
                    </TextInputWrapper>
                </SingleInputWrapper>
                )
              }
              return (
                <SingleInputWrapper key={warrantyKey}>
                  <LabelWrapper>
                    {`${storeInfoKeys[warrantyKey]} : `}
                  </LabelWrapper>
                  <TextInputWrapper>
                    <TextInput
                      isEnable={isEditable}
                      value={damageWarranty[warrantyKey] ? damageWarranty[warrantyKey] : ""}
                      onChange={(val) => changeDamageWarranty(warrantyKey, val.target.value)}
                    />
                  </TextInputWrapper>
              </SingleInputWrapper>
              )
            })
          }
        </SectionContainer>
        <LargeTextInputSectionWrapper>
              <LabelWrapper>
                施主 住所 : 
              </LabelWrapper>
              <AddressTextWrapper>
                <TextInput
                  isEnable={isEditable}
                  value={damageWarranty.customer_address ? damageWarranty.customer_address : ""}
                  onChange={(val) => changeDamageWarranty("customer_address", val.target.value)}  
                />
              </AddressTextWrapper>
          </LargeTextInputSectionWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionContainer>
          <SectionTitle>
            施工先情報
          </SectionTitle>
          <LargeTextInputSectionWrapper>
              <LabelWrapper>
                施工場所 住所 : 
              </LabelWrapper>
              <AddressTextWrapper>
                <TextInput
                  isEnable={isEditable}
                  value={damageWarranty.construction_address ? damageWarranty.construction_address : ""}
                  onChange={(val) => changeDamageWarranty("construction_address", val.target.value)}  
                />
              </AddressTextWrapper>
          </LargeTextInputSectionWrapper>
          {
            Object.keys(constructionInfoKeys).map((warrantyKey) => {
              return (
                <SingleInputWrapper key={warrantyKey}>
                  <LabelWrapper>
                    {`${constructionInfoKeys[warrantyKey]} : `}
                  </LabelWrapper>
                  <TextInputWrapper>
                    {switchInputSection(warrantyKey)}
                  </TextInputWrapper>
                </SingleInputWrapper>
              )
            })
          }
        </SectionContainer>
          <MemoWrapper>
            <LabelWrapper>
              防除施工前の被害状況: 
            </LabelWrapper>
            <MemoTextArea 
              disabled={!isEditable}
              value={damageWarranty.damage ? damageWarranty.damage : ""} 
              onChange={(val) => changeDamageWarranty("damage", val.target.value)}
            />
          </MemoWrapper>
      </SectionWrapper>
      <ChemicalSectionWrapper>
        <div>
          <SectionTitle>
            使用薬剤情報
          </SectionTitle>
          {
            chemicalKeys.map((chemical, index) => {
              return (
                <ChemicalSection key={index.toString()}>
                  {
                    chemical && Object.keys(chemical).map((key) => {
                      if (chemicalUsed.includes(key)) {
                        return (
                          <SingleInputWrapper key={key}>
                          <LabelWrapper>
                            {`${chemical[key]} : `} 
                          </LabelWrapper>
                          <TextInputWrapper>
                            <TextHalfNumberInput 
                              isEnable={isEditable}
                              textType={'number'}
                              value={damageWarranty[key]}
                              onChange={(val) => changeDamageWarranty(key, val.target.value)}
                            />
                          </TextInputWrapper>
                        </SingleInputWrapper>
                        )
                      }
                      return (
                        <SingleInputWrapper key={key}>
                        <LabelWrapper>
                          {`${chemical[key]} : `}
                        </LabelWrapper>
                        <TextInputWrapper>
                          <TextInput
                            isEnable={isEditable}
                            value={damageWarranty[key]}
                            onChange={(val) => changeDamageWarranty(key, val.target.value)}
                          />
                        </TextInputWrapper>
                      </SingleInputWrapper>
                      )
                    })
                  }
                </ChemicalSection>
              )
            })
          }
          <MemoWrapper>
            <LabelWrapper>
              使用薬剤 備考 : 
            </LabelWrapper>
            <MemoTextArea 
              disabled={!isEditable}
              value={damageWarranty.chemical_remarks ? damageWarranty.chemical_remarks : ""} 
              onChange={(val) => changeDamageWarranty("chemical_remarks", val.target.value)}
            />
          </MemoWrapper>
        </div>
      </ChemicalSectionWrapper>
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 4px;
`
const SectionTitle = styled.div`
  width: 100%;
  height: 40px;
  padding: 4px;
  font-size: ${FontSize.lg};
  font-weight: 500;
`
const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: ${BreakPoints.lg}) {
    align-items: center;
  }
`
const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${BreakPoints.lg}) {
    align-items: center;
  }
`
const ChemicalSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.lg}) {
    align-items: flex-start;
    flex-direction: column;
  }
`
const ChemicalSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0;
  }
`
const DropDownWrapper = styled.div`
  height: 40px;
  margin-top: 12px;
`
const SingleInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`
const LabelWrapper = styled.div`
  width: 180px;
  text-align: right;
  font-size: ${FontSize.md};
`
const TextInputWrapper = styled.div`
  max-width: 250px;
  min-width: 180px;
  width: 20%;
`
const LargeTextInputSectionWrapper = styled.div`
  display: flex;
  width: ${props => props.width ? props.width : '100%'};
  flex-direction: row;
  align-items: ${props => props.align ? props.align : 'center'};
  padding:  ${props => props.padding ? props.padding : '4px'};
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: row;
    align-items: flex-start;
  }
`
const AddressTextWrapper = styled.div`
  max-width: 600px;
  min-width: 400px;
  width: 50%;
`

const MemoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px 4px;
  align-self: flex-start;
`
const MemoTextArea = styled.textarea`
  min-height: 80px;
  min-width: 300px;
  border: 1px solid black;
  :disabled {
    background-color: white;
  }
`

DamageWarrantyInput.propTypes = {
  damageWarranty: PropTypes.object.isRequired,
  changeDamageWarranty: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

DamageWarrantyInput.defaultProps = {
  damageWarranty: {},
  changeDamageWarranty: () => {},
  isEditable: true
};



export default DamageWarrantyInput;
