export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE";


export const setErrorMessage = ({ where, msg }) => async (dispatch) => {
  dispatch({
    type: SET_ERROR_MESSAGE,
    payload: { msg: msg, where: where},
  })
};

export const removeErrorMessage = () => (dispatch) => {
  dispatch({
    type: REMOVE_ERROR_MESSAGE,
  })
}