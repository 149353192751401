import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import StoreInputs from './children/StoreInputs';
import DialogMessage from 'src/common/DialogMessage';
//redux
import { useSelector } from 'react-redux';
//functions
import StoreFunctions from 'src/functions/mtbFunctions/StoreFunctions';

/*
  店舗登録コンポーネント
  テキスト入力後、
  保存ボタンから店舗データをDBに格納
  キャンセルボタンで入力情報を破棄してリストに戻る
*/
const MtbStoreRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, mtb_enterprise_id, token } = useSelector(state => state.auth);
  const [store, setStore] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  /*
    店舗データを初期化
  */
  useEffect(() => {
    const getData = () => {
      const { state } = location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const baseStoreData = {
        id: null,
        store_code: null,
        partner_name: '',
        mtb_partner_id: '',
        store_name: '',
        postal_code: '',
        address: '',
        phone: '',
        is_deleted: 0,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      return baseStoreData;
    };
    if (employee_name && mtb_enterprise_id) {
      const _store = getData();
      setStore({ ..._store });
    }
  }, [employee_name, mtb_enterprise_id, location]);

  /*
    店舗データを格納
  */
  const changeStoreData = useCallback((key, value) => {
    const _store = { ...store };
    if (value !== null) {
      _store[key] = value;
      setStore(_store);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [store, modalRequired]);

  /*
    保存ボタンから店舗データをDBに格納
  */
  const onSaveButtonPressed = useCallback(async () => {
    const storeFunctions = new StoreFunctions({ storeData: store, token: token });
    const response = await storeFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({ pathname: previousPath.current });
    }
  }, [store, token, history, modalRequired, previousPath]);

  /*
    キャンセルボタンで入力情報を破棄してリストに戻る
  */
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <StoreInputs
        data={store}
        isEditing={true}
        errorText={errorText}
        isRegistering={true}
        onChangeData={changeStoreData}
        onSaveButtonPressed={onSaveButtonPressed}
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbStoreRegister;