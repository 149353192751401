import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
//components
import { SubmitButton } from 'src/common/StylesComponents';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setCustomerSupportMasterSelectionData } from 'src/redux/actions/MasterDataHandler';
//styles and configs
import { Container, Table, ColumnNameWrapper, Th, Tr, Td, ButtonWrapper, BackToSettingButton } from 'src/views/settings/children/MtbStyles';

const columnNames = {
  text: { label: '内容', width: '100%', align: 'left' }
};

/*
  対応履歴（内容、その他１、その他２）共通一覧ページコンポーネント
  内容、その他１、その他２でページは分かれているが、共通のコンポーネントを使用
  各行からその詳細ページへ推移
  新規登録からカテゴリにのデータを保持したまま登録ページへ推移
*/
const MtbCustomerSupportList = () => {

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { mtb_sales_office_id } = useSelector(state => state.auth);
  const { customerSupportData } = useSelector(state => state.master);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const category = useRef(null);

  /*
    DBからそれぞれの（カテゴリによって）顧客対応データをフェッチ
  */
  useEffect(() => {
    if (mtb_sales_office_id) {
      const { pathname } = location;
      const _category = pathname.split('/').pop(); //get last element
      if (_category === 'content') {
        category.current = 0;
        document.title = '対応履歴 内容 | FTS顧客管理システム';
      } else if (_category === 'other1') {
        category.current = 1;
        document.title = '対応履歴 その他1 | FTS顧客管理システム';
      } else {
        category.current = 2;
        document.title = '対応履歴 その他2 | FTS顧客管理システム';
      }
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, category.current));
    }
  }, [mtb_sales_office_id, location, dispatch]);

  const initSalesData = () => {
    if (typeof category.current === 'number') {
      const _data = customerSupportData[category.current] ? customerSupportData[category.current] : [];
      setData([..._data]);
      setIsLoading(false);
    }
  };
  useMemo(initSalesData, [category.current]);

  /*
    詳細ページへ推移するときにカテゴリも一緒に渡す
  */
  const navigateToDetail = useCallback((id) => {
    const index = data.findIndex((d) => d.id === id);
    if (index !== -1) {
      history.push({ pathname: `${pathname}/${id}`, state: { data: data[index], category: category.current } });
    }
  }, [history, pathname, data, category]);

  /*
    新規登録ページへ推移するときにカテゴリも一緒に渡す
  */
  const addNewEntry = useCallback(() => {
    history.push({ pathname: `${pathname}/register`, state: { category: category.current, prevPath: pathname } });
  }, [history, pathname, category]);

  const Row = (row) => {
    const { data, id } = row;
    return (
      <Tr onClick={() => navigateToDetail(id)}>
        {
          Object.keys(columnNames).map((columnName) => {
            return (
              <Td key={columnName} width={columnNames[columnName].width}>
                {data[columnName]}
              </Td>
            )
          })
        }
      </Tr>
    )
  };

  if (isLoading) {
    return <Container />
  }
  return (
    <Container>
      <ButtonWrapper>
        <BackToSettingButton onClick={() => { history.goBack() }} ><i className="fa fa-angle-left" style={{ fontSize: '24px' }} /></BackToSettingButton>
        <SubmitButton onClick={addNewEntry}>新規登録</SubmitButton>
      </ButtonWrapper>
      <CustomTable>
        <ColumnNameWrapper>
          {
            Object.keys(columnNames).map((cName) => <Th width={columnNames[cName].width} key={cName}>{columnNames[cName].label}</Th>)
          }
        </ColumnNameWrapper>
        <tbody>
          {
            data.length > 0 && data.map((support, index) => {
              return <Row data={support} id={support.id} key={index.toString()} />
            })
          }
        </tbody>
      </CustomTable>
    </Container>
  )
};

const CustomTable = styled(Table)`
  width: 80%;
  min-width: 400px;
  max-width: 700px;
`


export default MtbCustomerSupportList;