import React, { memo } from 'react';
import styled from 'styled-components';
//styles & configs
import { BreakPoints } from 'src/configs/StyleConfig';
import { SearchFieldContainer, CheckboxContainer } from 'src/common/StylesComponents';
//components
import Checkbox from 'src/common/Checkbox';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';


registerLocale('ja', ja) // カレンダーを日本語対応させるための準備

const dateSortingOption = [
  { value: 0, label: '登録日時（新）' },
  { value: 1, label: '登録日時（古）' },
];

const dateOptions = [
  { value: 'warranty_date', label: '保証期間' },
  { value: 'create_date', label: '作成年月日' },
  { value: 'exported_date', label: '出力日' }
];

const WarrantyListSearchField = memo((props) => {
  const { selectedDateType, setSelectedDateType, startDate, setStartDate, endDate, setEndDate, onSearchClicked, onSearchClear, checkedState, setCheckedState, checkedIsExported, setCheckedIsExported, dateSortSelected, sortType } = props;

  // 選択された日付の種類をstateに保持
  const dateSelected = (e) => {
    setSelectedDateType(e.target.value);
  };

  return (
    <>
      <WarrantyListSearchFieldStyle>
        <SearchContainer>
          <CheckBoxesContainer>
            <CheckboxContainer>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[0]}
                  changeStatus={() => setCheckedState(0)}
                  label={'シロアリ防除施工保証書'}
                />
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  isChecked={checkedState.list[2]}
                  changeStatus={() => setCheckedState(2)}
                  label={'損害賠償保証書'}
                />
              </CheckBoxWrapper>
            </CheckboxContainer>
          </CheckBoxesContainer>
          <SearchInputContainer>
            <div style={{ display: 'flex' }}>
              <div style={{}}>
                <select style={{ height: "30px" }} value={selectedDateType} onChange={(e) => dateSelected(e)}>
                  <option key={"default"} value={""}>--日付の種類--</option>
                  {dateOptions.map((key, index) => {
                    return (
                      <option key={index} value={key.value}>{key.label}</option>
                    )
                  })}
                </select>
              </div>
              <div style={{ margin: '4px 12px 0 36px', color: '#3f3f3f', fontWeight: 'bold' }}>範囲: </div>
              <div style={{ width: '20%' }}>
                <DatePicker locale="ja" dateFormat="yyyy/MM/dd" selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
              <div style={{ margin: '4px 8px 0 8px', color: '#3f3f3f', fontWeight: 'bold' }}>
                〜
              </div>
              <div style={{ width: '20%' }}>
                <DatePicker locale="ja" dateFormat="yyyy/MM/dd" selected={endDate} onChange={(date) => setEndDate(date)} />
              </div>
            </div>
            <SearchButton type="submit" onClick={() => onSearchClicked()}><i className="fa fa-search"></i></SearchButton>
            <ShowAllButton onClick={onSearchClear}>全て表示</ShowAllButton>
          </SearchInputContainer>
        </SearchContainer>
      </WarrantyListSearchFieldStyle>
      <div style={{ display: 'flex', margin: '12px 0px 0px auto' }}>
        <div style={{ display: 'flex', marginTop: '4px', textAlign: 'right' }}>
          <CheckBoxWrapper style={{ paddingBottom: '4px', marginRight: '16px' }}>
            <Checkbox
              isChecked={checkedIsExported}
              changeStatus={() => setCheckedIsExported(prev => !prev)}
              label={'CSV未出力'}
            />
          </CheckBoxWrapper>
          <span style={{ color: '#3f3f3f', fontWeight: 'bold', padding: '6px 8px' }}>並び替え</span>
          <select value={sortType} style={{ height: "28px", margin: '4px 36px 0px 4px' }} onChange={(e) => dateSortSelected(e)}>
            {dateSortingOption.map((key, index) => {
              return (
                <option key={index} value={key.value}>{key.label}</option>
              )
            })}
          </select>
        </div>
      </div >
    </>
  )
});

const WarrantyListSearchFieldStyle = styled(SearchFieldContainer)`
  min-width: 680px;
  padding: 8px 0px;
  @media screen and (max-width: ${BreakPoints.md}) {
    flex-direction: column;
    overflow-x: auto;
    display: inline;
  }
`

const CheckBoxesContainer = styled.div`
  @media screen and (max-width: ${BreakPoints.md}) {
    display: flex;
    flex-direction: row;
  }
`

const CheckBoxWrapper = styled.div`
  min-width: 60px;
`

const SearchButton = styled.button`
  width: 60px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 8px 24px;
  margin: 2px 4px;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

const ShowAllButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 8px 20px;
  font-weight: bold;
  margin: 2px 4px;
  transition: 0.3s;
  :hover {
    color: #1d1d1f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`
const SearchContainer = styled.div`
  color: #3f3f3f;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  background-color: white;
  padding: 36px 40px;
  margin: 30px auto 0px auto;
  border: 1px solid #dedede;
  border-radius: 6px;
  @media screen and (max-width: ${BreakPoints.xl}) {
    max-width: 960px;
    min-width: 660px;
    width: 80%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
`

export const SearchInputContainer = styled.div`
  color: #3f3f3f;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 4px 40px;
`

export default WarrantyListSearchField;