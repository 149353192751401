import React, { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//components
import StatusMapping from 'src/common/StatusMapping';
import ReactPaginate from 'react-paginate';
import Loader from 'react-loader';
//configs & styles
import { Colors, BreakPoints, FontSize } from 'src/configs/StyleConfig';
import 'src/index.css'; // only needs to be imported once
//hooks
import { useWindowSize } from 'src/views/hooks/useWindowSize';


const baseColumnNames = {
  status: { label: 'ステータス', width: '10%' },
  customer_code: { label: '顧客No', width: '15%' },
  sales_office: { label: '会社/営業所', width: '20%' },
  customer_name: { label: '氏名', width: '20%' },
  customer_address1: { label: '住所', width: '25%' },
  customer_phone1: { label: 'TEL', width: '10%' },
};

const keyPairs = {
  contract_date: ['contract_date', 'construction_end_date'],
  sales_office: ['enterprise', 'sales_office'],
  customer_name: ['customer_kana', 'customer_name'],
  customer_address1: ['customer_postal_code', 'customer_address1']
};

const CustomerTable = memo((props) => {
  const { data, navigation, selectedDateType, isDataProcessing } = props;
  const { width } = useWindowSize();
  const [columnNames, setColumnNames] = useState(baseColumnNames)
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [isTwoLine, setIsTwoLine] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 100;

  useEffect(() => {
    //windowのサイズに合わせてテーブルのカラムを調整（初期化）
    if (window.innerWidth < 790) {
      const updatedColumnNames = {
        customer_code: { label: '顧客No', width: '14%' },
        sales_office: { label: '会社/営業所', width: '20%' },
        customer_name: { label: '氏名', width: '16%' },
        customer_address1: { label: '住所', width: '24%' },
        customer_phone1: { label: 'TEL', width: '16%' },
      }
      setColumnNames(updatedColumnNames);
      setIsTwoLine(true);
    } else {
      setColumnNames(baseColumnNames);
      setIsTwoLine(false);
    }
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    setIsLoading(false);
  }, [itemsPerPage, itemOffset, data, selectedDateType]);

  const changeTableColumn = () => {
    //windowのサイズに合わせてテーブルのカラムを調整（アップデート）
    if (width < 790) {
      const updatedColumnNames = {
        customer_code: { label: '顧客No', width: '14%' },
        sales_office: { label: '会社/営業所', width: '20%' },
        customer_name: { label: '氏名', width: '16%' },
        customer_address1: { label: '住所', width: '24%' },
        customer_phone1: { label: 'TEL', width: '16%' },
      }
      setColumnNames(updatedColumnNames);
      setIsTwoLine(true);
    } else {
      setColumnNames(baseColumnNames);
      setIsTwoLine(false);
    }
  };
  useMemo(changeTableColumn, [width]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const TableContent = ({ data }) => {

    return (
      <>
        {
          isTwoLine ?
            <TrTop>
              <td colSpan="5"><StatusMapping oneLine={true} statusList={data['status']} /></td>
            </TrTop>
            :
            null
        }
        <Tr key={data.id} onClick={() => navigation(data.id)}>
          {
            Object.keys(columnNames).map((columns) => {
              if (columns === 'status') {
                return (
                  <Td width={columnNames[columns].width} key={columns}>
                    <StatusMapping statusList={data[columns]} />
                  </Td>
                )
              } else if (columns === 'sales_office' || columns === 'customer_name' || columns === 'contract_date') {
                const key = keyPairs[columns];
                const fontSize = columns === 'customer_name' ? 8 : FontSize.xs;
                return (
                  <Td key={columns}>
                    <MultiLine style={{ fontSize: fontSize }}>
                      {data[key[0]]}
                    </MultiLine>
                    <MultiLine>
                      {data[key[1]]}
                    </MultiLine>
                  </Td>
                )
              } else if (columns === 'customer_address1') {
                return (
                  <Td key={columns}>
                    <MultiLine>
                      {data['customer_postal_code']}
                    </MultiLine>
                    {
                      data['customer_address2'] ?
                        <MultiLine>
                          {`${data['customer_address1']} \n ${data['customer_address2']}`}
                        </MultiLine>
                        :
                        <MultiLine>
                          {`${data['customer_address1']}`}
                        </MultiLine>
                    }
                  </Td>
                )
              }
              return (
                <Td key={columns}>
                  {data[columns]}
                </Td>
              )
            })
          }
        </Tr>
      </>
    );
  }

  return (
    <Container>
      <Table>
        <TitleContainer>
          <tr>
            {
              Object.keys(columnNames).map((cName) => <Th key={cName}>{columnNames[cName].label}</Th>)
            }
          </tr>
        </TitleContainer>
        <tbody>
          {
          isDataProcessing || isLoading ?
          null
          :
            currentItems &&
            currentItems.map((data, index) => (
              <TableContent data={data} key={index.toString()} />
            ))
          }
          </tbody>
      </Table>
      {
        isDataProcessing || isLoading ?
        <div style={{ height: 320, display: 'flex', alignItems: 'center', backgroundColor: 'black'}}>
          <Loader position="relative" />
        </div>
        :
        null
      }
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={'page-selection'}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </Container>
  )
});

const Container = styled.div`
  width: 100%;
  margin: 32px 0 0 0;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${BreakPoints.lg}) {
    width: 95%;
  }
  @media screen and (max-width: ${BreakPoints.md}) {
    width: 92%;
  }
`
const Table = styled.table`
  max-width: ${BreakPoints.xxl};
  min-width: ${BreakPoints.md};
  width: 96%;
  border-collapse: collapse;
`
const TitleContainer = styled.thead`
  border: 1px solid #3f3f3f;
  border-radius: 6px;
  height: 48px;
  background-color: #3f3f3f;
`
const Th = styled.th`
  color: #ffffff;
  font-size: ${FontSize.md};
  font-weight: 600;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}`};
`
const Tr = styled.tr`
  min-height: 60px;
  border-bottom: 1px solid lightgray;
  font-size: ${FontSize.xs};
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: ${Colors.boxHighlight};
  }
  :after {
    content: "";
    display: block;
  }
`
const TrTop = styled.tr`
  height: 24px;
  font-size: ${FontSize.xs};
  cursor: pointer;
  background-color: #ffffff;
  @media screen and (max-width: 790) {
    border-bottom: 1px solid lightgray;
  }
`
const Td = styled.td`
  list-style-type: none;
  color: ${Colors.textColor};
  font-weight: 500;
  text-align: ${props => props.align ? props.align : 'left'};
  width: ${props => props.width && `${props.width}`};
`
const MultiLine = styled.pre`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
`

CustomerTable.propTypes = {
  data: PropTypes.array.isRequired,
  navigation: PropTypes.func.isRequired,
  selectedDateType: PropTypes.number.isRequired,
  isDataProcessing: PropTypes.bool.isRequired,
};
CustomerTable.defaultProps = {
  data: [],
  navigation: () => { },
  selectedDateType: 0,
  isDataProcessing: true
};

export default CustomerTable;