import axios from 'axios';
import Big from 'big.js';
import {
  CONSTRUCTION_DATA_CHANGE_URL,
  CONSTRUCTION_DETAIL_DATA_CHANGE_URL,
  CONSTRUCTION_DETAIL_DATA_URL,
  GET_CONSTRUCTION_CSV_URL,
  TAX_RATE_URL
} from 'src/apis';
//functions
import DateFunctions from 'src/functions/DateFunctions';
import DeletingFunctions from './DeletingFunctions';
import GeneralFunctions from './GeneralFunctions';

//はがき出力用のimport
import PostalBackPDF from 'src/static/post_back.pdf';
import PostalFrontPDF from 'src/static/post_front.pdf';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import JaFontUrl from 'src/static/fonts/ZenOldMincho-Black.ttf';
import NotoSansJPMedium from 'src/static/fonts/NotoSansJP-Medium.otf';
import { getPartnerData } from './mtbFunctions/PartnerFunctions';

//工事データのカラム
const constructionProperties = [
  'id', 'support_date', 'contract_date', 'contract_no', 'serial_no', 'remarks', 'destination_name', 'destination_postal_code', 'destination_address', 'destination_phone',
  'mtb_sales_office_id', 'mtb_partner_id', 'mtb_store_id', 'construction_start_date',
  'construction_end_date', 'mtb_payment_method_id', 'payment_description', 'approval_number', 'is_agreement_form', 'is_maintenance', 'has_other_construction',
  'sales_employee_name1', 'sales_employee_name2', 'sales_employee_name3', 'sales_employee_name4',
  'construction_employee_name1', 'construction_employee_name2', 'construction_employee_name3', 'construction_employee_name4',
  'tax_rate', 'tax_division', 'tax_price', 'rounding_division', 'stamp_price', 'stamp_amount', 'memo', 'subtotal', 'tax_price', 'total', 'margin', 'tax_exclude', 'tax_include',
  'is_exported', 'exported_at', 'exported_employee_name', 'mtb_customer_id', 'mtb_enterprise_id', 'created_employee_name', 'updated_employee_name'
];

//工事データの契約情報必須のカラム（properties）
// const constructionContractRequiredProperties = { };

//工事データの工事情報必須カラム
const constructionRequiredProperties = { mtb_customer_id: '顧客名', mtb_sales_office_id: '営業所', mtb_partner_id: '提携先' };

//工事データの消費税情報必須カラム
// const constructionTaxRequiredProperties = { };

//工事詳細データ内訳の必須カラム
const breakdownRequiredProperties = { product_code: '商品コード', jan_code: 'JANコード', content: '内容', is_warranty: '保証の有無', quantity: '数量', unit: '単位', price: '金額' };

/*
工事データの登録/編集
工事詳細データ（工事内訳）の登録/編集
- Parameters in constructor:
 - constructionInfo: 工事データ（工事内訳も含む）
 - deletingConstructionDetail: 工事内訳の消去行データ
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - constructionData: DBに登録/編集した工事データ
 - constructionDetailData: DBに登録/編集/消去した工事内訳データ
 - logs: DBに登録/編集した工事データのinsertIdなどを含むデータ
*/

class ConstructionFunctions {

  constructor(props) {
    const { constructionInfo, deletingConstructionDetail, token } = props;
    this.constructionInfo = constructionInfo;
    this.deletingConstructionDetail = deletingConstructionDetail;
    this.token = token;
    this.response = { error: '', constructionData: {}, constructionDetailData: [], logs: {} };
  }

  //validation ------------------------------------

  /*
    private method
    工事データの工事データの工事情報のみ必須項目、選択肢チェック
    '提携先', '営業所', '顧客名', '店舗名', '支払い方法'は０以外の選択肢のみ受けとる
  */
  _constructionValidation() {
    // const contract = this.constructionInfo["契約情報"];
    const construction = this.constructionInfo["工事情報"];
    // const ConstructionBreakdown = this.constructionInfo["工事内訳"];
    let error = [];
    // 工事データの契約情報のvalidation
    // Object.keys(constructionContractRequiredProperties).map((requiredForm) => {
    //   if(GeneralFunctions.isNullOrEmpty(contract[requiredForm])) {
    //     error.push(constructionContractRequiredProperties[requiredForm])
    //   }
    // });
    // 工事データの工事情報のvalidation
    const selectionForm = ['提携先', '営業所', '顧客名', '店舗名', '支払い方法'] //選択肢 default 0だが0に値はない為 0以外を受け入れ
    Object.keys(constructionRequiredProperties).forEach((requiredForm) => {
      if (selectionForm.includes(constructionRequiredProperties[requiredForm])) {
        parseInt(construction[requiredForm]) === 0 && error.push(constructionRequiredProperties[requiredForm])
      } else {
        if (GeneralFunctions.isNullOrEmpty(construction[requiredForm])) error.push(constructionRequiredProperties[requiredForm])
      }
    });

    if (error.length > 0) {
      if (selectionForm.includes(error[0])) return `${error[0]}を選択してください。`;
      return `${error[0]}を記入してください。`;
    }
    return;
  };

  /*
    private method
    工事詳細データの必須項目チェック
    product_code: '商品コード', jan_code: 'JANコード', content: '内容', is_warranty: '保証の有無', quantity: '数量', unit: '単位', price: '金額'
    税関係の記入チェックは含まない
  */
  _constructionDetailValidation() {
    const ConstructionBreakdown = this.constructionInfo["工事内訳"];
    const breakdown = ConstructionBreakdown.tableContents.breakdown;
    // const breakdownTotal = ConstructionBreakdown.tableContents.breakdownTotal;
    // const taxInfo = ConstructionBreakdown.taxInfo;

    let error = [];
    //税関係の記入チェック
    // Object.keys(constructionTaxRequiredProperties).map((requiredForm) => {
    //   if (GeneralFunctions.isNullOrEmpty(taxInfo[requiredForm])) {
    //     error.push(constructionTaxRequiredProperties[requiredForm])
    //   }
    // });
    // if (error.length > 0) {
    //   if (error[0] === 'tax_rate') return `${error[0]}を記入してください。`;
    //   return  `${error[0]}を選択してください。`;
    // }

    //工事内訳propertiesのチェック（テーブルのカラム）
    breakdown.map((row, index) => {
      Object.keys(breakdownRequiredProperties).forEach((requiredForm) => {
        if (typeof row[requiredForm] === null) {
          error.push({ key: 'required', column: breakdownRequiredProperties[requiredForm], at: index });
        }
      });
    });

    if (error.length > 0) {
      return `${error[0].at + 1} 行目のカラム${error[0].column}を記入してください。`;
    }
    return;
  };

  /*
    private method
    工事データ、工事内訳データの必須項目チェック
  */
  _validation() {
    let error = this._constructionValidation();
    if (error) {
      return error;
    }
    error = this._constructionDetailValidation();
    if (error) {
      return error;
    }

    return;
  }

  //sending data ---------------------------

  /*
    private method
    工事データ送信メソッド
    (1) 契約情報、工事情報等のデータ(dtb_construction)をDBのカラムのタイプと一致するようにObjectに格納
    (2) 工事APIにPOSTでリクエスト
  */
  async _sendConstructionData(contract, construction, breakdownTotal, taxInfo, otherInfo, isRegistering) {
    //(1) DBに送る工事データの格納
    const IntTypeKey = ['mtb_customer_id', 'mtb_sales_office_id', 'mtb_partner_id', 'mtb_payment_method_id', 'mtb_store_id', 'is_agreement_form', 'is_maintenance', 'has_other_construction']; //String型からint型に直してDBに格納するカラム
    const constructionData = {};
    let constructionDataResponse = { dtb_construction_id: null, error: null };
    constructionProperties.forEach((key) => {
      if (Object.keys(contract).includes(key)) {
        constructionData[key] = contract[key];
      } else if (Object.keys(construction).includes(key)) {
        if (IntTypeKey.includes(key)) {
          constructionData[key] = parseInt(construction[key]); // str ---> int
        } else {
          constructionData[key] = construction[key];
        }
      } else if (Object.keys(breakdownTotal).includes(key)) {
        constructionData[key] = breakdownTotal[key].value;
      } else if (Object.keys(taxInfo).includes(key)) {
        constructionData[key] = taxInfo[key];
      } else if (Object.keys(otherInfo).includes(key)) {
        constructionData[key] = otherInfo[key]; // mtb_enterprise_id のみ
      }
    });
    constructionData['created_employee_name'] = otherInfo['created_employee_name'];
    constructionData['updated_employee_name'] = otherInfo['updated_employee_name'];
    if (!isRegistering) {
      //updateの場合は今日の日付を代入
      const updated_at = new Date().toISOString();
      constructionData["updated_at"] = updated_at;
    }
    const constructionDataJson = [constructionData];

    this.response.constructionData = { ...constructionData }; //DB送信後、推移した詳細画面で見せるデータ

    try {
      //(2)
      const response = await axios.post(CONSTRUCTION_DATA_CHANGE_URL, constructionDataJson, { headers: { "Authorization": `Bearer ${this.token}` } });
      const { data } = response;
      this.response.logs = data;
      constructionDataResponse.dtb_construction_id = data.insertId;
      return constructionDataResponse;
    } catch (error) {
      constructionDataResponse.error = error;
      return error;
    }
  };

  /*
    private method
    工事内訳データ送信メソッド
    (1) DBに送る工事内訳データ(dtb_construction_detail)の格納
    (2) 工事内訳APIにPOSTでリクエスト
  */
  async _sendConstructionDetailData(breakdown, otherInfo, dtb_construction_id, isRegistering) {
    //(1)DBに送る工事内訳データの格納
    const constructionDetailDataJson = [];
    if (breakdown.length === 0) return;

    breakdown.map((row) => {
      let constructionDetailData = { ...row };
      delete constructionDetailData.unit_price;
      delete constructionDetailData.price;
      if (isRegistering) {
        delete constructionDetailData.id;
      } else {
        constructionDetailData['updated_at'] = otherInfo['updated_at'];
      }
      constructionDetailData['price'] = row.unit_price; //単価価格
      constructionDetailData['total'] = row.price; //金額
      constructionDetailData['created_employee_name'] = otherInfo['created_employee_name'];
      constructionDetailData['updated_employee_name'] = otherInfo['updated_employee_name'];
      constructionDetailData['dtb_construction_id'] = dtb_construction_id;
      constructionDetailDataJson.push(constructionDetailData)
    });
    this.response.constructionDetailData = [...constructionDetailDataJson];
    try {
      //(2)工事内訳APIにPOSTでリクエスト
      await axios.post(CONSTRUCTION_DETAIL_DATA_CHANGE_URL, constructionDetailDataJson, { headers: { "Authorization": `Bearer ${this.token}` } })
        .then((response) => {
          if (response.status === 200) {
            return response;
          }
          throw new Error('工事詳細データを保存できませんでした。');
        })
        .then(() => {
          return;
        })
      return;
    } catch (error) {
      return error.message;
    }
  };

  /*
    private method
    工事内訳消去行送信メソッド
    工事編集時のみ呼び出せるメソッドで、工事内訳にて消去した行のリストから
    消去する工事内訳をそのIdを元に消去APIを呼び出し
  */
  async _deleteConstructionDetail() {
    const deletingIds = [...this.deletingConstructionDetail];
    if (deletingIds.length === 0) return [];

    const tableName = '/dtb_construction_detail';
    const promises = deletingIds.map(async (id) => {
      const deletingFunctions = new DeletingFunctions({ tableName: tableName, id: id, token: this.token });
      const error = await deletingFunctions.delete();
      if (error) return id;
      return;
    });

    return Promise.all(promises);
  }

  /*
    private method
    工事データ、工事詳細データ、保証情報データ、ファイル取り込みデータをDBに登録する処理を行うメソッド
    (1) 工事データの送信
    (2) 工事データ新規登録後、レスポンスから追加行のIndexを取ってくる（工事外部キー：dtb_construction_id）
    (3) 新規工事追加行Idを工事内訳データの外部キーとして格納し、工事内訳データをDB送信
  */
  async _handleRegistration() {
    try {
      const otherInfo = this.constructionInfo["その他情報"];
      const contract = this.constructionInfo["契約情報"];
      const construction = this.constructionInfo["工事情報"];
      const ConstructionBreakdown = this.constructionInfo["工事内訳"];
      const breakdown = ConstructionBreakdown.tableContents.breakdown;
      const breakdownTotal = ConstructionBreakdown.tableContents.breakdownTotal;
      const taxInfo = ConstructionBreakdown.taxInfo;
      const isRegistering = true;
      //(1) 工事データの送信
      const constructionResponseData = await this._sendConstructionData(contract, construction, breakdownTotal, taxInfo, otherInfo, isRegistering);
      if (constructionResponseData.error) {
        return constructionResponseData.error;
      }
      //(2) 工事データ新規登録後、レスポンスから追加行のIndexを取ってくる（工事外部キー：dtb_construction_id）
      const { dtb_construction_id } = constructionResponseData;
      if (dtb_construction_id) {
        //(3) 新規工事追加行Idを工事内訳データの外部キーとして格納し、工事内訳データをDB送信
        let error = await this._sendConstructionDetailData(breakdown, otherInfo, dtb_construction_id, isRegistering);
        if (error) return "工事内訳を保存できませんでした。";
      }

      return;
    } catch (error) {
      return error.message;
    }
  };

  /*
    private method
    工事データ、工事詳細データ、保証情報データ、ファイル取り込みデータをDBに編集する処理を行うメソッド
    (1) 工事データの送信
    (2) 必要であれば編集する工事データのIdを工事内訳データに外部キーとして格納しアップデート
    (3) 消去する工事詳細データのDB処理
  */
  async _handleUpdate() {
    // 工事データ、工事詳細データ、保証情報データ、ファイル取り込みデータをアップデートする処理
    try {
      const otherInfo = this.constructionInfo["その他情報"];
      const contract = this.constructionInfo["契約情報"];
      const construction = this.constructionInfo["工事情報"];
      const ConstructionBreakdown = this.constructionInfo["工事内訳"];
      const breakdown = ConstructionBreakdown.tableContents.breakdown;
      const breakdownTotal = ConstructionBreakdown.tableContents.breakdownTotal;
      const taxInfo = ConstructionBreakdown.taxInfo;
      const dtb_construction_id = construction.id;
      const isRegistering = false;
      let errors = [];
      //(1) 工事データの送信
      const constructionResponseData = await this._sendConstructionData(contract, construction, breakdownTotal, taxInfo, otherInfo, isRegistering);
      if (constructionResponseData.error) errors.push("工事データ");
      //(2) 必要であれば編集する工事データのIdを工事内訳データに外部キーとして格納しアップデート
      const constructionDetailError = await this._sendConstructionDetailData(breakdown, otherInfo, dtb_construction_id, isRegistering);
      if (constructionDetailError) errors.push("工事内訳");
      //(3) 消去する工事詳細データのDB処理
      const deletingError = await this._deleteConstructionDetail();
      const errorIds = deletingError.length > 0 && deletingError.filter((id) => id);
      if (errorIds.length > 0) errors.push("消去する工事内訳");

      return errors;
    } catch (error) {
      console.log(error)
      return [error.message];
    }
  }


  /*
    public method
    外部から呼び出すメソッド
    工事データ登録時のみ呼び出す
  */
  async register() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    error = await this._handleRegistration();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    this.response.error = '';
    return this.response;
  }

  /*
    public method
    外部から呼び出すメソッド
    工事データ編集時のみ呼び出す
  */
  async update() {
    let error = this._validation();
    if (error) {
      this.response.error = error;
      return this.response;
    }
    const errorArr = await this._handleUpdate();
    if (errorArr.length > 0) {
      const errorMsg = errorArr.join(",");
      this.response.error = errorMsg + "をアップデートできませんでした。";
      return this.response;
    }

    this.response.error = '';
    return this.response
  }

};

export default ConstructionFunctions;

/*
日付を元に消費税率を引っ張ってくる関数
ConstructionDetailとConstructionRegisterにて使用
- Parameters:
 - token: APIアクセストークン
- returns:
 - tax_rate: 通常時：日付による消費税率、error時：0
*/
export const getTaxRate = async (token) => {
  try {
    const today = new Date().toISOString();
    const response = await axios.get(TAX_RATE_URL, {
      params: {
        date: today
      },
      headers: { "Authorization": `Bearer ${token}` }
    });
    const { tax_rate } = response.data[0];
    if (tax_rate) return tax_rate;
    throw new Error("could not get tax rate");
  } catch (error) {
    return 0;
  }
};


/*
工事一覧からのcsv出力クラス
- 流れ：
 -　工事一覧からデータの受け取り 
 -　工事一覧データからCSV出力データ（内容とヘッダー）を抽出し返す
 -　（クラス呼び出しファイルにてCSV出力）
 -　CSV出力ステータスの変更
- Parameters in constructor:
 - constructionListData: 工事一覧データ
 - loginEmployeeName: ログインユーザー名
 - token: APIアクセストークン
- returns:
 - error: Validation,　APIアクセスなどのエラー
 - data: CSV出力データ
*/
class ConstructionCSVFunctions {

  constructor(props) {
    const { constructionListData, token, loginEmployeeName } = props;
    this.constructionListData = constructionListData;
    this.token = token;
    this.loginEmployeeName = loginEmployeeName;
    this.sortingIndex = [];
    this.csvResponse = { data: {}, error: '' };
  }


  async _fetchConstructionData(ids) {
    const dtb_construction_id_array = `${ids.join(",")}`;
    const body = { "dtb_construction_id_array": dtb_construction_id_array };
    try {
      const response = await axios.post(GET_CONSTRUCTION_CSV_URL, body, { headers: { "Authorization": `Bearer ${this.token}` } });
      if (response.status !== 200) throw new Error("Construction detail data could not found");
      return response.data;
    } catch (error) {
      this.csvResponse["error"] = error;
      return [];
    }
  }

  /*
    private method
    csvに出力する工事データを取得するAPIを呼び出す
    絞り込み、デフォルトの工事Idsを元にフェッチしてきているので、
    データが多い場合小分けにしてデータを取得する。
  */
  async _getConstructionDataForCSVExport() {
    if (this.constructionListData.length === 0) return [];

    let csvData = [];
    const ids = this.constructionListData.map((d) => d.id);
    const maxValue = 10000;
    if (ids.length > maxValue) {
      let end = 0;
      for (let start = 0; start < ids.length; start = start + maxValue) {
        end = start + maxValue;
        const id_array = ids.slice(start, end);
        const _csvData = await this._fetchConstructionData(id_array);
        csvData = [...csvData, ..._csvData];
      }
    } else {
      const _csvData = await this._fetchConstructionData(ids);
      csvData = [..._csvData];
    }
    return csvData;
  }

  /*
    private method
    CSVで出力するデータのリストを返す関数
    - (1) CSV出力用のデータをリストに格納
    - (2) 非同期処理を含むのでPromiseでリストを返す
  */
  async _getCSVArrayData(constructionData) {
    let allCSVData = [];
    let _invoice_no = 0;
    let invoice_index = 0;
    const promises = constructionData.map((data, index) => {

      const { id, customer_code, contract_date, total, tax_rate, tax_price, tax_include, customer_name, content, unit, quantity, unit_price } = data;
      this.sortingIndex.push(id);
      const enterprise_code = '706900';
      const invoice_no = GeneralFunctions.padding(id, 9); //ここの桁数を変えたならsortDataToMatchShowingDataOrderのpaddingの引数も合わせる
      let invoice_date_ISO;

      if (!contract_date || new Date(contract_date).toString() === "Invalid Date") {
        invoice_date_ISO = "";
      } else {
        invoice_date_ISO = new Date(contract_date).toISOString();
      }
      const payment_completion_date = DateFunctions.ISOStringDateToLocalDateCSVFormat(DateFunctions.addDays(invoice_date_ISO, 21));
      const invoice_date = DateFunctions.ISOStringDateToLocalDateCSVFormat(invoice_date_ISO);
      const _customer_name = GeneralFunctions.halfToFullString(customer_name);

      let _unit_price = unit_price;
      if (tax_include === 0 || !tax_include) { //外税の場合
        _unit_price = _unit_price + _unit_price * tax_rate * 0.01;
      }

      Big.RM = Big.roundDown;
      const price = new Big(unit_price).times(quantity);

      // 明細番号を工事ごとに1, 2, 3...とincrementする処理。次の工事に進むと明細番号を初期化する。
      if (_invoice_no !== invoice_no) {
        _invoice_no = invoice_no;
        invoice_index = 1;
      } else {
        invoice_index += 1;
      }

      const json = {
        enterprise_code: enterprise_code, //会社コード
        invoice_no: '1' + invoice_no, //請求書番号
        customer_code: customer_code, //顧客コード
        invoice_date: invoice_date, //請求日付
        total: total, //請求金額
        tax_price: tax_price, //消費税額
        payment_completion_date: payment_completion_date, //入金予定日
        customer_name: _customer_name, //顧客名
        remarks1: '毎度ありがとうございます。下記の通りご請求申し上げます。', //備考１
        remarks2: '毎度格別のお引き立てを賜り、厚くお礼申し上げます。下記の通りご請求させていただきますので、ご査収の上なにとぞ、よろしくお願いいたします。お問い合わせ先　０３ー５２９７ー８５４５', //備考２
        detail_no: invoice_index, //明細番号
        content: content, //概要
        quantity: quantity, //数量
        unit: unit, //単位
        unit_price: _unit_price, //単価
        price: price //金額
      };
      allCSVData.push(json);
      return allCSVData;
    });

    return Promise.all(promises).then(() => allCSVData);
  }

  /*
    private method
    uniqueのvalueだけ抽出する
  */
  _onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  /*
    private method
    CSVで出力するデータのリストをソートして出力
  */
  _sortDataToMatchShowingDataOrder = (data) => {

    const ids = this.sortingIndex.filter(this._onlyUnique);
    const sorted_ids = ids.reverse();

    let results = [];
    sorted_ids.map((index) => data.filter((d) => {
      if (d.invoice_no === '1' + GeneralFunctions.padding(index, 9)) {
        results.push(d);
      }
    }));
    return results;
  }

  /*
    public method
    外部から呼び出す関数
    ConstructionListにて呼び出し
    CSV出力データを返す
  */
  async getConstructionDetailForCSV() {
    const csvHeader = {
      enterprise_code: '会社コード',
      invoice_no: '請求書番号',
      customer_code: '顧客コード',
      invoice_date: '請求日付',
      total: '請求金額',
      tax_price: '消費税額',
      payment_completion_date: '入金予定日',
      customer_name: '顧客名',
      remarks1: '備考１',
      remarks2: '備考２',
      detail_no: '明細番号',
      content: '概要',
      quantity: '数量',
      unit: '単位',
      unit_price: '単価',
      price: '金額'
    };
    // const dataSortingIndex = this.constructionListData.map((d) => d.id);
    const csvConstructionData = await this._getConstructionDataForCSVExport();
    const csvData = await this._getCSVArrayData(csvConstructionData);
    console.log(csvData)
    if (csvData.length === 0) {
      this.csvResponse["error"] = "Could not get csv data";
      return this.csvResponse;
    }
    const sortedCSVData = this._sortDataToMatchShowingDataOrder(csvData);
    const data = { csvHeader: csvHeader, csvData: sortedCSVData };
    this.csvResponse['data'] = data;
    return this.csvResponse;
  };

  //csv status update　-----------------------

  /*
    private method
    CSV出力済みフラグを変更する為CSV出力された尚且つ以前出力されてない工事IDを返す処理を行う内部メソッド
    （渡されたcsv出力する工事データのis_exportedがnullか0のデータのみAPIに付随してis_exportedを1にする処理）
  */
  _getUpdateRequiredConstructionId() {
    let updatedId = [];
    const today = new Date().toISOString();
    this.constructionListData.map((data) => {
      if (!data.is_exported || data.is_exported === 0) { //未出力のデータのIDのみ
        let json = {};
        constructionProperties.map((key) => {
          if (key === "is_exported") {
            //CSV出力フラグ
            json[key] = 1;
          } else if (key === 'exported_at') {
            json[key] = today;
          } else if (key === 'exported_employee_name') {
            json[key] = this.loginEmployeeName;
          } else {
            json[key] = data[key];
          }
        });
        updatedId.push(json);
      }
    });
    return updatedId;
  }

  /*
    public method
    外部から呼び出す関数
    ConstructionListにて呼び出し
    工事データのCSV出力済みフラグを１にする処理を行うメソッド
  */
  async updateCSVExportStatus() {
    const updatedConstructionData = this._getUpdateRequiredConstructionId();
    if (updatedConstructionData.length === 0) return;//すでに全てのデータがcsv出力済み
    const updatedConstructionDataJson = [updatedConstructionData];
    try {
      await axios.post(CONSTRUCTION_DATA_CHANGE_URL, updatedConstructionData, { headers: { "Authorization": `Bearer ${this.token}` } });
      return;
    } catch (error) {
      console.log(error.message);
      return;
    }
  }
};

export { ConstructionCSVFunctions };


/*
工事はがき出力クラス
- Parameters in constructor:
 - postcardData: 工事一覧の工事データ
 - isFirstOptionSelected: 0：企業名＋営業所名、1：企業名＋営業所名（表面のみ）、2：フマキラー出力時の設定、3：フマキラー出力時の設定（表面のみ）
 - salesOfficeData: 営業所データ
 - loginSalesOfficeId: ログインユーザーの営業所Idデータ
 - token: APIアクセストークン
*/
class ConstructionPostcardPrint {

  constructor(props) {
    const { postcardData, isFirstOptionSelected, salesOfficeData, loginSalesOfficeId, token } = props;
    this.postcardData = postcardData;
    this.isFirstOptionSelected = isFirstOptionSelected;
    this.salesOfficeData = salesOfficeData;
    this.loginSalesOfficeId = loginSalesOfficeId;
    this.token = token;
  };

  /*
    private method
    はがきに出力する提携先ロゴを引っ張ってきた後、提携先データに画像のbyte arrayを格納するメソッド
  */
  async _getPartnerLogoData() {
    try {
      const allPartnerData = await getPartnerData(this.token);
      const promises = allPartnerData.map(async (partner) => {
        if (partner.signed_url) {
          const jpgImageBytes = await fetch(partner.signed_url).then((res) => res.arrayBuffer());
          return {
            ...partner,
            'hasLogoImage': true,
            'imageBytes': jpgImageBytes
          };
        }
        return {
          ...partner,
          'hasLogoImage': false,
          'imageBytes': new Uint8Array()
        };
      });

      return Promise.all(promises);
    } catch (error) {
      console.log(error.message)
      return;
    }
  };

  /*
    private method
    SOI not found error を避ける為のメソッド
  */
  async _getEmbedImage(mergedPdf, imageBytes) {
    try {
      return await mergedPdf.embedJpg(imageBytes);
    } catch (error) {
      return null;
    }
  }

  _getNumberStartingPointX(numStr, fontSize) {
    if (numStr.length === 2) return fontSize * 0.1;
    if (numStr.length < 2) return - fontSize * 0.15;
    return numStr.length * fontSize / 7.5;
  }

  /**
   * Textを縦に描画する関数
   * @param {*} front 
   * @param {*} font 
   * @param {*} fontSize 
   * @param {*} x 
   * @param {*} startY 
   * @param {*} text 
   */
  _drawTextVertically(front, font, fontSize, x, startY, text) {
    // const text = "栃木県矢板市中0000-157-00-1";
    let char = "";
    let startHeight = startY;
    for (let i=0;i<text.length;i++) {
      // if (text[i].trim() === "") continue;
      
      if (Number(text[i]) >= 0) {
        char = char + text[i];
      } else {
        if (char.length) {
          const startX = x - this._getNumberStartingPointX(char, fontSize);
          front.drawText(char, {
            x: startX,
            y: startHeight,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
          });
          char = "";
          startHeight = startHeight - fontSize;
        }
        
        if (text[i] === "-" || text[i] === "ー") {
          front.drawText("l", {
            x: x + fontSize * 0.35,
            y: startHeight,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
          startHeight = startHeight - fontSize;
        } else {
          front.drawText(text[i], {
            x: x,
            y: startHeight,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
          });
          startHeight = startHeight - fontSize;
        }
      }
    }
    if (char.length) {
      const startX = x - this._getNumberStartingPointX(char, fontSize);
      front.drawText(char, {
        x: startX,
        y: startHeight,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
    }
  }

  /*
    private method
    与えられた工事データの分はがきデータを生成するメソッド
  */
  async _getPDFData(partners) {
    try {
      const config = {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: {
          'Accept': 'application/pdf'
        }
      };
      const isEnterpriseOptionSelected = this.isFirstOptionSelected === 0 || this.isFirstOptionSelected === 1 ? true : false;
      const isExportBackground = this.isFirstOptionSelected === 1 || this.isFirstOptionSelected === 3 ? false : true;
      const fontBytes = await fetch(NotoSansJPMedium).then((res) => res.arrayBuffer());
      const phoneFontBytes = await fetch(JaFontUrl).then((res) => res.arrayBuffer());

      const mergedPdf = await PDFDocument.create();
      const back = await axios.get(PostalBackPDF, config);
      const front = await axios.get(PostalFrontPDF, config);
      const backData = back.data;
      const frontData = front.data;
      const pdfA = await PDFDocument.load(frontData);
      const pdfB = await PDFDocument.load(backData);
      const dataLength = this.postcardData.length;

      //工事データの数だけ表裏のはがきのセットを追加
      for (let i = 0; i < dataLength; i++) {
        if (isExportBackground) {
          const [copiedPageFront] = await mergedPdf.copyPages(pdfA, [0])
          const [copiedPageBack] = await mergedPdf.copyPages(pdfB, [0])
          mergedPdf.addPage(copiedPageFront);
          mergedPdf.addPage(copiedPageBack);
        } else {
          mergedPdf.addPage([566, 419]);
          mergedPdf.addPage([566, 419]);
        }
      }
      mergedPdf.registerFontkit(fontkit);
      const font = await mergedPdf.embedFont(fontBytes);
      const phoneFont = await mergedPdf.embedFont(phoneFontBytes);
      const pageNum = mergedPdf.getPageCount();

      const pages = mergedPdf.getPages();
      const firstPage = pages[0];
      const { width, height } = firstPage.getSize();
      const logoWidth = 180;
      const logoHeight = 35

      //prepare embedded images
      const promises = partners.map(async (partner) => {
        if (partner.hasLogoImage) {
          const logoImage = await this._getEmbedImage(mergedPdf, partner.imageBytes);
          if (logoImage) {
            logoImage.scaleToFit(logoWidth, logoHeight);
          }
          return { ...partner, logoImage: logoImage };
        } else {
          return { ...partner, logoImage: null };
        }
      });
      const partnerLogoArr = await Promise.all(promises);
      const partnerLogoObj = {};
      partnerLogoArr.forEach((partner) => { partnerLogoObj[partner.id] = partner });

      //config
      const fontSize = 7;
      const leftSideStart = 94;
      const rightSideStart = 310;
      const addressStartHeight = height - 135;
      const postalCodeStart = leftSideStart - 12;
      const halfPageWidth = width / 2;
      const logoStartHeight = 55;
      const smallFontSize = fontSize + 2;
      const mediumFontSize = fontSize + 3;
      const largeFontSize = fontSize + 6;

      const verticalStringStartHeight = height - 90;
      const enterpriseVerticalStringStartHeight = verticalStringStartHeight - 4;
      const salesOfficeVerticalStringStartHeight = enterpriseVerticalStringStartHeight - 120;
      const customerNameVerticalStringStartHeight = enterpriseVerticalStringStartHeight - 65;
      const addressStartX = postalCodeStart + 130;
      const enterpriseNameStartX = postalCodeStart + 70;
      const salesOfficeNameStartX = postalCodeStart + 45;

      const customerIDFontSize = fontSize + 10;
      const enterpriseFontSize = fontSize + 5;
      const logoAddressFontSize = fontSize + 2;
      const logoPhoneFontSize = fontSize + 6;
      const postalFontSize = 22;
      const verticalMediumFontSize = fontSize + 8;
      const verticalLargeFontSize = fontSize + 10;

      const sales_office_data = this.salesOfficeData[this.loginSalesOfficeId]; //ログインユーザー登録営業所
      const {
        examination_content, examination_duration, holiday, reception_time,
        fts_enterprise_sales_office_name, fts_phone, sales_office_name, postal_code, address, phone
      } = sales_office_data;

      for (let i = 0; i < pageNum; i += 2) {
        const front = pages[i];
        const back = pages[i + 1];
        const index = i / 2;
        const data = this.postcardData[index];

        const {
          customer_address1, customer_address2, customer_postal_code, customer_name, customer_code,
          enterprise_name
        } = data;

        //true = 企業名＋営業所名, false = フマキラー出力時の設定
        const showingEnterpriseName = isEnterpriseOptionSelected ? enterprise_name : fts_enterprise_sales_office_name;
        // ロゴ下、中央の企業名　フマキラー選択時に営業所に紐づいているフマキラー出力時の企業、営業所名、その他　工事に紐づいている企業名
        const showingPhoneNumber = isEnterpriseOptionSelected ? phone : fts_phone; // ロゴ下の電話番号　フマキラー選択時にfts_phone、その他　営業所設定の電話番号
        const customer_address = customer_address1 + " " + (customer_address2 || ""); //工事に紐づく顧客住所
        const partner_logo = partnerLogoObj[data.mtb_partner_id].logoImage; //工事データに紐づく提携先ロゴ

        // 郵便番号
        if (postal_code) {
          let postalCodeArr = postal_code.split('').filter((num) => {
            if (num !== '-') return num
          });
          const postalCodeY = height - 63;
          let postalCodeStartX = postalCodeStart;
          postalCodeArr.slice(0, 3).forEach((num) => {
            postalCodeStartX = postalCodeStartX + 22;
            front.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          });
          postalCodeStartX = postalCodeStartX + 6;
          postalCodeArr.slice(3, 7).forEach((num) => {
            postalCodeStartX = postalCodeStartX + 22;
            front.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          });
        }

        //企業住所
        if (address) {
          const _sales_office_address = GeneralFunctions.fullToHalfString(address);
          this._drawTextVertically(front, font, verticalMediumFontSize, addressStartX, verticalStringStartHeight, _sales_office_address);
        }

        if (isEnterpriseOptionSelected) {
          //企業名＋営業所名
          //企業名
            this._drawTextVertically(
              front,
              font, 
              verticalLargeFontSize, 
              enterpriseNameStartX, 
              enterpriseVerticalStringStartHeight, 
              showingEnterpriseName
            );

          //営業所名
          if (sales_office_name) {

            const _sales_office_name = sales_office_name + "      行";
            this._drawTextVertically(
              front, 
              font, 
              verticalLargeFontSize, 
              salesOfficeNameStartX, 
              salesOfficeVerticalStringStartHeight, 
              _sales_office_name
            );
          }
        } else {
          // フマキラー出力時の設定
          //企業名
          if (showingEnterpriseName) {

            this._drawTextVertically(
              front, 
              font, 
              verticalLargeFontSize, 
              enterpriseNameStartX, 
              enterpriseVerticalStringStartHeight, 
              showingEnterpriseName
            );
          }
        }

        if (isExportBackground) {
          //検査内容
          if (examination_content) {
            const examination_content_arr = examination_content.split("\n");
            front.drawText(examination_content_arr[0], {
              x: rightSideStart,
              y: 216,
              size: smallFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
            if (examination_content_arr.length > 1) {
              front.drawText(examination_content_arr[1], {
                x: rightSideStart,
                y: 204,
                size: smallFontSize,
                font: font,
                color: rgb(0, 0, 0),
              });
            }
          }
          //検査時間
          if (examination_duration) {
            front.drawText(examination_duration, {
              x: rightSideStart,
              y: 170,
              size: smallFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
          //定休日
          if (holiday) {
            front.drawText(holiday, {
              x: rightSideStart,
              y: 138,
              size: smallFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
          //電話受付時間
          if (reception_time) {
            const _reception_time = GeneralFunctions.fullToHalfString(reception_time);
            front.drawText(_reception_time, {
              x: rightSideStart,
              y: 106,
              size: mediumFontSize + 1,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
        }

        const rightHalfWidth = halfPageWidth / 2 + halfPageWidth;
        //右下ロゴ以下
        if (isExportBackground) {
          //ロゴ
          if (partner_logo) {
            front.drawImage(partner_logo, {
              x: rightHalfWidth - logoWidth / 2,
              y: logoStartHeight,
              width: logoWidth,
              height: logoHeight,
            });
          }
          //企業名
          if (showingEnterpriseName) {
            const enterpriseNameTextWidth = font.widthOfTextAtSize(showingEnterpriseName, enterpriseFontSize);
            front.drawText(showingEnterpriseName, {
              x: rightHalfWidth - enterpriseNameTextWidth / 2,
              y: logoStartHeight - 15,
              size: enterpriseFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
          //営業所住所
          if (postal_code && address) {
            let _sales_office_postal_code = "〒" + GeneralFunctions.fullToHalfString(postal_code);
            _sales_office_postal_code = _sales_office_postal_code + " " + GeneralFunctions.fullToHalfString(address);
            const salesOfficeTextWidth = font.widthOfTextAtSize(_sales_office_postal_code, logoAddressFontSize);
            front.drawText(_sales_office_postal_code, {
              x: rightHalfWidth - salesOfficeTextWidth / 2,
              y: logoStartHeight - 25,
              size: logoAddressFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
          //営業所電話番号
          if (showingPhoneNumber) {
            const _showingPhoneNumber = "TEL." + showingPhoneNumber;
            const salesOfficePhoneTextWidth = font.widthOfTextAtSize(_showingPhoneNumber, logoPhoneFontSize);
            front.drawText(_showingPhoneNumber, {
              x: rightHalfWidth - salesOfficePhoneTextWidth / 2,
              y: logoStartHeight - 40,
              size: logoPhoneFontSize,
              font: phoneFont,
              color: rgb(0, 0, 0),
            });
          }
        }


        // ---------------------------------------------------

        // 顧客郵便番号
        if (customer_postal_code) {
          let postalCodeArr = customer_postal_code.split('').filter((num) => {
            if (num !== '-') return num
          });
          const postalCodeY = height - 63;
          let postalCodeStartX = postalCodeStart;
          postalCodeArr.slice(0, 3).forEach((num) => {
            postalCodeStartX = postalCodeStartX + 22;
            back.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          });
          postalCodeStartX = postalCodeStartX + 6;
          postalCodeArr.slice(3, 7).forEach((num, ind) => {
            postalCodeStartX = postalCodeStartX + 22;
            back.drawText(num, {
              x: postalCodeStartX + 5,
              y: postalCodeY + 8,
              size: postalFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          });
        }

        //顧客住所
        if (customer_address) {
          const _customer_address = GeneralFunctions.fullToHalfString(customer_address);
          this._drawTextVertically(back, font, verticalMediumFontSize, addressStartX, verticalStringStartHeight, _customer_address);
        }
        //顧客名
        if (customer_name) {
          let _customer_name = GeneralFunctions.fullToHalfString(customer_name);
          _customer_name = _customer_name + "  様"
          this._drawTextVertically(back, font, verticalLargeFontSize, enterpriseNameStartX, customerNameVerticalStringStartHeight, _customer_name);
        }

        //左下ロゴ以下

        //ロゴ
        if (partner_logo) {
          back.drawImage(partner_logo, {
            x: halfPageWidth / 2 - logoWidth / 2,
            y: logoStartHeight,
            width: logoWidth,
            height: logoHeight,
          });
        }
        //企業名
        if (showingEnterpriseName) {
          const enterpriseNameTextWidth = font.widthOfTextAtSize(showingEnterpriseName, enterpriseFontSize);
          back.drawText(showingEnterpriseName, {
            x: halfPageWidth / 2 - enterpriseNameTextWidth / 2,
            y: logoStartHeight - 15,
            size: enterpriseFontSize,
            font: font,
            color: rgb(0, 0, 0),
          });
        }
        //営業所住所
        if (postal_code && address) {
          let _sales_office_postal_code = "〒" + GeneralFunctions.fullToHalfString(postal_code);
          _sales_office_postal_code = _sales_office_postal_code + " " + GeneralFunctions.fullToHalfString(address);
          const salesOfficeTextWidth = font.widthOfTextAtSize(_sales_office_postal_code, logoAddressFontSize);
          back.drawText(_sales_office_postal_code, {
            x: halfPageWidth / 2 - salesOfficeTextWidth / 2,
            y: logoStartHeight - 25,
            size: logoAddressFontSize,
            font: font,
            color: rgb(0, 0, 0),
          });
        }
        //営業所電話番号
        if (showingPhoneNumber) {
          const _showingPhoneNumber = "TEL." + showingPhoneNumber;
          const salesOfficePhoneTextWidth = font.widthOfTextAtSize(_showingPhoneNumber, logoPhoneFontSize);
          back.drawText(_showingPhoneNumber, {
            x: halfPageWidth / 2 - salesOfficePhoneTextWidth / 2,
            y: logoStartHeight - 40,
            size: logoPhoneFontSize,
            font: phoneFont,
            color: rgb(0, 0, 0),
          });
        }

        if (isExportBackground) {
          //right hand side
          //顧客ID
          if (customer_code) {
            back.drawText(customer_code, {
              x: rightSideStart + 80,
              y: height - 100,
              size: customerIDFontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          }
        }
      }

      const mergedPdfFile = await mergedPdf.save();
      const mergedPDFUrl = URL.createObjectURL(new Blob([mergedPdfFile], { type: 'application/pdf' }));
      return mergedPDFUrl;
    } catch (error) {
      console.log(error.message);
      return;
    }
  };

  /*
    public method
    外部から呼び出す関数
    ConstructionListにて使用
    はがきを生成し、出力まで行う
  */
  async print() {
    const partnerDataArray = await this._getPartnerLogoData();
    const data = await this._getPDFData(partnerDataArray);
    if (!data) {
      return "something went wrong";
    }
    window.open(data, "PRINT", "height=400,width=600");
    return;
  };

};

export { ConstructionPostcardPrint }