import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
//components
import FTS_ICON from 'src/static/FTS_icon.png';
//functions
import AuthFunctions from 'src/functions/AuthFunctions';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticationStatus } from 'src/redux/actions/AuthHandler';
import { FontSize } from 'src/configs/StyleConfig';

/*
  ログイン画面コンポーネント
  ログイン処理後顧客一覧ページへ推移
*/
const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector(state => state.auth);
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  /*
    タブタイトルの設定後、
    ログインしていたら顧客リストに推移
  */
  useEffect(() => {
    document.title = 'ログイン | FTS顧客管理システム';
    if (typeof isLoggedIn === "boolean" && isLoggedIn) {
      history.push({ pathname: "/customer-list" });
    }
  }, [isLoggedIn, history]);

  /*
    アカウント名をstateに格納
  */
  const changeAccountName = useCallback((e) => {
    const text = e.target.value
    if (text) {
      setAccountName(text)
    } else {
      setAccountName('');
    }
  }, []);

  /*
    パスワードをstateに格納
  */
  const changePassword = useCallback((e) => {
    const text = e.target.value
    if (text) {
      setPassword(text)
    } else {
      setPassword('');
    }
  }, []);

  /*
    ログインボタンからログインの関数ブロックを呼び出しAuthチェック後：
    エラー：エラー表示
    Auth OK：顧客一覧へ推移
  */
  const onLoginButtonPressed = useCallback(async () => {
    setError('');
    const authFunctions = new AuthFunctions({ accountName: accountName, password: password });
    const response = await authFunctions.login();
    if (response.error) {
      setError(response.error);
    } else {
      dispatch(setAuthenticationStatus(response.data));
      history.push({ pathname: "/customer-list" });
    }
  }, [accountName, password, dispatch, history]);

  return (
    <>
      <HeaderStyle>
        <ImageWrapper>
          <img src={FTS_ICON} style={{ width: '100%', height: '100%' }} alt={"logo"}/>
        </ImageWrapper>
      </HeaderStyle>
      <Container>
        <h3 style={{ color: '#70c66b', fontWeight: 'bold', fontSize: '36px' }}>
          ログイン
        </h3>
        <InputSection>
          <InputContainer>
            <LabelStyle>
              アカウント
            </LabelStyle>
            <LoginInput type="text" onChange={changeAccountName} value={accountName} placeholder="アカウント名" />
          </InputContainer>
          <InputContainer>
            <LabelStyle>
              パスワード
            </LabelStyle>
            <LoginInput type="password" onChange={changePassword} value={password} placeholder="パスワード" />
          </InputContainer>
        </InputSection>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        <LoginButton onClick={onLoginButtonPressed}>ログイン</LoginButton>
      </Container>
    </>
  )
};

const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const InputSection = styled.div`
  padding: 40px 60px 40px 12px;
  width: 30%;
  min-width: 300px;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

`
const LabelStyle = styled.h5`
  width:  160px;
  padding-right: 12px;
  font-size: ${FontSize.sm};
`

const HeaderStyle = styled.div`
  background-color: #3F3F3F;
  /* position: fixed; */
  left:0;
  top:0;
  height: 70px;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  border: -1px solid #CCCCCC;
  flex-direction: row;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  height: 50px;
  width: 120px;
  cursor: pointer;
  padding: 40px;
`

const LoginButton = styled.button`
  color: #ffffff;
  background-color: #70c66b;
  border: 2px solid #70c66b;
  border-radius: 28px;
  font-size: 12px;
  padding: 8px 40px;
  margin: 0 4px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #70c66b;
    background-color: #ffffff;
    border: 2px solid #70c66b;
  }
`

const LoginInput = styled.input`
border-radius: 4px;
padding: 12px;
border: 1px solid #ccc;
width: 100%;
`

export default Login;