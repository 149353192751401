import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
//configs & styles
import 'src/index.css';
import { Colors, BreakPoints, FontSize } from 'src/configs/StyleConfig';
//components
import DropDownSelection from 'src/common/DropDownSelection';
import Checkbox from "src/common/Checkbox";
import {TextInput} from 'src/common/Inputs';
import { InputLabelStyle } from 'src/common/StylesComponents';
//redux
import { useSelector } from 'react-redux';


const CustomerStatusList = (props) => {
  const { statusList,
          setStatusList,
          enterprise,
          dropdownSelection,
          mtb_sales_office_id,
          onChangeSelection,
          isEditable,
          isRegistering,
          customerCode,
        } = props;

  const { role } = useSelector(state => state.auth);

  const statusMapping = (item) => {
    if (!item.isSelected) return ;

    if (item.status === "対応不可" || item.status === "信販不可") {
      return (
        <CustomerStatusContainer color={Colors.statusRed} key={item.id.toString()}>
          {item.status}
        </CustomerStatusContainer>
      )
    }
    return (
      <CustomerStatusContainer color={Colors.statusBlue} key={item.id.toString()}>
        {item.status}
      </CustomerStatusContainer>
    )
  } 

  return (
    <Container>
      <StatusContainer>
        {
          statusList.map((item) => {
              return statusMapping(item)
          })
        }
      </StatusContainer>
      <StatusEditContainer>
        <StatusEditLeftSideContainer>
          {
            isRegistering ? 
            null
            :
            <div style={{ height: 30, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 4 }}>
              <InputLabelStyle align={'flex-start'} Selection>
                顧客No
              </InputLabelStyle>
              <TextInput value={customerCode}/>
            </div>
          }
          <SelectionContainer isRegistering>
            <div style={{ height: 30, minWidth: 240, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 12 }}>
              <InputLabelStyle align={'flex-start'} Selection >
                会社
              </InputLabelStyle>
              <TextInput value={enterprise}/>
            </div>
            <div style={{ height: 30, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <InputLabelStyle align={'flex-start'} Selection>
                営業所
              </InputLabelStyle>
              <div style={{ height: 28, width: '100%' }}>
                {
                  role === 3 ? //営業所権限
                  <DropDownSelection 
                    options={dropdownSelection} 
                    value={mtb_sales_office_id ? mtb_sales_office_id.toString() : "0"} 
                    onChange={onChangeSelection}
                    isEnable={false}
                  />
                :
                <DropDownSelection 
                  options={dropdownSelection} 
                  value={mtb_sales_office_id ? mtb_sales_office_id.toString() : "0"} 
                  onChange={onChangeSelection}
                  isEnable={isEditable}
                />
                }
              </div>
            </div>
          </SelectionContainer>
        </StatusEditLeftSideContainer>
        <StatusEditRightSideContainer>
          {
            statusList.map((item) => {
              return (
                <CheckBoxContainer key={item.id.toString()}>
                  <Checkbox
                    isChecked={item.isSelected}
                    changeStatus={() => isEditable && setStatusList(item.id)}
                    label={item.status}
                  />
                </CheckBoxContainer>
              )
            })
          }
        </StatusEditRightSideContainer>
      </StatusEditContainer>
    </Container>
  )
};


const Container = styled.div`
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`
const StatusContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const CustomerStatusContainer = styled.div`
  width: 150px;
  padding: 8px 12px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
  color: ${props => props.color && props.color};
  z-index:1;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
  border: 2.5px solid ${props => props.color && props.color};
  background-color: 'white';
  @media screen and (max-width: ${BreakPoints.lg}) {
    font-size: ${FontSize.md}
  }
`
const StatusEditContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 2.5px solid gray;
  border-bottom: 2.5px solid gray;
  padding: 12px 0;
  @media screen and (max-width: ${BreakPoints.md}) {
    height: 150px;
    flex-direction: column;
  }
`
const StatusEditLeftSideContainer = styled.div`
  max-width: 600px;
  min-width: 450px;
  width: 40%;
  height: 80%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${BreakPoints.md}) {
    width: 80%;
    height: 50%;
  }
`
const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${props => props.isRegistering ? "60px" : "12px"};
`
const StatusEditRightSideContainer = styled.div`
  max-width: 600px;
  min-width: 450px;
  width: 45%;
  height: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  @media screen and (max-width: ${BreakPoints.md}) {
    width: 250px;
    height: 50%;
  }
`
const CheckBoxContainer = styled.div`
  width: 120px;
  display: flex;
  align-items: flex-start;
`
CustomerStatusList.propTypes = {
  statusList: PropTypes.array.isRequired,
  setStatusList: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  enterprise: PropTypes.string.isRequired,
  dropdownSelection: PropTypes.array,
  mtb_sales_office_id: PropTypes.number,
  onChangeSelection: PropTypes.func,
  isRegistering: PropTypes.bool,
  customerCode: PropTypes.string,
};

CustomerStatusList.defaultProps = {
  statusList: [],
  setStatusList: () => {},
  isEditable: false,
  enterprise: "",
  dropdownSelection: [],
  mtb_sales_office_id: 0,
  onChangeSelection: () => {},
  isRegistering: false,
  customerCode: "",
};

export default CustomerStatusList;