import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//components
import MtbPaymentMethodInput from './children/MtbPaymentMethodInput';
import DialogMessage from 'src/common/DialogMessage';
//functions
import PaymentMethodFunctions from 'src/functions/mtbFunctions/PaymentMethodFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  支払い方法登録コンポーネント
  テキスト入力後、保存ボタンからDB送信、キャンセルボタンから入力を破棄して一覧ページへ戻る
*/
const MtbPaymentMethodRegister = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, mtb_enterprise_id, token } = useSelector(state => state.auth);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [errorText, setErrorText] = useState('');

  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = () => {
      const { state } = location;
      const { prevPath } = state;
      previousPath.current = prevPath;
      const basePaymentMethodData = {
        id: null,
        payment_method: '',
        is_deleted: 0,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      };
      return basePaymentMethodData;
    };
    if (employee_name && mtb_enterprise_id) {
      const _paymentMethod = getData();
      setPaymentMethod({..._paymentMethod});
    }
  }, [employee_name, mtb_enterprise_id, location]);

  const changePaymentMethodData = useCallback((key, value) => {
    const _paymentMethod = {...paymentMethod};
    if (value !== null) {
      _paymentMethod[key] = value;
      setPaymentMethod(_paymentMethod);
      if (!modalRequired.current) modalRequired.current = true;
    }
  }, [paymentMethod, modalRequired]);

  const onSaveButtonPressed = useCallback(async () => {
    const paymentMethodFunctions = new PaymentMethodFunctions({ paymentMethod: paymentMethod, token: token });
    const response = await paymentMethodFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current});
    }
  }, [paymentMethod, token, history, modalRequired, previousPath]);

  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <MtbPaymentMethodInput 
        data={paymentMethod}
        isEditing={true}
        errorText={errorText}
        changeData={changePaymentMethodData}
        onCancelButtonPressed={onCancelButtonPressed}
        onSaveButtonPressed={onSaveButtonPressed}
      />
    </>
  )
};

export default MtbPaymentMethodRegister;