import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//functions
import DeletingFunctions from 'src/functions/DeletingFunctions';
//components
import EnterpriseInputs from './children/EnterpriseInputs';
import DeleteConfirmModal from 'src/views/settings/children/DeleteConfirmModal';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { setPartnerData } from 'src/redux/actions/MasterDataHandler';

/*
  企業詳細コンポーネント
  編集ボタンから編集ページへ
  消去ボタンから消去確認モダル表示後に確認で企業データを消去、リストに戻る
*/
const MtbEnterpriseDetail = (props) => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const { partnerData } = useSelector(state => state.master);
  const [enterprise, setEnterprise] = useState({});
  const [partners, setPartners] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [modalRequired, setModalRequired] = useState(false);

  useEffect(() => {
    const {state} = props.location;
    const data = state && state.data;
    const { id } = data;
    dispatch(setPartnerData(id));
    setEnterprise({...data});
  }, [props, dispatch]);

  const initPartnerData = () => {
    if (Object.keys(partnerData).length > 0) {
      const partners = Object.keys(partnerData).map((id) => partnerData[id]);
      setPartners(partners);
    }
  };
  useMemo(initPartnerData, [partnerData]);

  const onEditButtonPressed = useCallback(() => {
    const {pathname, state} = location;
    history.push({pathname: `${pathname}/edit`, state: {data: state.data, partners: partners, prevPath: pathname}});
  }, [history, partners, location]);

  const onDeleteButtonPressed = useCallback(() => {
    setModalRequired(true);
  }, []);

  const deleteSelectedItem = useCallback(async () => {
    const { id } = enterprise;
    const tableName = '/mtb_enterprise';
    const deletingFunctions = new DeletingFunctions({tableName: tableName, id: id, token: token});
    const error = await deletingFunctions.delete();
    if (error) {
      setErrorText(error);
    } else {
      setErrorText('');
      history.replace('/settings/enterprise-list');
    }
  }, [history, token, enterprise]);

  return (
    <>
      <DeleteConfirmModal
        modalRequired={modalRequired}
        onCloseModalButtonPressed={() => setModalRequired(false)}
        onConfirmButtonPressed={deleteSelectedItem}
      />
      <EnterpriseInputs data={enterprise} isEditing={false} errorText={errorText} partners={partners} onEditButtonPressed={onEditButtonPressed} onDeleteButtonPressed={onDeleteButtonPressed} />
    </>
  )
};

export default MtbEnterpriseDetail;