import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ImageUploader from 'react-images-upload';
//components
import {TextInput, TextHalfNumberInput} from 'src/common/Inputs';
//styles and configs
import { Colors } from 'src/configs/StyleConfig';
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';

const PartnerInputs = (props) => {

  const { data, isEditing, errorText, changeData, onEditButtonPressed, onSaveButtonPressed, onCancelButtonPressed, onDeleteButtonPressed } = props;
  const { partner_code, partner_name, signed_url, label } = data;
  const fileInput = useRef(null);

  const changeImage = (e) => {
    if (e.target) {
      const imageFile = e.target.files[0];
      if (imageFile) {
        const imageURL = URL.createObjectURL(imageFile); // for preview
        changeData('signed_url', imageURL);
        e.target.value = null;
      }
    } else {
      const imageURL = URL.createObjectURL(e[0]); // for preview
      changeData('signed_url', imageURL);
    }
  };

  const onLogoButtonPressed = useCallback(() => {
    fileInput.current.click();
  }, [fileInput]);

  const displayImageHandler = (url) => {
    // ロゴURLがある時：　画像表示、otherwise：　uploaderの表示
    if (url) {
      return (
        <div>
          <input
            accept="image/jpeg,image/gif,image/png"
            id="contained-button-file"
            type="file"
            ref={fileInput}
            hidden={true}
            onChange={changeImage}
          />
          <div style={{ width: '300px', height: '150px', cursor: 'pointer' }} onClick={() => isEditing && onLogoButtonPressed()}>
            <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={url} alt={"LOGO"} />
          </div>
        </div>

      )
    } else {
      if (!isEditing) {
        return <div style={{ width: '100%', height: '160px', border: '1px solid gray', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 12 }}>No Logo</div>
      }
      return <ImageUploader
        withIcon={true}
        label="Upload a picture"
        multiple={false}
        onChange={changeImage}
      />
    }
  };

  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                提携先コード
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={partner_code ? partner_code : ''} onChange={(val) => changeData('partner_code', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                提携先名
              </SettingList>
              <TextInput isEnable={isEditing} value={partner_name ? partner_name : ''} onChange={(val) => changeData('partner_name', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                ロゴ
              </SettingList>
              {displayImageHandler(signed_url)}
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                指定工事会社表記
              </SettingList>
              <TextInput isEnable={isEditing} value={label ? label : ''} onChange={(val) => changeData('label', val.target.value)} />
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`

PartnerInputs.propTypes = {
  data: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  changeData: PropTypes.func,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

PartnerInputs.defaultProps = {
  data: {},
  isEditing: false,
  errorText: '',
  changeData: () => { },
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default PartnerInputs;