import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
//components
import { BaseBodyStyle, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import DialogMessage from 'src/common/DialogMessage';
import { ConstructionInfo, ConstructionContract, ConstructionBreakdown, CustomerStatusSection } from 'src/views/construction/common';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { removeSelectedConstructionDetailData } from 'src/redux/actions/ConstructionDataHandler';
import { updateSelectedDetailedCustomerData } from 'src/redux/actions/CustomerDataHandler';
//functions
import ConstructionFunctions, { getTaxRate } from 'src/functions/ConstructionFunctions';
import CustomerFunction from 'src/functions/CustomerFunctions';
import { logCategory, logTableType, sendLogData } from 'src/functions/LogFunctions';


const ConstructionRegister = () => {
  //set customer selections in the view to get selected customer id 
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const { state } = location;
  const { previousPath } = state;
  const dispatch = useDispatch();

  const { mtb_enterprise_id, employee_name, token, mtb_sales_office_id } = useSelector(state => state.auth);
  const { partnerData } = useSelector(state => state.master);
  const { selectedCustomerData } = useSelector(state => state.customer);

  const [constructionInfo, setConstructionInfo] = useState(
    {
      "契約情報": {
        support_date: null, contract_date: null, contract_no: '', serial_no: '', remarks: '',
        destination_name: '', destination_postal_code: '', destination_address: '', destination_phone: ''
      },
      "工事情報": {
        mtb_partner_id: '0', mtb_store_id: '0', construction_start_date: null, construction_end_date: null,
        mtb_payment_method_id: '0', payment_description: '', approval_number: '', is_agreement_form: 0, is_maintenance: 0, has_other_construction: 0,
        sales_employee_name1: '', sales_employee_name2: '', sales_employee_name3: '', sales_employee_name4: '',
        construction_employee_name1: '', construction_employee_name2: '', construction_employee_name3: '', construction_employee_name4: '',
        mtb_customer_id: '0', mtb_sales_office_id: '0'
      },
      "工事内訳": {
        tableContents: {
          breakdown: [],
          breakdownTotal: {
            subtotal: { label: '小計', value: 0 },
            tax_price: { label: '消費税額', value: 0 },
            total: { label: '税込額', value: 0 },
            subtotal_margin: { label: '小計 x マージン', value: 0 },
          }
        },
        taxInfo: {
          tax_rate: 0, tax_division: 1, rounding_division: 0, stamp_price: 0, stamp_amount: 0, memo: '', tax_exclude: 0, tax_include: 0
        }
      },
      "その他情報": {
        mtb_enterprise_id: null, created_employee_name: '', created_at: '', updated_employee_name: '', updated_at: '', margin: 0, is_exported: 0, exported_at: null, exported_employee_name: null
      }
    }
  );
  const [customerInfo, setCustomerInfo] = useState(
    {
      id: null, customer_code: '', enterprise_name: '', sales_office_name: '', customer_kana: '', customer_name: '',
      customer_address1: '', customer_address2: '', customer_postal_code: '', customer_phone1: '', customer_phone2: '',
      mtb_enterprise_id: null, mtb_sales_office_id: null,
      status: [
        { id: 0, status: '対応不可', isSelected: false },
        { id: 1, status: '信販不可', isSelected: false },
        { id: 2, status: '転居', isSelected: false },
        { id: 3, status: 'クレーム', isSelected: false }
      ]
    }
  );
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const partnerMargin = useRef(0);
  const modalRequired = useRef(false);
  const hasCustomerStatusChanged = useRef(false);


  useEffect(() => {
    const initConstructionData = async () => {
      //工事データに消費税率、従業員などを格納する処理
      setUserInfo();
      dispatch(removeSelectedConstructionDetailData());
      const taxRate = await getTaxRate(token);
      const _constructionInfo = { ...constructionInfo };
      _constructionInfo["工事内訳"]["taxInfo"]["tax_rate"] = taxRate;
      _constructionInfo["工事情報"]["mtb_sales_office_id"] = mtb_sales_office_id;
      setConstructionInfo(_constructionInfo);
      setIsLoading(false);
    }

    initConstructionData();
  }, [token]);

  const initConstructionStatus = () => {
    let initialCustomerInfo = { ...customerInfo };
    Object.keys(initialCustomerInfo).map((key) => {
      if (key === 'status') {
        const statusList = selectedCustomerData[key];
        const status = customerInfo['status'];
        const initialStatus = status.map((row) => {
          if (statusList.includes(row.id)) {
            return {
              ...row,
              isSelected: true
            };
          }
          return { ...row };
        });
        initialCustomerInfo[key] = initialStatus;
      } else if (key === 'enterprise_name') {
        initialCustomerInfo[key] = selectedCustomerData['enterprise'];
      } else if (key === 'sales_office_name') {
        initialCustomerInfo[key] = selectedCustomerData['sales_office'];
      } else {
        initialCustomerInfo[key] = selectedCustomerData[key]
      }
    });
    setCustomerInfo(initialCustomerInfo);
  };

  useEffect(() => {
    if (selectedCustomerData) {
      // mtb_customer_id = 選択された顧客のid
      // 施工先情報を選択された顧客情報を元に初期化
      const _constructionInfo = { ...constructionInfo };
      _constructionInfo["工事情報"].mtb_customer_id = selectedCustomerData.id;
      _constructionInfo["契約情報"].destination_name = selectedCustomerData.customer_name;
      _constructionInfo["契約情報"].destination_postal_code = selectedCustomerData.customer_postal_code;
      _constructionInfo["契約情報"].destination_address = selectedCustomerData.customer_address1 + " " + selectedCustomerData.customer_address2;
      _constructionInfo["契約情報"].destination_phone = selectedCustomerData.customer_phone1;
      setConstructionInfo(_constructionInfo);
      initConstructionStatus();
    }

  }, [selectedCustomerData]);


  const setUserInfo = () => {
    if (mtb_enterprise_id && employee_name) {
      const _constructionInfo = { ...constructionInfo };
      _constructionInfo["その他情報"].mtb_enterprise_id = parseInt(mtb_enterprise_id);
      _constructionInfo["その他情報"].created_employee_name = employee_name;
      _constructionInfo["その他情報"].updated_employee_name = employee_name;
      setConstructionInfo(_constructionInfo);
    }
  };

  const getPartnerMargin = useCallback((mtb_partner_id) => {
    if (partnerData) {
      const margin = partnerData[mtb_partner_id].margin ? parseInt(partnerData[mtb_partner_id].margin) : 0;
      const updatedTotal = { ...constructionInfo["工事内訳"].tableContents.breakdownTotal };
      updatedTotal['subtotal_margin']['value'] = updatedTotal['subtotal']['value'] * margin * 0.01;
      const _constructionInfo = { ...constructionInfo };
      _constructionInfo["その他情報"]['margin'] = margin;
      setConstructionInfo(_constructionInfo);
      changeTableInfo('breakdownTotal', updatedTotal);
      partnerMargin.current = margin;
    }
  }, [constructionInfo, partnerData]);

  const changeContractInfo = useCallback((key, val) => {
    // 契約情報のアップデート
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["契約情報"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  const changeConstructionInfo = useCallback((key, val) => {
    // 工事情報のアップデート
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["工事情報"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  // 工事内訳の内容変更関数
  const changeTableInfo = useCallback((key, val) => {
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["工事内訳"]["tableContents"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  // 工事税金変更関数
  const changeTaxInfo = useCallback((key, val) => {
    const _constructionInfo = { ...constructionInfo };
    _constructionInfo["工事内訳"]["taxInfo"][key] = val;
    setConstructionInfo(_constructionInfo);
  }, [constructionInfo]);

  // 顧客のステータス変更関数
  const changeCustomerStatus = useCallback((id) => {
    const _customerInfo = { ...customerInfo };
    _customerInfo['status'][id].isSelected = !_customerInfo["status"][id].isSelected;
    setCustomerInfo(_customerInfo);
    if (!hasCustomerStatusChanged.current) hasCustomerStatusChanged.current = true;
  }, [customerInfo]);

  const handleNavigation = useCallback((navigationKey) => {
    if (navigationKey === "FILE_UPLOAD") {
      //ファイル取り込みページへ推移
      history.push({ pathname: '/file-upload', state: { isEditable: true } });
    } else {
      history.push({ pathname: `${currentPath}/warranty`, state: { isEditable: true } });
    }
  }, []);

  const changeUnsavedStatus = () => {
    if (!modalRequired.current) modalRequired.current = true;
  };
  useMemo(changeUnsavedStatus, [constructionInfo]);

  const onSaveButtonPressed = useCallback(async () => {
    //ボタンを押した後に工事データ、工事詳細データをDBに登録する関数が走る。
    const constructionFunctions = new ConstructionFunctions({ constructionInfo: constructionInfo, deletingConstructionDetail: [], token: token });
    const response = await constructionFunctions.register();

    if (response.error) {
      setErrorText(response.error);
    } else {
      const { insertLogId } = response.logs;
      const constructionLog = {
        id: null,
        category: logCategory.register,
        remarks: '工事情報登録',
        mtb_enterprise_id: mtb_enterprise_id,
        table_type: logTableType.construction,
        log_id: insertLogId,
        created_employee_name: employee_name,
        updated_employee_name: employee_name
      }
      const json = [constructionLog];
      const error = await sendLogData(json, token);
      modalRequired.current = false;
      setErrorText('');
      if (hasCustomerStatusChanged.current) {
        const customerFunctions = new CustomerFunction({ customerInfo: customerInfo, token: token });
        const customerResponse = await customerFunctions.updateData();
        if (typeof customerResponse === "object" && customerResponse.error) {
          setErrorText(customerResponse.error);
        } else {
          const { insertLogId } = customerResponse.logs;
          const customerLog = {
            id: null,
            category: logCategory.update, //更新ログ
            remarks: '顧客情報更新',
            mtb_enterprise_id: mtb_enterprise_id,
            table_type: logTableType.customer, //顧客ログ
            log_id: insertLogId,
            created_employee_name: employee_name,
            updated_employee_name: employee_name
          };
          const json = [customerLog];
          const error = await sendLogData(json, token);
          dispatch(updateSelectedDetailedCustomerData(customerResponse.data));
        }
      }
      history.replace(previousPath);
    }
  }, [previousPath, constructionInfo, token, history, modalRequired, mtb_enterprise_id, employee_name]);


  const onCancelButtonPressed = useCallback(() => {
    history.replace(previousPath);
  }, [previousPath]);

  if (isLoading) {
    return (
      <BaseBodyStyle main />
    )
  }
  return (
    <BaseBodyStyle main>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <div style={{ width: '100%' }}>
        <div style={{ width: '83%', display: 'flex', margin: '0 auto' }}>
          <CustomerStatusSection status={customerInfo.status} changeStatus={changeCustomerStatus} />
        </div>
        <ConstructionContract isEditable={true} isRegister={true} contractInfo={constructionInfo["契約情報"]} changeInfo={changeContractInfo} />
        <ConstructionInfo
          isEditable={true}
          constructionInfo={constructionInfo["工事情報"]}
          changeInfo={changeConstructionInfo}
          getPartnerMargin={getPartnerMargin}
          mtb_enterprise_id={constructionInfo["その他情報"].mtb_enterprise_id}
        />
        <ConstructionBreakdown
          isEditable={true}
          tableContents={constructionInfo["工事内訳"].tableContents}
          taxInfo={constructionInfo["工事内訳"].taxInfo}
          changeTableInfo={changeTableInfo}
          changeTaxInfo={changeTaxInfo}
          handleNavigation={handleNavigation}
          partnerMargin={partnerMargin.current}
        />
        <span style={{ width: '100%', padding: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red' }}>
          {errorText && errorText}
        </span>
        <ButtonMenu>
          <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
        </ButtonMenu>
      </div>
    </BaseBodyStyle>
  )
};

const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 0;
`

export default ConstructionRegister;