import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { Colors, BreakPoints } from 'src/configs/StyleConfig';
import { CustomerInputContainer, CustomerInputLabelStyle } from "src/views/customer/common/CommonCustomerStyle";
//components
import { TextInput, TextHalfNumberInput, PostalCodeInput } from 'src/common/Inputs';


const showingCustomerInfo_Ja = {
  customer_kana: 'かな',
  customer_name: '氏名',
  customer_postal_code: '郵便番号',
  customer_address1: '住所',
  customer_phone1: '電話番号',
}

const CustomerInput = (props) => {
  //顧客登録/編集、顧客詳細ページで使用

  const { customerInfo, setCustomerInfo, isRegistering, isEditable } = props;

  return (
    <Container isRegistering={isRegistering}>
      <SectionContainer>
        <SectionHeader>施主</SectionHeader>
        {
          Object.keys(showingCustomerInfo_Ja).map((key) => {
            if (key === 'customer_postal_code') {
              return (
                <CustomerInputContainer key={key}>
                  <CustomerInputLabelStyle align={'start'}>
                    {showingCustomerInfo_Ja[key]}
                  </CustomerInputLabelStyle>
                  <PostalCodeInput 
                    isEnable={isEditable} 
                    value={customerInfo[key]} 
                    onChange={(e) => setCustomerInfo(key, e.target.value)} 
                    onChangeAddress={(address) => setCustomerInfo('customer_address1', address)} 
                  />
                </CustomerInputContainer>
              )
            } else if (key === 'customer_phone1') {
              return (
                <CustomerInputContainer key={key}>
                  <CustomerInputLabelStyle align={'start'}>
                    {showingCustomerInfo_Ja[key]}
                  </CustomerInputLabelStyle>
                  <TextHalfNumberInput isEnable={isEditable} value={customerInfo[key]} onChange={(e) => setCustomerInfo(key, e.target.value)}/>
                </CustomerInputContainer>
              )
            }
            return (
              <CustomerInputContainer key={key}>
                <CustomerInputLabelStyle align={'start'}>
                  {showingCustomerInfo_Ja[key]}
                </CustomerInputLabelStyle>
                <TextInput isEnable={isEditable} value={customerInfo[key]} onChange={(e) => setCustomerInfo(key, e.target.value)}/>
              </CustomerInputContainer>
            )
          })
        }
      </SectionContainer>
      <SectionContainer style={{ display: 'flex', alignItems: 'start' }}>
        <MemoLabel>
          メモ
        </MemoLabel>
        <MemoTextField disabled={!isEditable} value={customerInfo['memo'] ? customerInfo['memo'] : ""} onChange={(e) => isEditable && setCustomerInfo('memo', e.target.value)}/>
      </SectionContainer>
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  padding: ${props => props.isRegistering ? '20px' : '4px 0px'};
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: ${BreakPoints.lg}) {
    flex-direction: row;
  }
  @media screen and (max-width: ${BreakPoints.md}) {
    flex-direction: column;
  }
`
const SectionContainer = styled.div`
  width: 24%;
  max-width: 350px;
  min-width: 250px;
  @media screen and (max-width: ${BreakPoints.md}) {
    width: 60%;
  }
`
const SectionHeader = styled.span`
  color: ${Colors.textColor};
  font-weight: 800;
`
const MemoLabel = styled.span`
  padding-right: 12px;
  width: 88px;
`
const MemoTextField = styled.textarea`
  width: 100%;
  aspect-ratio: 1.6;
  padding: 4px;
`

CustomerInput.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  setCustomerInfo: PropTypes.func.isRequired,
  isRegistering: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

CustomerInput.defaultProps = {
  customerInfo: {
    '施主': {},
    'メモ': '',
  },
  setCustomerInfo: () => { },
  isRegistering: false,
  isEditable: false,
};

export default CustomerInput;
