import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//components
import ProductInputs from './children/ProductInputs';
import DialogMessage from 'src/common/DialogMessage';
//functions
import ProductFunctions from 'src/functions/mtbFunctions/PrtoductFunctions';
//redux
import { useSelector } from 'react-redux';

/*
  商品編集ページ
  テキスト入力後、DB送信もしくは入力を破棄して商品一覧画面へ推移
*/
const MtbProductEdit = (props) => {

  const history = useHistory();
  const { employee_name, token } = useSelector(state => state.auth);
  const [product, setProduct] = useState({});
  const [errorText, setErrorText] = useState('');
  const modalRequired = useRef(false);
  const previousPath = useRef('');

  useEffect(() => {
    const getData = () => {
      const {state} = props.location;
      const {data, prevPath} = state;
      data["updated_employee_name"] = employee_name;
      previousPath.current = prevPath;
      return data;
    };
    if (employee_name) {
      const _productData = getData();
      setProduct({..._productData});
    }
  }, [employee_name, props]);

/*
  state変更関数
*/
  const changeProductData = useCallback((key, val) => {
    const _product = {...product};
    let { value } = val.target;
    if (key === 'price' || key === 'jan_code') {
      const re = /^(\s*|[-0-9.。ー−１２３４５６７８９０]+)$/
      if (!re.test(value)) {
        value = 0;
      }
    }
    if (value !== null) {
      _product[key] = value;
      setProduct(_product);
      if (!modalRequired.current) modalRequired.current = true;
    } else {
      _product[key] = '';
      setProduct('');
    }
  }, [product, modalRequired]);

/*
  DB送信関数
*/
  const onSaveButtonPressed = useCallback(async () => {
    const productFunctions = new ProductFunctions({ productData: product, token: token });
    const response = await productFunctions.sendData();
    if (response.error) {
      setErrorText(response.error);
    } else {
      modalRequired.current = false;
      setErrorText('');
      history.replace({pathname: previousPath.current, state: {data: response.data}});
    }
  }, [product, token, history, modalRequired, previousPath]);

/*
  入力を破棄後、一覧ページへ推移
*/
  const onCancelButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <DialogMessage
        modalRequired={modalRequired}
        text={`
          編集中のデータがあります。\n
          続行すると編集中の内容は全て破棄されます。
        `}
      />
      <ProductInputs 
        data={product}
        isEditing={true}
        errorText={errorText}
        onChange={changeProductData}
        onSaveButtonPressed={onSaveButtonPressed} 
        onCancelButtonPressed={onCancelButtonPressed}
      />
    </>
  )
};

export default MtbProductEdit;