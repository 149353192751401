import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//styles & configs
import { SearchFieldContainer } from 'src/common/StylesComponents';
import { BreakPoints } from 'src/configs/StyleConfig';
//components
import Checkbox from 'src/common/Checkbox';

const sortingOption = [
  { value: 0, label: '作成日（新）' }, //created_at
  { value: 1, label: '作成日（古）' }, 
  { value: 2, label: '対応日（新）' }, //support_date
  { value: 3, label: '対応日（古）' },
  { value: 4, label: '契約日（新）' }, //contract_date
  { value: 5, label: '契約日（古）' },
  { value: 6, label: '工事完了日（新）' }, //construction_end_date
  { value: 7, label: '工事完了日（古）' },
  { value: 8, label: '最終更新日（新）'}, //updated_at
  { value: 9, label: '最終更新日（古）'},
];

const ConstructionListSearchField = (props) => {
  const { onSearchClear, SortSelected, setModalIsOpen, sortType, checkedState, setCheckedState, setCheckedIsExported, checkedIsExported, searchResult } = props;

  return (
    <>
      <ConstructionListSearchFieldStyle />
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '96%' }}>
        <div>
          <NarrowDownButton onClick={() => setModalIsOpen(true)}><i className="fa fa-search-plus" style={{ paddingRight: '16px', fontSize: '12px' }} />条件絞込検索</NarrowDownButton>
          <ShowAllButton onClick={onSearchClear}>全て表示</ShowAllButton>
          <span style={{ color: '#3f3f3f', fontWeight: 'bold', padding: '12px' }}>{searchResult == null ? "" : "絞込検索該当件数： " + searchResult?.length + "件"}</span>
        </div>
        <div style={{ display: 'flex', marginTop: '4px', textAlign: 'right' }}>
          <CheckBoxWrapper style={{ paddingBottom: '4px', marginRight: '16px' }}>
            <Checkbox
              isChecked={checkedState}
              changeStatus={() => setCheckedState(prev => !prev)}
              label={'みずほ用データのみ絞込'}
            />
            <Checkbox
              isChecked={checkedIsExported}
              changeStatus={() => setCheckedIsExported(prev => !prev)}
              label={'CSV未出力'}
            />
          </CheckBoxWrapper>
          <span style={{ color: '#3f3f3f', fontWeight: 'bold', padding: '10px 8px' }}>並び替え</span>
          <select value={sortType} style={{ height: "28px", margin: '8px 4px 0px 4px' }} onChange={(e) => SortSelected(e)}>
            {sortingOption.map((key, index) => {
              return (
                <option key={index} value={key.value}>{key.label}</option>
              )
            })}
          </select>
        </div>
      </div >
    </>
  )
};

ConstructionListSearchField.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearchClicked: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired
};

ConstructionListSearchField.defaultProps = {
  setSearchText: () => { },
  searchText: "",
  onSearchClicked: () => { },
  onSearchClear: () => { }
};

const ConstructionListSearchFieldStyle = styled(SearchFieldContainer)`
  min-width: 680px;
  padding-top: 40px;
  @media screen and (max-width: ${BreakPoints.lg}) {
    height: 380px;
    flex-direction: column;
    overflow-x: auto;
    display: inline;
  }
`

const NarrowDownButton = styled.button`
  width: 160px;
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  padding: 12px 24px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

const ShowAllButton = styled.button`
  color: #ffffff;
  background-color: #3f3f3f;
  border: 2px solid #3f3f3f;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 12px 28px;
  font-weight: bold;
  margin: 2px 4px;
  transition: 0.3s;
  :hover {
    color: #3f3f3f;
    background-color: #ffffff;
    border: 2px solid #3f3f3f;
  }
`

export const SearchInputContainer = styled.div`
  color: #3f3f3f;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 8px 4px 40px;
`

const CheckBoxWrapper = styled.div`
  display: flex;
  min-width: 60px;
`

export default ConstructionListSearchField;