import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//components
import { TextInput, TextHalfNumberInput, PostalCodeInput } from 'src/common/Inputs';
import styled from 'styled-components';
import { MtbContainer, MtbButtonMenu, SubmitButton, CancelButton } from 'src/common/StylesComponents';
import { Colors } from 'src/configs/StyleConfig';
import DropDownSelection from 'src/common/DropDownSelection';
//redux
import { useSelector } from 'react-redux';

const SalesOfficeInputs = (props) => {

  const { data, onChange, isEditing, errorText, onSaveButtonPressed, onCancelButtonPressed, onEditButtonPressed, onDeleteButtonPressed } = props;
  const { 
    sales_office_code,
    sales_office_name,
    postal_code, address,
    phone,
    label,
    mtb_enterprise_id,
    examination_content,
    examination_duration,
    holiday,
    reception_time,
    fts_enterprise_sales_office_name,
    fts_phone
  } = data;

  const { enterpriseData } = useSelector(state => state.master);
  const { role } = useSelector(state => state.auth);
  const [enterpriseOptions, setEnterpriseOptions] = useState();

  useEffect(() => {
    const getEnterpriseOptions = () => {
      const options = Object.keys(enterpriseData).map((key) => {
        return {
          value: key.toString(),
          label: enterpriseData[key].enterprise_name
        };
      });
      const empty = { value: '0', label: ' ' };
      return [empty, ...options];
    };


    if (enterpriseData && role === 1) {
      const enterpriseOptionsData = getEnterpriseOptions();
      setEnterpriseOptions(enterpriseOptionsData);
    };

  }, [enterpriseData, role]);

  const onChangeTextArea = (e) => {
    const { value } = e.target;
    if (value) {
      let lines = value.split('\n').length;
      if (lines < 3) {
        onChange('examination_content', value);
      }
    } else {
      onChange('examination_content', "");
    }
  };

  const onChangeRestrictedInput = (key, val) => {

    if (val.length <= 26) {
      onChange(key, val);
    }
  };


  return (
    <MtbContainer>
      <div style={{ 'padding': '36px 60px' }}>
        <SettingFlexCenter>
          <div>
            <SettingFlex>
              <SettingList>
                営業所コード
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={sales_office_code} onChange={(val) => onChange('sales_office_code', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                営業所名
              </SettingList>
              <TextInput isEnable={isEditing} value={sales_office_name} onChange={(val) => onChange('sales_office_name', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                郵便番号
              </SettingList>
              <PostalCodeInput
                isEnable={isEditing}
                value={postal_code}
                onChange={(val) => onChange('postal_code', val.target.value)}
                onChangeAddress={(address) => onChange('address', address)}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                住所
              </SettingList>
              <TextInput isEnable={isEditing} value={address} onChange={(val) => onChange('address', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                電話番号
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={phone} onChange={(val) => onChange('phone', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                郵便局名
              </SettingList>
              <TextInput isEnable={isEditing} value={label} onChange={(val) => onChange('label', val.target.value)} />
            </SettingFlex>
            {
              role === 1 ?
                <SettingFlex style={{padding: '4px 0'}}>
                  <SettingList>
                    企業
                  </SettingList>
                  <div style={{width: '100%'}}>
                  <DropDownSelection
                    options={enterpriseOptions}
                    value={mtb_enterprise_id}
                    onChange={(val) => onChange('mtb_enterprise_id', val.target.value)}
                    isEnable={isEditing}
                  />
                  </div>
                </SettingFlex>
                :
                null
            }
          </div>
        </SettingFlexCenter>
        <SettingFlexCenter>
          <div>
            <SettingFlex style={{margin: '4px 0'}}>
              <SettingList>
                点検はがき用設定
              </SettingList>
            </SettingFlex>
            <SettingFlex style={{ alignItems: 'self-start' }}>
              <SettingList style={{ marginTop: '-2px' }}>
                検査内容
              </SettingList>
              <TextArea
                id="examination_content"
                maxLength="52"
                cols="30"
                rows="2"
                disabled={!isEditing}
                value={examination_content ? examination_content : ""} 
                onChange={onChangeTextArea}
              />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                検査時間
              </SettingList>
              <TextInput isEnable={isEditing} value={examination_duration} onChange={(val) => onChangeRestrictedInput('examination_duration', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                定休日
              </SettingList>
              <TextInput isEnable={isEditing} value={holiday} onChange={(val) => onChangeRestrictedInput('holiday', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                電話受付時間
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={reception_time} onChange={(val) => onChangeRestrictedInput('reception_time', val.target.value)} />
            </SettingFlex>
            <SettingFlex style={{margin: '4px 0'}}>
              <SettingList>
                フマキラー出力時の設定
              </SettingList>
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                会社名・営業所名
              </SettingList>
              <TextInput isEnable={isEditing} value={fts_enterprise_sales_office_name} onChange={(val) => onChange('fts_enterprise_sales_office_name', val.target.value)} />
            </SettingFlex>
            <SettingFlex>
              <SettingList>
                電話番号
              </SettingList>
              <TextHalfNumberInput isEnable={isEditing} value={fts_phone} onChange={(val) => onChangeRestrictedInput('fts_phone', val.target.value)} />
            </SettingFlex>
          </div>
        </SettingFlexCenter>
      </div>
      <span style={{ color: Colors.errorText, marginBottom: '8px' }}> {errorText && errorText}</span>
      {
        isEditing ?
          <MtbButtonMenu>
            <CancelButton onClick={onCancelButtonPressed}>キャンセル</CancelButton>
            <SubmitButton onClick={onSaveButtonPressed}>保存</SubmitButton>
          </MtbButtonMenu>
          :
          <MtbButtonMenu>
            <CancelButton onClick={onDeleteButtonPressed}>消去</CancelButton>
            <SubmitButton onClick={onEditButtonPressed}>編集</SubmitButton>
          </MtbButtonMenu>
      }
    </MtbContainer>
  );
};

const SettingFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40vw;
`

const SettingList = styled.div`
  width: 32%;
  margin: 8px 0;
`
const TextArea = styled.textarea`
  width: 100%;
  padding: 4px;
  /* height: 40px; */
  resize: none;
  :disabled {
    background-color: white;
    border-radius: 2px;
    border: 1px solid ${Colors.textColor};
    color: ${Colors.textColor};
  }
  ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }  
`
SalesOfficeInputs.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
  errorText: PropTypes.string.isRequired,
  onSaveButtonPressed: PropTypes.func,
  onCancelButtonPressed: PropTypes.func,
  onEditButtonPressed: PropTypes.func,
  onDeleteButtonPressed: PropTypes.func
};

SalesOfficeInputs.defaultProps = {
  data: {},
  onChange: () => { },
  isEditing: true,
  errorText: '',
  onSaveButtonPressed: () => { },
  onCancelButtonPressed: () => { },
  onEditButtonPressed: () => { },
  onDeleteButtonPressed: () => { }
};

export default SalesOfficeInputs;