import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { BreakPoints } from 'src/configs/StyleConfig';

import { PrivateRoute } from 'src/views/PrivateRoute';

//views
import Login from 'src/views/authentication/Login';
//customer
import CustomerList from 'src/views/customer/customerList/CustomerList';
import CustomerDetail from 'src/views/customer/customerDetail/CustomerDetail';
import CustomerRegister from 'src/views/customer/customerRegistration/CustomerRegister';
import CustomerEdit from 'src/views/customer/customerEdit/CustomerEdit';
//construction
import ConstructionList from 'src/views/construction/constructionList/ConstructionList';
import ConstructionDetail from 'src/views/construction/constructionDetail/ConstructionDetail';
import ConstructionDetailEdit from 'src/views/construction/constructionDetailEdit/ConstructionDetailEdit';
import ConstructionRegister from 'src/views/construction/constructionRegister/ConstructionRegister';
//customer support
import CustomerSupportDetail from 'src/views/customerSupport/customerSupportDetail/CustomerSupportDetail';
import CustomerSupportEdit from 'src/views/customerSupport/customerSupportEdit/CustomerSupportEdit';
import CustomerSupportRegister from 'src/views/customerSupport/customerSupportRegistration/CustomerSupportRegistration';
//warranty
import Warranty from 'src/views/warranty/warranty/Warranty';
import WarrantyEdit from 'src/views/warranty/warranty/WarrantyEdit';
import DamageWarrantyDetail from 'src/views/warranty/warrantyDetail/DamageWarrantyDetail';
import TermiteAntifungalWarrantyDetail from 'src/views/warranty/warrantyDetail/TermiteAntifungalWarrantyDetail';
import TermiteAntifungalWarrantyEdit from 'src/views/warranty/warrantiesEdit/TermiteAntifungalWarrantyEdit';
import DamageWarrantyEdit from 'src/views/warranty/warrantiesEdit/DamageWarrantyEdit';
import WarrantyList from 'src/views/warranty/warrantyList/WarrantyList';
//fileUpload
import FileUpload from 'src/views/fileUpload/FileUpload';
import FileUploadEdit from 'src/views/fileUpload/FileUploadEdit';
//other
import MtbNavigation from 'src/views/settings/MtbNavigation';
import MtbSalesOfficeList from 'src/views/settings/mtbSalesOffice/MtbSalesOfficeList';
import MtbSalesOfficeDetail from 'src/views/settings/mtbSalesOffice/MtbSalesOfficeDetail';
import MtbSalesOfficeEdit from 'src/views/settings/mtbSalesOffice/MtbSalesOfficeEdit';
import MtbSalesOfficeRegister from 'src/views/settings/mtbSalesOffice/MtbSalesOfficeRegister';
import MtbPaymentMethodList from 'src/views/settings/mtbPaymentMethods/MtbPaymentMethodList';
import MtbPaymentMethodDetail from './settings/mtbPaymentMethods/MtbPaymentMethodDetail';
import MtbPaymentMethodEdit from './settings/mtbPaymentMethods/MtbPaymentMethodEdit';
import MtbPaymentMethodRegister from './settings/mtbPaymentMethods/MtbPaymentMethodRegister';
import MtbProductFavoriteDetail from './settings/mtbProductFavorite/MtbProductFavoriteDetail';
import MtbProductFavoriteEdit from './settings/mtbProductFavorite/MtbProductFavoriteEdit';
import MtbProductList from './settings/mtbProduct/MtbProductList';
import MtbProductDetail from './settings/mtbProduct/MtbProductDetail';
import MtbProductEdit from './settings/mtbProduct/MtbProductEdit';
import MtbProductRegister from './settings/mtbProduct/MtbProductRegister';
import MtbTaxList from './settings/mtbTax/MtbTaxList';
import MtbTaxDetail from './settings/mtbTax/MtbTaxDetail';
import MtbTaxEdit from './settings/mtbTax/MtbTaxEdit';
import MtbTaxRegister from './settings/mtbTax/MtbTaxRegister';
import MtbEnterpriseList from './settings/mtbEnterprise/MtbEnterpriseList';
import MtbEnterpriseRegister from './settings/mtbEnterprise/MtbEnterpriseRegister';
import MtbEnterpriseEdit from './settings/mtbEnterprise/MtbEnterpriseEdit';
import MtbEnterpriseDetail from './settings/mtbEnterprise/MtbEnterpriseDetail';
import MtbPartnerList from './settings/mtbPartner/MtbPartnerList';
import MtbPartnerEdit from './settings/mtbPartner/MtbPartnerEdit';
import MtbPartnerDetail from './settings/mtbPartner/MtbPartnerDetail';
import MtbPartnerRegister from './settings/mtbPartner/MtbPartnerRegister';
import MtbStoreList from './settings/mtbStore/MtbStoreList';
import MtbStoreDetail from './settings/mtbStore/MtbStoreDetail';
import MtbStoreEdit from './settings/mtbStore/MtbStoreEdit';
import MtbStoreRegister from './settings/mtbStore/MtbStoreRegister';
import MtbEmployeeList from 'src/views/settings/mtbEmployee/MtbEmployeeList';
import MtbEmployeeDetail from './settings/mtbEmployee/MtbEmployeeDetail';
import MtbEmployeeEdit from './settings/mtbEmployee/MtbEmployeeEdit';
import MtbEmployeeRegister from './settings/mtbEmployee/MtbEmployeeRegister';
import MtbCustomerSupportList from './settings/mtbCustomerSupport/MtbCustomerSupportList';
import MtbCustomerSupportRegister from './settings/mtbCustomerSupport/MtbCustomerSupportRegister';
import MtbCustomerSupportEdit from './settings/mtbCustomerSupport/MtbCustomerSupportEdit';
import MtbCustomerSupportDetail from './settings/mtbCustomerSupport/MtbCustomerSupportDetail';

import SalesAggregation from 'src/views/salesAggregation/SalesAggregation';
import ChangingLogList from 'src/views/log/logList/ChangingLogList';
import ChangeLogDetail from './log/logDetail/ChangeLogDetail';

import Canvas from 'src/common/Canvas';
import NotFound from 'src/views/NotFound';

import Header from 'src/common/Header';
import Sidebar from 'src/common/Sidebar';

const FTSonly = [1];　//FTS権限のみ（FTS権限のみ）
const FTSandEnterprise = [1, 2];　//企業権限以上（企業権限とFTS権限のみ）
const allRoles = [1, 2, 3];　//営業所権限以上(全ての権限)

const Router = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return "Loading";
  }

  return (
    <BrowserRouter>
      {
        isLoggedIn ?
          <>
            <Header />
            <Sidebar />
          </>
          : null
      }
      <Wrapper isLoggedIn={isLoggedIn}>
        <Switch>
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path="/">
            <Redirect to="/customer-list" />
          </PrivateRoute>
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-list' component={CustomerList} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-list/register' component={CustomerRegister} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-list/:customer_code' component={CustomerDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-list/:customer_code/edit' component={CustomerEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list' component={ConstructionList} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/register' component={ConstructionRegister} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id' component={ConstructionDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/edit' component={ConstructionDetailEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/warranty' component={Warranty} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/warranty/edit' component={WarrantyEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/warranty/:warranty_id/damage-warranty-detail' component={DamageWarrantyDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/warranty/:warranty_id/termite-antifungal-warranty-detail' component={TermiteAntifungalWarrantyDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/warranty/edit/:warranty_id/damage-warranty-edit' component={DamageWarrantyEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/construction-list/:construction_id/warranty/edit/:warranty_id/termite-antifungal-warranty-edit' component={TermiteAntifungalWarrantyEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/warranty-list' component={WarrantyList} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/warranty-list/:warranty_id/damage-warranty-detail' component={DamageWarrantyDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/warranty-list/:warranty_id/termite-antifungal-warranty-detail' component={TermiteAntifungalWarrantyDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/file-upload' component={FileUpload} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/file-upload/edit' component={FileUploadEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-support' component={CustomerSupportDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-support/register' component={CustomerSupportRegister} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-support/:support_id' component={CustomerSupportDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/customer-support/:support_id/edit' component={CustomerSupportEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/sales-aggregation' component={SalesAggregation} />
          
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings' component={MtbNavigation} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/sales-office-list' component={MtbSalesOfficeList} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/sales-office-list/register' component={MtbSalesOfficeRegister} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/sales-office-list/:sales_office_id' component={MtbSalesOfficeDetail} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/sales-office-list/:sales_office_id/edit' component={MtbSalesOfficeEdit} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/employee-list' component={MtbEmployeeList} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/employee-list/register' component={MtbEmployeeRegister} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/employee-list/:employee_id' component={MtbEmployeeDetail} />
          <PrivateRoute acceptedRoles={FTSandEnterprise} exact path='/settings/employee-list/:employee_id/edit' component={MtbEmployeeEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/payment-method-list' component={MtbPaymentMethodList} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/payment-method-list/register' component={MtbPaymentMethodRegister} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/payment-method-list/:payment_method_id' component={MtbPaymentMethodDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/payment-method-list/:payment_method_id/edit' component={MtbPaymentMethodEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/product-favorite' component={MtbProductFavoriteDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/product-favorite/edit' component={MtbProductFavoriteEdit} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/product-list' component={MtbProductList} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/product-list/register' component={MtbProductRegister} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/product-list/:product_id' component={MtbProductDetail} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/product-list/:product_id/edit' component={MtbProductEdit} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/tax-list' component={MtbTaxList} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/tax-list/register' component={MtbTaxRegister} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/tax-list/:tax_id' component={MtbTaxDetail} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/tax-list/:tax_id/edit' component={MtbTaxEdit} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/enterprise-list' component={MtbEnterpriseList} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/enterprise-list/register' component={MtbEnterpriseRegister} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/enterprise-list/:enterprise_id' component={MtbEnterpriseDetail} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/enterprise-list/:enterprise_id/edit' component={MtbEnterpriseEdit} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/partner-list' component={MtbPartnerList} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/partner-list/register' component={MtbPartnerRegister} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/partner-list/:partner_id' component={MtbPartnerDetail} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/partner-list/:partner_id/edit' component={MtbPartnerEdit} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/store-list' component={MtbStoreList} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/store-list/register' component={MtbStoreRegister} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/store-list/:store_id' component={MtbStoreDetail} />
          <PrivateRoute acceptedRoles={FTSonly} exact path='/settings/store-list/:store_id/edit' component={MtbStoreEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/customer-support/:category_id' component={MtbCustomerSupportList} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/customer-support/:category_id/register' component={MtbCustomerSupportRegister} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/customer-support/:category_id/:customer_support_id' component={MtbCustomerSupportDetail} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/settings/customer-support/:category_id/:customer_support_id/edit' component={MtbCustomerSupportEdit} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/logs' component={ChangingLogList} />
          <PrivateRoute acceptedRoles={allRoles} exact path='/logs/:log_id' component={ChangeLogDetail} />

          <PrivateRoute acceptedRoles={allRoles} exact path='/test' component={Canvas} />
          <PrivateRoute component={NotFound} />
        </Switch>
      </Wrapper>
    </BrowserRouter >
  );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 0;
  height: 90%;
  overflow: scroll;
  ${props => props.isLoggedIn ?
    css`
      top: 72px;
      left: 200px;
      right: 0;
      @media screen and (max-width: ${BreakPoints.xl}) {
        left: 0;
      }
    `
    :
    css`
      top: 0;
      left: 0;
      right: 0;
    `
  }
`

export default Router;
